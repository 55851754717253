import * as React from 'react';
import { StyledBadgeAvatar } from 'kerberus-components';
import { useTheme } from '@mui/material';

export default function ProfileIcon({ avatarWidth, avatarHeight, isSmall, userId, lastseenminutes, onlineData }) {
    const theme = useTheme();
    const statusColor = onlineData?.status === 'busy'
        ? theme.palette.mlRed.main
        : onlineData?.status === 'hidden'
            ? '#adadb8'
            : lastseenminutes <= 10 && lastseenminutes >= 5
                ? theme.palette.mlYellow.main
                : lastseenminutes > 0 && lastseenminutes < 5
                    ? theme.palette.mlGreen.main
                    : '#adadb8';
    const statusText = onlineData?.status === 'busy'
        ? 'Busy'
        : onlineData?.status === 'hidden'
            ? 'Hidden'
            : lastseenminutes <= 10 && lastseenminutes >= 5
                ? 'Away'
                : lastseenminutes && lastseenminutes > -5 && lastseenminutes < 5
                    ? 'Active'
                    : 'Offline';
    return (
        <StyledBadgeAvatar
            tooltipContent={statusText}
            tooltipPlacement="bottom-end"
            badgeOverlap="circular"
            badgeAnchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeVariant="dot"
            badgeheight={30}
            badgewidth={30}
            badgeAnimation={onlineData?.status !== 'busy' && lastseenminutes ? lastseenminutes > -5 && lastseenminutes < 5 : false}
            badgeboxshadow={`0 0 0 2px ${theme.palette.background.paper}`}
            badgeSx={{
                width: avatarWidth,
                height: avatarHeight,
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
            }}
            avatarSx={{
                width: avatarWidth,
                height: avatarHeight,
                borderWidth: 7,
                borderStyle: "solid",
                borderColor: statusColor
            }}
            avatarVariant="circular"
            alt="ProfileIcon"
            imgSrc={userId}
            lastseenminutes={lastseenminutes}
            busy={onlineData?.status === 'busy'}
            imageSize="_256"
        />
    );
}