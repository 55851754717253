import React, { useState } from 'react';
import { Box, Button, Typography, Divider, Step, Stepper, StepLabel, Grid} from '@mui/material';
import Toast from '../Toast';
import { useNavigate } from 'react-router-dom';
import ProfileSettings from './ProfileSettings';
import AccountSettings from './AccountSettings';
import DataSharing from './DataSharing';

const contentLocation = <><div>Allow your location to be shown to other users instead of last seen time.</div></>;
const contentPublic = <><div>Allow profile usage in info dashboard.</div></>;
const contentPublicSocial = <><div>Allow profile visibility in your company's social list.</div></>;
const contentPublicCalendar = <><div>Allow other users to see your calendar.</div></>;
const contentPushNotification = <><div>Enable to receive push notifications. You need to have notifications enabled on the device you want to receive them on.</div></>;
const contentAllowTracking = <><div>Allow users to track your online activity.<br /> Other users can setup one time notifications.</div></>;

const steps = ['Account settings', 'Profile settings', 'Data sharing'];

function usernameValidation(val) {
    return /\s/g.test(val);
};

export default function AccountCreateStepper({
    isSmall,
    token
}) {
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [first_name, setFirst_name] = useState('');
    const [last_name, setLast_name] = useState('');
    const [title, setTitle] = useState('');
    const [tz, setTz] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [location_enabled, setLocation_enabled] = useState(false);
    const [push_enabled, setPush_enabled] = useState(false);
    const [public_social, setPublic_social] = useState(false);
    const [public_dashboard, setPublic_dashboard] = useState(false);
    const [public_calendar, setPublic_calendar] = useState(false);
    const [allow_tracking, setAllow_tracking] = useState(false);
    const [termsValid, setTermsValid] = useState(false);

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());


    const isStepOptional = (step) => {
        return step === null;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /*     const handleSkip = () => {
            if (!isStepOptional(activeStep)) {
                // You probably want to guard against something like this,
                // it should never occur unless someone's actively trying to break something.
                throw new Error("You can't skip a step that isn't optional.");
            }
    
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped((prevSkipped) => {
                const newSkipped = new Set(prevSkipped.values());
                newSkipped.add(activeStep);
                return newSkipped;
            });
        };
    
        const handleReset = () => {
            setActiveStep(0);
        }; */

    const handleSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        try {
            var response = await fetch(process.env.REACT_APP_AUTH_HOST + '/api/users/create_user', {
                method: 'POST',
                body: JSON.stringify({
                    username: username,
                    password: newPassword,
                    first_name: first_name,
                    last_name: last_name,
                    title: title,
                    tz: tz,
                    location_enabled: location_enabled,
                    push_enabled: push_enabled,
                    public_social: public_social,
                    public: public_dashboard,
                    public_calendar: public_calendar,
                    allow_tracking: allow_tracking,
                    terms_accepted: termsValid,
                    token: token
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            });
            if (response.ok) {
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
                localStorage.removeItem('userId');
                handleNext();
                setSuccess(true);
            }
            else {
                const text = await response.json();
                setError(text);
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    const AccountValid = ((!newPassword || !repeatPassword || !username) || (newPassword !== repeatPassword)
        || usernameValidation(username) || usernameValidation(newPassword) || usernameValidation(repeatPassword));

    const ProfileValid = ((!first_name || !last_name));

    return (
        <Grid sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column", minWidth: "30vw" }}>
                <Typography component={"div"} variant={isSmall ? "caption" : "overline"} sx={{ fontSize: 18, display: "flex", alignItems: "center", justifyContent: "center", mt: 3 }}>
                    Create account
                </Typography>
                <Divider sx={{ width: "100%" }} />
                {activeStep === steps.length ?
                    (
                        <>
                            <Typography textAlign="center" sx={{ mt: 2, mb: 1 }}>
                                All steps completed - Account has been created
                            </Typography>
                        </>
                    )
                    :
                    activeStep === 1 ?
                        (
                            <ProfileSettings
                                isSmall={isSmall}
                                first_name={first_name}
                                setFirst_name={setFirst_name}
                                last_name={last_name}
                                setLast_name={setLast_name}
                                title={title}
                                setTitle={setTitle}
                                tz={tz}
                                setTz={setTz}
                            />
                        )
                        :
                        activeStep === 2 ?
                            (
                                <DataSharing
                                    isSmall={isSmall}
                                    location_enabled={location_enabled}
                                    setLocation_enabled={setLocation_enabled}
                                    contentLocation={contentLocation}
                                    push_enabled={push_enabled}
                                    setPush_enabled={setPush_enabled}
                                    contentPushNotification={contentPushNotification}
                                    public_social={public_social}
                                    setPublic_social={setPublic_social}
                                    contentPublicSocial={contentPublicSocial}
                                    public_dashboard={public_dashboard}
                                    setPublic_dashboard={setPublic_dashboard}
                                    contentPublic={contentPublic}
                                    public_calendar={public_calendar}
                                    setPublic_calendar={setPublic_calendar}
                                    contentPublicCalendar={contentPublicCalendar}
                                    allow_tracking={allow_tracking}
                                    setAllow_tracking={setAllow_tracking}
                                    contentAllowTracking={contentAllowTracking}
                                    termsValid={termsValid}
                                    setTermsValid={setTermsValid}
                                />
                            )
                            :
                            (
                                <AccountSettings
                                    isSmall={isSmall}
                                    username={username}
                                    setUsername={setUsername}
                                    usernameValidation={usernameValidation}
                                    newPassword={newPassword}
                                    setNewPassword={setNewPassword}
                                    repeatPassword={repeatPassword}
                                    setRepeatPassword={setRepeatPassword}
                                />
                            )
                }
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4, justifyContent: "space-between", width: "100%" }}>

                    {activeStep === steps.length ?
                        <Box sx={{ display: 'flex', mb: 2, alignItems: "center", justifyContent: "center", width: "100%" }}>
                            <Button variant="contained"
                                onClick={() => navigate('/login', {
                                    state: {
                                        toRoot: true
                                    }
                                })}
                            >
                                Login
                            </Button>
                        </Box>
                        :

                        <>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                variant="outlined"
                            >
                                Back
                            </Button>

                            {activeStep === steps.length - 1
                                ?
                                <Button disabled={AccountValid || ProfileValid || !termsValid}
                                    variant="contained" onClick={handleSubmit}>
                                    Create account
                                </Button>
                                :
                                activeStep === 1 ?
                                    <Button variant="contained" onClick={handleNext}
                                        disabled={ProfileValid}
                                    >
                                        Next
                                    </Button>
                                    :
                                    <Button variant="contained" onClick={handleNext}
                                        disabled={AccountValid}
                                    >
                                        Next
                                    </Button>
                            }
                        </>
                    }
                </Box>
            </Box>

            <Toast
                sx={{}}
                type="error"
                title={error?.data}
                message={error?.hint?.split(";")[0]}
                message2={error?.hint?.split(";")[1]}
                verticalPos="bottom"
                horizontalPos="center"
                open={error}
                handleClose={() => setError(false)}
                autoHideDuration={5000}
            />

            <Toast
                sx={{}}
                type="success"
                title="Success"
                message="Account has been created"
                verticalPos="bottom"
                horizontalPos="center"
                open={success}
                handleClose={() => setSuccess(false)}
                autoHideDuration={5000}
            />

        </Grid >
    );
}