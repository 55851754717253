import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearLoading({ data }) {
    const [progress, setProgress] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        if (timerRef.current) {
            setProgress(0);
            clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(() => {
            setProgress(old => old + 100 / 60 / 10);
        }, 100);

        return () => {
            if (timerRef.current) clearInterval(timerRef.current);
        };

    }, [data]);

    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progress}
                sx={{
                    height: 10,
                    "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#1976d2",
                    },
                }}
            />
        </Box>
    );
}