import { useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeviceCalendar from './DeviceCalendar';
import DeviceView from './DeviceView';
import LayoutPaper from '../../Components/LayoutPaper';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import { useTheme, Box, Typography, Tab, Tabs } from '@mui/material';
import { MainContext, useWsDataProvider } from 'kerberus-components';
import moment from 'moment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={"div"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const parseLocation = (location) => {
    var split = location.split('/');
    switch (split[split.length - 1]) {
        case ('device'): return 0;
        case ('calendar'): return 1;
        default: return 0;
    }
};


export default function DeviceRoutes() {
    const { user } = useContext(MainContext);
    const { userId, deviceId } = useParams();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const [value, setValue] = useState(0);
    const [calDate, setCalDate] = useState(new Date());

    const { getData, data: providerData } = useWsDataProvider();

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const devGroup = useMemo(() => user.device_groups.find(i => i.id === deviceId), [deviceId, user.device_groups]);

    const macAddress = useMemo(() => {
        if (devGroup) {
            return devGroup.addresses.map(i => i.address);
        }
        return null;
    }, [devGroup]);

    useEffect(() => {
        if (macAddress && calDate) {
            getData('socketLocation', 'devtrackingAddresses', {
                filters: {
                    mac_array: macAddress, start_time: moment(calDate).startOf('week'), end_time: moment(calDate).endOf('week')
                }
            });
        }
    }, [calDate, getData, macAddress]);



    useEffect(() => { setValue(parseLocation(location.pathname)); }, [location]);

    return (
        <LayoutPaper hideoverflowy={isSmall ? "false" : "true"} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: -4, marginX: -4 }}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    centered

                >
                    <Tab icon={<PermDeviceInformationIcon />} label={isSmall ? "" : "Device"} component={RouterLink} {...a11yProps(0)} to={`/profile/${userId}/device/${deviceId}`} replace />
                    <Tab icon={<CalendarTodayIcon />} label={isSmall ? "" : "Calendar"} component={RouterLink} {...a11yProps(1)} to={`/profile/${userId}/device/${deviceId}/calendar`} replace />
                </Tabs>
            </Box>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <DeviceView />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <DeviceCalendar devGroup={devGroup} calDate={calDate} setCalDate={setCalDate} providerData={providerData} />
                </TabPanel>
            </SwipeableViews>
        </LayoutPaper>
    );

}