import * as React from 'react';
import { Typography, Box } from "@mui/material";
import StyledBadgeAvatar from "../StyledBadgeAvatar";
import { dayjs } from '../dayjs-utils';



export default function GridLarge(props) {
    const { lastseenminutes, avatarWidth, avatarHeight, busy, item, small, userObj } = props;

    return (
        <>
            <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", minHeight: !small && 225 }}>


                <Box sx={{ height: "20%", maxHeight: "20%", display: "flex", alignItems: "flex-end", justifyContent: "center", position: "relative", bottom: 2 }}>
                    <Typography
                        fontSize={
                            small
                                ?
                                {
                                    xl: 16,
                                    lg: 16,
                                    md: 14,
                                    sm: 12,
                                    xs: 12
                                }
                                :
                                {
                                    xl: 22,
                                    lg: 22,
                                    md: 20,
                                    sm: 18,
                                    xs: 16
                                }
                        }
                        component="div"
                        sx={{ color: props.textColor, textAlign: "center" }}
                    >
                        {(item.first_name && item.last_name) ? item.first_name : item.username}
                    </Typography>
                </Box>


                <Box sx={{ height: "48%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <StyledBadgeAvatar
                        tooltipContent={lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? 'Away' : lastseenminutes > -5 && lastseenminutes < 5 ? 'Online' : 'Offline' : 'Offline'}
                        tooltipPlacement="bottom-end"
                        badgeOverlap="circular"
                        badgeAnchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeVariant="dot"
                        badgeheight="25%"
                        badgewidth="25%"
                        badgeanimation={!busy ? lastseenminutes ? lastseenminutes > -5 && lastseenminutes < 5 : false : false}
                        badgeboxshadow={`0 0 0 2px #fff`}
                        badgeSx={{
                            width: avatarWidth,
                            height: avatarHeight,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                        avatarSx={{
                            width: avatarWidth,
                            height: avatarHeight,
                            borderWidth: 0,
                            borderStyle: "solid",
                            borderColor: busy ? "#E72D50" : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? "#FCC041" : lastseenminutes > -5 && lastseenminutes < 5 ? "#8DBF30" : '#adadb8' : '#adadb8',
                        }}
                        avatarVariant="circular"
                        alt="ProfileIcon"
                        imgSrc={item.id}
                        lastseenminutes={lastseenminutes}
                        busy={busy}
                        skipCookie
                        imageSize="_256"
                    />
                </Box>


                <Box component="div" sx={{ height: "20%", maxHeight: "20%", position: "relative", bottom: "4%", }}>

                    {(props.hasUserTitle && item?.title?.length > 0) &&
                        <Typography
                            textAlign="center"
                            fontSize={
                                small
                                    ?
                                    {
                                        xl: 9,
                                        lg: 8,
                                        md: 8,
                                        sm: 8,
                                        xs: 7
                                    }
                                    :
                                    {
                                        xl: 13,
                                        lg: 12,
                                        md: 11,
                                        sm: 11,
                                        xs: 10
                                    }
                            }
                            sx={{ color: props.subheaderColor, paddingX: 0.5 }}>
                            {item.title}
                        </Typography>
                    }
                </Box>


                <Box sx={{ height: "12%", maxHeight: "12%", display: "flex", alignItems: "flex-start", justifyContent: "center", textAlign: "center" }}>
                    {props.hasLocation ?
                        <Typography
                            fontSize={
                                small
                                    ?
                                    {
                                        xl: 14,
                                        lg: 12,
                                        md: 12,
                                        sm: 11,
                                        xs: 11
                                    }
                                    :
                                    {
                                        xl: 18,
                                        lg: 16,
                                        md: 14,
                                        sm: 12,
                                        xs: 10
                                    }
                            }
                            sx={{ color: props.textColor }}>
                            {
                                userObj?.timestamp
                                    ?
                                    lastseenminutes <= 10 && lastseenminutes >= 5
                                        ?
                                        dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                        :
                                        lastseenminutes >= 10
                                            ?
                                            dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                            :
                                            userObj.layer_name ?? dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                    :
                                    'Long time ago'
                            }
                        </Typography>
                        :
                        <Typography
                            fontSize={{
                                xl: 18,
                                lg: 16,
                                md: 14,
                                sm: 12,
                                xs: 10
                            }}
                            sx={{ color: props.textColor }}>
                            {userObj?.timestamp ? dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds')) : 'Long time ago'}
                        </Typography>

                    }
                </Box>
            </Box>
        </>
    );
}