import { useRef, useContext, useCallback, useEffect, useState } from 'react';
import SocketContext from './SocketContext';
import { v4 as uuidv4 } from 'uuid';

export const useDataProvider = () => {
    const { sendJsonMessage, lastJsonMessage } = useContext(SocketContext);
    const [senderId, setSenderId] = useState(null);
    const resolve = useRef(null);
    const reject = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (lastJsonMessage?.payload?.client === senderId) {
            resolve.current(lastJsonMessage.payload);
            timeoutRef.current = false;
        }
    }, [lastJsonMessage, senderId])

    const getData = useCallback((device, command, target, params) => {
        return new Promise((res, rej) => {
            resolve.current = res;
            reject.current = rej;
            let client = uuidv4();
            setSenderId(client);
            if (!command || !target || !device) {
                reject('Invalid function call')
            }
            sendJsonMessage({
                dest: device.serial,
                payload: {
                    rpc: {
                        command: command,
                        target: target,
                        params: { ...params, client: client }
                    }
                }
            });
            timeoutRef.current = true;
        });
    }, [sendJsonMessage]);

    useEffect(() => {
        setTimeout(() => {
            if (timeoutRef.current) {
                reject.current('No response from device');
            }
        }, 15000)
    }, [senderId])

    return { getData };
}
