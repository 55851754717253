import * as React from 'react';
import { useEffect, useState, useContext, useMemo, useRef, useCallback } from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery, IconButton, Divider, Tooltip, Link as MuiLink, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import LayoutPaper from '../../Components/LayoutPaper';
import { MainContext, SmallAvatarWrapper, useWsDataProvider } from 'kerberus-components';
import useSWR from 'swr';
import SettingsIcon from '@mui/icons-material/Settings';
/* import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; */
import UserList from '../../Components/UserList';
import DatagridColumns from '../../Components/Company/DatagridColumns';
import { SlackIcon, SlackIconBlack } from '../../Components/Icons/Slack';
import DatagridColumnsMobile from '../../Components/Company/DatagridColumnsMobile';
import GraphModal from '../../Components/Groups/GraphModal';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardModalCompany from '../../Components/DashboardModalCompany.js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    height: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

export default React.memo(function CompanyView() {
    const { user, bulkData } = useContext(MainContext);
    const { user_company } = user;
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [onlineMembers, setOnlineMembers] = useState(0);
    const { data: profiles = [] } = useSWR('users/profilelistsocial/');


    const [date, setDate] = useState(moment());
    const [interval, setInterval] = useState(60);
    const [duration, setDuration] = useState("day");
    const [chartType, setChartType] = useState("bar");
    const [openCompany, setOpenCompany] = useState(false);

    const { data, getData } = useWsDataProvider();

    const cacheMap = useRef(new Map());

    const [graphData, setGraphData] = useState();

    const dataProviderParams = useRef();

    useEffect(() => {
        if (data) {
            cacheMap.current.set(
                moment(dataProviderParams.current.date).startOf(dataProviderParams.current.duration).toJSON()
                +
                moment(dataProviderParams.current.date).endOf(dataProviderParams.current.duration).toJSON(), data);
            setGraphData(data);
        }
    }, [data]);

    const getDataProviderData = useCallback(() => {
        getData('socketLocation', 'ucountGraph', {
            filters: {
                start_time: moment(date).startOf(duration),
                end_time: moment(date).endOf(duration),
                interval: interval,
                company: user_company.id,
            }
        });
        dataProviderParams.current = {
            date: date,
            duration: duration
        };
    }, [date, duration, getData, interval, user_company.id]);

    useEffect(() => {
        const cacheObj = cacheMap.current.get(moment(date).startOf(duration).toJSON() + moment(date).endOf(duration).toJSON());
        if (cacheObj) {
            setGraphData(cacheObj);
        }
        else {
            getDataProviderData();
        }
    }, [date, duration, getData, getDataProviderData, interval, user_company]);

    useEffect(() => {
        if (duration === "day") {
            setInterval(60);
        }
        else if (duration === "week") {
            setInterval(120);
        }
        else {
            setInterval(240);
        }
    }, [date, duration, getData, interval, user_company]);

    useEffect(() => {
        let tempNum = 0;
        if (bulkData) {
            for (const userObj of bulkData.company) {
                const now = moment();
                const lastSeen = userObj?.timestamp ? moment(userObj.timestamp) : null;
                const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                if (lastSeen && lastseenminutes <= 10) {
                    tempNum++;
                }
            }
        }
        setOnlineMembers(tempNum);
    }, [bulkData]);

    const gridColumnsMobile = useMemo(() => (
        DatagridColumnsMobile
    ), []);

    return (
        <LayoutPaper>
            <Grid container>

                <Grid item xs={12} md={10} sx={{ display: "flex", flexDirection: isSmall ? "column" : "row", marginBottom: 1, marginTop: isSmall ? 3 : -1, alignItems: "center" }}>

                    <Box sx={isSmall ? { width: "90%" } : {}}>
                        <Typography component={"div"} variant="h4" textAlign="center" sx={{ color: theme.palette.primary.main, width: isSmall ? "100%" : undefined, display: "flex", alignItems: "center", justifyContent: "center", mb: isSmall && 2 }} >
                            <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50, marginRight: 2, "&:hover": { cursor: "pointer" } }}
                                userId={user.user_company.id} imageType="companypic" imageSize="_64" />
                            {user_company.name}
                        </Typography>
                    </Box>

                    {!isSmall &&
                        <Typography component={"div"} variant="h4"
                            sx={{ color: theme.palette.primary.main, marginLeft: 2 }}
                        >
                            {onlineMembers}/{profiles.length}
                        </Typography>
                    }
                </Grid>

                <Grid item xs md={2} sx={isSmall ? { position: "absolute", top: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "flex-end" } : { display: "flex", alignItems: "center", justifyContent: "flex-end", position: "relative", bottom: 20, left: 20 }}>
                    <Tooltip title="Info Screen">
                        <IconButton sx={{ mr: 0.5 }} onClick={() => setOpenCompany(true)}>
                            <DashboardIcon color="primary" sx={{ width: 30, height: 30 }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Slack">
                        <IconButton sx={{ padding: 0, mr: 0.5 }} disabled={!user_company.links.slack} component={MuiLink} href={user_company.links.slack} target="_blank" rel="noopener noreferrer">
                            {!user_company.links.slack
                                ?
                                <SlackIconBlack sx={{ width: 50, height: 50 }} />
                                :
                                <SlackIcon sx={{ width: 50, height: 50 }} />
                            }
                        </IconButton>
                    </Tooltip>
                    {user.next &&
                        <GraphModal data={graphData} setDuration={setDuration} interval={interval} duration={duration} chartType={chartType} setChartType={setChartType} date={date} setDate={setDate} label="Company Occupancy" />
                    }
                    {(user.perm_level === "superuser" || user.perm_level === "admin") &&
                        <Tooltip title="Settings">
                            <IconButton onClick={() => navigate('./settings')}>
                                <SettingsIcon sx={{ height: 30, width: 30 }} />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>


                <Grid item xs={12}>
                    <Divider sx={{ mb: 2 }} />
                </Grid>

                <Grid container>
                    <UserList allGroups userList={profiles} onlineData={bulkData?.company} gridColumns={DatagridColumns} gridColumnsMobile={gridColumnsMobile} default="card" datagridHeight="68vh" />
                </Grid>

                <Modal
                    open={openCompany}
                    onClose={() => setOpenCompany(false)}
                    aria-labelledby="modal-modal-title2"
                    aria-describedby="modal-modal-description2"
                    BackdropProps={{ style: { opacity: 0.2 } }}
                >
                    <Box sx={style}>
                        <DashboardModalCompany />
                    </Box>
                </Modal>

            </Grid>
        </LayoutPaper >
    );

});