import React, { useContext, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { TextField, InputAdornment, IconButton, Tooltip, Box, Button, useMediaQuery, useTheme, Typography, Checkbox } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';
import { MainContext, useFetchCloud } from 'kerberus-components';
import { useSWRConfig } from 'swr';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ConfirmationDialog from '../ConfirmationDialog';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TwitterIcon from '@mui/icons-material/Twitter';

const toastError = {
    open: true,
    type: "error",
    title: "Error",
    message: `Something went wrong!`,
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const toastInfo = {
    open: true,
    type: "success",
    title: "Success",
    message: "Personal information saved!",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

export default function SocialLinks() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [socialLinkDialog, setSocialLinkDialog] = useState();
    const [isDisabled, setIsDisabled,] = useState(true);
    const { user, setOpen } = useContext(MainContext);
    const { fetchCloud } = useFetchCloud();
    const { mutate } = useSWRConfig();
    const { handleSubmit, control, formState: { errors }, register } = useForm({
        defaultValues: {
            linkedin: user?.links?.linkedin,
            twitter: user?.links?.twitter,
            whatsapp: user?.links?.whatsapp,
            calendly: user?.links?.calendly
        },
        mode: "onChange"
    });
    const onSubmit = data => { handleSocialLinksSave(data); console.log(data); };

    const handleSocialLinksSave = async (e) => {
        try {
            await fetchCloud(`users/timetableprofile/`, 'post', {
                links: e
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastInfo);
        }
        catch (err) {
            setOpen(toastError);
        };
    };

    const contentSocialLinks = (
        <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
            You are about to add your personal data to the system.
            <br />
            Some of the information you provide may be subject to GDPR legislation.
            <br />
            Therefore the system has all the necessary settings to control the visibility of this information to other users.
            <br />
            <br />
            Please ensure that you understand that you are personally responsible for the accuracy of the provided information you share and its visibility and use.
            <br />
            <Typography sx={{ fontWeight: "bold", position: "absolute", right: 5, bottom: 65 }}>
                I understand
                <Checkbox checked={!isDisabled} onChange={() => setIsDisabled(isDisabled => !isDisabled)} />
            </Typography>
            <br />
            {!isSmall && <br />}
            <div style={isSmall ? { marginBottom: 30 } : { width: isSmall ? "45%" : "65%", position: "absolute", bottom: 5 }}>
                If you would like further information on this matter you can review our
                <Typography component={Link} to={`/termsconditions`} sx={{ color: theme.palette.mlBlue.main, cursor: 'pointer', display: "inline", marginLeft: 1 }}>
                    Terms & Conditions
                </Typography>
                {" again."}
            </div>
        </Typography>
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={isSmall ? { width: "100%", display: "flex", flexDirection: "column" } : { width: "35%", position: "absolute", }}>

            <Box sx={{ width: "100%" }}>
                <Controller
                    name="linkedin"
                    control={control}
                    render={({ field: { onChange, value } }) =>
                        <Box sx={{ display: "flex", flexDirection: isSmall && "column" }}>

                            <Box sx={{ display: isSmall && "flex" }}>
                                <Tooltip placement="top" title="LinkedIn">
                                    <LinkedInIcon sx={{ height: isSmall ? 20 : 40, width: isSmall ? 20 : 40, color: "#0A66C2", mt: 1 }} />
                                </Tooltip>
                                {isSmall &&
                                    <Typography sx={{ mt: 0.8, ml: 0.5 }}>LinkedIn</Typography>
                                }
                            </Box>
                            <Box sx={{ mb: 1, width: "100%" }}>
                                <TextField
                                    {...register("linkedin", {
                                        pattern: {
                                            value: /^https:\/\/www\.linkedin\.com\/in\/.*$/,
                                            message: "Invalid link. Must start with https://"
                                        }
                                    })}
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    label={!isSmall && "LinkedIn"}
                                    fullWidth
                                    error={errors.linkedin?.message}
                                    helperText={errors.linkedin?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip placement="top" title="By pressing this button you will be forwarded to LinkedIn's helping page for getting your profile link. Post the profile link to this input.">
                                                    <IconButton comnponent={Link} href="https://www.linkedin.com/help/linkedin/answer/a522735/find-your-linkedin-public-profile-url?lang=en" target="_blank" rel="noopener noreferrer">
                                                        <HelpIcon color="mlBlue" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}


                                />
                            </Box>
                        </Box>
                    }
                />
                <Controller
                    name="twitter"
                    control={control}
                    render={({ field: { onChange, value } }) =>
                        <Box sx={{ display: "flex", flexDirection: isSmall && "column" }}>

                            <Box sx={{ display: isSmall && "flex" }}>
                                <Tooltip placement="left" title="Twitter">
                                    <TwitterIcon sx={{ height: isSmall ? 20 : 40, width: isSmall ? 20 : 40, color: "#1da1f2", mt: 1 }} />
                                </Tooltip>
                                {isSmall &&
                                    <Typography sx={{ mt: 0.8, ml: 0.5 }}>Twitter</Typography>
                                }
                            </Box>
                            <Box sx={{ mb: 1, width: "100%" }}>
                                <TextField
                                    {...register("twitter", {
                                        pattern: {
                                            value: /^https:\/\/twitter\.com.*$/,
                                            message: "Invalid link. Must start with https://"
                                        }
                                    })}
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    label={!isSmall && "Twitter"}
                                    fullWidth
                                    error={errors.twitter?.message}
                                    helperText={errors.twitter?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ padding: 1 }}>
                                                <Tooltip placement="top" title="Post your Twitter profile URL here.">
                                                    {/* <IconButton comnponent={Link} href="https://www.linkedin.com/help/linkedin/answer/a522735/find-your-linkedin-public-profile-url?lang=en" target="_blank" rel="noopener noreferrer"> */}
                                                    <HelpIcon color="mlBlue" />
                                                    {/*  </IconButton> */}
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}


                                />
                            </Box>
                        </Box>
                    }
                />
                <Controller
                    name="calendly"
                    control={control}
                    render={({ field: { onChange, value } }) =>

                        <Box sx={{ display: "flex", flexDirection: isSmall && "column" }}>

                            <Box sx={{ display: isSmall && "flex" }}>
                                <Tooltip placement="left" title="Calendly">
                                    <CalendarMonthIcon sx={{ height: isSmall ? 20 : 40, width: isSmall ? 20 : 40, color: theme.palette.mlBlue.main, mt: 1 }} />
                                </Tooltip>
                                {isSmall &&
                                    <Typography sx={{ mt: 0.8, ml: 0.5 }}>Calendly</Typography>
                                }
                            </Box>
                            <Box sx={{ mb: 1, width: "100%" }}>
                                <TextField
                                    {...register("calendly", {
                                        pattern: {
                                            value: /^https:\/\/calendly\.com.*$/,
                                            message: "Invalid link. Must start with https://"
                                        }
                                    })}
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    label={!isSmall && "Calendly"}
                                    fullWidth
                                    error={errors.calendly?.message}
                                    helperText={errors.calendly?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="By pressing this button you will be forwarded to Calendly's helping page for getting your scheduling link. Post the Calendly link to this input.">
                                                    <IconButton comnponent={Link} href="https://help.calendly.com/hc/en-us/articles/223193448-Sharing-your-scheduling-link#sharing-your-scheduling-link-0-0" target="_blank" rel="noopener noreferrer">
                                                        <HelpIcon color="mlBlue" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}


                                />
                            </Box>
                        </Box>
                    }
                />
                <Controller
                    name="whatsapp"
                    control={control}
                    render={({ field: { onChange, value } }) =>

                        <Box sx={{ display: "flex", flexDirection: isSmall && "column" }}>

                            <Box sx={{ display: isSmall && "flex" }}>
                                <Tooltip title="WhatsApp">
                                    <WhatsAppIcon sx={{ height: isSmall ? 20 : 40, width: isSmall ? 20 : 40, color: "#25D366", mt: 1 }} />
                                </Tooltip>
                                {isSmall &&
                                    <Typography sx={{ mt: 0.8, ml: 0.5 }}>WhatsApp</Typography>
                                }
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <PhoneInput
                                    onChange={(e) => e.length > 0 ? onChange('https://wa.me/' + e) : onChange(e)}
                                    value={value}
                                    placeholder="+358 12 345 67 89"
                                    inputStyle={{ width: "100%" }}
                                    preferredCountries={["fi", "gb", "se"]}
                                    country="fi"
                                />
                            </Box>
                        </Box>
                    }
                />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: !isSmall && 3, marginY: isSmall && 2 }}>
                <Button sx={{ marginTop: !isSmall && 1 }} disabled={errors.whatsapp?.message || errors.linkedin?.message || errors.twitter?.message || errors.calendly?.message} variant="contained" onClick={() => setSocialLinkDialog(true)} >
                    save
                </Button>
            </Box>

            <ConfirmationDialog
                title={["Save"]}
                handleDevice={handleSubmit(onSubmit)}
                info
                isDisabled={isDisabled}
                name={"social links"}
                open={socialLinkDialog}
                setOpen={setSocialLinkDialog}
                content={contentSocialLinks}
            />
        </form>

    );

}