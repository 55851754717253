import { useMemo, useEffect } from "react";
import { Grid, createTheme, Box } from "@mui/material";
import { dayjs } from '../dayjs-utils';
import Card from '@mui/material/Card';
import GridLarge from './GridLarge';
import GridSmall from './GridSmall';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

const style = createTheme({
    gridItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#808080" + 50,
        borderRadius: 5,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        flexDirection: "column",
    },
    gridItemGreen: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#8DBF30",
        borderRadius: 5,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        flexDirection: "column"
    },
    gridItemYellow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#FCC041",
        borderRadius: 5,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        flexDirection: "column"
    },
    gridItemRed: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#E72D50",
        borderRadius: 5,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        flexDirection: "column"
    },
    avatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column"
    },
    profilepic: {
        height: "8vw",
        width: "8vw",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#000",
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "1.25vw"
    }
});

function createArrays(array, numberOfGroups) {
    const perGroup = array && numberOfGroups && Math.ceil(array.length / numberOfGroups);
    return array && numberOfGroups && new Array(numberOfGroups)
        .fill('')
        .map((_, i) => array.slice(i * perGroup, (i + 1) * perGroup));
}

const options = { delay: 30000 }; // Options
const autoplayRoot = (emblaRoot) => emblaRoot.parentElement; // Root node
const autoplay = Autoplay(options, autoplayRoot);

export default function SocialsDashboard(props) {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [autoplay]);
    const slicedArray = useMemo(() => createArrays(props?.users, props.numberOfSlides), [props?.users, props.numberOfSlides]);

    /*     useEffect(() => {
            setInterval(() => {
                setTimer(emblaApi?.scrollProgress() * 100);
            }, 1000);
        }, [emblaApi]); */

    useEffect(() => {
        if (emblaApi) {
            emblaApi.reInit()
          }
    }, [emblaApi]);

    return (


        <div style={{ overflow: "hidden", height: "100%" }} ref={emblaRef}>
            <div style={{ display: "flex", height: "100%" }}>
                {props.users && slicedArray && slicedArray?.map((item, index) => {

                    let gridSize = "";
                    let padding = "";
                    let paddingX = "";
                    let spacing = "";
                    let component = "";

                    switch (true) {
                        case item.length > 0 && item.length <= 2:
                            gridSize = 12 / 2;
                            padding = props.small ? 12 : "18vh";
                            spacing = 10;
                            break;
                        case item.length > 2 && item.length <= 4:
                            gridSize = 12 / 2;
                            padding = 4;
                            paddingX = 26;
                            spacing = 8;
                            break;
                        case item.length > 4 && item.length <= 6:
                            gridSize = 12 / 3;
                            padding = 6;
                            paddingX = 14;
                            spacing = 5;
                            break;
                        case item.length > 6 && item.length <= 8:
                            gridSize = 12 / 4;
                            padding = 8;
                            paddingX = 8;
                            spacing = 5;
                            break;
                        case item.length > 8 && item.length <= 10:
                            gridSize = 12 / 5;
                            padding = 4;
                            paddingX = 10;
                            spacing = 5;
                            component = "GridLarge";
                            break;
                        case item.length > 10 && item.length <= 12:
                            gridSize = 12 / 6;
                            padding = 3;
                            paddingX = 6;
                            spacing = 5;
                            component = "GridLarge";
                            break;
                        case item.length > 12 && item.length <= 14:
                            gridSize = 12 / 7;
                            padding = 4;
                            paddingX = 6;
                            spacing = 5;
                            component = "GridLarge";
                            break;
                        case item.length > 14 && item.length <= 16:
                            gridSize = 12 / 8;
                            padding = 4;
                            paddingX = 6;
                            spacing = 5;
                            component = "GridLarge";
                            break;
                        case item.length > 16 && item.length <= 18:
                            gridSize = 12 / 9;
                            padding = 3;
                            spacing = 4;
                            component = "GridLarge";
                            break;
                        case item.length > 18 && item.length <= 20:
                            gridSize = 12 / 10;
                            padding = 2;
                            spacing = 3;
                            component = "GridLarge";
                            break;
                        default:
                            gridSize = 12 / 10;
                            padding = 3;
                            spacing = 4;
                            component = "GridLarge";
                    }
                    return (
                        <div key={index} style={{ position: "relative", flex: "0 0 100%" }}>
                            <Grid container justifyContent="center" spacing={spacing} padding={padding} paddingX={paddingX} sx={{ height: "100%", overflow: "hidden", }}>
                                {item.map(item => {
                                    const userObj = props?.onlineData?.find(i => i.user_id === item.id);
                                    const busy = userObj?.status === 'busy';
                                    const now = dayjs().add(30, 'seconds');
                                    const lastSeen = userObj?.timestamp ? dayjs(userObj.timestamp) : null;
                                    const duration = lastSeen ? dayjs.duration(now.diff(lastSeen)) : null;
                                    const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                                    const avatarHeight = props.avatarSize;
                                    const avatarWidth = props.avatarSize;
                                    return (
                                        <Grid key={item.id} item xs={gridSize}>
                                            <Card sx={busy ? style.gridItemRed : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? style.gridItemYellow : lastseenminutes > -5 && lastseenminutes < 5 ? style.gridItemGreen : style.gridItem : style.gridItem}>
                                                <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
                                                    {
                                                        component === "GridLarge"
                                                            ?
                                                            <GridLarge {...props} item={item} busy={busy} lastseenminutes={lastseenminutes} avatarHeight={avatarHeight} avatarWidth={avatarWidth} userObj={userObj} />
                                                            :
                                                            <GridSmall {...props} item={item} busy={busy} lastseenminutes={lastseenminutes} avatarHeight={avatarHeight} avatarWidth={avatarWidth} userObj={userObj} />
                                                    }
                                                </Box>
                                            </Card>
                                        </Grid>
                                    );
                                }
                                )}
                            </Grid>
                        </div>
                    );
                }
                )}
            </div>
        </div>
    );
}
