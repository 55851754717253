import * as React from 'react';
import {
    Typography, Box, Card, Tooltip, useTheme, useMediaQuery, CardContent
} from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import { StyledBadgeAvatar, MainContext } from 'kerberus-components';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SocialIcons from '../../Components/SocialIcons';
import { useNavigate } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';
import { Link } from "react-router-dom";

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default React.memo(function ProfileCard({
    busy,
    lastseenminutes,
    user,
    socialGroup,
    hideLinks,
    allGroups,
    userObj
}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { user: userInfo } = useContext(MainContext);
    const { id } = userInfo;

    return (
        <Card
            elevation={3}
            sx={{
                marginX: !isSmall && 2,
                marginY: 2,
                borderRadius: 3,
                height: 350,
                '&:hover': { filter: "invert(2%)" },
                borderColor: busy ? theme.palette.mlRed.main : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? theme.palette.mlYellow.main : lastseenminutes > -5 && lastseenminutes < 5 ? theme.palette.mlGreen.main : '#adadb8' : '#adadb8',
                borderStyle: "solid",
                borderWidth: "3px",
                position: "relative"
            }}


        >
            {socialGroup && socialGroup.creator === user.id &&
                <Tooltip title={"Group admin"} placement="bottom-start">
                    <AdminPanelSettingsIcon color="primary" sx={{ position: "absolute", left: 10, top: 10 }} />
                </Tooltip>
            }
            {/*                 {(user.perm_level === "superuser" || user.perm_level === "admin") &&
            <Tooltip title={"Admin"} placement="bottom-start">
                <AdminPanelSettingsIcon color="primary" sx={{ position: "absolute", left: 10, top: 10 }} />
            </Tooltip>
        } */}

            {id !== user.id &&
                <ProfileMenu allGroups={allGroups} user={user} userId={user.id} sx={{ position: "absolute", right: 0, top: 0 }} />
            }
            <CardContent sx={{ padding: 4, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", position: "relative", width: "100%", height: "100%", top: 40 }}>

                <Link to={'/profile/' + user.id}>
                    <StyledBadgeAvatar
                        noTooltip
                        avatarSx={{
                            width: 120,
                            height: 120,
                            borderWidth: 4,
                            borderStyle: "solid",
                            marginBottom: 1,
                            borderColor: busy ? theme.palette.mlRed.main : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? theme.palette.mlYellow.main : lastseenminutes > -5 && lastseenminutes < 5 ? theme.palette.mlGreen.main : '#adadb8' : '#adadb8',
                            position: "relative",
                            top: -115
                        }}
                        avatarVariant="circular"
                        alt="ProfileIcon"
                        imgSrc={user.id}
                        lastseenminutes={lastseenminutes}
                        busy={busy}
                        imageSize="_128"
                        onClick={(e) => navigate('/profile/' + user.id)}
                    />
                </Link>
                <Box sx={{ position: "absolute", top: 115 }}>
                    <Typography component={"div"} sx={{ fontSize: 14 }} color="text.secondary" textAlign="center" gutterBottom>
                        {user.title && <div>{bull}{user.title}{bull} </div>}
                    </Typography>
                    <Typography component={"div"} variant="h5" sx={{ textAlign: "center", position: "relative", top: 0 }}>
                        {user.first_name ? <div>{user.first_name} <br />{user.last_name}</div> : user.username}
                    </Typography>
                </Box>
                {!hideLinks &&
                    <Box sx={{ position: "absolute", top: 228 }}>
                        <SocialIcons user={user} links={socialGroup?.links} />
                    </Box>
                }
                <Typography component={"div"} variant="body2" sx={{ position: "absolute", top: 270, fontSize: 18 }}>
                    {
                        userObj?.timestamp
                            ?
                            lastseenminutes <= 10 && lastseenminutes >= 5
                                ?
                                moment(userObj.timestamp).from(moment().add(30, 'seconds'))
                                :
                                lastseenminutes >= 10
                                    ?
                                    moment(userObj.timestamp).from(moment().add(30, 'seconds'))
                                    :
                                    userObj.layer_name
                                        ?
                                        userObj.layer_name
                                        :
                                        moment(userObj.timestamp).from(moment().add(30, 'seconds'))
                            :
                            'Long time ago'
                    }
                </Typography>
            </CardContent>
        </Card>
    );
});

