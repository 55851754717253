import React from 'react';
import { useFetchCloud } from 'kerberus-components';
import { useState } from 'react';
import { Button, TextField, Typography, Box, Autocomplete } from '@mui/material';
import HelperIcon from '../../Components/HelperIcon';
import useSWR from 'swr';
import Toast from '../../Components/Toast';

export default React.memo(function UserCreation({ userList }) {
    const { fetchCloud } = useFetchCloud();
    const [email, setEmail] = useState('');
    const { data: socialGroupSelectData = [] } = useSWR('users/socialgroupselect/');
    const [selectedSocialGroup, setSelectedSocialGroup] = useState(null);
    const [selectedUserEmail, setSelectedUserEmail] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [inputValueGroup, setInputValueGroup] = useState('');
    const [openEmailSuccess, setOpenEmailSuccess] = useState(false);
    const [openEmailError, setOpenEmailError] = useState(false);
    const [createEmailSuccess, setCreateEmailSuccess] = useState(false);
    const [createEmailError, setCreateEmailError] = useState(false);
    const handleEmailCreate = async () => {
        try {
            await fetchCloud('users/generate_user_url', 'POST', {
                email: email
            });
            setCreateEmailSuccess(true);
        }
        catch (err) {
            setCreateEmailError(err);
        }
    };

    const handleInvite = async () => {
        try {
            await fetchCloud('users/create_social_invite', 'POST', {
                email: selectedUserEmail,
                social_group: selectedSocialGroup
            });
            setOpenEmailSuccess(true);
        }
        catch (err) {
            setOpenEmailError(true);
        }
    };

    return (
        <>
            <Box sx={{ position: "absolute", right: 0, top: 0 }}>
                <HelperIcon content="Created user will join your current company." />
            </Box>
            <Typography component={"div"} variant="h6" sx={{ fontSize: 24, fontWeight: "bold", marginLeft: 2 }}>
                User Creation
            </Typography>
            <Typography component={"div"} variant="h6" sx={{ fontWeight: "bold", marginTop: 2, marginLeft: 2 }}>
                Email
            </Typography>
            <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="emailinput"
                label="Email"
                variant="outlined"
                helperText="Set email"
                fullWidth
                autoComplete="new-password"
                sx={{ marginLeft: 2 }}
            />
            <Button disabled={!email} variant="contained" onClick={handleEmailCreate} sx={{ mt: 1, mb: 2, alignSelf: "flex-end" }}>
                Create
            </Button>

            <Typography component={"div"} variant="h6" textAlign="center" sx={{ fontSize: 24, fontWeight: "bold", marginLeft: 2, marginTop: 5 }}>
                Invite to social group
            </Typography>

            <Typography component={"div"} variant="h6" sx={{ fontWeight: "bold", marginTop: 2, marginLeft: 2 }}>
                Social Group
            </Typography>
            <Autocomplete
                disablePortal
                id="combo-box-social-group"
                options={socialGroupSelectData}
                getOptionLabel={option => option.name}
                onChange={(e, newVal) => newVal !== null ? setSelectedSocialGroup(newVal.id) : setSelectedSocialGroup(null)}
                inputValue={inputValueGroup}
                onInputChange={(e, newVal) => setInputValueGroup(newVal)}
                sx={{ marginLeft: 2, marginTop: 2 }}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Social Group" />}
            />

            <Typography component={"div"} variant="h6" sx={{ fontWeight: "bold", marginTop: 2, marginLeft: 2 }}>
                User
            </Typography>
            <Autocomplete
                disablePortal
                id="combo-box-user-email"
                /* Checks & filters to remove people who already are in the social group from the option list  */
                options={userList.map(users => users).filter(user => !socialGroupSelectData.filter(group => group.id === selectedSocialGroup)[0]?.users.map(item => item.id).includes(user.id))}
                getOptionLabel={option => option.first_name && option.last_name ? `${option.first_name} ${option.last_name}` : option.username}
                onChange={(e, newVal) => newVal !== null ? setSelectedUserEmail(newVal.email) : setSelectedUserEmail(null)}
                inputValue={inputValue}
                onInputChange={(e, newVal) => setInputValue(newVal)}
                sx={{ marginLeft: 2, marginTop: 2 }}
                fullWidth
                disabled={!selectedSocialGroup}
                renderInput={(params) => <TextField {...params} label="User" error={inputValue && !selectedUserEmail} helperText={!selectedSocialGroup ? "You need to choose social group first" : (inputValue && !selectedUserEmail) ? "User has no email" : "Select user"} />}
            />

            <Button disabled={!selectedSocialGroup || !selectedUserEmail} variant="contained" onClick={handleInvite} sx={{ mt: 2, mb: 2, alignSelf: "flex-end" }}>
                Invite
            </Button>


            <Toast
                sx={{}}
                type="success"
                title="Email sent"
                message="Instructions for accepting group invite has been sent to the user if the user's email exists"
                verticalPos="bottom"
                horizontalPos="center"
                open={openEmailSuccess}
                handleClose={() => setOpenEmailSuccess(false)}
                autoHideDuration={50000}
            />
            <Toast
                sx={{}}
                type="error"
                title="Email send failed"
                message="Internal server error"
                verticalPos="bottom"
                horizontalPos="center"
                open={openEmailError}
                handleClose={() => setOpenEmailError(false)}
                autoHideDuration={50000}
            />
            <Toast
                sx={{}}
                type="success"
                title="Email sent"
                message="Instructions for creating an account has been sent to the user if the user's email exists"
                verticalPos="bottom"
                horizontalPos="center"
                open={createEmailSuccess}
                handleClose={() => setCreateEmailSuccess(false)}
                autoHideDuration={50000}
            />

            <Toast
                sx={{}}
                type="error"
                title="Email send failed"
                message={createEmailError.data}
                verticalPos="bottom"
                horizontalPos="center"
                open={createEmailError}
                handleClose={() => setCreateEmailError(false)}
                autoHideDuration={50000}
            />
        </>
    );
});