import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Typography, IconButton, useTheme, useMediaQuery, Checkbox, Grid, FormControlLabel } from '@mui/material';
import moment from 'moment';
import SearchBar from '../MyConnections/SearchBar';
import ConfirmationDialog from '../ConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import HelperIcon from '../HelperIcon';
import { MaskedTextFieldWrapper, useFetchCloud, MainContext } from 'kerberus-components';
import { Link, useParams } from "react-router-dom";
import { useSWRConfig } from 'swr';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const disabledContent = <div>This will disable mac address</div>;
const deleteContent = <div>This will delete mac address</div>;
const restoreContent = <div>This will restore mac address</div>;

const statusHelperIcon = (
    <>
        <div>This indicates if address is active at the moment</div>
    </>
);

const bluetoothHelperIcon = (
    <>
        <div>This selection determines if it is a bluetooth mac address.</div>
    </>
);

export default React.memo(function DataGridMacAddress({ macAddresses, activeMacAddresses }) {
    const { user } = useContext(MainContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { deviceId } = useParams();
    const [visibilityDialog, setVisibilityDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [valueAdd, setValueAdd] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [newMacAddress, setNewMacAddress] = useState("");
    const [addrBluetooth, setAddrBluetooth] = useState(false);
    const { fetchCloud } = useFetchCloud();
    const { mutate } = useSWRConfig();
    const macAddressAlreadyTaken = user.device_groups.findIndex(i => i.addresses.some(addr => addr.address.toLowerCase() === newMacAddress.toLowerCase())) !== -1

    const handleAddressAdd = async () => {
        await fetchCloud(`devices/addaddressdevgroup`, 'POST', {
            address: [{
                address: newMacAddress,
                bluetooth: addrBluetooth
            }],
            device_group: deviceId
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        setNewMacAddress("")
    };

    const handleAddressVisibility = async () => {
        await fetchCloud(`devices/addaddressdevgroup`, 'POST', {
            address: [{
                address: visibilityDialog.address,
                visible: !visibilityDialog.visible
            }],
            device_group: deviceId
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    const handleAddressRemove = async () => {
        await fetchCloud(`devices/removeaddressdevgroup`, 'DELETE', {

            address: [deleteDialog],
            device_group: deviceId
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    const today = moment();

    const columns = [
        {
            field: 'icon', headerName: 'Type', width: 30, renderCell: (e) =>
                e.row.bluetooth
                    ?
                    <BluetoothIcon color="primary" />
                    :
                    <SignalWifi3BarIcon color="primary" />
        },
        { field: 'address', headerName: 'Mac Address', width: 160 },
        {
            field: 'timestamp', headerName: 'Last seen', flex: 0.5, renderCell: (e) => {
                const rowActiveAddresses = activeMacAddresses.filter(item => item.wlan_sa === e.row.address)
                const lastseen = rowActiveAddresses.length > 0 && rowActiveAddresses.reduce((a, b) => today - moment(a.timestamp) < today - moment(b.timestamp) ? a : b);

                return (
                    lastseen ? lastseen.timestamp ? moment(lastseen.timestamp).fromNow() : '> 1 week' : 'Long time ago'
                )
            }
        },
        {
            field: 'site_name', headerName: 'Location', flex: 0.5, renderCell: (e) => {
                const rowActiveAddresses = activeMacAddresses.filter(item => item.wlan_sa === e.row.address)
                const lastseen = rowActiveAddresses.length > 0 && rowActiveAddresses.reduce((a, b) => today - moment(a.timestamp) < today - moment(b.timestamp) ? a : b);

                return (
                    lastseen.site_name ?? "Unknown"
                )
            }
        },
        {
            field: 'status', flex: 0.5, renderHeader: () =>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mr: 1 }}>
                    <Typography>
                        Status
                    </Typography>
                    <HelperIcon content={statusHelperIcon} style={{ position: "relative", top: 5, left: 3 }} />
                </Box>,
            renderCell: (e) => {

                const rowActiveAddresses = activeMacAddresses.filter(item => item.wlan_sa === e.row.address)
                const lastseen = rowActiveAddresses.length > 0 && rowActiveAddresses.reduce((a, b) => today - moment(a.timestamp) < today - moment(b.timestamp) ? a : b);
                const lastseenminutes = moment.duration(today.diff(lastseen.timestamp)).asMinutes();

                return (
                    e.row.visible ?
                        lastseen && lastseenminutes <= 10
                            ?
                            <Typography sx={lastseenminutes >= 5 ? { color: theme.palette.mlYellow.main } : { color: theme.palette.mlGreen.main }}>Active</Typography>
                            :
                            <Typography sx={{ color: "gray" }}>Inactive</Typography>
                        :
                        <Typography sx={{ color: "gray" }}>Disabled</Typography>
                )
            }
        },
        {
            field: '', headerName: 'Visibility', display: "flex", align: "center", headerAlign: "center", renderCell: (e) =>
                e.row.visible
                    ?
                    <IconButton onClick={() => setVisibilityDialog(e.row)}>
                        <VisibilityIcon color="success" />
                    </IconButton>
                    :
                    <IconButton onClick={() => setVisibilityDialog(e.row)}>
                        <VisibilityOffIcon sx={{ color: "#808080" }} />
                    </IconButton>
        },
        {
            field: 'Delete', headerName: 'Delete', display: "flex", align: "center", headerAlign: "center", renderCell: (e) =>
                <IconButton onClick={() => setDeleteDialog(e.row.address)}>
                    <DeleteIcon color="error" />
                </IconButton>
        }
    ];

    const contentAdd = (
        <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
            By adding
            <span style={{ fontWeight: "bold" }}>{newMacAddress ? " " + newMacAddress + "" : " Device"}</span>
            , you confirm that you own and/or hold this mac address and have the legal right to track and share it's location data.
            <br />
            <br />
            <br />
            <Typography sx={{ fontWeight: "bold", position: "absolute", right: 5, bottom: 65 }}>
                I understand
                <Checkbox checked={!isDisabled} onChange={() => setIsDisabled(isDisabled => !isDisabled)} />
            </Typography>

            <div style={isSmall ? { marginBottom: 30 } : { width: isSmall ? "45%" : "65%", position: "absolute", bottom: 5 }}>
                If you would like further information on this matter you can review our
                <Typography component={Link} to={`/termsconditions`} sx={{ color: theme.palette.mlBlue.main, cursor: 'pointer', display: "inline", marginLeft: 1 }}>
                    Terms & Conditions
                </Typography>
                {" again."}
            </div>

            {isSmall && <br />}
        </Typography>
    );

    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(macAddresses);

    useEffect(() => {
        if (macAddresses) {
            setRows(macAddresses)
        }
    }, [macAddresses])

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = macAddresses.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] ? searchRegex.test(row[field].toString()) : null;
            });
        });
        setRows(filteredRows);
    };

    return (
        <>
            <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 1 }}>
                <Grid item sm={12} md="auto" sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                    <FormControlLabel sx={{ mr: -1 }}
                        label={
                            <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#00000099", fontSize: 13 }}>
                                Bluetooth
                                <HelperIcon
                                    style={{ position: "relative", top: 3, left: 3 }}
                                    content={bluetoothHelperIcon}
                                />
                            </Typography>}
                        labelPlacement="bottom"
                        control={
                            <Checkbox
                                checked={addrBluetooth}
                                onChange={(e) => setAddrBluetooth(e.target.checked)}
                            />}
                    />
                    <MaskedTextFieldWrapper
                        id="DeviceSearch"
                        label="Mac Address"
                        variant="outlined"
                        error={macAddressAlreadyTaken}
                        helperText={macAddressAlreadyTaken ? "MAC Address already in use!" : null}
                        sx={{ ml: !isSmall && 3, mt: isSmall && 1 }}
                        value={newMacAddress}
                        onChange={(e) => setNewMacAddress(e)}
                    />

                    <Button
                        disabled={newMacAddress.length !== 17 || macAddressAlreadyTaken}
                        variant="contained"
                        onClick={() => { setValueAdd(newMacAddress); setOpenAdd(true); }}
                        sx={{ ml: 2 }}
                    >
                        Add
                    </Button>


                </Grid>

                <Grid item sm={12} md="auto" sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                    <SearchBar
                        sx={{
                            width: {
                                xs: 1,
                                sm: 'auto',
                            },
                            '& .MuiSvgIcon-root': {
                                mr: 0.5,
                            },
                            '& .MuiInput-underline:before': {
                                borderBottom: 1,
                                borderColor: 'divider',
                            },
                            my: 1,
                        }}
                        value={searchText}
                        onChange={(event) => requestSearch(event.target.value)}
                        clearSearch={() => requestSearch('')}
                    />
                </Grid>
            </Grid>
            <Box sx={{
                height: 350, width: '100%',
                '& .disabled-row': {
                    opacity: 0.35,
                    '&:hover': {
                        opacity: 0.85,
                    },
                },
            }}>
                <DataGrid
                    density="compact"
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10]}
                    disableSelectionOnClick
                    getRowClassName={(params) => !params.row.visible && `disabled-row`}
                />
            </Box>


            <ConfirmationDialog
                title={[visibilityDialog.visible ? "Disable" : "Restore"]}
                handleDevice={handleAddressVisibility}
                handle
                name={visibilityDialog.address}
                open={visibilityDialog}
                setOpen={setVisibilityDialog}
                content={visibilityDialog.visible ? disabledContent : restoreContent}
            />

            <ConfirmationDialog
                title={["Add"]}
                handleDevice={handleAddressAdd}
                value={valueAdd}
                isDisabled={isDisabled}
                name={newMacAddress}
                open={openAdd}
                setOpen={setOpenAdd}
                content={contentAdd}
            />

            <ConfirmationDialog
                title={["Delete"]}
                handleDevice={handleAddressRemove}
                handle
                name={deleteDialog}
                open={deleteDialog}
                setOpen={setDeleteDialog}
                content={deleteContent}
            />
        </>
    );
}) 
