import * as React from 'react';
import { Box, Typography, Badge, styled } from "@mui/material";

const StyledBadge = styled(Badge)(({ status, badgewidth, badgeheight, badgeboxshadow, badgeanimation, lastseenminutes, theme, badgecolor }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: badgecolor,
        color: badgecolor,
        width: badgewidth,
        height: badgeheight,
        borderRadius: "50%",
        boxShadow: badgeboxshadow,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        '&::after':
        {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
            display: !badgeanimation ? "none" : undefined
        }
    },
    '& .MuiBadge-root': {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "red"
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.5)',
            opacity: 0,
        },
    },
}));

export default function StatusBadges({ textColor, scale }) {
    const badgeWidth = `calc(1.5vw * ${scale ?? 1})`
    const badgeHeight = `calc(1.5vw * ${scale ?? 1})`
    const fontSize = `calc(1vw * ${scale ?? 1})`
    const marginX = `calc(1.3vw * ${scale ?? 1})`

    return (
        <Box sx={{ color: "#ebecf0", flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <StyledBadge badgeanimation badgeContent="" badgewidth={badgeWidth} badgeheight={badgeHeight} badgecolor="#8DBF30" />
            <Typography sx={{ marginX: marginX, fontSize: fontSize }}>
                Present
            </Typography>
            <StyledBadge badgeContent="" badgewidth={badgeWidth} badgeheight={badgeHeight} badgecolor="#FCC041" />
            <Typography sx={{ marginX: marginX, fontSize: fontSize }}>
                Seen lately
            </Typography>
            <StyledBadge badgeContent="" badgewidth={badgeWidth} badgeheight={badgeHeight} badgecolor='#adadb8' />
            <Typography sx={{ marginX: marginX, fontSize: fontSize }}>
                Away
            </Typography>
            <StyledBadge badgeContent="" badgewidth={badgeWidth} badgeheight={badgeHeight} badgecolor="#E72D50" />
            <Typography sx={{ marginX: marginX, fontSize: fontSize }}>
                Occupied
            </Typography>
        </Box>
    );
}
