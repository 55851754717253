import React from 'react';
import { TextField, Typography } from '@mui/material';



export default React.memo(function AccountSettings({
    isSmall,
    username,
    setUsername,
    usernameValidation,
    newPassword,
    setNewPassword,
    repeatPassword,
    setRepeatPassword,
}) {

    return (
        <>
            <Typography component={"div"} color="#808080" textAlign="center" sx={{ mt: 1 }}>
                Enter account details
            </Typography>
            <TextField
                margin="normal"
                required
                sx={{ width: isSmall ? "100%" : "80%" }}
                id="username"
                label='Username'
                name="username"
                autoComplete="off"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={usernameValidation(username)}
                helperText={usernameValidation(username) && "No whitespaces allowed"}
            />
            <TextField
                margin="normal"
                type="password"
                required
                sx={{ width: isSmall ? "100%" : "80%" }}
                id="password"
                label='Password'
                name="password"
                autoComplete="off"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={usernameValidation(newPassword)}
                helperText={usernameValidation(newPassword) && "No whitespaces allowed"}
            />
            <TextField
                margin="normal"
                type="password"
                required
                sx={{ width: isSmall ? "100%" : "80%" }}
                id="password-repeat"
                label='Repeat password'
                name="password-repeat"
                autoComplete="off"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                error={usernameValidation(repeatPassword)}
                helperText={usernameValidation(repeatPassword) && "No whitespaces allowed"}
            />

        </>
    )
})
