import * as React from 'react';
import { IconButton, Modal, Box, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ActivityGraph from '../ActivityGraph';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 3,
    borderRadius: 4,
};

const styleMobile = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 1,
    borderRadius: 4,
};

export default function GraphModal(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ mr: 1 }}>
            <Tooltip title="Activity Graph">
                <IconButton onClick={handleOpen}>
                    <EqualizerIcon sx={{ height: 30, width: 30 }} color="mlBlue" />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={isSmall ? styleMobile : style}>
                    <ActivityGraph {...props} />
                </Box>
            </Modal>
        </Box>
    );
}