import * as React from 'react';
import { useState } from 'react';
import {
    useTheme, IconButton, useMediaQuery, Tooltip, Link as MuiLink, Modal, Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import GraphModal from '../../Components/Groups/GraphModal';
import BallotIcon from '@mui/icons-material/Ballot';
import { SlackIcon, SlackIconBlack } from '../../Components/Icons/Slack';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardModalSocialGroup from '../../Components/DashboardModalSocialGroup';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    height: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

export default React.memo(function SocialGroupToolbar({ socialGroup, modal, setActivityLogModal, activityData, setInterval, setDuration, interval, duration, chartType, setChartType, date, setDate }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [openGroup, setOpenGroup] = useState(false);

    return (
        <>
            {isSmall &&
                <Tooltip title="Group Info">
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => modal.setModalOpen(true)}
                    >
                        <InfoIcon color="primary" />
                    </IconButton>
                </Tooltip>
            }
            <Tooltip title="Info Screen">
                <IconButton sx={{ mr: 0.5 }} onClick={() => setOpenGroup(true)}>
                    <DashboardIcon color="primary" sx={{ width: 30, height: 30 }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Slack">
                <IconButton sx={{ padding: 0, mr: 0.5 }} disabled={!socialGroup.links.slack} component={MuiLink} href={socialGroup.links.slack} target="_blank" rel="noopener noreferrer">
                    {!socialGroup.links.slack
                        ?
                        <SlackIconBlack sx={{ width: 50, height: 50 }} />
                        :
                        <SlackIcon sx={{ width: 50, height: 50 }} />
                    }
                </IconButton>
            </Tooltip>
            {!isSmall &&
                <Tooltip title="Activity Log">
                    <IconButton onClick={() => setActivityLogModal(true)} sx={{ mr: 1 }}>
                        <BallotIcon sx={{ height: 30, width: 30, color: theme.palette.primary.main }} />
                    </IconButton>
                </Tooltip>
            }
            <GraphModal data={activityData} setInterval={setInterval} setDuration={setDuration} interval={interval} duration={duration} chartType={chartType} setChartType={setChartType} date={date} setDate={setDate} label="Social Group Occupancy" />

            <Tooltip title="Settings">
                <IconButton onClick={() => navigate('./settings')}>
                    <SettingsIcon sx={{ height: 30, width: 30 }} />
                </IconButton>
            </Tooltip>


            <Modal
                open={openGroup}
                onClose={() => setOpenGroup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <DashboardModalSocialGroup item={socialGroup.id} />
                </Box>
            </Modal>
        </>
    );
});