import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Copyright(props) {
    return (
        <Typography component={"div"} variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.missing-link.fi/">
                Missing-Link
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}