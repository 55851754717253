import React, { useContext, useEffect } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Checkbox, Tab, Tabs } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Dialog, DialogContent, DialogTitle, Autocomplete } from '@mui/material';
import { MainContext, useFetchCloud, SmallAvatarWrapper, timezones } from 'kerberus-components';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import ConfirmationDialog from '../../Components/ConfirmationDialog';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import TimePicker from '@mui/lab/TimePicker';
import moment from 'moment';
import DayEnabledCheckbox from '../../Components/DayEnabledCheckbox';
import { useSWRConfig } from 'swr';
import SocialLinks from '../../Components/Profile/SocialLinks';
import HelperIcon from '../../Components/HelperIcon';
import { StyledBadgeAvatar } from 'kerberus-components';
import ProfilePermissions from '../../Components/Profile/ProfilePermissions';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import SearchBar from '../../Components/MyConnections/SearchBar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApartmentIcon from '@mui/icons-material/Apartment';

const contentPic = (
    <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
        Are you sure you want to delete your profile picture?
    </Typography>
);

const contentPublic = (<><div>Allow profile usage in info dashboard</div></>);
const contentPublicSocialsGroup = (<><div>Allow profile visibility in your group's social list</div></>);
const contentPublicCalendar = (<><div>Allow other users to see your calendar</div></>);

const toastError = {
    open: true,
    type: "error",
    title: "Error",
    message: `Something went wrong!`,
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const toastInfo = {
    open: true,
    type: "success",
    title: "Success",
    message: "Personal information saved!",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const toastDataShareUser = {
    open: true,
    type: "success",
    title: "Success",
    message: "User data sharing settings saved!",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const toastDataShareGroup = {
    open: true,
    type: "success",
    title: "Success",
    message: "Group data sharing settings saved!",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};
function TabPanel(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: isSmall ? 0 : 3 }}>
                    <Typography component={"div"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default function ProfileSettings() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const { user, setOpen } = useContext(MainContext);
    const [openPic, setOpenPic] = useState(false);
    const [valuePic, setValuePic] = useState(false);
    const { userId } = useParams();
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [loading, setLoading] = useState('');
    const [file, setFile] = useState(null);
    const { fetchCloud } = useFetchCloud();
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [tz, setTz] = useState(user.tz);
    const [title, setTitle] = useState(user.title);
    const [image, setImage] = useState();
    const [startTime, setStartTime] = useState(moment.utc().startOf('day').add(user.utc_start).local());
    const [endTime, setEndTime] = useState(moment.utc().startOf('day').add(user.utc_end).local());
    const [daysEnabled, setDaysEnabled] = useState(new Set(user.enable_days));
    const [isDisabled, setIsDisabled,] = useState(true);
    const [alwaysActive, setAlwaysActive] = useState(user.utc_start === "00:00:00" && user.utc_end === "1 00:00:00");
    const { mutate } = useSWRConfig();

    const onDrop = useCallback(async acceptedFiles => {
        setUploadDisabled(true);
        const imageUrl = URL.createObjectURL(acceptedFiles[0]);
        setImage(imageUrl);
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, disabled: uploadDisabled });

    const handleUpload = async () => {
        try {
            setLoading('Loading...');
            await fetchCloud('upload/profile', 'post', {
                file: file,
            }, true, true);
            window.location.reload();
        }
        catch (err) {
            setLoading('Failed upload');
            console.error(err);
        }
    };

    const handleDelete = async () => {
        try {
            await fetchCloud(`delete/profile`, 'delete', null, false, true);
            window.location.reload();
        }
        catch (err) {
            console.error(err);
        }
    };

    const handleProfileSave = async () => {
        try {
            await fetchCloud(`users/timetableprofile/`, 'post', {
                first_name: firstName,
                last_name: lastName,
                email: email,
                title: title,
                tz: tz,
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastInfo);
        }
        catch (err) {
            setOpen(toastError);
        };
    };

    const handleChange = async () => {
        let startDiff = null;
        let endDiff = null;

        if (!alwaysActive) {
            let startUtc = moment.utc(startTime).set("second", 0);
            let startUtcMid = startUtc.clone().startOf('day');
            startDiff = startUtc.diff(startUtcMid, 'seconds');
            let endUtc = moment.utc(endTime).set("second", 0);
            let endUtcMid = endUtc.clone().startOf('day');
            endDiff = endUtc.diff(endUtcMid, 'seconds');
        }

        try {
            await fetchCloud('users/timetableprofile/', 'post', {
                utc_start: !alwaysActive ? startDiff : 0,
                utc_end: !alwaysActive ? endDiff : 86400,
                enable_days: Array.from(daysEnabled).sort()
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastDataShareUser);
        }
        catch (err) {
            setOpen(toastError);
        }
    };

    const handleChangeLocation = async () => {
        try {
            await fetchCloud('users/timetableprofile/', 'post', {
                location_enabled: !user.location_enabled,
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastDataShareUser);
        }
        catch (err) {
            setOpen(toastError);
        }
        setIsDisabled(true);
    };
    const handleChangeNotification = async () => {
        try {
            await fetchCloud('users/timetableprofile/', 'post', {
                push_enabled: !user.push_enabled,
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastDataShareUser);
        }
        catch (err) {
            setOpen(toastError);
        }
        setIsDisabled(true);
    };
    const handleChangePublicSocials = async () => {
        try {
            await fetchCloud('users/timetableprofile/', 'post', {
                public_social: !user.public_social,
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastDataShareUser);
        }
        catch (err) {
            setOpen(toastError);
        }
        setIsDisabled(true);
    };
    const handleChangePublic = async () => {
        try {
            await fetchCloud('users/timetableprofile/', 'post', {
                public: !user.public,
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastDataShareUser);
        }
        catch (err) {
            setOpen(toastError);
        }
        setIsDisabled(true);
    };
    const handleChangeCalendar = async () => {
        try {
            await fetchCloud('users/timetableprofile/', 'post', {
                public_calendar: !user.public_calendar,
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastDataShareUser);
        }
        catch (err) {
            setOpen(toastError);
        }
        setIsDisabled(true);
    };
    const handleChangeTracking = async () => {
        try {
            await fetchCloud('users/timetableprofile/', 'post', {
                allow_tracking: !user.allow_tracking,
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastDataShareUser);
        }
        catch (err) {
            setOpen(toastError);
        }
        setIsDisabled(true);
    };

    const [value, setValue] = React.useState(0);

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(user.social_groups);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = user.social_groups.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] ? searchRegex.test(row[field].toString()) : null;
            });
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        setRows(user.social_groups);
    }, [user.social_groups]);

    return (
        <>
            <Dialog
                open={uploadDialogOpen}
                onClose={() => setUploadDialogOpen(false)}
            >
                <DialogTitle>
                    <Typography component={"div"}>
                        Upload Profile Picture
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {!file &&
                        <div {...getRootProps()} style={{ borderStyle: "solid", borderWidth: 1, height: 200, width: "100%", alignItems: "center", justifyContent: "center" }}>
                            <input {...getInputProps()} />
                            {
                                uploadDisabled ? <p style={{ textAlign: "center" }}>File selected</p> :
                                    isDragActive ?
                                        <p>Drop the files here ...</p> :
                                        <p style={{ textAlign: "center", marginInline: 30 }}>{isSmall ? 'Press to select file' : 'Drag and drop some files here, or click to select files'}</p>
                            }
                        </div>}
                    {file && <Grid container >
                        <Grid item style={{}}>
                            <Typography component={"div"} style={{ margin: 10 }}>{file.name}</Typography>
                        </Grid>
                        <Grid item >
                            <Button style={{ marginTop: 3 }} variant="contained" onClick={() => {
                                setFile(null);
                                setUploadDisabled(false);
                                setImage(null);
                            }} > Remove </Button>

                        </Grid>
                    </Grid>}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                        {image && <Avatar src={image} sx={{ width: 150, height: 150 }} />}
                    </div>
                    <div style={{ marginTop: 20, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Button variant="contained" onClick={handleUpload} disabled={!file}> Confirm </Button>
                        <Typography component={"div"}>{loading}</Typography>
                    </div>
                </DialogContent>
            </Dialog>

            <Box sx={{ mt: -3 }}>
                <Tabs value={value} onChange={handleChangeTabs} variant="fullWidth" centered textColor="inherit">
                    <Tab icon={<SettingsIcon />} label={!isSmall ? "General" : null} />
                    <Tab icon={<ApartmentIcon />} label={!isSmall ? "Company Communications" : null} />
                    <Tab icon={<GroupIcon />} label={!isSmall ? "Group Communications" : null} />
                </Tabs>
            </Box>

            {/* TAB GENERAL */}
            <TabPanel value={value} index={0}>

                <Grid container spacing={2}>

                    {/* PROFILE PICTURE */}
                    <Grid item xs={12} sm={7} sx={{ mt: isSmall ? 3 : 0, display: "flex", alignItems: isSmall && "center", justifyContent: isSmall && "center" }}>
                        <Typography component={"div"} variant="h5" sx={{ textAlign: isSmall && "center" }}>
                            Profile Picture
                        </Typography>
                        <ConfirmationDialog
                            title={["Delete"]}
                            handleDevice={handleDelete}
                            value={valuePic}
                            name={"profile picture"}
                            /* address={value}  */
                            open={openPic}
                            setOpen={setOpenPic}
                            content={contentPic}
                        />
                    </Grid>

                    {!isSmall &&
                        <Grid item xs={5}>
                            <Typography component={"div"} variant="h5" sx={{ textAlign: isSmall && "center", display: "flex", alignItems: "center", }}>
                                Social Links
                                <HelperIcon content="Adding social media links will let your company and group members see them." style={{ marginLeft: 5 }} />
                            </Typography>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <Card elevation={1}
                            sx={{ backgroundColor: "#FFFFFF", padding: 3, borderWidth: 3, borderColor: theme.palette.primary.main + 80, borderStyle: "solid", borderRadius: 5 }}>
                            <Grid container spacing={2} sx={{ display: isSmall && "flex", alignItems: isSmall && "center", justifyContent: isSmall && "center" }}>
                                <Grid item xs={12} sm={7} sx={isMedium ? { minHeight: 320, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" } : { display: "flex", alignItems: "center", justifyContent: "flex-start", minHeight: 320 }}>
                                    <StyledBadgeAvatar
                                        noTooltip
                                        avatarVariant="circular"
                                        alt="ProfileIcon"
                                        imgSrc={userId}
                                        avatarSx={{ width: 200, height: 200, ml: !isMedium && 5 }}
                                        imageSize="_256"
                                    />
                                    <Box sx={isMedium ? { display: "flex", flexDirection: "row", mt: 2 } : { display: "flex", width: "100%" }}>
                                        <Button sx={{ marginLeft: !isMedium && 3 }} variant="contained" onClick={() => setUploadDialogOpen(true)}>
                                            Update Profile Picture
                                        </Button>
                                        <IconButton aria-label="delete" onClick={() => { setValuePic(userId); setOpenPic(true); }} sx={{ marginLeft: 1, display: isMedium && "none" }}>
                                            <DeleteIcon sx={{ color: theme.palette.mlRed.main }} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                                {/*                             <Grid item xs={12} sm={4} sx={{ alignItems: isSmall ? "center" : "flex-start", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                                        <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                                            <Button sx={{}} variant="contained" onClick={() => setUploadDialogOpen(true)}>
                                                Update Profile Picture
                                            </Button>
                                            <IconButton aria-label="delete" onClick={() => { setValuePic(userId); setOpenPic(true); }} sx={{ marginLeft: 1, display: isSmall && "none" }}>
                                                <DeleteIcon sx={{ color: theme.palette.mlRed.main }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid> */}
                                {isSmall &&
                                    <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 5 }}>
                                        <Typography component={"div"} variant="h5" sx={{ textAlign: isSmall && "center", display: "flex", alignItems: "center", }}>
                                            Social links
                                            <HelperIcon content="Adding social media links will let your company and group members see them." style={{ marginLeft: 5 }} />
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={5}
                                    sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-evenly", flexDirection: "column", minHeight: 200 }}>
                                    <SocialLinks user={user} setOpen={setOpen} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    {/* PROFILE SETTINGS */}
                    <Grid item xs={12}>
                        <Typography component={"div"} variant="h5" sx={{ textAlign: isSmall && "center", marginTop: 1 }}>
                            Profile Settings
                        </Typography>
                        <Typography component={"div"} variant="h6" sx={{ textAlign: isSmall && "center", color: "#808080" }}>
                            Change personal information for your account
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Card elevation={1} sx={{ backgroundColor: "#FFFFFF", padding: 3, borderWidth: 3, borderColor: theme.palette.primary.main + 80, borderStyle: "solid", borderRadius: 5 }}>

                            <Grid container sx={{ display: "flex" }}>
                                <Grid item xs={12} sm={2} sx={{}}>
                                    <Typography component={"div"} variant="h6" sx={{ textAlign: isSmall && "center", fontWeight: "bold", marginTop: 1 }}>
                                        First Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} sx={{}}>
                                    <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} id="FirstNameInput" label="First Name" variant="outlined" helperText="You can update your first name" fullWidth />
                                </Grid>
                            </Grid>

                            <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />

                            <Grid container sx={{ display: "flex" }}>
                                <Grid item xs={12} sm={2} sx={{}}>
                                    <Typography component={"div"} variant="h6" sx={{ textAlign: isSmall && "center", fontWeight: "bold", marginTop: 1 }}>
                                        Last Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} sx={{}}>
                                    <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} id="LastNameInput" label="Last Name" variant="outlined" helperText="You can update your last name" fullWidth />
                                </Grid>
                            </Grid>

                            <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />

                            <Grid container sx={{ display: "flex" }}>
                                <Grid item xs={12} sm={2} sx={{}}>
                                    <Typography component={"div"} variant="h6" sx={{ textAlign: isSmall && "center", fontWeight: "bold", marginTop: 1 }}>
                                        Title
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} sx={{}}>
                                    <TextField value={title} onChange={(e) => setTitle(e.target.value)} id="TitleInput" label="Title" variant="outlined" helperText="You can update your title" fullWidth />
                                </Grid>
                            </Grid>

                            <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />

                            <Grid container sx={{ display: "flex" }}>
                                <Grid item xs={12} sm={2} sx={{}}>
                                    <Typography component={"div"} variant="h6" sx={{ textAlign: isSmall && "center", fontWeight: "bold", marginTop: 1 }}>
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} sx={{}}>
                                    <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="EmailInput" label="Email" variant="outlined" helperText="You can update your email" fullWidth />
                                </Grid>
                            </Grid>

                            <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />

                            <Grid container sx={{ display: "flex" }}>
                                <Grid item xs={12} sm={2} sx={{}}>
                                    <Typography component={"div"} variant="h6" sx={{ textAlign: isSmall && "center", fontWeight: "bold", marginTop: 1 }}>
                                        Timezone
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} sx={{}}>
                                    <Autocomplete
                                        id="timezone-autocomplete-input"
                                        options={timezones}
                                        value={tz}
                                        onChange={(e, newVal) => setTz(newVal)}
                                        renderInput={(params) => <TextField {...params} label="Timezone" helperText="You can update your timezone" />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container sx={{ display: "flex" }}>
                                <Grid item xs={12} sm={12} sx={{ display: isSmall && "flex", justifyContent: isSmall && "center" }}>
                                    <Button variant="contained" onClick={handleProfileSave} sx={{ marginTop: isSmall && 2, float: !isSmall && "right", marginRight: !isSmall && 3, width: !isSmall && 80 }}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>

                        </Card>

                    </Grid>

                </Grid >

            </TabPanel>

            {/* TAB COMPANY COMMUNICATIONS */}
            <TabPanel value={value} index={1}>
                <>
                    <Grid item xs={12} sx={{ mt: isSmall ? 3 : 0 }}>

                        <Card elevation={1} sx={{ backgroundColor: "#FFFFFF", padding: 3, borderWidth: 3, borderColor: theme.palette.primary.main + 80, borderStyle: "solid", borderRadius: 5 }}>

                            <Grid container sx={{ display: "flex" }}>
                                <Grid item xs={12} sm={2} sx={{}}>
                                    <Typography component={"div"} variant="h5" sx={{ textAlign: isSmall && "center", fontWeight: "bold", marginTop: 1, marginBottom: isSmall ? 2 : 4 }}>
                                        Company communication
                                    </Typography>
                                </Grid>
                                {/* Permission CheckBoxes Component */}
                                <ProfilePermissions
                                    isDisabled={isDisabled}
                                    setIsDisabled={setIsDisabled}
                                    handleChangeLocation={handleChangeLocation}
                                    handleChangeNotification={handleChangeNotification}
                                    handleChangePublicSocials={handleChangePublicSocials}
                                    handleChangePublic={handleChangePublic}
                                    handleChangeCalendar={handleChangeCalendar}
                                    handleChangeTracking={handleChangeTracking}
                                />

                                <Grid container sx={{ marginBottom: 3, textAlign: "center" }}>

                                    <Grid item xs={12} mt={3} mb={3}>
                                        <Divider flexItem sx={{ width: "60%", mb: 3, borderBottomWidth: 2 }} />
                                        <Typography sx={{ fontWeight: "bold" }} component={"div"} variant="h5" textAlign={isSmall ? "small" : "left"} >
                                            Visibility time
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 3 }}>
                                        <Divider flexItem variant="fullWidth" sx={{ mb: 3, borderBottomWidth: 2 }} />
                                        <Grid container>
                                            <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                                                    Always active
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={10}>
                                                <Grid container>
                                                    <Grid item xs={5} md={12} sx={{ display: "flex", justifyContent: isSmall ? "center" : "left", alignItems: "center" }}>
                                                        <Checkbox
                                                            checked={alwaysActive}
                                                            onChange={(e) => {
                                                                setAlwaysActive(!alwaysActive);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={7} md={12} sx={{ display: "flex", alignItems: "center" }}>
                                                        <Typography component={"div"} variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                                            This choice only affects time
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 3 }}>
                                        <Grid container>
                                            <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                                                    Active time
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={10}>
                                                <Grid container sx={{ my: 1 }}>
                                                    <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", mr: isSmall || isMedium ? 0 : 3 }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <TimePicker
                                                                label="Start time"
                                                                ampm={false}
                                                                value={startTime}
                                                                onChange={(e) => setStartTime(e)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                disabled={alwaysActive}
                                                                views={['hours']}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={12} md={2} sx={{ mt: isSmall || isMedium ? 3 : 0, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <TimePicker
                                                                label="End time"
                                                                ampm={false}
                                                                value={endTime}
                                                                onChange={(e) => setEndTime(e)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                disabled={alwaysActive}
                                                                views={['hours']}
                                                                readOnly
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 3 }}>
                                        <Grid container>
                                            <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                                                    Active days
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", ml: !isSmall ? 5.5 : 0 }}>
                                                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((item, idx) => (
                                                    <Grid key={idx} item xs={2} md={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <DayEnabledCheckbox list={daysEnabled} num={idx + 1} setList={setDaysEnabled} day={item} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                            <Grid item xs={12} md={2} sx={{ marginTop: 3 }} >
                                                <Button variant="contained" onClick={handleChange}>Save</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>

                            </Grid>

                        </Card>

                    </Grid >
                </>
            </TabPanel>

            {/* GROUP COMMUNICATIONS */}
            <TabPanel value={value} index={2}>
                <Card elevation={1} sx={{ mt: isSmall ? 3 : 0, backgroundColor: "#FFFFFF", padding: 3, borderWidth: 3, borderColor: theme.palette.primary.main + 80, borderStyle: "solid", borderRadius: 5 }}>

                    <Grid container sx={{ display: "flex" }}>
                        <Grid item xs={12} sx={isSmall ? {} : { display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
                            <Typography component={"div"} variant="h6" sx={{ textAlign: isSmall && "center", fontWeight: "bold", marginTop: 1 }}>
                                Groups
                            </Typography>
                            <SearchBar
                                sx={{
                                    width: {
                                        xs: 1,
                                        sm: 'auto',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        mr: 0.5,
                                    },
                                    '& .MuiInput-underline:before': {
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    },
                                    my: 1
                                }}
                                value={searchText}
                                onChange={(event) => requestSearch(event.target.value)}
                                clearSearch={() => requestSearch('')}
                            />
                        </Grid>
                        <Grid container sx={{ textAlign: "center" }}>
                            {rows.length > 0
                                ?
                                rows.sort((a, b) => a.name > b.name ? 1 : -1).map(item => (
                                    <Grid key={item.id} item xs={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="group-content"
                                                id="group-header"
                                                sx={{ '&:hover': { backgroundColor: theme.palette.primary.main + 80 }, cursor: "pointer" }}
                                            >
                                                <SmallAvatarWrapper
                                                    alt="GroupPic"
                                                    userId={item.id}
                                                    imageType="socialgrouppic"
                                                    sx={{ width: 50, height: 50 }}
                                                    imageSize="_64"
                                                />
                                                <Typography component={"div"} sx={isSmall ? { width: "100%", px: 1, display: "flex", alignItems: "center", justifyContent: "center" } : { ml: 3, fontSize: 24, marginTop: 0.5 }}>{item.name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <SocialGroupPermissionCheck item={item} setOpen={setOpen} />
                                                {/* <Button variant="contained" onClick={() => {
                                                        setSocialModalItem(item);
                                                        setSocialModalOpen(true);
                                                    }}>Edit</Button> */}
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider sx={{ background: theme.palette.primary.main + 80, borderBottomWidth: 3 }} />
                                    </Grid>
                                )
                                )
                                :
                                <Grid container sx={{ marginBottom: 3 }}>
                                    <Grid item xs={12}>
                                        <Typography component={"div"} variant="h6" sx={{ textAlign: "center" }}>
                                            You have not joined any group yet.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Card>
            </TabPanel>
        </>
    );
}


function SocialGroupPermissionCheck({ item, setOpen }) {
    const [publicSocial, setPublicSocial] = useState(item.permissions.public_social);
    const [publicDashboard, setPublicDashboard] = useState(item.permissions.public_dashboard);
    const [publicCalendar, setPublicCalendar] = useState(item.permissions.public_calendar);
    const [startTime, setStartTime] = useState(moment.utc().startOf('day').add(item.permissions.utc_start).local());
    const [endTime, setEndTime] = useState(moment.utc().startOf('day').add(item.permissions.utc_end).local());
    const [daysEnabled, setDaysEnabled] = useState(new Set(item.permissions.enable_days));
    const { fetchCloud } = useFetchCloud();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [alwaysActive, setAlwaysActive] = useState(item.permissions.utc_start === "00:00:00" && item.permissions.utc_end === "1 00:00:00");
    const { mutate } = useSWRConfig();

    const handleChange = async () => {
        let startDiff = null;
        let endDiff = null;

        if (!alwaysActive) {
            let startUtc = moment.utc(startTime).set("second", 0);
            let startUtcMid = startUtc.clone().startOf('day');
            startDiff = startUtc.diff(startUtcMid, 'seconds');
            let endUtc = moment.utc(endTime).set("second", 0);
            let endUtcMid = endUtc.clone().startOf('day');
            endDiff = endUtc.diff(endUtcMid, 'seconds');
        }

        try {
            await fetchCloud(`users/update_social_group_perm`, 'post', {
                social_group: item.id,
                public_social: publicSocial,
                public_dashboard: publicDashboard,
                public_calendar: publicCalendar,
                utc_start: !alwaysActive ? startDiff : 0,
                utc_end: !alwaysActive ? endDiff : 86400,
                enable_days: Array.from(daysEnabled).sort()
            });
            setOpen(toastDataShareGroup);
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            setOpen(toastError);
            console.log(err)
        }
    };

    useEffect(() => {
        setPublicSocial(item.permissions.public_social);
        setPublicDashboard(item.permissions.public_dashboard);
        setPublicCalendar(item.permissions.public_calendar);
        setStartTime(moment.utc().startOf('day').add(item.permissions.utc_start).local());
        setEndTime(moment.utc().startOf('day').add(item.permissions.utc_end).local());
        setDaysEnabled(new Set(item.permissions.enable_days));
    }, [item]);

    return (

        <Grid container sx={{ textAlign: "center" }}>
            <Grid item xs={12} sx={{ mb: 3 }}>
                <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                            Public social
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: "flex", justifyContent: isSmall ? "center" : "left", alignItems: "center" }}>
                                <Checkbox checked={publicSocial} onChange={() => setPublicSocial(prev => !prev)} />
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography component={"div"} variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    {contentPublicSocialsGroup}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
                <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                            Public dashboard
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: "flex", justifyContent: isSmall ? "center" : "left", alignItems: "center" }}>
                                <Checkbox checked={publicDashboard} onChange={() => setPublicDashboard(prev => !prev)} />
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography component={"div"} variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    {contentPublic}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                            Public calendar
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: "flex", justifyContent: isSmall ? "center" : "left", alignItems: "center" }}>
                                <Checkbox checked={publicCalendar} onChange={() => setPublicCalendar(prev => !prev)} />
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography component={"div"} variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    {contentPublicCalendar}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: 3, textAlign: "center" }}>
                <Grid item xs={12} mt={3} mb={3}>
                    <Divider sx={{ my: 1, borderBottomWidth: 2 }} />
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} component={"div"} variant="h5" textAlign={isSmall ? "small" : "left"} >
                        Visibility time
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }}>
                    <Grid container>
                        <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                                Always active
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container>
                                <Grid item xs={5} md={12} sx={{ display: "flex", justifyContent: isSmall ? "center" : "left", alignItems: "center" }}>
                                    <Checkbox
                                        checked={alwaysActive}
                                        onChange={(e) => {
                                            setAlwaysActive(!alwaysActive);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={7} md={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography component={"div"} variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                        This choice only affects time
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }}>
                    <Grid container>
                        <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                                Active time
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container sx={{ my: 1 }}>
                                <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", mr: isSmall ? 0 : 3 }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <TimePicker
                                            label="Start time"
                                            ampm={false}
                                            value={startTime}
                                            onChange={(e) => setStartTime(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                            disabled={alwaysActive}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={2} sx={{ mt: isSmall ? 3 : 0, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <TimePicker
                                            label="End time"
                                            ampm={false}
                                            value={endTime}
                                            onChange={(e) => setEndTime(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                            disabled={alwaysActive}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }}>
                    <Grid container>
                        <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                                Active days
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", ml: !isSmall ? 5.5 : 0 }}>
                            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((item, idx) => (
                                <Grid key={idx} item xs={2} md={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <DayEnabledCheckbox list={daysEnabled} num={idx + 1} setList={setDaysEnabled} day={item} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <Grid item xs={12} md={2} sx={{ marginTop: 3 }} >
                            <Button variant="contained" onClick={handleChange}>Save</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
