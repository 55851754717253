import * as React from 'react';
/* import PropTypes from 'prop-types'; */
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBar({ clearSearch, ...props }) {
    return (
        <TextField
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
            placeholder="Search…"
            color="primary"
            InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                    <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: props.value ? 'visible' : 'hidden' }}
                        onClick={clearSearch}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                ),
            }}
            {...props}
        />
    );
}

/* 
SearchBar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
}; 
*/