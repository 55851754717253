import * as React from 'react';
/* import PropTypes from 'prop-types'; */
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

export default function SearchToolbar({ clearSearch, ...props }) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
            }}
        >
            <div>
                {/* <GridToolbarFilterButton /> */}
                <GridToolbarDensitySelector />
            </div>
            {!props.hideSearch &&
                <TextField
                    variant="standard"
                    value={props.value}
                    onChange={props.onChange}
                    placeholder="Search…"
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                onClick={clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        width: {
                            xs: 1,
                            sm: 'auto',
                        },
                        m: (theme) => theme.spacing(1, 0.5, 1.5),
                        '& .MuiSvgIcon-root': {
                            mr: 0.5,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                    }}
                />
            }
        </Box>
    );
}

/* 
SearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
}; 
*/