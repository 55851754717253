import * as React from 'react';
import { useState, useContext, useRef, useEffect, useMemo } from "react";
import { Grid, Typography, createTheme, Box, styled, Badge } from "@mui/material";
import MainContext from "../Backend/MainContext";
import Clock from "../Clock";
import LinearLoading from "../LinearLoading";
import SocialsDashboardLandscape from './SocialsDashboardLandscape';
import SocialsDashboardPortrait from './SocialsDashboardPortrait';
import { dayjs } from '../dayjs-utils';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';

/* Defines if the current time is between SocialGroups' active time */
function getActiveState(utc_start, utc_end) {
    let [hoursEnd, minsEnd] = utc_end && utc_end.split(':');
    let [hours, mins] = utc_start.split(':');
    let yesterday = dayjs.utc().startOf('day').subtract(1, 'day');
    let today = dayjs.utc().startOf('day');
    let tomorrow = dayjs.utc().startOf('day').add(1, 'day');

    if (hours > hoursEnd) {
        let isBetween1 = dayjs().isBetween(yesterday.set('hour', hours).set('minute', mins).local(), today.set('hour', hoursEnd).set('minute', minsEnd).local());
        let isBetween2 = dayjs().isBetween(today.set('hour', hours).set('minute', mins).local(), tomorrow.set('hour', hoursEnd).set('minute', minsEnd).local());

        return (isBetween1 || isBetween2);
    }
    else {
        return (dayjs().isBetween(today.set('hour', hours).set('minute', mins).local(), today.set('hour', hoursEnd).set('minute', minsEnd).local()));
    }
}

const StyledBadge = styled(Badge)(({ status, badgewidth, badgeheight, badgeboxshadow, badgeanimation, lastseenminutes, theme, badgecolor }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: badgecolor,
        color: badgecolor,
        width: badgewidth,
        height: badgeheight,
        borderRadius: "50%",
        boxShadow: badgeboxshadow,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        '&::after':
        {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
            display: !badgeanimation ? "none" : undefined
        }
    },
    '& .MuiBadge-root': {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "red"
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.5)',
            opacity: 0,
        },
    },
}));

export default function LayoutTemplate({ socialsData, avatarSize, fontSize, gridFlexNumber, type, badgeSize, users, onlineData, titleFontSize, utc_start, utc_end, enable_days, small, orientation }) {
    const { user } = useContext(MainContext);
    const [onlineMembers, setOnlineMembers] = useState(0);
    const socials =
        type === "dashboard" ? user.company_dashboard?.socials :
            type === "user" ? user?.dashboard?.socials : socialsData;
    const [time, setTime] = useState(dayjs());
    const timeInter = useRef();
    const [isActive, setIsActive] = useState((utc_start && utc_end) ? getActiveState(utc_start, utc_end) : true);

    var format = 'HH:mm',
        startTime = dayjs(socials?.onlineTime?.start ? socials?.onlineTime?.start : "00:00", format),
        endTime = dayjs(socials?.onlineTime?.end ? socials?.onlineTime?.end : "24:00", format);

    useEffect(() => {
        let tempNum = 0;
        if (onlineData) {
            for (const userObj of onlineData) {
                const now = dayjs();
                const lastSeen = userObj?.timestamp ? dayjs(userObj.timestamp) : null;
                const duration = lastSeen ? dayjs.duration(now.diff(lastSeen)) : null;
                const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                if (lastSeen && lastseenminutes <= 10) {
                    tempNum++;
                }
            }
        }
        setOnlineMembers(tempNum);
    }, [onlineData]);

    useEffect(() => {
        timeInter.current = setInterval(() => {
            setTime(dayjs());
            if (utc_start && utc_end) {
                setIsActive(getActiveState(utc_start, utc_end));
            }
        }, 60000);

        return () => clearInterval(timeInter.current);
    }, [utc_start, utc_end]);

    const shouldRender = useMemo(() => {
        if (type === 'group') {
            if ((enable_days.includes(dayjs().isoWeekday()) || enable_days === undefined) && isActive) {
                if (socials?.onlineTime?.showWeekDay) {
                    if (socials?.onlineTime?.showWeekDay.includes(dayjs().isoWeekday()) || socials?.onlineTime?.showWeekDay === undefined || socials === undefined) {
                        if (time.isBetween(startTime, endTime)) {
                            return true;
                        }
                    }
                }
                else {
                    if (time.isBetween(startTime, endTime)) {
                        return true;
                    }
                }
            }
        }
        else {
            if (socials?.onlineTime?.showWeekDay) {
                if (socials?.onlineTime?.showWeekDay.includes(dayjs().isoWeekday()) || socials?.onlineTime?.showWeekDay === undefined || socials === undefined) {
                    if (time.isBetween(startTime, endTime)) {
                        return true;
                    }
                }
            }
            else {
                if (time.isBetween(startTime, endTime)) {
                    return true;
                }
            }
        }
        return false;
    }, [enable_days, endTime, isActive, socials, startTime, time, type]);

    return (
        <Grid container
            /* Background image and color */
            sx={{
                backgroundImage: socials?.background?.path || socials?.background?.url
                    ? socials.background.path
                        ? `url(${process.env.PUBLIC_URL + socials.background.path})`
                        : `url(${socials.background.url})`
                    : "",
                backgroundColor: socials?.backgroundColor ? socials.backgroundColor : "#fff",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: socials?.backgroundSize,
                height: "100%"
            }}>
            <Grid item xs={socials?.hasSecondLogo ? 4 : 3}
                /*  Company logo */
                sx={{
                    height: "15%",
                    backgroundImage: socials?.companylogo?.path || socials?.companylogo?.url
                        ? socials.companylogo.path
                            ?
                            `url(${process.env.PUBLIC_URL + socials.companylogo?.path})`
                            : `url(${socials.companylogo?.url})`
                        : "",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: socials?.companylogoSize
                }}
            >
            </Grid>
            {socials?.hasSecondLogo
                ?
                <>
                    <Grid item xs={2}
                        /*  Second Company logo */
                        sx={{
                            height: "15%",
                            backgroundImage: socials?.companylogo2?.path || socials?.companylogo2?.url
                                ? socials.companylogo2?.path
                                    ?
                                    `url(${process.env.PUBLIC_URL + socials.companylogo2?.path})`
                                    : `url(${socials.companylogo2?.url})`
                                : "",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: socials?.companylogo2Size
                        }}
                    >
                    </Grid>
                    <Grid item xs={3} />
                </>
                :
                <Grid item xs={6}
                    /* Layout title */
                    sx={style.gridItem1}>
                    <Typography variant="h4" sx={{ fontSize: 16, color: socials?.titleColor }}>
                        {socials?.title}
                    </Typography>
                </Grid>
            }
            <Grid item xs={3}
                /* Clock */
                sx={style.gridClock}>
                <Clock fontSize={16} textColor={socials?.clockColor} />
            </Grid>
            <Grid item xs={12}
                /* Main content, for example Social status screen */
                sx={style.gridContent}>
                {
                    shouldRender
                        ?
                        orientation === "portrait"
                            ?
                            <SocialsDashboardPortrait
                                textColor={socials?.textColor}
                                subheaderColor={socials?.subheaderColor}
                                hasUserTitle={socials?.hasUserTitle}
                                hasLocation={socials?.hasLocation}
                                avatarSize={avatarSize}
                                fontSize={fontSize}
                                titleFontSize={titleFontSize}
                                gridFlexNumber={gridFlexNumber}
                                badgeSize={badgeSize}
                                users={users}
                                onlineData={onlineData}
                                small={small}
                            />
                            :
                            <SocialsDashboardLandscape
                                textColor={socials?.textColor}
                                subheaderColor={socials?.subheaderColor}
                                hasUserTitle={socials?.hasUserTitle}
                                hasLocation={socials?.hasLocation}
                                avatarSize={avatarSize}
                                fontSize={fontSize}
                                titleFontSize={titleFontSize}
                                gridFlexNumber={gridFlexNumber}
                                badgeSize={badgeSize}
                                users={users}
                                onlineData={onlineData}
                                small={small}
                            />
                        :
                        <Typography variant="h5" sx={{ color: socials?.titleColor, textAlign: "center", top: "35%", position: "relative" }}>
                            <div>Charging Batteries...</div>
                            <BatteryCharging60Icon sx={{ height: 50, width: 50, transform: "rotate(90deg)" }} />
                        </Typography>
                }
            </Grid>
            <Grid item xs={7} sx={style.gridFooter}>
                <Box sx={{ color: "#ebecf0", flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <StyledBadge badgeContent="" badgewidth="30x" badgeheight="20px" badgecolor="#8DBF30" />
                    <Typography variant="h6" sx={{ color: "white", marginX: "4vw", fontSize: "3vw" }}>
                        Present: {onlineMembers}
                    </Typography>
                    <StyledBadge badgeContent="" badgewidth="30x" badgeheight="20px" badgecolor='#adadb8' />
                    <Typography variant="h6" sx={{ color: "white", marginX: "4vw", fontSize: "3vw" }}>
                        Away: {users?.length - onlineMembers}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={5}
                /* Powered By */
                sx={{
                    height: "10%",
                    backgroundImage: socials?.poweredBlack ? `url(${process.env.PUBLIC_URL + "/MissingLink_PoweredBy_NoBg_Black.png"})` : `url(${process.env.PUBLIC_URL + "/MissingLink_PoweredBy_NoBg.png"})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: orientation === "portrait" ? "100%" : "60%",
                    width: "100%",
                }}>
            </Grid>
            <Grid item xs={12}
                /* Loading bar */
                sx={style.gridBottombar}>
                <LinearLoading sx={{ color: "#4EC1ED", }} data={onlineData} />
            </Grid>
        </Grid >
    );
}

const style = createTheme({
    layoutGrid: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "lightgreen"
    },
    gridLogo: {

    },
    gridItem1: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "15%",
    },
    gridClock: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "15%",
    },
    gridContent: {
        height: "70%",
    },
    gridPicture: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "70%",
    },
    gridFooter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "10%",
    },
    gridPoweredBy: {
    },
    gridBottombar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "5%",
    }
});
