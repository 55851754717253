import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootRoute from './Components/Backend/RootRoute';

const container = ReactDOMClient.createRoot(document.getElementById('root'));
container.render(<React.StrictMode>
    <ToastContainer />
    <BrowserRouter>
        <RootRoute />
    </BrowserRouter>
</React.StrictMode>);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(toast);

// If service workers are supported...
if ('serviceWorker' in navigator) {
    // Check to see if the page is currently controlled.
    let isControlled = Boolean(navigator.serviceWorker.controller);

    // Listen for a change to the current controller...
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (isControlled) {
            // ...and if the page was previosly controlled, reload the page.
            window.location.reload();
        } else {
            // ...otherwise, set the flag for future updates, but don't reload.
            isControlled = true;
        }
    });

    navigator.serviceWorker.getRegistration()
        .then((registration) => {
            if (registration?.waiting?.postMessage) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
