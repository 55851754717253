import { useContext, useCallback, useState, useRef } from 'react';
import MainContext from './MainContext';
import { handleAuthentication } from './CloudFetcher';

export const useImageProvider = () => {
    const { accessToken, tokenType } = useContext(MainContext);
    const [imageData, setImageData] = useState(null);
    const accessRef = useRef(accessToken);

    const getImage = useCallback(async (id, imageType, imageSize) => {
        const authstr = tokenType === 'bearer' ? 'Bearer ' : 'Token ';

        const requestVar = new Request(`${process.env.REACT_APP_IMAGE_SERVE_HOST}/${imageType}/${id}${imageSize ?? ''}.webp`, {
            method: 'GET',
            headers: new Headers({ 'Authorization': authstr + accessRef.current.access, })
        });

        try {
            var cache = await caches.open('pwa-cache');
            const cacheRes = await cache.match(requestVar);

            const cacheBlob = cacheRes ? await cacheRes.blob() : null;
            if (cacheBlob && cacheBlob.size > 0) setImageData(URL.createObjectURL(cacheBlob));
        }
        catch {
            console.log('Cache unavailable');
        }

        try {
            if (tokenType === 'bearer') {
                const auth = await handleAuthentication(accessRef.current.access, accessRef.current.refresh);
                accessRef.current.access = auth;
            }
            const bearerFetchRes = await fetch(requestVar);
            if (bearerFetchRes.ok) {
                const fetchBlob = await bearerFetchRes.blob();
                if (fetchBlob && fetchBlob.size > 0) setImageData(URL.createObjectURL(fetchBlob));
                else setImageData(null);
                try {
                    cache.add(requestVar);
                }
                catch {
                    console.log('Cache unavailable');
                }
            }
            else {
                console.error(bearerFetchRes.statusText);
            }
        }
        catch (err) {
            console.error(err);
        }
    }, []);

    return { getImage, imageData };
};