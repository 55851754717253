import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { dayjs } from './dayjs-utils';

export default function Clock({ textColor, fontSize, variant, mr, tz, showTz }) {
    const [time, setTime] = useState(dayjs().tz(tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm:ss'));

    const updateTime = useCallback(() => {
        setTime(dayjs().tz(tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm:ss'));
    }, [tz]);

    useEffect(() => {
        const inter = setInterval(updateTime, 1000);
        return () => clearInterval(inter);
    }, [updateTime]);

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Typography variant={variant} style={{ color: textColor, fontSize: fontSize ?? "2vw", textAlign: "center", marginRight: mr }}>
                {time}
            </Typography>
            <Typography variant={variant} style={{ color: textColor, fontSize: fontSize * 0.5 ?? "2vw" * 0.5, textAlign: "center", marginRight: mr, display: !showTz && "none" }}>
                {tz}
            </Typography>
        </Box>
    );
}