import { Box, Button, Grid, TextField, Typography, Paper, Container, Divider } from '@mui/material';
import { useLayoutEffect, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Toast from '../Components/Toast';
import Copyright from '../Components/Copyright';
import CssBaseline from '@mui/material/CssBaseline';
import MissingLinkLogo from '../Assets/Images/missingLinkLogo.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import jwt_decode from 'jwt-decode';


export default function PasswordReset(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [validToken, setValidToken] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    useLayoutEffect(() => {
        if (token) {
            try {
                var tokenTest = jwt_decode(token).exp * 1000;
                if (new Date(tokenTest) > new Date()) {
                    setValidToken(true);
                }
                else setValidToken(false);
            }
            catch (e) {
                setValidToken(false);
            }
        }
    }, [token]);

    const handleSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        try {
            var response = await fetch(process.env.REACT_APP_AUTH_HOST + '/api/users/new_password', {
                method: 'POST',
                body: JSON.stringify({
                    password: newPassword,
                    token: token
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            });
            if (response.ok) {
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
                localStorage.removeItem('userId');
                setSuccess(true);
            }
            else {
                const text = await response.json();
                setError(text);
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (success) {
            setTimeout(() => navigate('/login'), 7000);
        }
    }, [navigate, success]);

    return (
        <Grid
            container
            component="form"
            onSubmit={handleSubmit}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'repeat',
                width: '100%',
                height: "100%",
                position: "fixed",
                background: "linear-gradient(70deg, rgba(231,45,80,.3), rgba(231,45,80,0) 70.71%), linear-gradient(140deg, rgba(141,191,48,.3), rgba(141,191,48,0) 70.71%), linear-gradient(240deg, rgba(78,193,237,.3), rgba(78,193,237,0) 70.71%), linear-gradient(320deg, rgba(0,28,81,.3), rgba(0,28,81,0) 70.71%)"
            }}>
            {validToken !== null &&
                <Paper
                    elevation={8}
                    sx={{ backgroundColor: "#ffffff", borderRadius: 10, padding: 2, margin: 5 }}
                >
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src={MissingLinkLogo} alt="MissingLinlogo" width={"50%"} style={{}} />

                            {validToken
                                ?
                                <Box sx={{ marginTop: 3 }}>
                                    <Typography component={"div"} variant={isSmall ? "caption" : "overline"} sx={{ fontSize: 18, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        Create new password
                                    </Typography>
                                    <Divider />
                                    <Typography component={"div"} color="#808080" textAlign="center">
                                        Enter new password
                                    </Typography>
                                    <TextField
                                        margin="normal"
                                        type="password"
                                        required
                                        fullWidth
                                        id="password"
                                        label='Password'
                                        name="password"
                                        autoComplete="off"
                                        autoFocus
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)} />
                                    <TextField
                                        margin="normal"
                                        type="password"
                                        required
                                        fullWidth
                                        id="password-repeat"
                                        label='Repeat password'
                                        name="password-repeat"
                                        autoComplete="off"
                                        value={repeatPassword}
                                        onChange={(e) => setRepeatPassword(e.target.value)}
                                    />
                                    <Button disabled={(!newPassword || !repeatPassword) || (newPassword !== repeatPassword)}
                                        fullWidth variant="contained" type="submit" sx={{ marginTop: 3 }}>
                                        Change Password
                                    </Button>

                                    <Toast
                                        sx={{}}
                                        type="error"
                                        title={error?.data}
                                        message={error?.hint?.split(";")[0]}
                                        message2={error?.hint?.split(";")[1]}
                                        verticalPos="bottom"
                                        horizontalPos="center"
                                        open={error}
                                        handleClose={() => setError(false)}
                                        autoHideDuration={5000}
                                    />

                                    <Toast
                                        sx={{}}
                                        type="success"
                                        title="Success"
                                        message="New password saved!"
                                        message2="You will be navigated to main page shortly"
                                        verticalPos="bottom"
                                        horizontalPos="center"
                                        open={success}
                                        handleClose={() => setSuccess(false)}
                                        autoHideDuration={7000}
                                    />

                                </Box>
                                :
                                <Box sx={{ marginTop: 5 }}>
                                    <Typography component={"div"} variant={isSmall ? "caption" : "overline"} sx={{ fontSize: 18, display: "flex", alignItems: "center", justifyContent: "center", color: "#E72D50" }}>
                                        Link is invalid or expired.
                                    </Typography>
                                    <Divider />
                                    <Typography component={"div"} color="#808080" textAlign="center">
                                        Resend password recovery email
                                    </Typography>
                                    <Button variant="contained" onClick={() => navigate('/login')} fullWidth sx={{ marginTop: 5 }}>
                                        Return to login
                                    </Button>
                                </Box>
                            }
                        </Box>

                        <Copyright style={{ mt: 4, mb: 2 }} />

                    </Container>

                </Paper>
            }
        </Grid>
    );
}