import React, { useState, useContext, useEffect } from "react";
import { MainContext, LayoutTemplate, useWsDataProvider, LayoutTemplateMobile } from "kerberus-components";
import useSWR from 'swr';
import { useTheme, useMediaQuery } from "@mui/material";

export default function DashboardModalCompany(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useContext(MainContext);
    const { data, getData, doUnsubscribe } = useWsDataProvider();
    const [onlineData, setOnlineData] = useState(null);
    const [userList, setUserList] = useState([]);
    const { data: profilelistSocialData } = useSWR('users/profilelistsocial/');

    const [orientation, setOrientation] = useState(window.orientation);

    window.onresize = function (event) {
        applyOrientation();
    };

    function applyOrientation() {
        if (window.innerHeight > window.innerWidth) {
            setOrientation("portrait");
        } else {
            setOrientation("landscape");
        }
    }

    useEffect(() => {
        applyOrientation();
    }, []);

    useEffect(() => {
        if (profilelistSocialData) {
            setUserList(profilelistSocialData);
        }
    }, [profilelistSocialData]);

    useEffect(() => {
        if (userList.length > 0) {
            getData('socketLocation', 'lastFoundMany', {
                subscribe: true,
                filters: {
                    company: user.user_company.id
                }
            });
        }
        return () => {
            doUnsubscribe();
        };
    }, [userList, getData, doUnsubscribe, user.user_company]);

    useEffect(() => {
        if (data) {
            setOnlineData(data);
        }
    }, [data]);

    return (

        isSmall
            ?
            <LayoutTemplateMobile
                orientation={orientation}
                onlineData={onlineData}
                users={userList}
                socialsData={user?.user_company.dashboard.data.socials}
                avatarSize={orientation === "portrait" ? 80 : 40}
            />
            :
            <LayoutTemplate
                onlineData={onlineData}
                users={userList}
                socialsData={user?.user_company.dashboard.data.socials}
                avatarSize="5vw"
                fontSize="1.00vw"
                gridFlexNumber={1.25}
                badgeSize="1.5vw"
                titleFontSize="0.65vw"
                small
            />
    );
}