import { SmallAvatarWrapper } from 'kerberus-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const DatagridColumns = [
    {
        field: 'id', headerName: '', flex: 0.1, align: "center", renderCell: (e) => (
            <SmallAvatarWrapper alt="ProfilePic" userId={e.row.id} sx={{ width: 32, height: 32 }} imageSize="_32" />
        )
    },
    {
        field: 'username', flex: 0.2, headerName: 'Username'
    },
    {
        field: 'public', flex: 0.1, headerName: 'Public',
        renderCell: (e) => e.value ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CheckCircleIcon color="green" sx={{}} />
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CancelIcon sx={{ color: "red" }} />
            </div>
    },
    {
        field: 'public_calendar', flex: 0.1, headerName: 'Calendar',
        renderCell: (e) => e.value ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CheckCircleIcon color="green" sx={{}} />
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CancelIcon sx={{ color: "red" }} />
            </div>
    },
    {
        field: 'public_social', flex: 0.1, headerName: 'Social',
        renderCell: (e) => e.value ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CheckCircleIcon color="green" sx={{}} />
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CancelIcon sx={{ color: "red" }} />
            </div>
    },
    {
        field: 'terms_accepted', flex: 0.1, headerName: 'Terms',
        renderCell: (e) => e.value ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CheckCircleIcon color="green" sx={{}} />
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CancelIcon sx={{ color: "red" }} />
            </div>
    },
    {
        field: 'next', flex: 0.1, headerName: 'Next',
        renderCell: (e) => e.value ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CheckCircleIcon color="green" sx={{}} />
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                <CancelIcon sx={{ color: "red" }} />
            </div>
    },
    {
        field: 'first_name', flex: 0.3, headerName: 'First name'
    },
    {
        field: 'last_name', flex: 0.3, headerName: 'Last name'
    },
    {
        field: 'company_name', flex: 0.3, headerName: 'Company name'
    },
    {
        field: 'email', flex: 0.5, headerName: 'Email'
    },
];

export default DatagridColumns;