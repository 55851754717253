import { Tooltip, Badge, Avatar, useTheme } from '@mui/material';
import * as React from 'react';
import DeviceIcons from '../Icons/DeviceIcons';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';

export default React.memo(function GetDeviceIcon({ value, badgeScale, tooltipPlacement, badgeOpacity, customTooltip, ...props }) {
    const theme = useTheme();
    const hasBluetooth = value?.addresses.some((item) => item.bluetooth);
    const hasWifi = value?.addresses.some((item) => !item.bluetooth);
    let tooltip = "";
    switch (value?.type) {
        case 0:
            tooltip = "Unknown";
            break;
        case 1:
            tooltip = "Android";
            break;
        case 2:
            tooltip = "Iphone";
            break;
        case 3:
            tooltip = "Android Tablet";
            break;
        case 4:
            tooltip = "Ipad";
            break;
        case 5:
            tooltip = "Laptop";
            break;
        case 6:
            tooltip = "Desktop";
            break;
        case 7:
            tooltip = "Tv";
            break;
        case 8:
            tooltip = "Headphones";
            break;
        case 9:
            tooltip = "Watch";
            break;
        case 10:
            tooltip = "Router";
            break;
        case 11:
            tooltip = "Iot Device";
            break;
        default:
            tooltip = "Iot Device";
    }
    return (
        <Tooltip title={customTooltip ?? tooltip} placement={tooltipPlacement}>
            <Badge
                invisible={!value?.addresses.length > 0 || !hasWifi}
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                badgeContent={
                    <SignalWifi3BarIcon
                        sx={{
                            height: 15,
                            width: 15,
                            borderRadius: "50%",
                            color: "white",
                            backgroundColor: theme.palette.primary.main,
                            transform: `scale(${badgeScale})`,
                            p: 0.2,
                            opacity: badgeOpacity,
                        }}
                    />
                }
            >
                <Badge
                    invisible={!value?.addresses.length > 0 || !hasBluetooth}
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                        <BluetoothIcon
                            sx={{
                                height: 15,
                                width: 15,
                                borderRadius: "50%",
                                color: "white",
                                backgroundColor: theme.palette.primary.main,
                                transform: `scale(${badgeScale})`,
                                p: 0.2,
                                opacity: badgeOpacity,
                            }}
                        />
                    }
                >
                    <Avatar {...props}>{DeviceIcons[value?.type]}</Avatar>
                </Badge>
            </Badge>
        </Tooltip>
    );
});
