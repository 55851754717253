import React, { useEffect, useState } from 'react';
import { Card, Typography, Box, useTheme, useMediaQuery, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Clock } from 'kerberus-components';
import moment from 'moment';

export default function LocationCard({ data, user, site, setSite, ...props }) {
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const [sites, setSites] = useState([]);

    var duration = moment.duration(moment().diff(data?.user.timestamp));
    var minutes = duration.asMinutes();

    useEffect(() => {
        var sitesArray = [];
        data?.user.online_minutes.forEach(function (obj) {
            sitesArray.push(obj.site_name);
        })
        setSites(sitesArray)
    }, [data?.user.online_minutes])

    const handleChange = (event) => {
        setSite(event.target.value);
    };

    return (
        <Card {...props}>
            <Box sx={{ height: "100%", p: 1, pl: 2, pr: 2, width: "100%" }}>
                <Box sx={{ height: "33%", display: "flex", alignItems: "center" }}>
                    {/*                     {data.user.timestamp && minutes < 10
                        ? */}
                    <Box sx={{ position: "relative", width: "100%", display: "flex" }}>
                        <Typography variant="h6" component="div" sx={{}}>
                            Location 
                        </Typography>
                        <FormControl size="small" sx={{ position: "absolute", top: 0, right: 0, width: "50%" }}>
                            <InputLabel id="demo-simple-select-label">Site</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={site}
                                label="Age"
                                onChange={handleChange}
                            >
                                {sites.map(item =>
                                    <MenuItem value={item}>{item}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    {/*                      :
                        <Typography variant="h6" component="div" sx={{}}>
                            Location
                        </Typography>  */}
                </Box>
                <Box sx={{ height: "34%", p: 2, pl: 1, display: "flex" }}>
                    <Box sx={{ width: "80%" }}>
                        {data?.user.timestamp && minutes < 10
                            ?
                            <Typography variant="h5" sx={{ display: "flex", alignItems: "center", height: "100%" }} color="text.secondary">
                                {data.user.layer_name ?? "Disabled"}
                            </Typography>
                            :
                            <Typography variant="h5" sx={{ display: "flex", alignItems: "center", height: "100%" }} color="text.secondary">
                                Offline
                            </Typography>
                        }
                    </Box>
                    <Box sx={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center", pl: 2 }}>
                        {!isLarge &&
                            <LocationOnIcon color="mlBlue" sx={{ width: 36, height: 36 }} />
                        }
                    </Box>
                </Box>
                <Box sx={{ height: "33%", display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>

                    {user?.location_enabled
                        ?
                        <Typography sx={{ fontSize: 16 }} color="primary">
                            Location share on
                        </Typography>
                        :
                        <Typography sx={{ fontSize: 16 }} color="primary">
                            Location share off
                        </Typography>
                    }
                    <Clock textColor={theme.palette.primary.main} fontSize={16} mr={5} tz={user?.tz} />

                </Box>
            </Box>
        </Card>
    );
}