import * as React from 'react';
import { IconButton, Tooltip, useTheme, Link as MuiLink } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';


export default function SocialIcons({ user, links }) {
    const { linkedinHide, twitterHide, company_pageHide, whatsappHide, calendlyHide } = { ...links }
    const theme = useTheme();

    return (
        <>
            <Tooltip title="LinkedIn">
                <IconButton onClick={(e) => e.stopPropagation()} disabled={!user.links?.linkedin} component={MuiLink} href={user.links?.linkedin} target="_blank" rel="noopener noreferrer" sx={{ display: linkedinHide && "none" }}>
                    <LinkedInIcon sx={{ color: user.links?.linkedin && "#0A66C2" }} />
                </IconButton>
            </Tooltip>
            {/*             <Tooltip title="Facebook">
                <IconButton onClick={(e) => e.stopPropagation()} disabled={!user.links?.facebook} component={MuiLink} href={user.links?.facebook} target="_blank" rel="noopener noreferrer">
                    <FacebookIcon />
                </IconButton>
            </Tooltip> */}
            <Tooltip title="Twitter">
                <IconButton onClick={(e) => e.stopPropagation()} disabled={!user.links?.twitter} component={MuiLink} href={user.links?.twitter} target="_blank" rel="noopener noreferrer" sx={{ display: twitterHide && "none" }}>
                    <TwitterIcon sx={{ color: user.links?.twitter && "#1da1f2" }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Company Page">
                <IconButton onClick={(e) => e.stopPropagation()} disabled={!user.company_page} component={MuiLink} href={user.company_page} target="_blank" rel="noopener noreferrer" sx={{ display: company_pageHide && "none" }}>
                    <LanguageIcon sx={{ color: user.company_page && theme.palette.mlBlue.main }} />
                </IconButton>
            </Tooltip>
            {/*<Tooltip title="Slack">
                <IconButton sx={{ padding: 0 }} onClick={(e) => e.stopPropagation()} disabled={!user.links?.slack} component={MuiLink} href={user.links?.slack} target="_blank" rel="noopener noreferrer">
                    {!user.links?.slack
                        ?
                        <SlackIconBlack sx={{ padding: 0, width: 40, height: 40 }} />
                        :
                        <SlackIcon sx={{ padding: 0, width: 40, height: 40 }} />
                    }
                </IconButton>
            </Tooltip> */}
            <Tooltip title="WhatsApp">
                <IconButton onClick={(e) => e.stopPropagation()} disabled={!user.links?.whatsapp} component={MuiLink} href={user.links?.whatsapp} target="_blank" rel="noopener noreferrer" sx={{ display: whatsappHide && "none" }}>
                    <WhatsAppIcon sx={{ color: user.links?.whatsapp && "#25D366" }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Calendly">
                <IconButton onClick={(e) => e.stopPropagation()} disabled={!user.links?.calendly} component={MuiLink} href={user.links?.calendly} target="_blank" rel="noopener noreferrer" sx={{ display: calendlyHide && "none" }}>
                    <CalendarMonthIcon sx={{ color: user.links?.calendly && theme.palette.mlBlue.main }} />
                </IconButton>
            </Tooltip>
        </>
    );
}


