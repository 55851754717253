import * as React from 'react';
import { useEffect, useContext, useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MainContext, useWsDataProvider, useFetchCloud } from 'kerberus-components';
import moment from 'moment';
import { Card, Tooltip, useTheme, Box, Typography, Grid, IconButton, Popover, TextField, Button } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import DataGridMacAddress from '../../Components/Device/DatagridMacAddress';
import GetDeviceIcon from '../../Components/Device/GetDeviceIcon';
import EditIcon from '@mui/icons-material/Edit';
import { useSWRConfig } from 'swr';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export default memo(function DeviceView() {
    const theme = useTheme();
    const { user } = useContext(MainContext);
    const { deviceId } = useParams();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const [onlineDevs, setOnlineDevs] = useState([]);
    const navigate = useNavigate();

    const { mutate } = useSWRConfig();
    const { fetchCloud } = useFetchCloud();

    // eslint-disable-next-line eqeqeq
    const deviceData = user.device_groups.find(item => item.id == deviceId);
    const macAddresses = deviceData.addresses;

    const [nameEdit, setNameEdit] = useState(null);
    const [devName, setDevName] = useState(deviceData?.group_name);

    const handleEditName = (event) => {
        setNameEdit(event.currentTarget);
    };

    const closeEditName = () => {
        setNameEdit(null);
    };

    /* Change device name*/
    const handleDeviceName = async () => {
        try {
            await fetchCloud(`devices/devicegroup/${deviceId}/`, 'PATCH', {
                group_name: devName
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            alert(err);
        }
    };

    /* Get online Address data */
    const { getData, data, doUnsubscribe } = useWsDataProvider();

    useEffect(() => {
        getData('socketLocation', 'onlineAddr', {
            subscribe: true
        });

        return () => doUnsubscribe();
    }, [getData, doUnsubscribe]);


    /* Get device vendorInfo data */
    const { getData: getDataVendorInfo, data: dataVendorInfo } = useWsDataProvider();

    /* Set array index */
    const [addressIndex, setAddressIndex] = useState(0);

    /* Keep Looking for device with vendorInfo, stops when one is found */
    useEffect(() => {
        if (macAddresses[addressIndex]?.address && !dataVendorInfo?.vendorInfo) {
            getDataVendorInfo('socketMac', 'vendorInfo', {
                filters: {
                    mac: macAddresses[addressIndex]?.address?.toUpperCase()
                }
            });
            setAddressIndex(prev => prev !== macAddresses.length - 1 ? prev + 1 : prev);
        }
    }, [addressIndex, dataVendorInfo?.vendorInfo, getDataVendorInfo, macAddresses]);

    useEffect(() => {
        if (data) {
            setOnlineDevs(data);
        }
    }, [data]);

    const today = moment();
    const activeMacAddresses = onlineDevs?.filter(addr => macAddresses.some(item => addr.wlan_sa === item.address));
    const lastseen = activeMacAddresses.length > 0 && activeMacAddresses.reduce((a, b) => today - moment(a.timestamp) < today - moment(b.timestamp) ? a : b);
    const lastseenminutes = moment.duration(today.diff(lastseen.timestamp)).asMinutes();

    return (

        <>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <IconButton disableFocusRipple disableRipple sx={{ position: "absolute", top: 10, left: 5}} onClick={() => navigate(-1)}>
                    <ArrowBackIcon color="primary" sx={{ width: 40, height: 40}} />
                </IconButton>
                <Grid item xs={12} sm={4} md={3.5} sx={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                    <GetDeviceIcon value={deviceData} badgeScale={3.5} tooltipPlacement="left"
                        sx={{
                            height: isLarge ? 150 : 200,
                            width: isLarge ? 150 : 200,
                            p: 4,
                            borderStyle: "solid",
                            borderColor: lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? "#FCC041" : lastseenminutes > 0 && lastseenminutes < 5 ? "#8DBF30" : '#adadb8' : '#adadb8',
                            borderWidth: 7,
                            bgcolor: theme.palette.primary.main + 50,
                            color: theme.palette.primary.main,
                        }}
                    />
                    <Typography component={"div"} variant="h5" sx={{ color: lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? "#FCC041" : lastseenminutes > 0 && lastseenminutes < 5 ? "#8DBF30" : '#adadb8' : '#adadb8' }}>
                        {lastseenminutes ? lastseenminutes <= 10 ? "Active" : "Inactive" : "Inactive"}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8.5} >
                    <Card elevation={isSmall ? 0 : 3} sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", minHeight: 240, position: "relative", py: 2 }}>

                        <Box sx={{ position: "absolute", left: 10, top: 10, display: "flex", flexDirection: "column" }}>
                            <Tooltip title={deviceData?.visible ? "Tracking on" : "Tracking off"} placement="right">
                                {deviceData?.visible
                                    ?
                                    <VisibilityIcon sx={{ color: theme.palette.mlGreen.main }} />
                                    :
                                    <VisibilityOffIcon sx={{ color: theme.palette.mlRed.main }} />
                                }
                            </Tooltip>
                            <Tooltip title={deviceData?.location ? "Location on" : "Location off"} placement="right">
                                {deviceData?.location
                                    ?
                                    <LocationOnIcon sx={{ color: theme.palette.mlBlue.main, mt: 1 }} />
                                    :
                                    <LocationOffIcon sx={{ color: theme.palette.mlRed.main, mt: 1 }} />
                                }
                            </Tooltip>
                        </Box>


                        <Grid container spacing={2} sx={{ textAlign: "center" }} >

                            <Grid item xs={6} >
                                <Typography component={"div"} variant="h5">
                                    Name:
                                </Typography>
                            </Grid>
                            <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }} >
                                <Typography component={"div"} variant="h6" sx={{ position: "relative" }}>
                                    {deviceData?.group_name}
                                    <IconButton sx={{ position: "absolute", ml: 1, top: -5 }} onClick={handleEditName}>
                                        <EditIcon color="primary" />
                                    </IconButton>
                                    <Popover
                                        open={nameEdit}
                                        anchorEl={nameEdit}
                                        onClose={closeEditName}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Box sx={{ p: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <TextField
                                                label="New name"
                                                value={devName}
                                                onChange={(e) => setDevName(e.target.value)}
                                            />
                                            <Button variant="contained" sx={{ ml: 1 }} onClick={() => { handleDeviceName(); closeEditName(); }}>
                                                Ok
                                            </Button>
                                        </Box>
                                    </Popover>
                                </Typography>
                            </Grid>

                            <Grid item xs={6} >
                                <Typography component={"div"} variant="h5">
                                    Manufacturer:
                                </Typography>
                            </Grid>
                            <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }} >
                                <Typography component={"div"} variant="h6">
                                    {dataVendorInfo?.vendorInfo?.name ?? "Unknown"}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} >
                                <Typography component={"div"} variant="h5">
                                    Country:
                                </Typography>
                            </Grid>
                            <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }} >
                                <Typography component={"div"} variant="h6">
                                    {dataVendorInfo?.vendorInfo?.country ?? "Unknown"}
                                </Typography>
                            </Grid>

                            {/* Display device's OUI */}
                            {/*<Grid item xs={6} >
                                <Typography component={"div"} variant="h5">
                                    Oui:
                                </Typography>
                            </Grid>
                            <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }} >
                                <Typography component={"div"} variant="h6">
                                    {dataVendorInfo?.vendorInfo?.oui ?? "Unknown"}
                                </Typography>
                            </Grid> */}

                            <Grid item xs={6} >
                                <Typography component={"div"} variant="h5">
                                    Latest location:
                                </Typography>
                            </Grid>
                            <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }} >
                                <Typography component={"div"} variant="h6">
                                    {lastseen?.site_name ?? "Unknown"}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography component={"div"} variant="h5">
                                    Last seen:
                                </Typography>
                            </Grid>
                            <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }}>
                                <Typography component={"div"} variant="h6">
                                    {lastseen ? lastseen.timestamp ? moment(lastseen.timestamp).fromNow() : '> 1 week' : 'Long time ago'}
                                </Typography>
                            </Grid>

                        </ Grid>
                    </Card >
                </Grid>
            </Grid >
            <DataGridMacAddress onlineDevs={onlineDevs} macAddresses={macAddresses} activeMacAddresses={activeMacAddresses} />
        </>
    );
});
