import { MainContext } from 'kerberus-components';
import { useContext, useMemo } from 'react';

export default function LastWeekOfflineHours({site}) {
    const { bulkData } = useContext(MainContext);

    const filteredData = bulkData.user.prev_online_minutes.filter(item => item.site_name === site)
    const weekData = useMemo(() => {
        return filteredData.filter(i => i.online_minutes > 0).map(i => {
            const minsSinceStart = 10080 - i.online_minutes;
            return {
                site_name: i.site_name,
                hours: Math.floor(minsSinceStart / 60),
                minutes: minsSinceStart % 60
            };
        });
    }, [filteredData]);

    return (
        weekData?.length > 0
            ?
            `${weekData[0].hours}h ${weekData[0].minutes}min last week`
            :
            `168h 0min last week`

    );
}