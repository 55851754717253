import * as React from 'react';
import { useEffect, useState, useContext, useMemo } from "react";
import { MainContext } from 'kerberus-components';
import moment from 'moment';
import { useParams, Routes, Route } from "react-router-dom";
import GroupSettings from './GroupSettings';
import GroupView from './GroupView';
import useSWR from 'swr';
import GroupInvitations from './GroupInvitations';

function getActiveState(utc_start, utc_end) {
    let [hoursEnd, minsEnd] = utc_end && utc_end.split(':');
    let [hours, mins] = utc_start.split(':');
    let yesterday = moment.utc().startOf('day').subtract(1, 'day');
    let today = moment.utc().startOf('day');
    let tomorrow = moment.utc().startOf('day').add(1, 'day');


    if (hours > hoursEnd || (hours === hoursEnd && mins > minsEnd)) {
        let isBetween1 = moment().isBetween(yesterday.utc().set('hour', hours).set('minute', mins).local(), today.utc().set('hour', hoursEnd).set('minute', minsEnd).local());
        let isBetween2 = moment().isBetween(today.utc().set('hour', hours).set('minute', mins).local(), tomorrow.utc().set('hour', hoursEnd).set('minute', minsEnd).local());
        return (isBetween1 || isBetween2);
    }
    else {
        return (moment().isBetween(moment.utc().startOf('day').add(utc_start).local(), moment.utc().startOf('day').add(utc_end).local()));
    }
}

export default function GroupRoutes() {
    const { bulkData, user } = useContext(MainContext);
    const { groupId } = useParams();
    const [socialGroups, setSocialGroups] = useState([]);
    const [count, setCount] = useState(0);
    const { data: groupListData } = useSWR('users/socialgrouplist/');
    const { data: grouprequestsData } = useSWR('users/grouprequests/');

    const [activityLogOn, setActivyLogOn] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const cloudGroup = useMemo(() => {
        return socialGroups.find(i => i.id === groupId);
    }, [groupId, socialGroups]);

    const bulkGroup = useMemo(() => {
        return bulkData?.social_groups.find(i => i.group_id === groupId);
    }, [bulkData, groupId]);

    const isActive = cloudGroup && (getActiveState(cloudGroup.utc_start, cloudGroup.utc_end) || cloudGroup.utc_start === cloudGroup.utc_end);
    const isActiveWeekday = cloudGroup && (cloudGroup.enable_days.includes(moment().isoWeekday()) || cloudGroup.enable_days === undefined);

    useEffect(() => {
        if (groupListData) {
            setSocialGroups(groupListData.map(item => ({ ...item, open: false })));
        }
    }, [groupListData]);

    useEffect(() => {
        if (bulkGroup) {
            let tempNum = 0;
            for (let userObj of bulkGroup.users) {
                const now = moment();
                const lastSeen = userObj?.timestamp ? moment(userObj.timestamp) : null;
                const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                if (cloudGroup?.users.some(i => i.id === userObj.user_id) && lastSeen && lastseenminutes <= 10) {
                    tempNum++;
                }
            }
            setCount(tempNum);
        }
    }, [cloudGroup, bulkGroup]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: activityLogOn ? 1 : 4,
        pr: activityLogOn ? 6 : 4,
        borderRadius: 4,
        display: "flex",
        width: "65vw",
        height: activityLogOn ? "75%" : undefined,
    };

    return socialGroups.length > 0 ? (
        <Routes>
            <Route path="" element={
                <GroupView
                    count={count}
                    socialGroup={cloudGroup}
                    grouprequestsData={grouprequestsData}
                    isActive={isActive}
                    isActiveWeekday={isActiveWeekday}
                    modal={{ modalStyle, modalOpen, setModalOpen, activityLogOn, setActivyLogOn }}
                />
            } />
            <Route path="settings" element={
                <GroupSettings
                    modalStyle={modalStyle}
                    count={count}
                    socialGroup={cloudGroup}
                    grouprequestsData={grouprequestsData}
                    isActive={isActive}
                    isActiveWeekday={isActiveWeekday}
                    modal={{ modalStyle, modalOpen, setModalOpen, activityLogOn, setActivyLogOn }}
                />
            } />
            {(cloudGroup?.creator === user.id || user.perm_level === "superuser") &&
                <Route path="invitations" element={
                    <GroupInvitations
                        modalStyle={modalStyle}
                        count={count}
                        socialGroup={cloudGroup}
                        grouprequestsData={grouprequestsData}
                        isActive={isActive}
                        isActiveWeekday={isActiveWeekday}
                        modal={{ modalStyle, modalOpen, setModalOpen, activityLogOn, setActivyLogOn }}
                    />
                } />
            }
        </Routes>

    )
        :
        null;
}

