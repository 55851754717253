import React, { useContext, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { TextField, InputAdornment, Tooltip, Box, Button, useMediaQuery, useTheme, Typography, Checkbox, IconButton, Grid } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';
import { MainContext, useFetchCloud } from 'kerberus-components';
import { useSWRConfig } from 'swr';
import ConfirmationDialog from '../ConfirmationDialog';
import LanguageIcon from '@mui/icons-material/Language';
import { SlackIcon } from '../../Components/Icons/Slack';

const toastError = {
    open: true,
    type: "error",
    title: "Error",
    message: `Something went wrong!`,
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const toastInfo = {
    open: true,
    type: "success",
    title: "Success",
    message: "Group settings saved!",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};


export default function CompanyLinks() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [socialLinkDialog, setSocialLinkDialog] = useState();
    const [isDisabled, setIsDisabled,] = useState(true);
    const { setOpen, user } = useContext(MainContext);
    const { fetchCloud } = useFetchCloud();
    const { mutate } = useSWRConfig();
    const { handleSubmit, control, formState: { errors, isDirty }, register } = useForm({
        defaultValues: {
            company_page: user.user_company?.links?.company_page,
            slack: user.user_company?.links?.slack,
        },
        mode: "onChange"
    });
    const isEdited = isDirty
    const onSubmit = data => handleSocialLinksSave(data);

    const handleSocialLinksSave = async (e) => {
        try {
            await fetchCloud(`users/company/${user.user_company.id}/`, 'PATCH', {
                links: e
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastInfo);
        }
        catch (err) {
            setOpen(toastError);
        }
    };

    const contentSocialLinks = (
        <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
            You are about to add your personal data to the system.
            <br />
            Some of the information you provide may be subject to GDPR legislation.
            <br />
            Therefore the system has all the necessary settings to control the visibility of this information to other users.
            <br />
            <br />
            Please ensure that you understand that you are personally responsible for the accuracy of the provided information you share and its visibility and use.
            <br />
            <Typography sx={{ fontWeight: "bold", position: "absolute", right: 5, bottom: 65 }}>
                I understand
                <Checkbox checked={!isDisabled} onChange={() => setIsDisabled(isDisabled => !isDisabled)} />
            </Typography>
            <br />
            {!isSmall && <br />}
            <div style={isSmall ? { marginBottom: 30 } : { width: isSmall ? "45%" : "65%", position: "absolute", bottom: 5 }}>
                If you would like further information on this matter you can review our
                <Typography component={Link} to={`/termsconditions`} sx={{ color: theme.palette.mlBlue.main, cursor: 'pointer', display: "inline", marginLeft: 1 }}>
                    Terms & Conditions
                </Typography>
                {" again."}
            </div>
        </Typography>
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", }}>

            <Grid container sx={{ width: "100%" }}>

                <Controller
                    name="company_page"
                    control={control}
                    render={({ field: { onChange, value } }) =>

                        <Grid item xs={12} sx={{ display: "flex", flexDirection: isSmall && "column" }}>

                            <Box sx={{ mt: 1.5 }}>
                                {!isSmall &&
                                    <Tooltip title="Company Page">
                                        <LanguageIcon sx={{ width: 30, height: 30, mr: 1, color: theme.palette.mlBlue.main }} />
                                    </Tooltip>
                                }
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <TextField
                                    {...register("company_page", {
                                        pattern: {
                                            value: /https:/,
                                            message: "Invalid link. Must start with https://"
                                        }
                                    })}
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    label={"Company Page"}
                                    placeholder="https://example.com/"
                                    fullWidth
                                    error={errors.company_page?.message ? true : false}
                                    helperText={errors.company_page?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip placement="top" title="Post your company's website URL here.">
                                                    <HelpIcon color="mlBlue" sx={{ mr: 1 }} />
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}


                                />
                            </Box>
                        </Grid>
                    }
                />

                <Controller
                    name="slack"
                    control={control}
                    render={({ field: { onChange, value } }) =>

                        <Grid item xs={12} sx={{ display: "flex", flexDirection: isSmall && "column", mt: 2 }}>

                            <Box sx={{ ml: -1.5, }}>
                                {!isSmall &&
                                    <SlackIcon sx={{ width: 50, height: 50, position: "relative", left: 1 }} />
                                }
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <TextField
                                    {...register("slack", {
                                        pattern: {
                                            value: /https:/,
                                            message: "Invalid link. Must start with https://"
                                        }
                                    })}
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    label={"Slack"}
                                    placeholder="https://example.slack.com/"
                                    fullWidth
                                    error={errors.slack?.message ? true : false}
                                    helperText={errors.slack?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip placement="top" title="By pressing this button you will be forwarded to Slack's helping page for getting your workspace link. Post the workspace link to this input.">
                                                    <IconButton comnponent={Link} href="https://slack.com/help/articles/221769328-Locate-your-Slack-URL" target="_blank" rel="noopener noreferrer">
                                                        <HelpIcon color="mlBlue" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}


                                />
                            </Box>
                        </Grid>
                    }
                />
            </Grid>
            <Grid item xs={12} sx={{ display: isEdited ? "flex" : "none", alignItems: "center", justifyContent: "flex-end", marginLeft: !isSmall && 3, marginY: 2 }}>
                <Button disabled={errors.whatsapp?.message || errors.linkedin?.message} variant="contained" onClick={() => setSocialLinkDialog(true)}>
                    save
                </Button>
            </Grid>

            <ConfirmationDialog
                title={["Save"]}
                handleDevice={handleSubmit(onSubmit)}
                info
                isDisabled={isDisabled}
                name={"social links"}
                open={socialLinkDialog}
                setOpen={setSocialLinkDialog}
                content={contentSocialLinks}
            />
        </form>

    );

}