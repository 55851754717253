import * as React from 'react';
import { useContext, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, Paper } from '@mui/material';
import { MainContext } from 'kerberus-components';
import ProfileList from './ProfileList';
import SocialGroupList from './SocialGroupList';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import ActivityLog from '../Components/ActivityLog';
import OfficeHoursCard from '../Components/OfficeHoursCard';
import LocationCard from '../Components/LocationCard';

export default function Dashboard(props) {
    const { bulkData, user } = useContext(MainContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    /* const isSmaller = useMediaQuery(theme.breakpoints.down('sm')); */
    const navigate = useNavigate();

    const [site, setSite] = useState("");

    useEffect(() => {
        if (bulkData.user.site_name) {
            setSite(bulkData.user.site_name)
        }
    }, [bulkData.user.site_name])


    return (
        <Box sx={{ width: "100%", height: "100vh", overflowY: !isSmall && "hidden", mt: 1 }}>
            {/* Lowers the page by amount of appBar */}
            <div style={{ minHeight: 56 }} />
            <Box sx={{ width: "100%", height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`, maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`, display: "flex", pr: 1, pl: 1, pt: 0.6, flexDirection: "column" }}>
                <Box sx={{ width: "100%" }}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={4}>
                            <LocationCard data={bulkData} user={user} site={site} setSite={setSite} sx={{ display: props.gridOneHidden ? "none" : "flex", my: 1, borderRadius: 4 }} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <OfficeHoursCard site={site} sx={{ display: props.gridOneHidden ? "none" : "flex", my: 1, borderRadius: 4 }} online />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <OfficeHoursCard site={site} sx={{ display: props.gridOneHidden ? "none" : "flex", my: 1, borderRadius: 4 }} />
                        </Grid>
                        <Grid item xs={12} sx={{}}>
                            <Paper elevation={2} sx={{ display: props.gridTwoHidden ? "none" : "flex", borderRadius: 4, height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 0.6*${theme.mixins.toolbar.minHeight}px)`, mt: 1 }}>
                                <ActivityLog />
                            </Paper>
                        </Grid>

                    </Grid>
                </Box>
                <Box sx={{ width: "100%", height: "100%", pt: 2 }}>
                    <Grid item xs={12} sx={{ height: "100%", ml: !isSmall && 1, mb: isSmall && 2 }}>
                        <Paper sx={
                            {
                                textAlign: "center",
                                width: "100%",
                                display: props.gridThreeHidden ? "none" : "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                overflowX: "hidden",
                                borderRadius: 0,
                                borderTopRightRadius: 16,
                                borderTopLeftRadius: 16,
                            }
                        }
                        >
                            <Box sx={{ height: "100%", textAlign: "center" }}>
                                {user.user_company &&
                                    <ProfileList avatarWidth={60} avatarHeight={60} isSmall={isSmall} />
                                }
                                <SocialGroupList avatarWidth={60} avatarHeight={60} isSmall={isSmall} />
                            </Box>

                        </Paper>

                        <Paper onClick={() => navigate(`/profile/${user.id}/settings/`)}
                            sx={{
                                height: `calc((100vh - ${theme.mixins.toolbar.minHeight}px - 0.6*${theme.mixins.toolbar.minHeight}px) * 0.05)`,
                                display: props.gridThreeHidden ? "none" : "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 0,
                                borderBottomRightRadius: 16,
                                borderBottomLeftRadius: 16,
                                '&:hover': {
                                    backgroundColor: "#80808095",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <SettingsIcon />
                        </Paper>
                    </Grid>

                </Box>

            </Box>
        </Box>
    );
}
