import React, { useContext, useState } from 'react';
import { Typography, Grid, Checkbox, useTheme, useMediaQuery, Divider, Autocomplete, TextField, Button, Tooltip } from '@mui/material';
import ConfirmationDialog from '../ConfirmationDialog';
import { MainContext } from 'kerberus-components';
import { Link } from 'react-router-dom';
/* import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox'; */
import { useSWRConfig } from 'swr';
import { useFetchCloud } from 'kerberus-components';

/* const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />; */

const contentLocation = (
    <>
        <div>Allow your location to be shown to other users instead of last seen time.</div>
    </>
);
const contentPublic = (
    <>
        <div>Allow profile usage in info dashboard.</div>
    </>
);
const contentPublicSocials = (
    <>
        <div>Allow profile visibility in your company's social list.</div>
    </>
);
const contentPublicCalendar = (
    <>
        <div>Allow other users to see your calendar.</div>
    </>
);
const contentPushNotification = (
    <>
        <div>Enable to receive push notifications. You need to have notifications enabled on the device you want to receive them on.</div>
    </>
);
const contentAllowTracking = (
    <>
        <div>
            Allow users to track your online activity.
            <br /> Other users can setup one time notifications.
        </div>
    </>
);

const updateToast = {
    open: true,
    type: 'success',
    title: 'Success',
    message: 'Device location settings were updated!',
    verticalPos: 'bottom',
    horizontalPos: 'center',
    autoHideDuration: 5000,
};

export default React.memo(function ProfilePermissions({ isDisabled, setIsDisabled, handleChangeLocation, handleChangeNotification, handleChangePublicSocials, handleChangePublic, handleChangeCalendar, handleChangeTracking }) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    /* const isMedium = useMediaQuery(theme.breakpoints.down('md')); */
    const { user, setOpen: setOpenToast } = useContext(MainContext);

    const [dialog, setDialog] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    const { mutate } = useSWRConfig();
    const { fetchCloud } = useFetchCloud();

    const [value, setValue] = useState(user.device_groups);

    /* const [locationChecked, setLocationChecked] = useState(user.device_groups.map((item) => item.location))

    console.log(user.device_groups.map((item) => item.location)) */

    /* const handleDeviceLocation = async (e) => {
        e.forEach(element => 
            { try {
                fetchCloud(`devices/devicegroup/${element.id}/`, 'PATCH', {
                    location: !element.location
                });
                mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            }
            catch (err) {
                alert(err);
            }
        }
        )
    }; */

    const handleDeviceLocationMany = async (e) => {
        try {
            fetchCloud(`devices/devicegrouplocation`, 'POST', {
                device_groups: e,
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpenToast(updateToast);
        } catch (err) {
            alert(err);
        }
    };

    const handleContent = (e) => {
        let content = '';
        let name = '';
        let handle = '';

        switch (e) {
            case 1:
                content = contentLocation;
                name = 'share location';
                handle = handleChangeLocation;
                break;
            case 2:
                content = contentPushNotification;
                name = 'push notification';
                handle = handleChangeNotification;
                break;
            case 3:
                content = contentPublicSocials;
                name = 'profile visibility';
                handle = handleChangePublicSocials;
                break;
            case 4:
                content = contentPublic;
                name = 'dashboard visibility';
                handle = handleChangePublic;
                break;
            case 5:
                content = contentPublicCalendar;
                name = 'calendar visibility';
                handle = handleChangeCalendar;
                break;
            case 6:
                content = contentAllowTracking;
                name = 'profile tracking';
                handle = handleChangeTracking;
                break;
            default:
                content = 'Something went wrong, please close modal, refresh page and try again.';
                break;
        }

        const contentSocialLinks = (
            <Typography component={'div'} style={{ overflowWrap: 'break-word' }}>
                {content}
                <br />
                Please ensure that you understand that you are personally responsible for the accuracy of the provided information you share and its visibility and use.
                <br />
                <Typography component={'div'} sx={{ fontWeight: 'bold', position: 'absolute', right: 5, bottom: 65 }}>
                    I understand
                    <Checkbox checked={!isDisabled} onChange={() => setIsDisabled((isDisabled) => !isDisabled)} />
                </Typography>
                <br />
                {!isSmall && <br />}
                <div style={isSmall ? { marginBottom: 30 } : { width: '62%', position: 'absolute', bottom: 5 }}>
                    If you would like further information on this matter you can review our
                    <Typography component={Link} to={`/termsconditions`} sx={{ color: theme.palette.mlBlue.main, cursor: 'pointer', display: 'inline', marginLeft: 1 }}>
                        Terms & Conditions
                    </Typography>
                    {' again.'}
                </div>
            </Typography>
        );

        return <ConfirmationDialog title={'Enable'} handleDevice={handle} isDisabled={isDisabled} name={name} open={dialogOpen} setOpen={setDialogOpen} content={contentSocialLinks} stringTitle />;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12}>
                        <Typography component="div" variant="h6">
                            Devices with location enabled
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 2 }}>
                        <Autocomplete
                            sx={{ mr: 1 }}
                            multiple
                            limitTags={ isSmall ? 2 : -1 }
                            id="checkboxes-tags-demo"
                            options={user.device_groups}
                            disableCloseOnSelect
                            getOptionLabel={(user) => user.group_name}
                            onChange={(e, newVal, status, option) =>
                                /* console.log(e, newVal, joku, option) */
                                setValue(
                                    value.map((obj) => {
                                        if (obj.id === option.option.id) {
                                            return { ...obj, location: !obj.location };
                                        }

                                        return obj;
                                    })
                                )
                            }
                            value={value.filter((item) => item.location)}
                            asd={console.log(value)}
                            renderOption={(props, item) => (
                                <li {...props}>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={value.find((device) => device.id === item.id)?.location}
                                        /* asd={console.log(value)} */
                                    />
                                    {item.group_name}
                                </li>
                            )}
                            style={{ width: 500 }}
                            renderInput={(params) => <TextField {...params} label="Devices" helperText="Update your device location settings here" />}
                        />
                        <Button variant="contained" sx={{ display: isSmall ? 'none' : 'flex', position: 'relative', mb: 3 }} onClick={() => handleDeviceLocationMany(value)}>
                            Update
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ display: isSmall ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                        <Button variant="contained" sx={{ position: 'relative', mb: 3 }} onClick={() => handleDeviceLocationMany(value)}>
                            Update
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography align="center" variant="h6" component={'div'} sx={{ mb: 2, height: 30 }}>
                            Share Location
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: 'flex', justifyContent: isSmall ? 'center' : 'left', alignItems: 'center' }}>
                                <Tooltip title={user.device_groups.filter((item) => item.location).length === 0 ? 'You must enable device location first' : ''}>
                                    <span>
                                        <Checkbox
                                            disabled={user.device_groups.filter((item) => item.location).length === 0}
                                            checked={user.location_enabled}
                                            onChange={
                                                user.location_enabled
                                                    ? () => handleChangeLocation()
                                                    : () => {
                                                          setDialogOpen(true);
                                                          setDialog(1);
                                                      }
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    Allow your location to be shown to other users instead of last seen time.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography align="center" variant="h6" component={'div'} sx={{ mb: 2, height: 30 }}>
                            Push Notifications
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: 'flex', justifyContent: isSmall ? 'center' : 'left', alignItems: 'center' }}>
                                <Checkbox
                                    checked={user.push_enabled}
                                    onChange={
                                        user.push_enabled
                                            ? () => handleChangeNotification()
                                            : () => {
                                                  setDialogOpen(true);
                                                  setDialog(2);
                                              }
                                    }
                                />
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    Enable to receive push notifications. You need to have notifications enabled on the device you want to receive them on.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography align="center" variant="h6" component={'div'} sx={{ mb: 2, height: 30 }}>
                            Public Social
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: 'flex', justifyContent: isSmall ? 'center' : 'left', alignItems: 'center' }}>
                                <Checkbox
                                    checked={user.public_social}
                                    onChange={
                                        user.public_social
                                            ? () => handleChangePublicSocials()
                                            : () => {
                                                  setDialogOpen(true);
                                                  setDialog(3);
                                              }
                                    }
                                />
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    Allow profile visibility in your company's social list.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography align="center" variant="h6" component={'div'} sx={{ mb: 2, height: 30 }}>
                            Public Dashboard
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: 'flex', justifyContent: isSmall ? 'center' : 'left', alignItems: 'center' }}>
                                <Checkbox
                                    checked={user.public}
                                    onChange={
                                        user.public
                                            ? () => handleChangePublic()
                                            : () => {
                                                  setDialogOpen(true);
                                                  setDialog(4);
                                              }
                                    }
                                    sx={{ marginTop: !isSmall && 2 }}
                                />
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    Allow profile usage in info dashboard.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography align="center" variant="h6" component={'div'} sx={{ mb: 2, height: 30 }}>
                            Public Calendar
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: 'flex', justifyContent: isSmall ? 'center' : 'left', alignItems: 'center' }}>
                                <Checkbox
                                    checked={user.public_calendar}
                                    onChange={
                                        user.public_calendar
                                            ? () => handleChangeCalendar()
                                            : () => {
                                                  setDialogOpen(true);
                                                  setDialog(5);
                                              }
                                    }
                                />
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    Allow other users to see your calendar.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider flexItem variant="fullWidth" sx={{ marginY: 2, borderBottomWidth: 2 }} />
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography align="center" variant="h6" component={'div'} sx={{ mb: 2, height: 30 }}>
                            Allow one-time tracking
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container>
                            <Grid item xs={5} md={12} sx={{ display: 'flex', justifyContent: isSmall ? 'center' : 'left', alignItems: 'center' }}>
                                <Checkbox
                                    checked={user.allow_tracking}
                                    onChange={
                                        user.allow_tracking
                                            ? () => handleChangeTracking()
                                            : () => {
                                                  setDialogOpen(true);
                                                  setDialog(6);
                                              }
                                    }
                                />
                            </Grid>
                            <Grid item xs={7} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle2" sx={{ ml: isSmall ? 0 : 1 }} align="left" color="#808080">
                                    Allow users to track your online activity. Other users can setup one time notifications.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {handleContent(dialog)}
        </Grid>
    );
});
