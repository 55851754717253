import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Avatar, Badge, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MainContext, SmallAvatarWrapper, useFetchCloud } from 'kerberus-components';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSWRConfig } from 'swr';
import ConfirmationDialog from '../../Components/ConfirmationDialog';
import ActiveTime from '../../Components/Groups/ActiveTime';
import SocialGroupHeader from '../../Components/Groups/SocialGroupHeader';
import SocialGroupModal from '../../Components/Groups/SocialGroupModal';
import SocialGroupSettingsAdmin from '../../Components/Groups/SocialGroupSettingsAdmin';
import SocialGroupSettingsUser from '../../Components/Groups/SocialGroupSettingsUser';
import LayoutPaper from '../../Components/LayoutPaper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const contentLeaveGroup = (
    <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
        Are you sure you want to leave this group?
    </Typography>
);

const toastDataShareGroup = {
    open: true,
    type: "success",
    title: "Success",
    message: "Group data sharing settings saved!",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const toastError = {
    open: true,
    type: "error",
    title: "Error",
    message: `Something went wrong!`,
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const copyToast = {
    open: true,
    type: "success",
    title: "Success",
    message: "Dashboard URL copied to clipboard successfully",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

export default function GroupSettings({ socialGroup, grouprequestsData, modal, count, isActive, isActiveWeekday }) {
    const { setOpen, user } = useContext(MainContext);
    const [openLeaveGroup, setOpenLeaveGroup] = useState(false);
    const [valueLeaveGroup, setValueLeaveGroup] = useState(false);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const { groupId } = useParams();
    const { mutate } = useSWRConfig();

    /* Image things */
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [loading, setLoading] = useState('');
    const [file, setFile] = useState(null);
    const [image, setImage] = useState();

    const [userGroupId, setUserGroupId] = useState(user.social_groups.findIndex(i => i.id === groupId));

    const [url, setUrl] = useState('');
    const [urlDialog, setUrlDialog] = useState(false);
    const [dialogShown, setDialogShown] = useState(false);
    const { setOpen: setOpenToast } = useContext(MainContext);

    const handleLayoutUrl = async (e) => {
        const res = await fetchCloud(`users/generate_dashboard`, 'post', {
            dashboard_type: "socialgroup",
            social_group: groupId,
        });
        setUrl(res.data);
    };

    useEffect(() => {
        setUserGroupId(user.social_groups.findIndex(i => i.id === groupId));
    }, [groupId, user.social_groups]);

    const { handleSubmit, control, formState, register, watch } = useForm({
        defaultValues: {
            publicSocialUser: user?.social_groups[userGroupId]?.permissions?.public_social,
            publicDashboardUser: user?.social_groups[userGroupId]?.permissions?.public_dashboard,
            publicCalendarUser: user?.social_groups[userGroupId]?.permissions?.public_calendar,
            alwaysActive: user?.social_groups[userGroupId]?.permissions?.utc_start === "00:00:00" && user?.social_groups[userGroupId]?.permissions?.utc_end === "1 00:00:00",
            startTimeUser: moment.utc().startOf('day').add(user?.social_groups[userGroupId]?.permissions?.utc_start).local(),
            endTimeUser: moment.utc().startOf('day').add(user?.social_groups[userGroupId]?.permissions?.utc_end).local(),
            daysEnabledUser: user?.social_groups[userGroupId]?.permissions?.enable_days.map(number => String(number))
        },
        mode: "onChange"
    });

    const onSubmit = data => handleChangeUser(data);

    const { handleSubmit: handleSubmitGroup, control: controlGroup, formState: formStateGroup, register: registerGroup, watch: watchGroup, setValue: setValueGroup } = useForm({
        defaultValues: {
            description: socialGroup.description,
            creator: socialGroup.creator,
            public: socialGroup.public,
            slack: socialGroup?.links?.slack,
            calendlyHide: !socialGroup.links.calendlyHide,
            company_pageHide: !socialGroup.links.company_pageHide,
            linkedinHide: !socialGroup.links.linkedinHide,
            twitterHide: !socialGroup.links.twitterHide,
            whatsappHide: !socialGroup.links.whatsappHide,
            alwaysActive: socialGroup.utc_start === "00:00:00" && socialGroup.utc_end === "1 00:00:00",
            startTime: moment.utc().startOf('day').add(socialGroup.utc_start).local(),
            endTime: moment.utc().startOf('day').add(socialGroup.utc_end).local(),
            daysEnabled: socialGroup.enable_days.map(number => String(number))

        },
        mode: "onChange"
    });

    const onSubmitGroup = data => handleChangeGroup(data);

    const dayOptions = [
        {
            label: "Mon",
            value: 1
        },
        {
            label: "Tue",
            value: 2
        },
        {
            label: "Wed",
            value: 3
        },
        {
            label: "Thu",
            value: 4
        },
        {
            label: "Fri",
            value: 5
        },
        {
            label: "Sat",
            value: 6
        },
        {
            label: "Sun",
            value: 7
        }
    ];

    const { fetchCloud } = useFetchCloud();

    const handleLeaveGroup = async () => {
        try {
            await fetchCloud(`users/leave_social`, 'POST', {
                social_group: groupId
            });
        }
        catch (err) {
            console.error(err);
        }
        window.location.href = '/';
    };

    const handleChangeUser = async (e) => {
        console.log(e);
        let startDiff = null;
        let endDiff = null;

        if (!e.alwaysActive) {
            let startUtc = moment.utc(e.startTimeUser).set("second", 0);
            let startUtcMid = startUtc.clone().startOf('day');
            startDiff = startUtc.diff(startUtcMid, 'seconds');
            let endUtc = moment.utc(e.endTimeUser).set("second", 0);
            let endUtcMid = endUtc.clone().startOf('day');
            endDiff = endUtc.diff(endUtcMid, 'seconds');
        }

        try {
            await fetchCloud(`users/update_social_group_perm`, 'post', {
                social_group: groupId,
                public_social: e.publicSocialUser,
                public_dashboard: e.publicDashboardUser,
                public_calendar: e.publicCalendarUser,
                utc_start: !e.alwaysActive ? startDiff : 0,
                utc_end: !e.alwaysActive ? endDiff : 86400,
                enable_days: e.daysEnabledUser.sort().map(string => Number(string))
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setOpen(toastDataShareGroup);
        }
        catch (err) {
            setOpen(toastError);
        }
    };

    const handleChangeGroup = async (e) => {
        console.log(e);
        let startDiff = null;
        let endDiff = null;

        if (!e.alwaysActiveGroup) {
            let startUtc = moment.utc(e.startTime).set("second", 0);
            let startUtcMid = startUtc.clone().startOf('day');
            startDiff = startUtc.diff(startUtcMid, 'seconds');
            let endUtc = moment.utc(e.endTime).set("second", 0);
            let endUtcMid = endUtc.clone().startOf('day');
            endDiff = endUtc.diff(endUtcMid, 'seconds');
        }

        try {
            await fetchCloud(`users/socialgroupselect/${groupId}/`, 'PATCH', {
                public: e.public,
                description: e.description,
                utc_start: !e.alwaysActive ? startDiff : 0,
                utc_end: !e.alwaysActive ? endDiff : 86400,
                enable_days: e.daysEnabled.sort().map(string => Number(string)),
                links: {
                    slack: e.slack,
                    calendlyHide: !e.calendlyHide,
                    company_pageHide: !e.company_pageHide,
                    linkedinHide: !e.linkedinHide,
                    twitterHide: !e.twitterHide,
                    whatsappHide: !e.whatsappHide,
                },
                creator: e.creator,
            });
            mutate('users/socialgrouplist/');
            setOpen(toastDataShareGroup);
        }
        catch (err) {
            console.log(err)
            setOpen(toastError);
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        setUploadDisabled(true);
        const imageUrl = URL.createObjectURL(acceptedFiles[0]);
        setImage(imageUrl);
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, disabled: uploadDisabled });

    const handleUpload = async () => {
        try {
            setLoading('Loading...');
            await fetchCloud(`upload/socialgroup`, 'post', {
                file: file,
                social_group: groupId
            }, true, true);
            window.location.reload();
        }
        catch (err) {
            setLoading('Failed upload');
            console.error(err);
        }
    };

    const copyToClipboardGroup = async () => {
        await navigator.clipboard.writeText(url);
        setOpenToast(copyToast);
    };

    const handleGetUrl = () => {
        if(!dialogShown) {
            setUrlDialog(true);
            /* setDialogShown(true); */
        } else {
            handleLayoutUrl()
        }
    }

    return (
        <LayoutPaper>
            <Grid container>

                <Grid item xs={12} md={10} sx={{ display: "flex", flexDirection: isSmall ? "column" : "row", marginBottom: 1, marginTop: isSmall ? 5 : -1, alignItems: "center" }}>
                    <SocialGroupHeader socialGroup={socialGroup} modal={modal} count={count} isActive={isActive} isActiveWeekday={isActiveWeekday} />
                    {!isSmall &&
                        <Button variant="contained" color="error" sx={{ marginLeft: 5 }} onClick={() => { setValueLeaveGroup(groupId); setOpenLeaveGroup(true); }}>
                            Leave Group
                        </Button>
                    }
                </Grid>

                <Grid item xs md={2} sx={isSmall ? { position: "absolute", top: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "flex-end" } : { display: "flex", alignItems: "center", justifyContent: "flex-end", position: "relative", bottom: 20, left: 20 }}>
                    <IconButton onClick={() => navigate(-1)} >
                        <ArrowBackIcon sx={{ height: 30, width: 30 }} />
                    </IconButton>
                </Grid>

                <Grid item xs={12} sm={11} sx={{ textAlign: isSmall && "center" }}>
                    <ActiveTime socialGroup={socialGroup} />
                </Grid>

                {(socialGroup.creator === user.id || user.perm_level === "superuser") &&
                    <Grid item xs={12} sm={1} sx={isSmall ? { display: "flex", alignItems: "center", justifyContent: "center", marginTop: 3, marginBottom: 1 } : { display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <Badge badgeContent={grouprequestsData?.filter(item => item.social_group === groupId && item).length}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            componentsProps={{
                                backgroundColor: "red",
                                marginLeft: 10,
                                position: "relative", left: 100
                            }}
                            color="error">
                            <Button variant="outlined" onClick={() => navigate(`/groups/${groupId}/invitations`)} sx={{ marginTop: -2 }}>
                                <GroupAddIcon sx={{ marginRight: 1 }} />
                                Invitations
                            </Button>
                        </Badge>
                    </Grid>
                }

                {/* {(socialGroup.creator === user.id || user.perm_level === "superuser") &&
                    <Grid item xs={12}>
                        <TextField
                            value={url}
                            variant="outlined"
                            sx={{ position: "relative", bottom: 10, width: 300, marginX: 1, marginTop: 0.75 }}
                            helperText="Click to reveal dashboard link"
                            disabled={url.length < 1}
                            size="small"
                            placeholder="Click to reveal dashboard link"
                            onClick={handleLayoutUrl}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Copy to Clipboard">
                                            <IconButton disabled={url.length < 1} onClick={copyToClipboardGroup}>
                                                <ContentCopyIcon sx={{ height: 20, width: 20 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                } */}

                <Divider sx={{ width: "100%", marginBottom: 2 }} />

                <Grid item xs={12} sx={{ display: "flex", flexDirection: isSmall ? "column" : "row" }}>
                    {/* User data share settings */}
                    <Grid item xs={12} sm={6} sx={{ flexDirection: "column", paddingRight: !isSmall && 4 }}>

                        <Grid item xs={12} >
                            <Typography component={"div"} variant="h5" sx={{ width: "100%", textAlign: "center", height: 100 }}>
                                My data share settings in {socialGroup.name}
                            </Typography>
                        </Grid>
                        <SocialGroupSettingsUser
                            handleSubmit={handleSubmit}
                            onSubmit={onSubmit}
                            control={control}
                            formState={formState}
                            register={register}
                            watch={watch}
                            dayOptions={dayOptions}
                        />
                    </Grid>

                    {/* Group data share settings SUPERUSER/CREATOR */}
                    {(socialGroup.creator === user.id || user.perm_level === "superuser") &&
                        <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", paddingLeft: !isSmall && 4, mt: isSmall && 8 }}>

                            <Grid item xs={12}>
                                <Typography component={"div"} variant="h5" sx={{ width: "100%", textAlign: "center" }}>
                                    Group data share settings
                                    {!isSmall &&
                                        <Tooltip title="Admin" sx={{ position: "relative", left: 10, top: 5 }}>
                                            <AdminPanelSettingsIcon color="primary" />
                                        </Tooltip>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: isSmall ? "none" : "flex" , justifyContent: "center", alignItems: "center" }}>
                                <TextField
                                    value={url}
                                    variant="outlined"
                                    sx={{ width: 300, mt: 2 }}
                                    helperText="Click to reveal dashboard link"
                                    disabled={url.length < 1}
                                    size="small"
                                    placeholder="Click to reveal dashboard link"
                                    onClick={handleGetUrl}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Copy to Clipboard">
                                                    <IconButton disabled={url.length < 1} onClick={copyToClipboardGroup}>
                                                        <ContentCopyIcon sx={{ height: 20, width: 20 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            
                            {isSmall
                                &&
                                <Divider flexItem sx={{ mb: 6 }}>
                                    <AdminPanelSettingsIcon color="primary" sx={{ position: "relative", top: 20 }} />
                                </Divider>
                            }
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: isSmall ? "column" : "row", alignItems: "center", mt: 2, justifyContent: isSmall && "center", mb: isSmall && 1 }}>
                                {isSmall
                                    ?
                                    <SmallAvatarWrapper onClick={() => setUploadDialogOpen(true)} alt="GroupIconEdit" userId={groupId} imageType="socialgrouppic" imageSize="_128" sx={{ height: 100, width: 100 }} />
                                    :

                                    <SmallAvatarWrapper alt="GroupIconEdit" userId={groupId} imageType="socialgrouppic" imageSize="_128" sx={{ height: 100, width: 100, marginLeft: 6, my: 1 }} />
                                }

                                {!isSmall &&
                                    <>
                                        <Button sx={{ marginLeft: 2 }} variant="contained" onClick={() => setUploadDialogOpen(true)}>
                                            Update Group Picture
                                        </Button>

                                        {/* COMMENTED OUT BECAUSE NOT DELETE METHOD NOT IN USE
                                            <IconButton aria-label="delete" onClick={() => { setValuePic(groupId); setOpenPic(true); }} sx={{ marginLeft: 1 }}>
                                                <DeleteIcon sx={{ color: theme.palette.mlRed.main }} />
                                            </IconButton>
                                            */}
                                    </>
                                }
                                <TextField
                                    value={url}
                                    variant="outlined"
                                    sx={{ display: isSmall ? "flex" : "none", width: 300, ml: isSmall ? 0 : 2, mt: 3 }}
                                    helperText="Click to reveal dashboard link"
                                    disabled={url.length < 1}
                                    size="small"
                                    placeholder="Click to reveal dashboard link"
                                    onClick={handleGetUrl}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Copy to Clipboard">
                                                    <IconButton disabled={url.length < 1} onClick={copyToClipboardGroup}>
                                                        <ContentCopyIcon sx={{ height: 20, width: 20 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Divider sx={{ my: 2 }} />

                            <SocialGroupSettingsAdmin
                                handleSubmit={handleSubmitGroup}
                                onSubmit={onSubmitGroup}
                                control={controlGroup}
                                formState={formStateGroup}
                                register={registerGroup}
                                watch={watchGroup}
                                dayOptions={dayOptions}
                                isSmall={isSmall}
                                groupId={groupId}
                                socialGroup={socialGroup}
                                user={user}
                                handleChange={handleChangeGroup}
                                setValue={setValueGroup}

                            />

                        </Grid>
                    }
                </Grid>

                {isSmall &&
                    <Grid item xs={12} sx={{ display: "flex", alignItems: "cente", justifyContent: "flex-end", marginTop: 15 }}>
                        <Button variant="contained" color="error" sx={{ marginLeft: 5 }} onClick={() => { setValueLeaveGroup(groupId); setOpenLeaveGroup(true); }}>
                            Leave Group
                        </Button>
                    </Grid>
                }

                <Dialog
                    open={uploadDialogOpen}
                    onClose={() => setUploadDialogOpen(false)}
                >
                    <DialogTitle>
                        <Typography component={"div"}>
                            Upload Group Picture
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        {!file &&
                            <div {...getRootProps()} style={{ borderStyle: "solid", borderWidth: 1, height: 200, width: "100%", alignItems: "center", justifyContent: "center" }}>
                                <input {...getInputProps()} />
                                {
                                    uploadDisabled ? <p style={{ textAlign: "center" }}>File selected</p> :
                                        isDragActive ?
                                            <p>Drop the files here ...</p> :
                                            <p style={{ textAlign: "center", marginInline: 30 }}>{isSmall ? 'Press to select file' : 'Drag and drop some files here, or click to select files'}</p>
                                }
                            </div>}
                        {file && <Grid container >
                            <Grid item style={{}}>
                                <Typography component={"div"} style={{ margin: 10 }}>{file.name}</Typography>
                            </Grid>
                            <Grid item >
                                <Button style={{ marginTop: 3 }} variant="contained" onClick={() => {
                                    setFile(null);
                                    setUploadDisabled(false);
                                    setImage(null);
                                }} > Remove </Button>
                            </Grid>
                        </Grid>}
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                            {image && <Avatar src={image} sx={{ width: 150, height: 150 }} />}
                        </div>
                        <div style={{ marginTop: 20, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            <Button variant="contained" onClick={handleUpload} disabled={!file}> Confirm </Button>
                            <Typography component={"div"}>{loading}</Typography>
                        </div>
                    </DialogContent>
                </Dialog>

                <ConfirmationDialog
                    title={["Leave"]}
                    handleDevice={handleLeaveGroup}
                    value={valueLeaveGroup}
                    name={socialGroup.name}
                    open={openLeaveGroup}
                    setOpen={setOpenLeaveGroup}
                    content={contentLeaveGroup}
                />

                <ConfirmationDialog
                    title={["Get"]}
                    handleDevice={() => {handleLayoutUrl(); setDialogShown(true)}}
                    name="dashboard URL"
                    open={urlDialog}
                    setOpen={setUrlDialog}
                    content={<div>
                        By fetching this url and sharing this dashboard you have agreed and are aware that you will be sharing group information, images and location data to where the dashboard will be displayed.
                        <br /><br />
                        Note that your responsibility is to make sure that there is no third-party access to the URL in order to prevent unauthorized copy and use.
                    </div>}
                />

                <SocialGroupModal modal={modal} groupId={groupId} socialGroup={socialGroup} />

            </Grid>
        </LayoutPaper>
    );
}

