import { Autocomplete, Button, Grid, TextField, Typography, Divider, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { useFetchCloud, SmallAvatarWrapper } from 'kerberus-components';
import { useEffect, useState } from 'react';
import Toast from '../../Components/Toast';
import AvatarGroup from '@mui/material/AvatarGroup';
import moment from 'moment';
import TimePicker from '@mui/lab/TimePicker';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DayEnabledCheckbox from '../../Components/DayEnabledCheckbox';
import SocialGroupRequests from './SocialGroupRequests';
import LayoutPaper from '../../Components/LayoutPaper';
import useSWR, { useSWRConfig } from 'swr';

export default function AdminSocialGroup(props) {
    const { fetchCloud } = useFetchCloud();
    const [groupChoices, setGroupChoices] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const [email, setEmail] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [groupPublic, setGroupPublic] = useState(false);
    const [openEmailSuccess, setOpenEmailSuccess] = useState(false);
    const [openEmailError, setOpenEmailError] = useState(false);
    const [openGroupCreated, setOpenGroupCreated] = useState(false);
    const [startTime, setStartTime] = useState(moment().startOf('day'));
    const [endTime, setEndTime] = useState(moment().endOf('day'));
    const [daysEnabled, setDaysEnabled] = useState(new Set([1, 2, 3, 4, 5, 6, 7]));
    const [alwaysActive, setAlwaysActive] = useState(true);
    const { data: socialGroupSelectData } = useSWR('users/socialgroupselect/');
    const { mutate } = useSWRConfig();

    useEffect(() => {
        if (socialGroupSelectData) {
            setGroupChoices(socialGroupSelectData);
        }
    }, [socialGroupSelectData]);


    const handleInvite = async () => {
        try {
            await fetchCloud('users/create_social_invite', 'POST', {
                email: email,
                social_group: selectedGroup.id
            });
            setOpenEmailSuccess(true);
        }
        catch (err) {
            setOpenEmailError(true);
        }
    };

    const handleCreate = async () => {
        let startDiff = null;
        let endDiff = null;

        if (!alwaysActive) {
            let startUtc = moment.utc(startTime).set("second", 0);
            let startUtcMid = startUtc.clone().startOf('day');
            startDiff = startUtc.diff(startUtcMid, 'seconds');
            let endUtc = moment.utc(endTime).set("second", 0);
            let endUtcMid = endUtc.clone().startOf('day');
            endDiff = endUtc.diff(endUtcMid, 'seconds');
        }

        try {
            await fetchCloud('users/socialgroupselect/', 'POST', {
                name: groupName,
                description: groupDescription,
                public: groupPublic,
                utc_start: !alwaysActive ? startDiff : 0,
                utc_end: !alwaysActive ? endDiff : 86400,
                enable_days: Array.from(daysEnabled).sort()
            });
            mutate('users/socialgroupselect/');
            setGroupName('');
            setGroupDescription('');
            setOpenGroupCreated(true);
            mutate('users/socialgrouplist/');
        }
        catch (err) {
            alert(err);
        }
    };

    return (
        <LayoutPaper>
            <Grid container >
                <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                    <Typography component={"div"} variant="h5">
                        Create group
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"div"} variant="h6">
                        Group name
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex" }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="groupname"
                        label='Group name'
                        name="groupname"
                        inputProps={{
                            autoComplete: "off",
                            maxLength: 64
                        }}
                        autoFocus
                        sx={{ marginBottom: 3, width: "50%" }}
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                    <FormControlLabel sx={{ marginLeft: 1, marginBottom: 1 }}
                        control={<Checkbox checked={groupPublic} onChange={() => setGroupPublic(!groupPublic)} />}
                        label="Public" />
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"div"} variant="h6">
                        Group description
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="groupdescription"
                        label='Group description'
                        name="groupdescription"
                        helperText="Describe for what purpose this group is formed and be sure mention clearly how and where the public information going to be shown. For example tell where all the info screens are located."
                        inputProps={{
                            autoComplete: "off",
                            maxLength: 256
                        }}
                        autoFocus
                        sx={{ marginBottom: 3 }}
                        value={groupDescription}
                        onChange={(e) => setGroupDescription(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"div"} id="modal-modal-title" variant="h6" marginBottom={3}>
                        Visibility Hours
                    </Typography>
                    <FormControlLabel
                        control={
                            <Tooltip title="This choice only affects time">
                                <Checkbox
                                    checked={alwaysActive}
                                    onChange={(e) => {
                                        setAlwaysActive(!alwaysActive);
                                    }}
                                />
                            </Tooltip>
                        } label="Always active" labelPlacement="top"
                    />
                    <Grid item xs={5} sx={{ display: "flex", alignItem: "center", justifyContent: "center", marginTop: 1 }}>
                        <Grid item xs={6}>
                            <Typography component={"div"} sx={{ marginBottom: 1 }}>
                                Starting Time
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label="Start time"
                                    ampm={false}
                                    value={startTime}
                                    onChange={(e) => setStartTime(e)}
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={alwaysActive}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography component={"div"} sx={{ marginBottom: 1 }}>
                                Ending Time
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label="End time"
                                    ampm={false}
                                    value={endTime}
                                    onChange={(e) => setEndTime(e)}
                                    renderInput={(params) => <TextField {...params} sx={{ marginBottom: 5 }} />}
                                    disabled={alwaysActive}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: -2 }}>
                    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((item, idx) => (
                        <DayEnabledCheckbox key={idx} list={daysEnabled} num={idx + 1} setList={setDaysEnabled} day={item} />
                    ))}
                </Grid>
                <Grid item xs={6} />
                <Grid sx={{ marginTop: 3 }}>
                    <Button variant="contained" disabled={!groupName} onClick={handleCreate} >Create</Button>
                </Grid>
                <Grid item xs={12} sx={{ paddingBottom: 4, paddingTop: 4 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                    <Typography component={"div"} variant="h5">
                        Invite to social group
                    </Typography>
                    {/*                <Grid container>
                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography component={"div"} variant="caption" sx={{ fontSize: 26, display: "flex", alignItems: "center", textDecoration: "underline", marginTop: -2, marginBottom: 2 }}>
                                Group invites
                            </Typography>
                            <HelperIcon content={content} style={{ marginLeft: 10, marginBottom: 25 }} />
                        </Grid> */}
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"div"}>
                        Select social group
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                    <Autocomplete
                        disablePortal
                        id="groupselect"
                        options={groupChoices}
                        getOptionLabel={option => option.name}
                        onChange={(e, newVal) => newVal !== null && setSelectedGroup(newVal)}
                        inputValue={inputValue}
                        onInputChange={(e, newVal) => setInputValue(newVal)}
                        sx={{ width: 300, left: 50 }}
                        renderInput={(params) => <TextField {...params} label="Social groups" />}
                    />
                    {inputValue &&
                        <AvatarGroup total={selectedGroup.users.length} sx={{ marginLeft: 2 }}>
                            {selectedGroup.users.map((item, id) =>
                                <SmallAvatarWrapper key={item.id} userId={item.id} hasTooltip tooltipContent={item.first_name.length > 0 && item.last_name.length > 0 ? item.first_name + " " + item.last_name : item.username} imageSize="_32" />
                            )}
                        </AvatarGroup>
                    }
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 5, marginBottom: -1 }}>
                    Email to send the invite to
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        required
                        id="email"
                        label='Email'
                        name="email"
                        inputProps={{
                            autoComplete: "off",
                        }}
                        sx={{ marginBottom: 3 }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={handleInvite} disabled={!selectedGroup || !email} >Invite</Button>
                </Grid>
                <Toast
                    sx={{}}
                    type="success"
                    title="Success"
                    message={`Group has been created successfully`}
                    verticalPos="bottom"
                    horizontalPos="center"
                    open={openGroupCreated}
                    handleClose={() => setOpenGroupCreated(false)}
                    autoHideDuration={50000}
                />
                <Toast
                    sx={{}}
                    type="success"
                    title="Email sent"
                    message="Instructions for accepting group invite has been sent to your email if the email exists"
                    verticalPos="bottom"
                    horizontalPos="center"
                    open={openEmailSuccess}
                    handleClose={() => setOpenEmailSuccess(false)}
                    autoHideDuration={50000}
                />
                <Toast
                    sx={{}}
                    type="error"
                    title="Email send failed"
                    message="Internal server error"
                    verticalPos="bottom"
                    horizontalPos="center"
                    open={openEmailError}
                    handleClose={() => setOpenEmailError(false)}
                    autoHideDuration={50000}
                />
                <Grid item xs={12} sx={{ paddingBottom: 4, paddingTop: 4 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                    <Typography component={"div"} variant="h5">
                        Social Group join requests
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SocialGroupRequests sx={{ height: 500 }} />
                </Grid>
            </Grid>
        </LayoutPaper>
    );
};
