import React, { } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useTheme, useMediaQuery } from '@mui/material';

export default function ConfirmationDialog({ handleDevice, name, address, open, setOpen, content, title, checkValue, value, handle, noAction, info, isDisabled, stringTitle }) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={!isSmall ? { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: 350 } : null}>
                {stringTitle ?  `${title} ${name}?` : name ? checkValue ? `${title[1]} ${name}?` : info ? `${title[0]} ${name}` : `${title[0]} ${name}?` : `${title[0]} Device?`}
            </DialogTitle>
            {(address)
                ?
                isSmall
                    ?
                    <DialogTitle id="alert-dialog-subtitle" sx={{ marginTop: -3, color: "gray" }}>
                        {`Mac: ${address}`}
                    </DialogTitle>
                    :
                    <DialogTitle id="alert-dialog-subtitle" sx={{ position: "absolute", right: 0 }}>
                        {`Mac: ${address}`}
                    </DialogTitle>
                :
                null
            }
            <DialogContent sx={{ width: "100%" }}>
                <DialogContentText component={"div"} id="alert-dialog-description" >
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)} >Close</Button>
                {!noAction &&
                    <Button disabled={isDisabled} variant="contained" onClick={handle ? () => { handleDevice(); setOpen(false); } : () => { handleDevice(value ? value : address); setOpen(false); }}>
                        {stringTitle ?  title : checkValue ? title[1] : title[0]}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}