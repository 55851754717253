import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import MissingLinkLogo from '../Assets/Images/mlLogo_noText_colored.svg';
import CircleIcon from '@mui/icons-material/Circle';
import { IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxHeight: "80%",
    bgcolor: 'background.paper',
    p: 4,
};

export default function InfoModal(props) {
    const theme = useTheme();
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    return (
        <>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpenModal}
            >
                <InfoIcon />
            </IconButton>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        size="medium"
                        onClick={handleCloseModal}
                        sx={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <ClearIcon sx={{ color: '#000' }} />
                    </IconButton>
                    <Typography component={"div"} id="modal-modal-title" variant="h6" sx={{ display: "flex", alignItems: "center", marginX: 3 }}>
                        <img src={MissingLinkLogo} alt="MissingLinlogo" width={60} />
                        <div style={{ marginLeft: 10, fontWeight: "bold" }}>Missing-Link WiFi-WiSE</div>
                    </Typography>
                    <Box
                        sx={{
                            overflow: 'scroll',
                            '&::-webkit-scrollbar': {
                                width: '0.3em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#1C4E71",
                            },
                            scrollbarColor: `${theme.palette.primary.main} #fff`,
                            scrollbarWidth: "thin",
                            height: "50vh",
                            overflowX: "hidden"

                        }}
                    >
                        <Typography component={"div"} id="modal-modal-title1" sx={{ mt: 3, marginX: 1, flexDirection: "row", display: "flex", alignItems: "center" }}>
                            <CircleIcon sx={{ color: theme.palette.mlBlue.main, fontSize: 16 }} />
                            <div style={{ fontWeight: "bold", marginLeft: 15 }}>Wifi-Wise system in general</div>
                        </Typography>
                        <Typography component={"div"} id="modal-modal-description" sx={{ mt: 2, marginX: 5.5, flexDirection: "row", display: "flex", alignItems: "center" }}>
                            This application is part of the WiSe System of Missing-Link Oy. The system scans wireless networks in exactly the same way as any wireless base station. The system does not store or process encrypted data, and therefore does not know anything about the payload data being transmitted in the area. The system is therefore fully legal and complies with both Finnish and EU legislation.
                            <br /><br />
                            The system continuously collects more data from its environment, which can be used for general statistical purposes. It can be used many wonderful things like an example: To improve the coverage of wireless networks, calculate their utilisation rates, interferences and find out the number of visitors and mass movements in the area without creating a so-called “personal data record.”
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 1 }}>
                        {/* APP VERSION NÄKYVISSÄ INFO NAPISSA*/}
                        <Typography component={"div"} color="primary" sx={{ fontSize: 18, fontWeight: "bold", position: "relative", top: 10 }}>
                            {process.env.REACT_APP_CI_COMMIT_REF_NAME ? process.env.REACT_APP_CI_COMMIT_REF_NAME : "dev"}
                        </Typography>

                        <Typography onClick={handleCloseModal} component={Link} to={`/termsconditions`} target="_blank" rel="noopener noreferrer" sx={{ color: theme.palette.mlBlue.main, cursor: 'pointer', position: "relative", left: 22, top: 10 }}>
                            Terms & Conditions
                            <ArrowForwardIosIcon sx={{ ml: 0.4, fontSize: 16 }} />
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
