import { useCallback, useReducer, useState } from 'react';
import { useDataProvider, } from 'kerberus-components';
import { Autocomplete, Button, MenuItem, Modal, Select, TextField, Typography, Card, Grid, IconButton } from '@mui/material';
import useSWR from 'swr';
import ClearIcon from '@mui/icons-material/Clear';
import Toast from '../Toast';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 1,
    pr: 6,
    borderRadius: 4,
    width: "90vw",
    height: "75%",
};

export default function ModifyToken() {
    const { getData: wsSetter } = useDataProvider();
    const [currentToken, setCurrentToken] = useState("");
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [selectedSocialAdd, setSelectedSocialAdd] = useState([]);
    const [selectedSocialRemove, setSelectedSocialRemove] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectedCompanyRemove, setSelectedCompanyRemove] = useState([]);
    const [selectedSites, setSelectedSites] = useState([]);
    const { data: socialGroupSelectData = [] } = useSWR('users/socialgroupselect/');
    const { data: companySelectData = [] } = useSWR('users/companyselect/');
    const { data: siteSelectData = [] } = useSWR('devices/siteselect/');
    const [addPerms, setAddPerms] = useState([]);
    const [socialsToAdd, setSocialsToAdd] = useState(new Map());
    const [companiesToAdd, setCompaniesToAdd] = useState(new Map());
    const [addModalOpen, toggleAddModal] = useReducer(prev => !prev, false);
    const [removeModalOpen, toggleRemoveModal] = useReducer(prev => !prev, false);
    const [siteAddModalOpen, toggleSiteAddModal] = useReducer(prev => !prev, false);
    const [siteRemoveModalOpen, toggleSiteRemoveModal] = useReducer(prev => !prev, false);

    const handleAddToToken = useCallback(async () => {
        if (!currentToken || (socialsToAdd.size === 0 && companiesToAdd.size === 0)) return;

        try {
            const res = await wsSetter({ serial: 'socketToken' }, 'set', 'addToToken', {
                filters: {
                    token: currentToken,
                    social_groups: socialsToAdd.size > 0 ? [...socialsToAdd].map(([key, value]) => ({ id: key, permissions: value.permissions })) : undefined,
                    companies: companiesToAdd.size > 0 ? [...companiesToAdd].map(([key, value]) => ({ id: key, permissions: value.permissions })) : undefined,
                }
            });
            alert(res.data);
        }
        catch (err) {
            console.error(err);
        }
    }, [companiesToAdd, currentToken, socialsToAdd, wsSetter]);

    const handleRemoveFromToken = useCallback(async () => {
        if (!currentToken || (selectedSocialRemove.length === 0 && selectedCompanyRemove.length === 0)) return;

        try {
            const res = await wsSetter({ serial: 'socketToken' }, 'set', 'removeFromToken', {
                filters: {
                    token: currentToken,
                    social_groups: selectedSocialRemove,
                    companies: selectedCompanyRemove,
                }
            });
            alert(res.data);
        }
        catch (err) {
            console.error(err);
        }
    }, [currentToken, selectedCompanyRemove, selectedSocialRemove, wsSetter]);


    const handleAddSites = useCallback(async () => {
        if (!currentToken || selectedSites.length === 0) return;

        try {
            const res = await wsSetter({ serial: 'socketToken' }, 'set', 'addSites', {
                filters: {
                    token: currentToken,
                    sites: selectedSites.map(i => i.id)
                }
            });
            alert(res.data);
        }
        catch (err) {
            console.error(err);
        }
    }, [currentToken, selectedSites, wsSetter]);


    const handleRemoveSites = useCallback(async () => {
        if (!currentToken || selectedSites.length === 0) return;

        try {
            const res = await wsSetter({ serial: 'socketToken' }, 'set', 'removeSites', {
                filters: {
                    token: currentToken,
                    sites: selectedSites.map(i => i.id)
                }
            });
            alert(res.data);
        }
        catch (err) {
            console.error(err);
        }
    }, [currentToken, selectedSites, wsSetter]);

    const handleDeleteToken = useCallback(async () => {
        if (!currentToken) return;

        try {
            const res = await wsSetter({ serial: 'socketToken' }, 'set', 'deleteToken', {
                filters: {
                    token: currentToken.trim()
                }
            });
            if (!res.data.error) {
                setDeleteSuccess(res.data.data);
            }
            else {
                setDeleteError(res.data.error);
            }
        }
        catch (err) {
            console.error(err);
        }
    }, [currentToken, wsSetter]);
    

    return (
        <Grid container>

            <Grid item xs={12} sx={{}}>
                <Typography component={"div"} variant="h5" sx={{ mb: 2 }}>Modify token</Typography>
                <TextField
                    label="Token"
                    value={currentToken}
                    onChange={(e) => setCurrentToken(e.target.value)}
                    sx={{ width: 300 }}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="clear"
                                onClick={() => setCurrentToken("")}
                            >
                                <ClearIcon />
                            </IconButton>
                        )
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Button sx={{ mt: 1 }} variant="contained" onClick={handleDeleteToken}>Delete Token</Button>
                {/*        
                MUUT LISÄTOIMINNOT MUOKKAAMAAN TOKENIA         
                <br />
                <Button variant="contained" onClick={() => toggleAddModal()}>Add Social Groups and Companies</Button>
                <br />
                <Button variant="contained" onClick={() => toggleRemoveModal()}>Remove Social Groups and Companies</Button>
                <br />
                <Button variant="contained" onClick={() => toggleSiteAddModal()}>Add Sites</Button>
                <br />
                <Button variant="contained" onClick={() => toggleSiteRemoveModal()}>Remove Sites</Button>
                <br /> 
                */}

            </Grid>
            <Modal
                open={addModalOpen}
                onClose={() => toggleAddModal()}
            >
                <Card sx={modalStyle}>
                    <br />
                    Social Groups to add:
                    {[...socialsToAdd].map(([key, value]) => (
                        <Typography>{value.name} {value.permissions.join(', ')}</Typography>
                    ))}
                    <br />
                    <Autocomplete
                        options={socialGroupSelectData}
                        getOptionLabel={option => option.name}
                        renderInput={(params) => <TextField {...params} label="Social Groups" />}
                        onChange={(e, value) => setSelectedSocialAdd(value)}
                    />
                    <Select
                        value={addPerms}
                        multiple
                        onChange={(e) => setAddPerms(e.target.value)}
                        label="Permissions"
                    >
                        <MenuItem value={0}>Every permission</MenuItem>
                        <MenuItem value={1}>Can view current online userss</MenuItem>
                        <MenuItem value={2}>Can get last seen online timestamps for users</MenuItem>
                        <MenuItem value={3}>Query with "public_social" filters</MenuItem>
                    </Select>
                    <Button onClick={() => {
                        if (selectedSocialAdd && addPerms.length > 0 && !socialsToAdd.has(selectedSocialAdd.id)) {
                            setSocialsToAdd(prev => {
                                prev.set(selectedSocialAdd.id, {
                                    name: selectedSocialAdd.name,
                                    permissions: addPerms
                                });
                                return prev;
                            });
                        }
                        else {
                            alert('Failed');
                        }
                    }}>Add Social Group</Button>
                    Companies to add:
                    {[...companiesToAdd].map(([key, value]) => (
                        <Typography>{value.name} {value.permissions.join(', ')}</Typography>
                    ))}
                    <br />
                    <Autocomplete
                        options={companySelectData}
                        getOptionLabel={option => option.name}
                        renderInput={(params) => <TextField {...params} label="Companies" />}
                        onChange={(e, value) => setSelectedCompany(value)}
                    />
                    <Select
                        value={addPerms}
                        multiple
                        onChange={(e) => setAddPerms(e.target.value)}
                        label="Permissions"
                    >
                        <MenuItem value={0}>Every permission</MenuItem>
                        <MenuItem value={1}>Can view current online userss</MenuItem>
                        <MenuItem value={2}>Can get last seen online timestamps for users</MenuItem>
                        <MenuItem value={3}>Query with "public_social" filters</MenuItem>
                    </Select>
                    <Button onClick={() => {
                        if (selectedCompany && addPerms.length > 0 && !companiesToAdd.has(selectedCompany.id)) {
                            setCompaniesToAdd(prev => {
                                prev.set(selectedCompany.id, {
                                    name: selectedCompany.name,
                                    permissions: addPerms
                                });
                                return prev;
                            });
                        }
                        else {
                            alert('Failed');
                        }
                    }}>Add Company</Button>
                    <Button onClick={handleAddToToken}>Add selected Companies and Social Groups</Button>
                </Card>
            </Modal>

            <Modal
                open={removeModalOpen}
                onClose={() => toggleRemoveModal()}
            >
                <Card sx={modalStyle}>
                    <Autocomplete
                        options={socialGroupSelectData}
                        multiple
                        getOptionLabel={option => option.name}
                        renderInput={(params) => <TextField {...params} label="Social Groups" />}
                        onChange={(e, value) => setSelectedSocialRemove(value)}
                    />
                    <Autocomplete
                        options={companySelectData}
                        multiple
                        getOptionLabel={option => option.name}
                        renderInput={(params) => <TextField {...params} label="Companies" />}
                        onChange={(e, value) => setSelectedCompanyRemove(value)}
                    />
                    <Button onClick={handleRemoveFromToken}>Remove selected Companies and Social Groups</Button>
                </Card>
            </Modal>

            <Modal
                open={siteAddModalOpen}
                onClose={() => toggleSiteAddModal()}
            >
                <Card sx={modalStyle}>
                    <Autocomplete
                        options={siteSelectData}
                        multiple
                        getOptionLabel={option => option.name}
                        renderInput={(params) => <TextField {...params} label="Sites" />}
                        onChange={(e, value) => setSelectedSites(value)}
                    />
                    <Button onClick={handleAddSites}>Add selected Sites</Button>
                </Card>
            </Modal>

            <Modal
                open={siteRemoveModalOpen}
                onClose={() => toggleSiteRemoveModal()}
            >
                <Card sx={modalStyle}>
                    <Autocomplete
                        options={siteSelectData}
                        multiple
                        getOptionLabel={option => option.name}
                        renderInput={(params) => <TextField {...params} label="Sites" />}
                        onChange={(e, value) => setSelectedSites(value)}
                    />
                    <Button onClick={handleRemoveSites}>Remove selected Sites</Button>
                </Card>
            </Modal>

            <Toast
                sx={{}}
                type="success"
                title={deleteSuccess}
                message="Token has been succesfully deleted"
                verticalPos="bottom"
                horizontalPos="center"
                open={deleteSuccess}
                handleClose={() => setDeleteSuccess(false)}
                autoHideDuration={50000}
            />
            <Toast
                sx={{}}
                type="error"
                title="Delete error"
                message={deleteError}
                verticalPos="bottom"
                horizontalPos="center"
                open={deleteError}
                handleClose={() => setDeleteError(false)}
                autoHideDuration={50000}
            />
        </Grid>
    );
}
