import { Route, Routes } from "react-router-dom";
import LoginScreen from "../../Views/Login";
import PasswordReset from "../../Views/PasswordReset";
import LoginProvider from "./LoginProvider";
import { Navigate } from 'react-router-dom';
import SocketProvider from "./SocketProvider";
import App from "../../App";
import AccountCreate from "../../Views/AccountCreate";
import TermsConditions from "../TermsConditions";

export default function RootRoute() {
    return (
        <Routes>
            <Route path="*" element={
                <LoginProvider>
                    <SocketProvider>
                        <App />
                    </SocketProvider>
                </LoginProvider>} />
            <Route exact path="/termsconditions" element={<TermsConditions />} />
            <Route exact path="/login" element={<LoginScreen />} />
            <Route path="/reset_password" exact element={<Navigate to="/" />} />
            <Route path="/reset_password/:token" element={<PasswordReset />} />
            <Route path="/create_account" exact element={<Navigate to="/" />} />
            <Route path="/create_account/:token" element={<AccountCreate />} />
        </Routes>
    );
};