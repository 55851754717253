import { Button, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useFetchCloud,SmallAvatarWrapper } from 'kerberus-components';
import { useEffect, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';

export default function SocialGroupRequests(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { fetchCloud } = useFetchCloud();
    const [requests, setRequests] = useState([]);
    const { data: grouprequestsData } = useSWR('users/grouprequests/');
    const { mutate } = useSWRConfig();

    useEffect(() => {
        if (grouprequestsData) {
            setRequests(grouprequestsData);
        }
    }, [grouprequestsData]);

    const handleRequest = async (request_id, accept) => {
        try {
            await fetchCloud('users/handle_group_request', 'POST', {
                request_id: request_id,
                accept: accept
            });
            mutate('users/grouprequests/');
        }
        catch (err) {
            console.error(err);
        }
    };

    const gridColumns = [
        {
            field: 'id', headerName: '', flex: 0.25, align: "center", renderCell: (e) => (
                <SmallAvatarWrapper alt="ProfilePic" userId={e.row.user} sx={{ width: 32, height: 32 }} imageSize="_32" />
            )
        },
        {
            field: 'first_name', headerName: 'First Name', flex: 1
        },
        {
            field: 'last_name', headerName: 'Last Name', flex: 1
        },
        {
            field: 'username', headerName: 'Username', flex: 1
        },
        {
            field: 'group_name', headerName: 'Social Group', flex: 1
        },
        {
            field: 'accept', headerName: '', renderCell: (e) => (<Button variant="contained" color="success" onClick={() => handleRequest(e.row.id, true)}>Accept</Button>)
        },
        {
            field: 'reject', headerName: '', renderCell: (e) => (<Button variant="contained" color="error" onClick={() => handleRequest(e.row.id, false)} >Reject</Button>)
        }
    ];

    return (
        <DataGrid
            components={!isSmall && { Toolbar: GridToolbar }}
            rows={props.filtered ? props.grouprequestsData : requests}
            columns={gridColumns}
            disableSelectionOnClick
            isCellEditable={() => false}
            {...props}
        />
    );
}