import { useContext } from 'react';
import { MainContext } from 'kerberus-components';
import moment from 'moment';

export default function GetOnlineData(user) {
    const { bulkData } = useContext(MainContext);
    const onlineData = bulkData.company

    const userObj = onlineData?.find(i => i.user_id === user.id);
    const busy = userObj?.status === 'busy';
    const now = moment();
    const lastSeen = userObj?.timestamp ? moment(userObj.timestamp) : null;
    const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
    const lastseenminutes = lastSeen ? duration.asMinutes() : null;

    return { lastseenminutes: lastseenminutes, busy: busy, timestamp: userObj?.timestamp, layer_name: userObj?.layer_name }
}
