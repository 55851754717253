import * as React from 'react';
import { useEffect, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProfileIcon from './ProfileIcon';
import ProfileText from './ProfileText';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import ProfileCalendar from '../Profile/ProfileCalendar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ProfileDevice from '../Profile/ProfileDevices';
import { MainContext, useFetchCloud, useWsDataProvider } from 'kerberus-components';
import ProfileSettings from './ProfileSettings';
import moment from 'moment';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import LayoutPaper from '../../Components/LayoutPaper';
import useSWR, { useSWRConfig } from 'swr';
import ProfileMutualSocialGroups from '../../Components/Profile/ProfileMutualSocialGroups';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={"div"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const parseLocation = (location) => {
    var split = location.split('/');
    switch (split[split.length - 1]) {
        case ('calendar'): return 1;
        case ('device'): return 2;
        case ('settings'): return 3;
        default: return 0;
    }
};

export default React.memo(function ProfileView() {
    const { user, bulkData } = useContext(MainContext);
    const [userData, setUserData] = useState(null);
    const { userId } = useParams();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isLarge= useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();
    const [value, setValue] = useState(0);
    const { getData, data, doUnsubscribe } = useWsDataProvider();
    const [onlineData, setOnlineData] = useState(null);
    const { fetchCloud } = useFetchCloud();
    const [onEnterChecked, setOnEnterChecked] = useState(() => {
        if (userId === user.id) return false;
        else {
            const alarmObj = user.alarms.find(i => i.target === userId);
            return alarmObj ? alarmObj.on_enter : false;
        }
    });
    const [onExitChecked, setOnExitChecked] = useState(() => {
        if (userId === user.id) return false;
        else {
            const alarmObj = user.alarms.find(i => i.target === userId);
            return alarmObj ? alarmObj.on_exit : false;
        }
    });
    const { data: swrUserData } = useSWR(user.id !== userId ? `users/timetableprofile/${userId}/` : null);
    const { data: swrSiteData } = useSWR(user.id === userId ? `users/usersites` : null);
    const { mutate } = useSWRConfig();
    useEffect(() => {
        if (userId !== user.id) {
            const alarmObj = user.alarms.find(item => item.target === userId);
            if (alarmObj) {
                setOnEnterChecked(alarmObj.on_enter);
                setOnExitChecked(alarmObj.on_exit);
            }
        }
    }, [user.alarms, user.id, userId]);

    const lastseenminutes = useMemo(() => {
        if (onlineData?.timestamp) {
            const now = moment();
            const lastSeen = moment(onlineData.timestamp);
            const duration = moment.duration(now.diff(lastSeen));
            return duration.asMinutes();
        }
        else return null;
    }, [onlineData]);

    useEffect(() => { setValue(parseLocation(location.pathname)); }, [location]);

    useEffect(() => {
        if (swrUserData) {
            setUserData(swrUserData);
        }
    }, [swrUserData]);

    useEffect(() => {
        if (user.id === userId && bulkData) {
            setUserData(user);
            setOnlineData(bulkData.user);
        }
    }, [user, userId, bulkData]);

    useEffect(() => {
        if (user.id !== userId) {
            getData('socketLocation', 'lastFoundOther', {
                filters: {
                    id: userId
                },
                subscribe: true
            });
        }
        return () => doUnsubscribe();
    }, [getData, doUnsubscribe, user.id, userId]);

    useEffect(() => {
        if (user.id !== userId) {
            setOnlineData(data);
        }
    }, [data, user.id, userId]);

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleEnterAlarmCheck = async () => {
        await fetchCloud(`alarms/create_user_alarm`, 'post', {
            target: userId,
            on_enter: !onEnterChecked,
            on_exit: onExitChecked
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    const handleExitAlarmCheck = async () => {
        await fetchCloud(`alarms/create_user_alarm`, 'post', {
            target: userId,
            on_enter: onEnterChecked,
            on_exit: !onExitChecked
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };
    return userData ? (
        <LayoutPaper>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: -4, marginX: -4 }}>
                <Tabs
                    value={value}
                    /* onChange={handleChange} */
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    centered

                >
                    <Tab icon={<PersonIcon />} label={isSmall ? "" : "Profile"} component={RouterLink} {...a11yProps(0)} to={`/profile/${userId}`} replace />
                    {(user.id === userId || userData.public_calendar) &&
                        <Tab icon={<CalendarTodayIcon />} label={isSmall ? "" : "Calendar"} component={RouterLink} {...a11yProps(1)} to={`/profile/${userId}/calendar`} replace />
                    }
                    {user.id === userId &&
                        <Tab icon={<DevicesOtherIcon />} label={isSmall ? "" : "Device"} component={RouterLink} {...a11yProps(2)} to={`/profile/${userId}/device`} replace />
                    }
                    {user.id === userId &&
                        <Tab icon={<SettingsIcon />} label={isSmall ? "" : "Settings"} component={RouterLink} {...a11yProps(3)} to={`/profile/${userId}/settings`} replace />
                    }
                    {/* <Tab label="Availability" component={RouterLink} {...a11yProps(1)} to={`/profile/${userId}/availability`} replace /> */}
                    {/* <Tab label="Map" component={RouterLink} {...a11yProps(3)} to={`/profile/${userId}/map`} replace /> */}
                </Tabs>
            </Box>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={4} md={3.5} sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                            <ProfileIcon onlineData={onlineData} avatarWidth={isLarge ? 150 : 200} avatarHeight={isLarge ? 150 : 200} isSmall={isSmall} userId={userId} lastseenminutes={lastseenminutes} />
                        </Grid>
                        <Grid item xs={12} sm={8} md={8.5} >
                            <ProfileText userData={userData} onlineData={onlineData} lastseenminutes={lastseenminutes} />
                        </Grid>
                    </Grid>
                    {/*                     {!user.next &&

                        user.id === userId &&
                        <Grid container sx={{}}>
                            <Grid item xs={12} sx={{ marginTop: 5 }}  >
                                <ProfileShare />
                            </Grid>
                        </Grid>

                    } */}
                    {user.id !== userId && userData?.allow_tracking &&
                        <Grid container sx={{}}>
                            <Grid sx={{ marginTop: 5}}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={onEnterChecked} onChange={handleEnterAlarmCheck} />}
                                        label="Notify me when this person is present next time" />
                                    <FormControlLabel
                                        control={<Checkbox checked={onExitChecked} onChange={handleExitAlarmCheck} />}
                                        label="Notify me when this person leaves" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    }     
                    <ProfileMutualSocialGroups userPersonalId={user.id} userId={userId} userData={userData} socialGroups={user.social_groups}/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <ProfileCalendar devgroup={userData.device_groups} userData={userData} swrSiteData={swrSiteData} />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <ProfileDevice devgroup={userData.device_groups} isSmall={isSmall} isMedium={isMedium} isLarge={isLarge} />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <ProfileSettings />
                </TabPanel>
            </SwipeableViews>
        </LayoutPaper>
    ) : null;
})
