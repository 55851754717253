import React from 'react';
import { TextField, Autocomplete, Typography } from '@mui/material';
import { timezones } from 'kerberus-components';



export default React.memo(function ProfileSettings({
    isSmall,
    first_name,
    last_name,
    setFirst_name,
    setLast_name,
    title, 
    setTitle,
    tz,
    setTz
}) {

    return (
        <>
            <Typography component={"div"} color="#808080" textAlign="center" sx={{ mt: 1 }}>
                Enter profile details
            </Typography>
            <TextField
                margin="normal"
                required
                sx={{ width: isSmall ? "100%" : "80%" }}
                id="first_name"
                label='First name'
                name="first_name"
                autoComplete="given-name"
                value={first_name}
                onChange={(e) => setFirst_name(e.target.value)}
            />
            <TextField
                margin="normal"
                required
                sx={{ width: isSmall ? "100%" : "80%" }}
                id="last_name"
                label='Last name'
                name="last_name"
                autoComplete="family-name"
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
            />
            <TextField
                margin="normal"
                sx={{ width: isSmall ? "100%" : "80%" }}
                id="title"
                label='Title'
                name="title"
                autoComplete="organization-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <Autocomplete
                disablePortal
                id="timezone-autocomplete-input"
                options={timezones}
                value={tz}
                onChange={(e, newVal) => setTz(newVal)}
                renderInput={(params) => <TextField {...params} label="Timezone" />}
                sx={{ width: isSmall ? "100%" : "80%", mt: 1 }}
            />

        </>
    )
})
