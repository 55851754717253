import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HelpIcon from '@mui/icons-material/Help';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { Autocomplete, Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, SvgIcon, TextField, Tooltip, Typography } from "@mui/material";
import * as React from 'react';
import { useState } from "react";
import { Controller } from "react-hook-form";
import { Link } from 'react-router-dom';
import { ReactComponent as SlackLogo } from '../../Assets/Images/Slack_Mark.svg';
import ConfirmationDialog from '../../Components/ConfirmationDialog';
import HelperIcon from '../../Components/HelperIcon';

function SlackIcon(props) {
    return (
        <Tooltip title="Slack">
            <SvgIcon {...props}>
                <SlackLogo />
            </SvgIcon>
        </Tooltip>
    );
}

export default React.memo(function SocialGroupSettingsAdmin({
    handleSubmit,
    onSubmit,
    control,
    formState,
    register,
    watch,
    isSmall,
    socialGroup,
    user,
    handleChangeGroup,
    setValue,
    dayOptions
}) {
    const [openCreatorDialog, setOpenCreatorDialog] = useState(false);
    const [isDisabled, setIsDisabled,] = useState(true);
    const [socialLinkDialog, setSocialLinkDialog] = useState();

    const contentSocialLinks = (
        <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
            Promote <span style={{ fontWeight: "bold" }}>{openCreatorDialog.first_name && openCreatorDialog.last_name ? openCreatorDialog.first_name + " " + openCreatorDialog.last_name : openCreatorDialog.username}</span> to the manager of the group.
            <br />
            <br />
            Please ensure that you understand that you will be giving your manager role to this person and you will lose your permissions as the group manager.
            <br />
            <br />
            The changes take effect when you press the Save button located at the bottom of the group data sharing settings.
            <br />
            <br />
            <Typography component={"div"} sx={{ fontWeight: "bold", position: "absolute", right: 5, bottom: 65 }}>
                I understand
                <Checkbox checked={!isDisabled} onChange={() => setIsDisabled(isDisabled => !isDisabled)} />
            </Typography>
            <br />
        </Typography>
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", }}>

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid item xs={12} md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                margin="normal"
                                label="Description"
                                inputProps={{
                                    autoComplete: "off",
                                    maxLength: 256
                                }}
                                sx={{ width: "100%", marginLeft: 1 }}
                            />
                        }
                    />
                </Grid>
                
                <Divider sx={{ my: 2 }} />

                <Grid item xs={12}>
                    <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography align="center" variant="h6" component={"div"} sx={{ mb: 2, height: 30 }}>
                                Public
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container>
                                <Grid item xs={5} md={12} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                    <Controller
                                        name="public"
                                        control={control}
                                        render={({ field }) =>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={field.value} {...field} />
                                                }
                                                label={
                                                    <div>
                                                        
                                                    </div>
                                                }
                                                labelPlacement="start"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={7} md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "left"}}>
                                    <Typography variant="subtitle2" sx={{ ml: isSmall ? 0 : 3 }} align="left" color="#808080">
                                        Make the group public.
                                    </Typography>
                                </Grid> 
                            </Grid>                          
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />

                {socialGroup.creator === user.id &&
                    <>
                        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <AdminPanelSettingsIcon color="primary" sx={{ width: 30, height: 30, ml: 1 }} />
                            </Box>
                            <Controller
                                name="creator"
                                control={control}
                                render={({ field }) =>
                                    <Autocomplete
                                        disablePortal
                                        id="groupcreatorautocomplete"
                                        options={socialGroup?.users}
                                        getOptionLabel={option => option.first_name && option.last_name ? option.first_name + " " + option.last_name : option.username}
                                        fullWidth
                                        sx={{ marginLeft: 1 }}
                                        value={socialGroup?.users[socialGroup?.users.findIndex(item => item.id === field.value)]}
                                        onChange={(e, newVal) => newVal !== null && setOpenCreatorDialog(newVal)}
                                        renderInput={(params) => <TextField {...params} label="Creator" />}
                                    />
                                }
                            />
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                    </>
                }


                <Grid item xs={12} md={12} textAlign="center" >
                    <Typography component={"div"} variant="h6" sx={{ marginBottom: 1 }}>
                        Social Group Links
                    </Typography>

                    <Controller
                        name="slack"
                        control={control}
                        render={({ field: { onChange, value } }) =>

                            <Box sx={{ display: "flex", flexDirection: isSmall && "column" }}>

                                <Box sx={{ display: isSmall && "flex", alignItems: "center", justifyContent: "center" }}>

                                    <SlackIcon sx={{ width: 50, height: 50 }} />
                                    {isSmall &&
                                        <Typography sx={{ mt: 0.8, ml: 0.5 }}>Slack</Typography>
                                    }
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <TextField
                                        {...register("slack", {
                                            pattern: {
                                                value: /https.*slack/,
                                                message: "Invalid link. Must start with https://"
                                            }
                                        })}
                                        onChange={onChange}
                                        value={value}
                                        variant="outlined"
                                        label={!isSmall && "Slack"}
                                        fullWidth
                                        error={formState.errors.slack?.message}
                                        helperText={formState.errors.slack?.message}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip placement="top" title="By pressing this button you will be forwarded to Slack's helping page for getting your workspace link. Post the workspace link to this input.">
                                                        <IconButton comnponent={Link} href="https://slack.com/help/articles/221769328-Locate-your-Slack-URL" target="_blank" rel="noopener noreferrer">
                                                            <HelpIcon color="mlBlue" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }}


                                    />
                                </Box>
                            </Box>
                        }
                    />

                    <Divider sx={{ my: 3, mx: 10 }} />

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography component={"div"} variant="h6" sx={{ marginBottom: 1 }}>
                            Member Social Links
                        </Typography>
                        <HelperIcon content="Select which links to display in group's member card" style={{ marginLeft: 4 }} />
                    </Box>
                    <FormGroup row sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Controller
                            name="linkedinHide"
                            control={control}
                            render={({ field }) =>
                                <Tooltip title="LinkedIn">
                                    <FormControlLabel
                                        control={
                                            <Tooltip>
                                                <Checkbox checked={field.value} {...field} />
                                            </Tooltip>
                                        }
                                        label={<LinkedInIcon sx={{ color: "#0A66C2" }} />}
                                        labelPlacement="top"
                                    />
                                </Tooltip>
                            }
                        />

                        <Controller
                            name="twitterHide"
                            control={control}
                            render={({ field }) =>
                                <Tooltip title="Twitter">
                                    <FormControlLabel
                                        control={
                                            <Tooltip>
                                                <Checkbox checked={field.value} {...field} />
                                            </Tooltip>
                                        }
                                        label={<TwitterIcon sx={{ color: "#1da1f2" }} />}
                                        labelPlacement="top"
                                    />
                                </Tooltip>
                            }
                        />
                        <Controller
                            name="company_pageHide"
                            control={control}
                            render={({ field }) =>
                                <Tooltip title="Company Page">
                                    <FormControlLabel
                                        control={
                                            <Tooltip>
                                                <Checkbox checked={field.value} {...field} />
                                            </Tooltip>
                                        }
                                        label={<LanguageIcon color="mlBlue" />}
                                        labelPlacement="top"
                                    />
                                </Tooltip>
                            }
                        />
                        <Controller
                            name="whatsappHide"
                            control={control}
                            render={({ field }) =>
                                <Tooltip title="WhatsApp">
                                    <FormControlLabel
                                        control={
                                            <Tooltip>
                                                <Checkbox checked={field.value} {...field} />
                                            </Tooltip>
                                        }
                                        label={<WhatsAppIcon sx={{ color: "#25D366" }} />}
                                        labelPlacement="top"
                                    />
                                </Tooltip>
                            }
                        />
                        <Controller
                            name="calendlyHide"
                            control={control}
                            render={({ field }) =>
                                <Tooltip title="Calendly">
                                    <FormControlLabel
                                        control={
                                            <Tooltip>
                                                <Checkbox checked={field.value} {...field} />
                                            </Tooltip>
                                        }
                                        label={<CalendarMonthIcon color="mlBlue" />}
                                        labelPlacement="top"
                                    />
                                </Tooltip>
                            }
                        />
                    </FormGroup>
                </Grid>

                <Divider sx={{ my: 2 }} />

                <Grid item xs={12} md={12} textAlign="center">
                    <Typography component={"div"} variant="h6" sx={{ marginBottom: 2 }}>
                        Visibility time
                    </Typography>
                    <Controller
                        name="alwaysActive"
                        control={control}
                        render={({ field }) =>
                            <FormControlLabel
                                control={
                                    <Tooltip title="This choice only affects time">
                                        <Checkbox checked={field.value} {...field} />
                                    </Tooltip>
                                } label="Always active" labelPlacement="top"
                            />
                        }
                    />

                </Grid>

                <Grid item xs={12} md={12} sx={{ display: "flex", textAlign: "center" }}>
                    <Grid item xs>
                        <Typography component={"div"} variant="h6">
                            Starting Time
                        </Typography>

                        <Controller
                            name="startTime"
                            control={control}
                            render={({ field }) =>
                                <TimePicker
                                    {...field}
                                    label="Start time"
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={watch("alwaysActive")}
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs={0.5} />

                    <Grid item xs>
                        <Typography component={"div"} variant="h6">
                            Ending Time
                        </Typography>
                        <Controller
                            name="endTime"
                            control={control}
                            render={({ field }) =>
                                <TimePicker
                                    {...field}
                                    label="End time"
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={watch("alwaysActive")}
                                />
                            }
                        />
                    </Grid>
                </Grid>

                <Divider sx={{ my: 4 }} />

                <Grid item xs={12} md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", textAlign: "center" }}>
                    <FormGroup row>
                        <Controller
                            name="daysEnabled"
                            control={control}
                            render={({ field }) => (
                                <>
                                    {dayOptions.map((day) => (
                                        <FormControlLabel
                                            key={day.value}
                                            label={day.label}
                                            labelPlacement="top"
                                            sx={{ width: 15 }}
                                            control={
                                                <Checkbox
                                                    value={day.value}
                                                    checked={field?.value?.some(
                                                        // eslint-disable-next-line eqeqeq
                                                        (existingValue) => existingValue == day.value
                                                    )}
                                                    onChange={(event, checked) => {
                                                        if (checked) {
                                                            field.onChange([
                                                                ...field.value,
                                                                event.target.value
                                                            ]);
                                                        } else {
                                                            field.onChange(
                                                                field.value.filter(
                                                                    (value) => value !== event.target.value
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    ))}
                                </>
                            )}
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={12} sx={{ display: formState.isDirty ? "inline" : "none", marginTop: 5, textAlign: isSmall ? "center" : "right", position: "relative", right: isSmall ? 0 : 20 }} >
                    <Button variant="contained" type="submit">Save</Button>
                </Grid>

                <ConfirmationDialog
                    title={"Promote"}
                    handleDevice={() => setValue('creator', openCreatorDialog.id)}
                    isDisabled={isDisabled}
                    name={openCreatorDialog.first_name && openCreatorDialog.last_name ? openCreatorDialog.first_name + " " + openCreatorDialog.last_name : openCreatorDialog.username}
                    open={openCreatorDialog}
                    setOpen={setOpenCreatorDialog}
                    content={contentSocialLinks}
                    stringTitle
                />


                <ConfirmationDialog
                    title={["Save"]}
                    handleDevice={handleSubmit(onSubmit)}
                    info
                    isDisabled={isDisabled}
                    name={"social links"}
                    open={socialLinkDialog}
                    setOpen={setSocialLinkDialog}
                    content={contentSocialLinks}
                />


            </LocalizationProvider>

        </form >
    );
});