import { SvgIcon } from '@mui/material';
import { ReactComponent as SlackLogo } from '../../Assets/Images/Slack_Mark.svg';
import { ReactComponent as SlackLogoBlack } from '../../Assets/Images/Slack_Mark_Black.svg';


function SlackIcon(props) {
    return (
        <SvgIcon {...props}>
            <SlackLogo />
        </SvgIcon>
    );
}

function SlackIconBlack(props) {
    return (
        <SvgIcon {...props}>
            <SlackLogoBlack />
        </SvgIcon>
    );
}

export {SlackIcon, SlackIconBlack};