import { useState, useEffect } from "react";
import { useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';

export default function ShowOfflineStatus() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [showText, setShowText] = useState(true);
    const [online, setOnline] = useState(window.navigator.onLine);
    useEffect(() => {
        const statusOnline = window.addEventListener("online", () => setOnline(true));
        const statusOffline = window.addEventListener("offline", () => setOnline(false));
        return () => {
            statusOnline?.remove();
            statusOffline?.remove();
        };
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setShowText((showText) => !showText);
        }, 750);
        return () => clearInterval(interval);
    }, []);

    return online ?
        ''
        :
        <div style={{ position: "absolute", left: isSmall ? 250 : 0, right: 0, marginLeft: "auto", marginRight: "auto", width: 200 }}>
            <Typography component={"div"} sx={{ justifySelf: "center", alignSelf: "center", display: showText ? 'none' : 'flex' }}>
                <SignalWifiBadIcon sx={{ mr: 1 }} /> {isSmall ? '' : 'Connection Lost'}
            </Typography>

        </div>;
}