import SocketContext from './SocketContext';
import { dayjs } from '../dayjs-utils';
import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getCached, populateCache } from './SocketCache';

export const useWsDataProvider = (wsInstance = null) => {
    const socketContext = useContext(SocketContext);
    const { sendJsonMessage, lastJsonMessage } = wsInstance ?? socketContext;
    const [requestData, setRequestData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const clientRef = useRef(null);
    const cacheParams = useRef(null);
    const subscribedRef = useRef(false);
    const deviceRef = useRef(null);

    useEffect(() => {
        let mounted = true;
        if (lastJsonMessage?.payload?.client === clientRef.current && mounted) {
            setRequestData(lastJsonMessage.payload.data);
            populateCache(cacheParams.current, lastJsonMessage.payload.data);
            setLoaded(true);
        }
        return () => mounted = false;
    }, [lastJsonMessage]);

    const getData = useCallback((device, target, { ignoreCache = false, subscribe = false, filters = {}, multi = false } = {}) => {
        let clientUuid = uuidv4();
        clientRef.current = clientUuid;
        deviceRef.current = device;
        if (!target || !device) {
            throw new Error('Invalid function call');
        }
        cacheParams.current = {
            device: device,
            target,
            user: localStorage.getItem('userId'),
            params: target === 'devtracking' ? { filters: { ...filters, today: dayjs(filters.today).format('GGGG-[W]WW') }, multi } : { filters, multi }
        };
        sendJsonMessage({
            dest: device,
            payload: {
                rpc: {
                    command: 'get',
                    target: target,
                    params: { filters, client: clientUuid, multi },
                    subscribe: subscribe ? target : undefined
                }
            },
        });
        if (subscribe && !subscribedRef.current) {
            subscribedRef.current = true;
        }
        if (!ignoreCache) {
            let cachedVal = getCached(cacheParams.current);
            if (cachedVal) {
                setRequestData(cachedVal);
            }
        }
    }, [sendJsonMessage]);

    const doUnsubscribe = useCallback(() => {
        if (!subscribedRef.current || !clientRef.current || !deviceRef.current) {
            return;
        }
        sendJsonMessage({
            dest: deviceRef.current,
            payload: {
                rpc: {
                    unsubscribe: clientRef.current,
                }
            }
        });
    }, [sendJsonMessage]);

    const setData = useCallback((device, target, { filters = {} } = {}) => {
        let clientUuid = uuidv4();
        clientRef.current = clientUuid;
        if (!target || !device) {
            throw new Error('Invalid function call');
        }
        sendJsonMessage({
            dest: device,
            payload: {
                rpc: {
                    command: 'set',
                    target: target,
                    params: { filters, client: clientUuid }
                }
            },
        });
    }, [sendJsonMessage]);

    return { getData, data: requestData, loaded, doUnsubscribe, setData };
};
