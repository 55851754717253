import { Typography } from '@mui/material';
import { MainContext } from 'kerberus-components';
import { useContext } from 'react';
import moment from 'moment';

export default function WeeklyHours({deviceHours, isMedium, site}) {
    const { bulkData } = useContext(MainContext);

    const filteredData = bulkData.user.online_minutes.filter(item => item.site_name === site)
    const totalTimeMinutes = filteredData.reduce((prev, next) => prev + next.online_minutes, 0);
    const minutesDevice = (deviceHours % 60)
    const hoursDevice = (Math.floor(168 - deviceHours / 60))
    const minsSinceStart = moment().diff(moment().startOf('week'), 'minutes') - totalTimeMinutes;
    const minutes = minsSinceStart % 60;
    const hours = Math.floor(minsSinceStart / 60);

    return (
        deviceHours || deviceHours === 0
            ?
            deviceHours > 0
                ?
                <Typography component={"div"} fontSize={isMedium ? "13px" : "14px"} color="text.secondary" variant="h5" sx={{}} >
                    {`${hoursDevice}h ${minutesDevice}min`}
                </Typography>
                :
                <Typography component={"div"} fontSize={isMedium ? "13px" : "14px"} color="text.secondary" variant="h5" sx={{}} >
                    0h 0min
                </Typography>
            :
            totalTimeMinutes > 0
                ?
                <Typography component={"div"} color="text.secondary" variant="h5" sx={{}}>
                    {`${hours}h ${minutes}min`}
                </Typography>
                :
                <Typography component={"div"} color="text.secondary" variant="h5" sx={{}} >
                    {`${Math.trunc(moment().diff(moment().startOf('week'), 'minutes') / 60)}h ${Math.trunc(moment().diff(moment().startOf('week'), 'minutes') % 60)}min`}
                </Typography>
    );
}
