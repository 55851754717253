import React from 'react';
import { Grid } from '@mui/material';
import ProfileCard from "./Profile/ProfileCard";
import moment from 'moment';

export default React.memo(function UserCardList({
    rows,
    smallArea,
    hideLinks,
    socialGroup,
    allGroups,
    onlineData
}) {
    return (
        <Grid container>
            {
                [...rows].sort((prev, next) => {
                    const prevObj = onlineData?.find(i => i.user_id === prev.id);
                    const nextObj = onlineData?.find(i => i.user_id === next.id);

                    if (prevObj?.timestamp && nextObj?.timestamp) {
                        const now = moment();
                        const prevLast = moment.duration(now.diff(prevObj.timestamp)).asMinutes();
                        const nextLast = moment.duration(now.diff(nextObj.timestamp)).asMinutes();
                        if (prevLast <= 5 && nextLast <= 5) {
                            return 0;
                        }
                        else if ((prevLast <= 10 && prevLast >= 5) && (nextLast <= 10 && nextLast >= 5)) {
                            return 0;
                        }
                        else if (prevLast > 10 && nextLast > 10) {
                            return 0;
                        }
                        else if (prevLast > nextLast) {
                            return 1;
                        }
                        else return -1;
                    }
                    else if (prevObj?.timestamp && !nextObj?.timestamp) {
                        return -1;
                    }
                    else if (!prevObj?.timestamp && nextObj?.timestamp) {
                        return 1;
                    }
                    else if (!prevObj?.timestamp && !nextObj?.timestamp) {
                        return 0;
                    }
                    else return 0;
                }).map(user => {
                    const userObj = onlineData?.find(i => i.user_id === user.id);
                    const busy = userObj?.status === 'busy';
                    const now = moment();
                    const lastSeen = userObj?.timestamp ? moment(userObj.timestamp) : null;
                    const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                    const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                    return (
                        <Grid key={user.id} item xs={12} sm={12} md={6} lg={smallArea ? 6 : 4} xl={smallArea ? 4 : 2.4}>
                            <ProfileCard allGroups={allGroups} socialGroup={socialGroup} busy={busy} lastseenminutes={lastseenminutes} user={user} userObj={userObj} hideLinks={hideLinks} />
                        </Grid>
                    );
                }
                )
            }
        </Grid>
    );
});