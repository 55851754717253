import * as React from 'react';
import { useEffect, useState, useContext, useMemo, memo } from 'react';
import { MainContext, SmallAvatarWrapper } from 'kerberus-components';
import Grid from '@mui/material/Grid';
import { Typography, IconButton, Divider, Button, useTheme } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { StyledBadgeAvatar } from 'kerberus-components';
import useSWR from 'swr';
import ActiveTime from '../Components/Groups/ActiveTime';

function getActiveState(utc_start, utc_end) {
    let [hoursEnd, minsEnd] = utc_end && utc_end.split(':');
    let [hours, mins] = utc_start.split(':');
    let yesterday = moment.utc().startOf('day').subtract(1, 'day');
    let today = moment.utc().startOf('day');
    let tomorrow = moment.utc().startOf('day').add(1, 'day');


    if (hours > hoursEnd || (hours === hoursEnd && mins > minsEnd)) {
        let isBetween1 = moment().isBetween(yesterday.utc().set('hour', hours).set('minute', mins).local(), today.utc().set('hour', hoursEnd).set('minute', minsEnd).local());
        let isBetween2 = moment().isBetween(today.utc().set('hour', hours).set('minute', mins).local(), tomorrow.utc().set('hour', hoursEnd).set('minute', minsEnd).local());
        return (isBetween1 || isBetween2);
    }
    else {
        return (moment().isBetween(moment.utc().startOf('day').add(utc_start).local(), moment.utc().startOf('day').add(utc_end).local()));
    }
}

export default function SocialGroupList({ avatarHeight, avatarWidth, isSmall, gridOneHidden, gridTwoHidden }) {
    const { user, bulkData } = useContext(MainContext);
    const [socialGroups, setSocialGroups] = useState([]);
    const navigate = useNavigate();
    const { data: groupListData } = useSWR('users/socialgrouplist/');

    useEffect(() => {
        if (groupListData) {
            setSocialGroups(groupListData);
        }
    }, [groupListData]);

    return (
        socialGroups.length > 0 &&
        <>
            <Divider flexItem sx={{ borderBottomWidth: 3, marginTop: 2 }} />
            <Typography component={"div"} sx={{ fontSize: 24, position: "relative", top: 20, marginBottom: 4 }}>Socials</Typography>

            <Grid container sx={{ marginBottom: 4, display: "flex", alignItems: "center", justifyContent: "center" }}>

                {socialGroups.map(item => {
                    let tempNum = 0;
                    const groupObj = bulkData.social_groups.find(i => i.group_id === item.id);
                    if (groupObj) {
                        for (const userObj of groupObj.users) {
                            const now = moment();
                            const lastSeen = userObj?.timestamp ? moment(userObj.timestamp) : null;
                            const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                            const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                            if (item.users.some(i => i.id === userObj.user_id) && lastSeen && lastseenminutes <= 10 && lastseenminutes > 0) {
                                tempNum++;
                            }
                        }
                    }

                    const isActive = (item.utc_start && item.utc_end) ? getActiveState(item.utc_start, item.utc_end) || item.utc_start === item.utc_end : true;
                    const isActiveWeekday = item.enable_days.includes(moment().isoWeekday()) || item.enable_days === undefined;

                    return (

                        <Grid key={item.id} container sx={{ display: "flex", marginBottom: 2, borderBottom: "2px solid gray", marginX: 2 }}>

                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Button sx={{ fontSize: 20, px: 1, marginY: 1, borderRadius: 4, }} onClick={() => navigate(`/groups/${item.id}`)} >
                                    <SmallAvatarWrapper alt="GroupPic" sx={{ width: 40, height: 40, marginRight: 1 }}
                                        userId={item.id} imageType="socialgrouppic" imageSize="_64" />
                                    {item.name}
                                    <div style={{ marginLeft: "5%" }}>{`${bulkData ? tempNum + '/' + item.users.length : item.users.length}`}</div>
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <SocialGroupRender users={item.users} avatarHeight={avatarHeight} avatarWidth={avatarWidth} isSmall={isSmall} groupId={item.id} user={user} bulkData={bulkData} isActive={isActive} isActiveWeekday={isActiveWeekday} gridOneHidden={gridOneHidden} gridTwoHidden={gridTwoHidden} />
                                </Grid>
                            </Grid>

                            {(!isActive || !isActiveWeekday) &&

                                <Grid container sx={{ marginBottom: 2 }}>
                                    <Grid item xs={12}>
                                        <Typography component={"div"} variant="h6" sx={{ marginBottom: 2 }}>
                                            Not active right now
                                        </Typography>
                                    </Grid>
                                    <ActiveTime socialGroup={item} center />
                                </Grid>
                            }

                        </Grid>

                    );
                }
                )}
            </Grid>
        </>
    );
}

function titleGen(item, userObj) {
    let retstring = '';
    if (item.first_name && item.last_name) {
        retstring += `${item.first_name} ${item.last_name} `;
    }
    else {
        retstring += item.username;
    }

    if (userObj?.status === 'busy') {
        retstring += 'is busy';
    }
    else if (userObj?.timestamp) {
        retstring += `
        last seen ${moment(userObj.timestamp).fromNow()}`;
    }
    else {
        retstring += `
        last seen over a week ago`;
    }

    return retstring;
}


const SocialGroupRender = memo(({ users, avatarHeight, avatarWidth, isSmall, groupId, bulkData, isActive, isActiveWeekday, gridOneHidden, gridTwoHidden }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const groupObj = useMemo(() => {
        return bulkData?.social_groups.find(i => i.group_id === groupId)?.users;
    }, [groupId, bulkData]);


    return (
        groupObj
            ?
            [...users].sort((prev, next) => {
                const prevObj = groupObj.find(i => i.user_id === prev?.id);
                const nextObj = groupObj.find(i => i.user_id === next?.id);
                if (prevObj?.timestamp && nextObj?.timestamp) {
                    const now = moment();
                    const prevLast = moment.duration(now.diff(prevObj.timestamp)).asMinutes();
                    const nextLast = moment.duration(now.diff(nextObj.timestamp)).asMinutes();
                    if (prevLast <= 5 && nextLast <= 5) {
                        return 0;
                    }
                    else if ((prevLast <= 10 && prevLast >= 5) && (nextLast <= 10 && nextLast >= 5)) {
                        return 0;
                    }
                    else if (prevLast > 10 && nextLast > 10) {
                        return 0;
                    }
                    else if (prevLast > nextLast) {
                        return 1;
                    }
                    else return -1;
                }
                else if (prevObj?.timestamp && !nextObj?.timestamp) {
                    return -1;
                }
                else if (!prevObj?.timestamp && nextObj?.timestamp) {
                    return 1;
                }
                else if (!prevObj?.timestamp && !nextObj?.timestamp) {
                    return 0;
                }
                else return 0;
            }).map(item => {
                const userObj = groupObj.find(i => i.user_id === item.id);
                const busy = userObj?.status === 'busy';
                const now = moment();
                const lastSeen = userObj?.timestamp ? moment(userObj.timestamp) : null;
                const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                return (
                    isActive && isActiveWeekday ?
                        <Grid key={item.id} item xs={isSmall ? 4 : gridOneHidden && gridTwoHidden ? 2 : 6} sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
                            <IconButton onClick={() => navigate('profile/' + item.id)} sx={{ margin: isSmall && 0, display: "flex", flexDirection: "column" }}>
                                {/*                        {isSmall &&
                            <Typography component={"div"} variant="h6" sx={{ position: "absolute", marginBottom: 10, zIndex: 1 }}>
                                {item.first_name ? item.first_name : item.username}
                            </Typography>
                        } */}
                                <StyledBadgeAvatar
                                    tooltipContent={
                                        <div>
                                            {titleGen(item, userObj)}
                                            {userObj?.layer_name &&
                                                <>
                                                    <br />
                                                    Location: {userObj.layer_name}
                                                </>}
                                        </div>
                                    }
                                    tooltipPlacement="bottom-end"
                                    badgeOverlap="circular"
                                    badgeAnchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeVariant="dot"
                                    badgeheight={15}
                                    badgewidth={15}
                                    badgeboxshadow={`0 0 0 1px ${theme.palette.background.paper}`}
                                    badgeSx={{
                                        width: avatarWidth,
                                        height: avatarHeight,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                    avatarSx={{
                                        width: avatarWidth,
                                        height: avatarHeight,
                                        borderWidth: 5,
                                        borderStyle: "solid",
                                        borderColor: busy ? theme.palette.mlRed.main : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? theme.palette.mlYellow.main : lastseenminutes > -5 && lastseenminutes < 5 ? theme.palette.mlGreen.main : '#adadb8' : '#adadb8'
                                    }}
                                    avatarVariant="circular"
                                    alt="ProfileIcon"
                                    imgSrc={item.id}
                                    lastseenminutes={lastseenminutes}
                                    busy={busy}
                                    imageSize="_64"
                                />

                                <Typography component={"div"} variant="h6" sx={{ zIndex: 1, fontSize: 18 }}>
                                    {item.first_name ? item.first_name : item.username}
                                </Typography>

                            </IconButton>

                        </Grid>
                        :
                        null
                );
            })
            :
            null

    );
});
