import { Slider, Box } from '@mui/material';

export default function HiddenTimeSlider({ handleStatus }) {
    return (
        <Box sx={{ width: "90%", marginLeft: 1 }}>
            <Slider
                defaultValue={0}
                valueLabelDisplay="auto"
                valueLabelFormat={(e) => e > 60 ? Math.floor(e / 60) + "h " + e % 60 + "min" : e + "min"}
                min={0}
                max={480}
                step={15}
                onChangeCommitted={(e, value) => handleStatus(value)}
            />
        </Box>
    );
}


