import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Toast({ message, verticalPos, horizontalPos, type, open, handleClose, sx, title, autoHideDuration, message2 }) {

    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose} anchorOrigin={{ vertical: verticalPos, horizontal: horizontalPos }} >
            <Alert onClose={handleClose} severity={type} sx={sx}>
                <AlertTitle>{title}</AlertTitle>
                {message}
                <br />
                {message2}
            </Alert>
        </Snackbar>
    );
}