import { Box } from "@mui/material";


function Circle(props) {

    return (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: `scale(${props.scale})` || 'scale(0.8)', color: props.color }}
        >
            •
        </Box>
    )
};

function Square(props) {

    return (
        <Box
        component="span"
        sx={{ display: 'inline-block', mx: 1, transform: `scale(${props.scale})` || 'scale(0.8)', color: props.color }}
    >
        ▪
    </Box>
    )
};

export { Circle, Square };