import { Grid, createTheme, Box } from "@mui/material";
import { dayjs } from '../dayjs-utils';
import Card from '@mui/material/Card';
import GridLarge from './GridLarge';

const style = createTheme({
    gridItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#808080" + 50,
        borderRadius: 5,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        flexDirection: "column",
    },
    gridItemGreen: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#8DBF30",
        borderRadius: 5,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        flexDirection: "column"
    },
    gridItemYellow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#FCC041",
        borderRadius: 5,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        flexDirection: "column"
    },
    gridItemRed: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#E72D50",
        borderRadius: 5,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        flexDirection: "column"
    },
    avatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column"
    },
    profilepic: {
        height: "8vw",
        width: "8vw",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#000",
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "1.25vw"
    }
});


export default function SocialsDashboardPortrait(props) {

    return (
        <Grid container justifyContent="center" spacing={2} padding={1} paddingRight={2} sx={{ height: "100%", overflow: "auto"}}>
            {props.users && props.users.map(item => {
                const userObj = props.onlineData?.find(i => i.user_id === item.id);
                const busy = userObj?.status === 'busy';
                const now = dayjs().add(30, 'seconds');
                const lastSeen = userObj?.timestamp ? dayjs(userObj.timestamp) : null;
                const duration = lastSeen ? dayjs.duration(now.diff(lastSeen)) : null;
                const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                const avatarHeight = props.avatarSize;
                const avatarWidth = props.avatarSize;
                return (
                    <Grid key={item.id} item xs={6}>
                        <Card sx={busy ? style.gridItemRed : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? style.gridItemYellow : lastseenminutes > -5 && lastseenminutes < 5 ? style.gridItemGreen : style.gridItem : style.gridItem}>
                            <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
                                <GridLarge {...props} item={item} busy={busy} lastseenminutes={lastseenminutes} avatarHeight={avatarHeight} avatarWidth={avatarWidth} userObj={userObj} />
                            </Box>
                        </Card>
                    </Grid>
                );
            }
            )}
        </Grid>
    );
}
