import * as React from "react";
import { useContext, useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, Paper } from "@mui/material";
import { MainContext } from "kerberus-components";
import ProfileList from "./ProfileList";
import SocialGroupList from "./SocialGroupList";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import ActivityLog from "../Components/ActivityLog";
import OfficeHoursCard from "../Components/OfficeHoursCard";
import LocationCard from "../Components/LocationCard";

export default function Dashboard(props) {
    const { bulkData, user } = useContext(MainContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const isSmaller = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const [height, setHeight] = useState(0);
    const ref = useRef(null);

    const [site, setSite] = useState("");

    useEffect(() => {
        if (bulkData.user.site_name) {
            setSite(bulkData.user.site_name)
        }
    }, [bulkData.user.site_name])

    useEffect(() => {
        setHeight(ref?.current?.clientHeight);
    }, [
        ref?.current?.clientHeight,
        props.gridOneHidden,
        props.gridTwoHidden,
        props.gridThreeHidden,
    ]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100vh",
                overflowY: !isSmall && "hidden",
            }}
        >
            {/* Lowers the page by amount of appBar */}
            <div style={{ minHeight: 56 }} />
            <Box
                sx={{
                    width: "100%",
                    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
                    maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
                    display: "flex",
                    pr: isSmall ? 1 : 2,
                    pl: 1,
                    pt: 0.6,
                    flexDirection: isSmall ? "column" : "row",
                }}
            >
                <Box sx={{ width: /* isSmall ? "100%" :  */ "100%" }}>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            width: "100%",
                        }}
                    >
                        <Grid
                            container
                            item
                            xs={props.gridThreeHidden ? 12 : 9}
                            sx={
                                {
                                    /* backgroundColor: "red" */
                                }
                            }
                        >
                            <Grid item xs={10} sm={4}>
                                <LocationCard data={bulkData} user={user} site={site} setSite={setSite}
                                    sx={
                                        !isSmaller
                                            ? {
                                                display: props.gridOneHidden
                                                    ? "none"
                                                    : "flex",
                                                mt: 2,
                                                mx: 1,
                                                /*  height: "calc(100% - 16px)", */
                                                borderRadius: 4,
                                                position: "relative",
                                            }
                                            : { my: 1, borderRadius: 4 }
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} sm={4} ref={ref}>
                                <OfficeHoursCard site={site}
                                    sx={
                                        !isSmaller
                                            ? {
                                                display: props.gridOneHidden
                                                    ? "none"
                                                    : "flex",
                                                mt: 2,
                                                mr: 1,
                                                ml: 1,
                                                /* height: "calc(100% - 16px)", */
                                                borderRadius: 4,
                                                position: "relative",
                                            }
                                            : { my: 1, borderRadius: 4 }
                                    }
                                    online
                                />
                            </Grid>
                            <Grid item xs={10} sm={4}>
                                <OfficeHoursCard site={site}
                                    sx={
                                        !isSmaller
                                            ? {
                                                display: props.gridOneHidden
                                                    ? "none"
                                                    : "flex",
                                                mt: 2,
                                                mx: 1,
                                                /* height: "calc(100% - 16px)", */
                                                borderRadius: 4,
                                                position: "relative",
                                            }
                                            : { my: 1, borderRadius: 4 }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sx={{}}>
                                <Paper
                                    elevation={2}
                                    sx={
                                        isSmaller
                                            ? {
                                                display: props.gridTwoHidden
                                                    ? "none"
                                                    : "flex",
                                                borderRadius: 4,
                                                height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 0.6*${theme.mixins.toolbar.minHeight}px)`,
                                                mt: 1,
                                            }
                                            : {
                                                display: props.gridTwoHidden
                                                    ? "none"
                                                    : "block",
                                                borderRadius: 4,
                                                height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${height}px - 0.6*${theme.mixins.toolbar.minHeight}px)`,
                                                mt: 2,
                                                mr: 1,
                                                ml: 1,
                                            }
                                    }
                                >
                                    <ActivityLog />
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={
                                props.gridOneHidden && props.gridTwoHidden
                                    ? 12
                                    : props.gridThreeHidden
                                        ? 0
                                        : 3
                            }
                            sx={{ mt: 2 }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    height: "100%",
                                    ml: !isSmall && 1,
                                    mb: isSmall && 2,
                                }}
                            >
                                <Paper
                                    sx={
                                        isSmall
                                            ? {
                                                textAlign: "center",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                overflowX: "hidden",
                                                borderRadius: 0,
                                                borderTopRightRadius: 16,
                                                borderTopLeftRadius: 16,
                                            }
                                            : {
                                                height: `calc((100vh - ${theme.mixins.toolbar.minHeight}px - 0.6*${theme.mixins.toolbar.minHeight}px) * 0.95)`,
                                                textAlign: "center",
                                                width: "100%",
                                                overflow: "scroll",
                                                "&::-webkit-scrollbar": {
                                                    width: "0.4em",
                                                },
                                                "&::-webkit-scrollbar-track":
                                                {
                                                    boxShadow:
                                                        "inset 0 0 6px rgba(0,0,0,0.00)",
                                                    webkitBoxShadow:
                                                        "inset 0 0 6px rgba(0,0,0,0.00)",
                                                    marginTop: 3,
                                                },
                                                "&::-webkit-scrollbar-thumb":
                                                {
                                                    backgroundColor:
                                                        "#1C4E71",
                                                },
                                                "&::-webkit-scrollbar-corner":
                                                {
                                                    display: "none",
                                                },
                                                scrollbarColor: `${theme.palette.primary.main} #fff`,
                                                scrollbarWidth: "thin",
                                                display: props.gridThreeHidden
                                                    ? "none"
                                                    : "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                overflowX: "hidden",
                                                borderRadius: 0,
                                                borderTopRightRadius: 16,
                                                borderTopLeftRadius: 16,
                                            }
                                    }
                                >
                                    <Box
                                        sx={{
                                            height: "100%",
                                            textAlign: "center",
                                        }}
                                    >
                                        {user.user_company && (
                                            <ProfileList
                                                avatarWidth={
                                                    isSmall ? 60 : "3.5vw"
                                                }
                                                avatarHeight={
                                                    isSmall ? 60 : "3.5vw"
                                                }
                                                isSmall={isSmall}
                                                gridOneHidden={props.gridOneHidden}
                                                gridTwoHidden={props.gridTwoHidden}
                                                gridThreeHidden={props.gridThreeHidden}
                                            />
                                        )}
                                        <SocialGroupList
                                            avatarWidth={isSmall ? 60 : "3.5vw"}
                                            avatarHeight={
                                                isSmall ? 60 : "3.5vw"
                                            }
                                            isSmall={isSmall}
                                            gridOneHidden={props.gridOneHidden}
                                            gridTwoHidden={props.gridTwoHidden}
                                        />
                                    </Box>
                                </Paper>

                                <Paper
                                    onClick={() =>
                                        navigate(
                                            `/profile/${user.id}/settings/`
                                        )
                                    }
                                    sx={{
                                        height: `calc((100vh - ${theme.mixins.toolbar.minHeight}px - 0.6*${theme.mixins.toolbar.minHeight}px) * 0.05)`,
                                        display: props.gridThreeHidden
                                            ? "none"
                                            : "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: 0,
                                        borderBottomRightRadius: 16,
                                        borderBottomLeftRadius: 16,
                                        "&:hover": {
                                            backgroundColor: "#80808095",
                                            cursor: "pointer",
                                        },
                                    }}
                                >
                                    <SettingsIcon />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
