import { StyledBadgeAvatar } from 'kerberus-components';
import SocialIcons from '../SocialIcons';
import ProfileMenu from '../Profile/ProfileMenu';
import moment from 'moment';
import GetOnlineData from '../GetOnlineData';
import GetUserData from '../GetUserData';

const DatagridColumns = [
    {
        field: 'id', headerName: '', flex: 0.1, align: "center", renderCell: (e) => (
            <StyledBadgeAvatar
                noTooltip
                avatarSx={{
                    width: 32,
                    height: 32,
                    borderWidth: 3,
                    borderStyle: "solid",
                    borderColor:
                        GetOnlineData(e.row).busy
                            ?
                            "#E72D50"
                            :
                            GetOnlineData(e.row).lastseenminutes
                                ?
                                GetOnlineData(e.row).lastseenminutes <= 10 && GetOnlineData(e.row).lastseenminutes >= 5
                                    ?
                                    "#FCC041"
                                    :
                                    GetOnlineData(e.row).lastseenminutes > -5 && GetOnlineData(e.row).lastseenminutes < 5
                                        ?
                                        "#8DBF30"
                                        :
                                        '#adadb8'
                                :
                                '#adadb8'
                }}
                avatarVariant="circular"
                alt="ProfileIcon"
                imgSrc={e.row.id}
                lastseenminutes={GetOnlineData(e.row).lastseenminutes}
                busy={GetOnlineData(e.row).busy}
                imageSize="_32"
            />
        )
    },
    {
        field: 'first_name', headerName: 'Name', flex: 0.65, renderCell: (e) => (
            <div>
                {e.row.first_name || e.row.last_name ? e.row.first_name + " " + e.row.last_name : e.row.username}
            </div>
        )
    },
    {
        field: 'links', headerName: 'Social Links', flex: 1, renderCell: (e) => (
            <SocialIcons user={e.row} links={e.row.links} />
        )
    },
    {
        field: '', headerName: 'Last seen', flex: 1, renderCell: (e) =>
            <div>
                {
                    GetOnlineData(e.row).timestamp
                        ?
                        GetOnlineData(e.row).lastseenminutes <= 10 && GetOnlineData(e.row).lastseenminutes >= 5
                            ?
                            moment(GetOnlineData(e.row).timestamp).from(moment().add(30, 'seconds'))
                            :
                            GetOnlineData(e.row).lastseenminutes >= 10
                                ?
                                moment(GetOnlineData(e.row).timestamp).from(moment().add(30, 'seconds'))
                                :
                                GetOnlineData(e.row).layer_name
                                    ?
                                    GetOnlineData(e.row).layer_name
                                    :
                                    moment(GetOnlineData(e.row).timestamp).from(moment().add(30, 'seconds'))
                        :
                        'Long time ago'
                }
            </div>
    },
    {
        field: 'Menu', headerName: '', flex: 0.2, renderCell: (e) => (
            <ProfileMenu allGroups={true} user={e.row} userId={e.row.id} sx={{ display: GetUserData().id === e.row.id && "none" }}/>
        )
    },
];

export default DatagridColumns;