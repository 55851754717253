import { useState, useRef, useEffect, Fragment } from 'react';
import { autoRefresh, MainContext, getCached } from 'kerberus-components';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Toast from '../Toast';
import useSWR, { SWRConfig } from 'swr';
import SWRFetcher from './SWRFetcher';

const theme = createTheme();

export default function LoginProvider({ children }) {
    let location = useLocation();
    const [accessToken, setAccessToken] = useState({
        access: localStorage.getItem('access'),
        refresh: localStorage.getItem('refresh'),
        userId: localStorage.getItem('userId')
    });
    const refreshInterval = useRef(null);
    const [open, setOpen] = useState({
        type: '',
        title: '',
        message: '',
        open: false,
        verticalPos: "bottom",
        horizontalPos: "center",
        autoHideDuration: 5000
    });
    const [bulkData, setBulkData] = useState({
        user: {
            online_minutes: [],
            prev_online_minutes: []
        },
        company: [],
        social_groups: []
    });
    const accessRef = useRef(accessToken);
    const navigate = useNavigate();
    const { data: user } = useSWR(['users/timetableprofile/', localStorage.getItem('userId')], (route) => SWRFetcher(route, accessRef.current.access, accessRef.current.refresh));
    const pushSubscribed = useRef(false);
    const bulkCacheLoaded = useRef(false);

    useEffect(() => {
        if (user) {
            if (!bulkCacheLoaded.current) {
                const cachedVal = getCached({
                    device: 'socketLocation',
                    target: 'clientBulk',
                    user: user.id,
                    params: {
                        filters: {
                            tz: user.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                        }
                    }
                });
                if (cachedVal) {
                    setBulkData(cachedVal);
                }
                bulkCacheLoaded.current = true;
            }
            if (user.push_enabled && !pushSubscribed.current) {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.getRegistration()
                        .then(registration => {
                            if (registration) {
                                Notification.requestPermission()
                                    .then(perm => {
                                        if (perm === 'granted') {
                                            navigator.serviceWorker.controller.postMessage({
                                                type: 'subscribe',
                                                access: accessRef.current.access
                                            });
                                        }
                                        pushSubscribed.current = true;
                                    });
                            }
                        });
                }
            }
        }

    }, [user, navigate]);

    const MainContextVal = { accessToken, setAccessToken, user, tokenType: 'bearer', setOpen, bulkData, setBulkData };

    useEffect(() => {
        if (accessToken?.refresh) {
            if (refreshInterval.current) return;
            const interval = 1000 * 60 * 5;
            refreshInterval.current = setInterval(() => {
                autoRefresh(accessToken.refresh)
                    .then(token => {
                        accessRef.current.access = token;
                        setAccessToken(prev => ({ ...prev, access: token }));
                    })
                    .catch((err) => {
                        if (err === 401 || err === 403) {
                            accessRef.current.refresh = null;
                            setAccessToken(prev => ({ ...prev, refresh: null }));
                        }
                    });

            }, interval);
        }
        // Redirect to login screen if refresh is removed for some reason
        else {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            localStorage.removeItem('userId');
            navigate('/login', { state: { from: location.pathname } });
        }

        return () => {
            clearInterval(refreshInterval.current);
            refreshInterval.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken?.refresh, navigate]);

    return (
        <Fragment>
            <Toast
                sx={{}}
                type={open.type}
                title={open.title}
                message={open.message}
                verticalPos={open.verticalPos}
                horizontalPos={open.horizontalPos}
                open={open.open}
                handleClose={() => setOpen({
                    type: '',
                    title: '',
                    message: '',
                    open: false,
                    verticalPos: "bottom",
                    horizontalPos: "center",
                    autoHideDuration: 5000
                })}
                autoHideDuration={open.autoHideDuration}
            />
            <ThemeProvider theme={theme}>
                <MainContext.Provider value={MainContextVal}>
                    {accessToken?.access && user &&
                        <SWRConfig value={{ fetcher: (route, init) => SWRFetcher(route, accessRef.current.access, accessRef.current.refresh) }} >
                            {children}
                        </SWRConfig>
                    }
                </MainContext.Provider>
            </ThemeProvider>
        </Fragment>
    );
}
