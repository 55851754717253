

import * as React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import {
    Typography, Autocomplete, useMediaQuery, IconButton, MenuItem, Menu, DialogTitle, Dialog, DialogContent, TextField, Button, DialogContentText, DialogActions, Divider, Box, Popover, Grid, ToggleButtonGroup, ToggleButton as MuiToggleButton, Icon, useTheme, styled
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useSWR from 'swr/immutable';
import { useParams } from "react-router-dom";
import { useFetchCloud, MainContext } from 'kerberus-components';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { useDataProvider } from 'kerberus-components';

const toastError = {
    open: true,
    type: "error",
    title: "Error",
    message: `Something went wrong!`,
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const toastInfo = {
    open: true,
    type: "success",
    title: "Success",
    message: "Instructions for accepting group invite has been sent to the user if the user's email exists",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const toastSuccess = {
    open: true,
    type: "success",
    title: "Success",
    message: "Emoji sent!",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

export default React.memo(function ProfileMenu({ allGroups, userId, ...props }) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { getData: wsSetter } = useDataProvider();
    const { setOpen, user } = useContext(MainContext);
    const { fetchCloud } = useFetchCloud();
    const { groupId } = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [groupChoices, setGroupChoices] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [dialog, setDialog] = useState(false);
    const { data: socialGroupListData } = useSWR('users/socialgrouplist/');
    const { data: socialGroupSelectData } = useSWR('users/socialgroupselect/');

    const filteredGroups = socialGroupListData?.filter(group => (groupChoices.some(group2 => group.id === group2.id)));
    const filteredGroupUsers = filteredGroups?.filter(item => !item.users.some(e => e.id === userId));

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (socialGroupSelectData?.length > 0) {
            setGroupChoices(socialGroupSelectData?.filter(e => e.id !== groupId));
        }
    }, [groupId, props.allGroups, socialGroupSelectData]);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInvite = async () => {
        try {
            await fetchCloud('users/create_social_invite', 'POST', {
                username: selectedUser,
                social_group: selectedGroup.id
            });
            setOpen(toastInfo);
        }
        catch (err) {
            setOpen(toastError);
        }
    };


    const [emoji, setEmoji] = useState('127866');

    const handleEmoji = (event, newAlignment) => {
        if (newAlignment !== null) {
            setEmoji(newAlignment);
        }
    };


    const [emojiPopover, setEmojiPopover] = useState(null);

    const handleEmojiPopover = (event) => {
        setEmojiPopover(event.currentTarget);
    };

    const handleEmojiPopoverClose = () => {
        setEmojiPopover(null);
    };

    const openEmojiPopover = Boolean(emojiPopover);
    const id = openEmojiPopover ? 'EmojiPopover' : undefined;

    const emojis = ["127866", "128077", "9749", "127828", "128073", "128169"];

    const ToggleButton = styled(MuiToggleButton, {
        shouldForwardProp: (prop) => prop !== "selectedColor"
    })(({ selectedColor }) => ({
        "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: theme.palette.mlBlue.main + 90,
        }
    }));


    const handleSendMessage = useCallback(async () => {
        try {
            const res = await wsSetter({ serial: 'socketActivity' }, 'set', 'emojiMessage', {
                filters: {
                    receiver: userId,
                    emoji: emoji
                }
            });
            if (res.data.success) {
                setOpen(toastSuccess);
            }
            else {
                setOpen(toastError);
            }

        }
        catch (err) {
            console.error(err);
        }
    }, [emoji, setOpen, userId, wsSetter]);

    return (
        <>
            <Box {...props} >
                {user.next &&
                <IconButton aria-describedby={id} onClick={handleEmojiPopover} >
                    <EmojiEmotionsIcon sx={{ width: 25, height: 25, color: "#ffcc4d" }} />
                </IconButton>}
                <IconButton onClick={handleClick} >
                    <MoreVertIcon color="primary" sx={{ width: 25, height: 25 }} />
                </IconButton>
            </Box>
            <Popover
                id={id}
                open={openEmojiPopover}
                anchorEl={emojiPopover}
                onClose={handleEmojiPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid container sx={isSmall ? { p: 1 } : { p: 2, width: 450 }}>
                    <Grid item xs={12} textAlign="center">
                        <Typography component={"div"} variant="h6">Send an emoji!</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center" sx={{ my: 1 }}>
                        <ToggleButtonGroup
                            value={emoji}
                            exclusive
                            onChange={handleEmoji}
                            fullWidth
                        >
                            {emojis.map(item =>
                                <ToggleButton value={item} aria-label={item}>
                                    <Icon sx={isSmall ? { fontSize: 18, width: "100%", height: "100%" } : { fontSize: 30, width: "100%", height: "100%" }}>{String.fromCodePoint(parseInt(item))}</Icon>
                                </ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12} textAlign="right">
                        <Button variant="contained" onClick={handleSendMessage}>
                            Send
                        </Button>
                    </Grid>

                </Grid>
            </Popover>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem disabled={socialGroupSelectData?.detail} onClick={() => { setDialog(true); handleClose(); setSelectedUser(props.user.username); }}>Invite to Social Group</MenuItem>
            </Menu>
            <Dialog open={dialog} onClose={() => setDialog(false)}>
                <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography component={"div"}>
                        Invite to social group
                    </Typography>
                    <Typography component={"div"} sx={{ ml: 2 }}>
                        {props.user?.first_name} {props.user?.last_name}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select social group where you want to invite
                    </DialogContentText>
                    <Autocomplete
                        id="groupselect"
                        options={filteredGroupUsers}
                        getOptionLabel={option => option.name}
                        onChange={(e, newVal) => newVal !== null ? setSelectedGroup(newVal) : setSelectedGroup("")}
                        inputValue={inputValue}
                        onInputChange={(e, newVal) => setInputValue(newVal)}
                        sx={{ mt: 2, mb: 5 }}
                        renderInput={(params) => <TextField {...params} label="Social groups" />}
                        noOptionsText={
                            <ul style={{ padding: 0, paddingLeft: "10%" }}>
                                <div style={{ textAlign: "center", paddingRight: "10%" }}>No options</div>
                                <br />
                                <li>You do not have permission to invite the user to any group</li>
                                <Divider sx={{ paddingRight: "10%", py: 1 }}>or</Divider>
                                <li>This user already belongs to group which you are trying invite to</li>
                            </ul>

                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setDialog(false)}>Cancel</Button>
                    <Button disabled={!selectedGroup} variant="contained" onClick={handleInvite}>Invite</Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

