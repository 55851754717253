import * as React from 'react';
import { Typography, Box } from "@mui/material";
import StyledBadgeAvatar from "../StyledBadgeAvatar";
import { dayjs } from '../dayjs-utils';



export default function GridLarge(props) {
    const { lastseenminutes, avatarWidth, avatarHeight, busy, item, userObj } = props;

    return (
        <>
            <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>


                <Typography
                    fontSize={10}
                    component="div"
                    sx={{ color: props.textColor, textAlign: "center" }}
                >
                    {(item.first_name && item.last_name) ? item.first_name : item.username}
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <StyledBadgeAvatar
                        tooltipContent={lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? 'Away' : lastseenminutes > -5 && lastseenminutes < 5 ? 'Online' : 'Offline' : 'Offline'}
                        tooltipPlacement="bottom-end"
                        badgeOverlap="circular"
                        badgeAnchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeVariant="dot"
                        badgeheight="25%"
                        badgewidth="25%"
                        badgeanimation={!busy ? lastseenminutes ? lastseenminutes > -5 && lastseenminutes < 5 : false : false}
                        badgeboxshadow={`0 0 0 2px #fff`}
                        badgeSx={{
                            width: avatarWidth,
                            height: avatarHeight,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                        avatarSx={{
                            width: "88%",
                            height: "88%",
                            borderWidth: 0,
                            borderStyle: "solid",
                            borderColor: busy ? "#E72D50" : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? "#FCC041" : lastseenminutes > -5 && lastseenminutes < 5 ? "#8DBF30" : '#adadb8' : '#adadb8',
                        }}
                        avatarVariant="circular"
                        alt="ProfileIcon"
                        imgSrc={item.id}
                        lastseenminutes={lastseenminutes}
                        busy={busy}
                        skipCookie
                        imageSize="_256"
                    />
                    {(props.hasUserTitle && item?.title?.length > 0) &&
                        <Typography
                            textAlign="center"
                            fontSize={5}
                            sx={{ color: props.subheaderColor, paddingX: 0.5 }}>
                            {item.title}
                        </Typography>
                    }
                </Box>

                {props.hasLocation ?
                    <Typography
                        fontSize={8}
                        sx={{ color: props.textColor }}>
                        {
                            userObj?.timestamp
                                ?
                                lastseenminutes <= 10 && lastseenminutes >= 5
                                    ?
                                    dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                    :
                                    lastseenminutes >= 10
                                        ?
                                        dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                        :
                                        userObj.layer_name ?? dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                :
                                'Long time ago'
                        }
                    </Typography>
                    :
                    <Typography
                        fontSize={8}
                        sx={{ color: props.textColor }}>
                        {userObj?.timestamp ? dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds')) : 'Long time ago'}
                    </Typography>

                }
            </Box>
        </>
    );
}