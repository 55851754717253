import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HelperIcon from '../../Components/HelperIcon';
import InteractiveDeviceList from '../../Components/InteractiveDeviceList';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, useTheme, Checkbox, Popover, Box, Avatar, Chip, FormControlLabel } from '@mui/material';
import { useFetchCloud, useWsDataProvider } from 'kerberus-components';
import ConfirmationDialog from '../../Components/ConfirmationDialog';
import { Link } from "react-router-dom";
import { useSWRConfig } from 'swr';
import MacTagsInput from '../../Components/MacTagsInput';
import DeviceIcons from '../../Components/Icons/DeviceIcons';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import DisplayDeviceIcons from '../../Components/Device/DisplayDeviceIcons';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';

const contentHelperIcon = (
    <>
        <div>This selection determines if device is used in status and calendar view.</div>
    </>
);

const locationHelperIcon = (
    <>
        <div>This selection determines which device is used for showing location data.</div>
    </>
);

const bluetoothHelperIcon = (
    <>
        <div>This selection determines if it is a bluetooth mac address.</div>
    </>
);

const content = (<><div>Add MAC address of the device to add it to the list.</div> <br /> <div>List displays all the devices which you are connected to.</div ></>);

export default React.memo(function ProfileDevice({ devgroup, isSmall, isMedium, isLarge }) {
    const theme = useTheme();
    const [openAdd, setOpenAdd] = useState(false);
    const [valueAdd, setValueAdd] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const [devGroupName, setDevGroupName] = useState('');
    const [devLocation, setDevLocation] = useState(false);
    const [devVisible, setDevVisible] = useState(true);
    const [addrBluetooth, setAddrBluetooth] = useState(false);
    const [deviceType, setDeviceType] = useState({ type: 0, component: DeviceIcons[0] })
    const [inputValue, setInputValue] = useState("");

    const { getData, data, doUnsubscribe } = useWsDataProvider();
    const [onlineDevs, setOnlineDevs] = useState([]);
    const { mutate } = useSWRConfig();

    const [macAddresses, setMacAddresses] = useState([]);

    function handleMacAddresses(items) {
        setMacAddresses(items);
    }

    useEffect(() => {
        getData('socketLocation', 'onlineAddr', {
            subscribe: true
        });

        return () => doUnsubscribe();
    }, [getData, doUnsubscribe]);

    useEffect(() => {
        if (data) {
            setOnlineDevs(data);
        }
    }, [data]);

    const { fetchCloud } = useFetchCloud();

    /* Create device group */
    const handleCreateDevGroup = async () => {
        try {
            await fetchCloud(`devices/devicegroup/`, 'POST', {
                group_name: devGroupName,
                description: null,
                type: deviceType.type,
                visible: devVisible,
                location: devLocation,
            }).then((res) => {
                fetchCloud(`devices/addaddressdevgroup`, 'POST', {
                    address: macAddresses.length > 0 ? macAddresses : null,
                    device_group: res.id
                });
            }
            );
            setDevGroupName("");
            setMacAddresses([])
            setDeviceType({ type: 0, component: DeviceIcons[0] })
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            alert(err);
        }
    };

    /* Change device Tracking/Visibility */
    const handleDeviceVisibility = async (e) => {
        try {
            await fetchCloud(`devices/devicegroup/${e.id}/`, 'PATCH', {
                visible: !e.visible
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            alert(err);
        }
    };

    /* Change device Location track*/
    const handleDeviceLocation = async (e) => {
        try {
            await fetchCloud(`devices/devicegroup/${e.id}/`, 'PATCH', {
                location: !e.location
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            alert(err);
        }
    };

    /* Delete device group */
    const handleDeleteDevGroup = async (e) => {
        try {
            await fetchCloud(`devices/devicegroup/${e.id}/`, 'DELETE')
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            alert(err);
        }
    };

    const contentAdd = (
        <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
            By adding
            <span style={{ fontWeight: "bold" }}>{devGroupName.length > 0 ? " " + devGroupName + "" : " Device"}</span>
            , you confirm that you own and/or hold this device and have the legal right to track and share it's location data.
            <br />
            <br />
            <br />
            <Typography sx={{ fontWeight: "bold", position: "absolute", right: 5, bottom: 65 }}>
                I understand
                <Checkbox checked={!isDisabled} onChange={() => setIsDisabled(isDisabled => !isDisabled)} />
            </Typography>

            <div style={isMedium ? { marginBottom: 30 } : { width: isMedium ? "45%" : "65%", position: "absolute", bottom: 5 }}>
                If you would like further information on this matter you can review our
                <Typography component={Link} to={`/termsconditions`} sx={{ color: theme.palette.mlBlue.main, cursor: 'pointer', display: "inline", marginLeft: 1 }}>
                    Terms & Conditions
                </Typography>
                {" again."}
            </div>

            {isMedium && <br />}
        </Typography>
    );

    /* Icon popover */
    const [popover, setPopover] = useState(null);
    const handleClick = (event) => {
        setPopover(event.currentTarget);
    };
    const handleClose = () => {
        setPopover(null);
    };

    const open = Boolean(popover);
    const id = open ? 'simple-popover' : undefined;

    /* Delete mac address chips */
    const handleDelete = item => () => {
        const newMacAddresses = [...macAddresses];
        newMacAddresses.splice(newMacAddresses.indexOf(item), 1);
        setMacAddresses(newMacAddresses);
    };

    const groupNameAlreadyTaken = devgroup.findIndex((obj) => obj.group_name.toLowerCase() === devGroupName.toLowerCase()) !== -1
    const macAddressAlreadyTaken = devgroup.findIndex(i => i.addresses.some(addr => addr.address.toLowerCase() === inputValue.toLowerCase())) !== -1

    return (
        <Card elevation={3}>
            <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={12} sx={{ mt: !isMedium && 2, ml: !isMedium && 5, display: "flex", alignItems: isMedium && "center", justifyContent: isMedium && "center" }}>
                    <Typography component={"div"} variant="h5" sx={{}}>
                        Create Device
                    </Typography>
                    <HelperIcon content={content} style={{ alignSelf: "center", justifySelf: "center", display: "flex", marginLeft: 5 }} />
                </Grid>

                <Grid container spacing={1} sx={{ display: "flex", alignItems: "center", mt: !isMedium && 2, ml: !isMedium && 3 }}>
                    <Grid item xs={12} lg={"auto"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column", mt: isMedium && 2 }}>
                        <IconButton sx={{ height: 50, width: 50, bgcolor: theme.palette.primary.main + 50, color: theme.palette.primary.main, "&:hover": { bgcolor: theme.palette.primary.main + 70 } }} aria-describedby={id} onClick={handleClick}>
                            {deviceType.component}
                        </IconButton>
                        <Typography component={"div"} variant="caption" textAlign="center" sx={{ color: "#00000099" }}>
                            Device type
                        </Typography>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={popover}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            PaperProps={
                                isMedium
                                    ?
                                    {
                                        style: { width: "100%" },
                                    }
                                    :
                                    {
                                        style: { width: 600 },
                                    }
                            }
                        >
                            <Typography component={"div"} variant="h6" textAlign="center" sx={{ mt: 2 }}>
                                Choose a device type
                            </Typography>
                            <Grid container spacing={1} padding={isMedium ? 1 : 2} paddingTop={isMedium ? 0 : 1}>
                                <DisplayDeviceIcons isSmall={isSmall} setDeviceType={setDeviceType} handleClose={handleClose} />
                            </Grid>
                        </Popover>
                    </Grid>

                    <Grid item xs={12} lg={"auto"} sx={{ display: "flex", justifyContent: "center" }}>
                        <FormControlLabel sx={{ mt: 0.8 }}
                            label={
                                <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#00000099", fontSize: 13 }}>
                                    Location
                                    <HelperIcon
                                        style={{ position: "relative", top: 3, left: 3 }}
                                        content={locationHelperIcon}
                                    />
                                </Typography>}
                            labelPlacement="bottom"
                            control={
                                <Checkbox
                                    checked={devLocation}
                                    onChange={(e) => setDevLocation(e.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel sx={{ mt: 0.8 }}
                            label={
                                <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#00000099", fontSize: 13 }}>
                                    Track
                                    <HelperIcon
                                        style={{ position: "relative", top: 3, left: 3 }}
                                        content={contentHelperIcon}
                                    />
                                </Typography>}
                            labelPlacement="bottom"
                            control={
                                <Checkbox
                                    checked={devVisible}
                                    onChange={(e) => setDevVisible(e.target.checked)}
                                />}
                        />
                    </Grid>


                    <Grid item xs={12} lg={"auto"} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <TextField
                            id="deviceName"
                            label="Device name"
                            name="devGroupName"
                            fullWidth
                            value={devGroupName}
                            onChange={(e) => setDevGroupName(e.target.value)}
                            helperText={groupNameAlreadyTaken ? "Name already in use" : "The display name of the device"}
                            error={groupNameAlreadyTaken}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="clear"
                                        onClick={() => setDevGroupName("")}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    </Grid>


                    <Grid item xs={12} md={12} lg={"auto"} sx={!isLarge ? { display: "flex", alignItems: "center", justifyContent: "center" } : null}>
                        <Box sx={isSmall ? {display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", border: "1px solid #1C4E71", padding: 1, borderRadius: 4,} : { display: "flex", border: "1px solid #1C4E71", padding: 1.5, borderRadius: 4, ml: 1}}>

                            <FormControlLabel sx={{ mt: 0.8 }}
                                label={
                                    <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#00000099", fontSize: 13 }}>
                                        Bluetooth
                                        <HelperIcon
                                            style={{ position: "relative", top: 3, left: 3 }}
                                            content={bluetoothHelperIcon}
                                        />
                                    </Typography>}
                                labelPlacement="bottom"
                                control={
                                    <Checkbox
                                        checked={addrBluetooth}
                                        onChange={(e) => setAddrBluetooth(e.target.checked)}
                                    />}
                            />
                            <MacTagsInput
                                selectedItem={macAddresses}
                                setSelectedItem={setMacAddresses}
                                selectedTags={handleMacAddresses}
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                                macAddressAlreadyTaken={macAddressAlreadyTaken}
                                addrBluetooth={addrBluetooth}
                                setAddrBluetooth={setAddrBluetooth}

                                variant="outlined"
                                id="mac address"
                                name="mac address"
                                placeholder="Add mac address"
                                error={macAddressAlreadyTaken}
                                label="Mac Address"
                                helperText={macAddressAlreadyTaken ? "MAC Address already in use!" : "You can enter multiple mac addresses"}
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ display: "flex", ml: 2, mb: 3 }}>
                            <Button
                                disabled={groupNameAlreadyTaken || macAddressAlreadyTaken || macAddresses?.length === 0}
                                variant="contained"
                                onClick={() => { setValueAdd(devGroupName); setOpenAdd(true); }}
                                sx={{ display: isLarge && "none" }}
                            >
                                Create
                            </Button>
                        </Box>


                    </Grid>

                    <Grid item sx={{ display: isLarge ? "flex" : "none", alignItems: "center", justifyContent: "center", mt: 2, width: "100%" }}>
                        <Button
                            disabled={groupNameAlreadyTaken || macAddressAlreadyTaken  || macAddresses?.length === 0}
                            variant="contained"
                            onClick={() => { setValueAdd(devGroupName); setOpenAdd(true); }}
                            sx={{}}
                        >
                            Create
                        </Button>
                    </Grid>



                </Grid>
                {macAddresses?.length > 0 &&
                    <>
                        <Typography component={"div"} variant="h6" color="primary" sx={{ ml: 4, mt: 1, display: "flex", alignItems: "center" }}>
                            Mac addresses to add to <Avatar sx={{ height: 30, width: 30, mx: 1, bgcolor: theme.palette.primary.main + 50, color: theme.palette.primary.main, p: 0.5 }}>{deviceType.component}</Avatar>{devGroupName.length > 0 ? <span style={{ fontWeight: "bold" }}>{devGroupName}</span> : "Device"}:
                        </Typography>
                        <Grid container paddingY={1} paddingX={3} sx={{ display: "flex", alignItems: "center", mt: -1 }}>
                            {macAddresses.map((item, index) => (
                                <Grid key={index} item sx={{ m: 0.5 }}>
                                    <Chip
                                        tabIndex={-1}
                                        label={<Typography component={"div"} sx={{ display: "flex", alignItems: "center" }}>{item.bluetooth ? <BluetoothIcon sx={{ height: 20, width: 20 }} /> : <SignalWifi3BarIcon sx={{ height: 20, width: 20, mb: 0.2, mr: 0.5 }} />}{item.address}</Typography>}
                                        onDelete={handleDelete(item)}
                                        sx={{ bgcolor: theme.palette.primary.main + 50, color: theme.palette.primary.main }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </>
                }

                <Grid item xs={12} sx={{ marginTop: 3 }}>
                    <InteractiveDeviceList isSmall={isSmall} devgroup={devgroup} handleDeleteDevGroup={handleDeleteDevGroup} handleDeviceVisibility={handleDeviceVisibility} handleDeviceLocation={handleDeviceLocation} onlineDevs={onlineDevs} />
                </Grid>

                <ConfirmationDialog
                    title={["Add"]}
                    handleDevice={handleCreateDevGroup}
                    value={valueAdd}
                    isDisabled={isDisabled}
                    name={devGroupName ? devGroupName : null}
                    open={openAdd}
                    setOpen={setOpenAdd}
                    content={contentAdd}
                />
            </Grid>
        </Card>
    );
})
