import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { MainContext, SmallAvatarWrapper } from 'kerberus-components';
import Grid from '@mui/material/Grid';
import { Typography, IconButton, Button } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { StyledBadgeAvatar } from 'kerberus-components';
import useSWR from 'swr';

function titleGen(item, userObj) {
    let retstring = '';
    if (item.first_name && item.last_name) {
        retstring += `${item.first_name} ${item.last_name} `;
    }
    else {
        retstring += item.username;
    }

    if (userObj?.status === 'busy') {
        retstring += 'is busy';
    }
    else if (userObj?.timestamp) {
        retstring += `
        last seen ${moment(userObj.timestamp).fromNow()}`;
    }
    else {
        retstring += `
        last seen over a week ago`;
    }

    return retstring;
}

export default function ProfileList({ avatarHeight, avatarWidth, isSmall, gridOneHidden, gridTwoHidden, gridThreeHidden }) {
    const [profiles, setProfiles] = useState([]);
    const { user, bulkData } = useContext(MainContext);
    const [onlineMembers, setOnlineMembers] = useState(0);
    const { data: profilelistSocialData } = useSWR('users/profilelistsocial/');

    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        if (profilelistSocialData) {
            setProfiles(profilelistSocialData);
        }
    }, [profilelistSocialData]);



    useEffect(() => {
        let tempNum = 0;
        if (bulkData) {
            for (const userObj of bulkData.company) {
                const now = moment();
                const lastSeen = userObj.timestamp ? moment(userObj.timestamp) : null;
                const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                if (lastSeen && lastseenminutes <= 10) {
                    tempNum++;
                }
            }
        }
        setOnlineMembers(tempNum);
    }, [bulkData]);

    return profiles ? (
        <>
            <Typography component={"div"} sx={{ fontSize: 24, position: "relative", top: 20, marginBottom: 4 }}>Company</Typography>
            <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddingX: 2 }}>
                <Grid item xs={12}>
                    <Button sx={{ fontSize: 20, px: 1, marginY: 1, borderRadius: 4 }} onClick={() => navigate("/company")}>

                        {/* <ApartmentIcon sx={{ width: 30, height: 30, marginRight: 1, marginBottom: 0.5 }} /> */}
                        <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50, marginRight: 2, "&:hover": { cursor: "pointer" } }}
                            userId={user.user_company.id} imageType="companypic" imageSize="_64" />

                        <div style={{ marginLeft: 6, marginRight: 10 }}>{user.user_company.name}</div>
                        {`${bulkData ? onlineMembers + '/' + profiles.length : ""}`}
                    </Button>
                </Grid>
                {bulkData && [...profiles].sort((prev, next) => {
                    const prevObj = bulkData.company.find(i => i.user_id === prev?.id);
                    const nextObj = bulkData.company.find(i => i.user_id === next?.id);
                    if (prevObj?.timestamp && nextObj?.timestamp) {
                        const now = moment();
                        const prevLast = moment.duration(now.diff(prevObj.timestamp)).asMinutes();
                        const nextLast = moment.duration(now.diff(nextObj.timestamp)).asMinutes();
                        if (prevLast <= 5 && nextLast <= 5) {
                            return 0;
                        }
                        else if ((prevLast <= 10 && prevLast >= 5) && (nextLast <= 10 && nextLast >= 5)) {
                            return 0;
                        }
                        else if (prevLast > 10 && nextLast > 10) {
                            return 0;
                        }
                        else if (prevLast > nextLast) {
                            return 1;
                        }
                        else return -1;
                    }
                    else if (prevObj?.timestamp && !nextObj?.timestamp) {
                        return -1;
                    }
                    else if (!prevObj?.timestamp && nextObj?.timestamp) {
                        return 1;
                    }
                    else if (!prevObj?.timestamp && !nextObj?.timestamp) {
                        return 0;
                    }
                    else return 0;
                }).map(item => {
                    const userObj = bulkData.company.find(i => i.user_id === item.id);
                    const busy = userObj?.status === 'busy';
                    const now = moment();
                    const lastSeen = userObj?.timestamp ? moment(userObj.timestamp) : null;
                    const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                    const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                    return (

                        <Grid key={item.id} item xs={isSmall ? 4 : gridOneHidden && gridTwoHidden ? 2 : 6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <IconButton onClick={() => navigate('profile/' + item.id)} sx={{ margin: isSmall && 0, display: "flex", flexDirection: "column" }}>
                                {/*                                {isSmall &&
                                    <Typography component={"div"} variant="h6" sx={{ position: "absolute", marginBottom: 10, zIndex: 1 }}>
                                        {item.first_name ? item.first_name : item.username}
                                    </Typography>
                                } */}
                                <StyledBadgeAvatar
                                    tooltipContent={
                                        <div>
                                            {titleGen(item, userObj)}
                                            {userObj?.layer_name &&
                                                <>
                                                    <br />
                                                    Location: {userObj?.layer_name}
                                                </>}
                                        </div>
                                    }
                                    tooltipPlacement="bottom-end"
                                    badgeOverlap="circular"
                                    badgeAnchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeVariant="dot"
                                    badgeheight={15}
                                    badgewidth={15}
                                    badgeboxshadow={`0 0 0 1px ${theme.palette.background.paper}`}
                                    badgeSx={{
                                        width: avatarWidth,
                                        height: avatarHeight,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                    avatarSx={{
                                        width: avatarWidth,
                                        height: avatarHeight,
                                        borderWidth: 5,
                                        borderStyle: "solid",
                                        borderColor: busy ? theme.palette.mlRed.main : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? theme.palette.mlYellow.main : lastseenminutes > -5 && lastseenminutes < 5 ? theme.palette.mlGreen.main : '#adadb8' : '#adadb8',
                                    }}
                                    avatarVariant="circular"
                                    alt="ProfileIcon"
                                    imgSrc={item.id}
                                    lastseenminutes={lastseenminutes}
                                    busy={busy}
                                    imageSize="_64"
                                />

                                <Typography component={"div"} variant="h6" sx={{ zIndex: 1, fontSize: 18 }}>
                                    {item.first_name ? item.first_name : item.username}
                                </Typography>

                            </IconButton>

                        </Grid>
                    );
                })
                }
            </Grid >
        </>
    ) : null;
}