import { Box, CssBaseline } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MainContext } from 'kerberus-components';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AppBar from './Components/Layout/AppBar';
import Drawer from './Components/Layout/Drawer';
import DrawerMobile from './Components/Layout/DrawerMobile';
import AdminCalendar from './Views/Admin/AdminCalendar';
import AdminDashboardMaker from './Views/Admin/AdminDashboardMaker';
import AdminSocialGroup from './Views/Admin/AdminSocialGroup';
import AdminUsers from './Views/Admin/AdminUsers';
import CompanySettings from './Views/Company/CompanySettings';
import CompanyView from './Views/Company/CompanyView';
import Dashboard from './Views/Dashboard';
import DeviceRoutes from './Views/Device/DeviceRoutes';
import GroupRoutes from './Views/Groups/GroupRoutes';
import GroupsList from './Views/Groups/GroupsList';
import MyConnectionsView from './Views/MyConnections/MyConnectionsView';
import ProfileView from './Views/Profile/ProfileView';
import SocialGroupInvite from './Views/SocialGroupInvite';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdminTokens from './Views/Admin/AdminTokens';
import OrganizationShare from './Views/OrganizationShare';
import DashboardMobile from './Views/DashboardMobile';

let theme = createTheme({
    palette: {
        primary: {
            main: "#1C4E71"
        },
        mlBlue: {
            main: "#4EC1ED"
        },
        mlGreen: {
            main: "#8DBF30"
        },
        mlRed: {
            main: "#E72D50"
        },
        mlYellow: {
            main: "#FCC041"
        },
        // Old darker green
        green: {
            main: "#44b700"
        },
        darkBlue: {
            main: "#081627"
        },
        bgWhite: {
            main: "#F9FAFC"
        },
    }
});

theme = responsiveFontSizes(theme);

function App() {
    const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(!isSmall ? true : false);

    useEffect(() => {
        if (!isSmall) {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [isSmall]);


    const toggleDrawer = () => {
        setOpen(!open);
    };

    const { user, bulkData } = useContext(MainContext);

    const lastseenminutes = useMemo(() => {
        if (bulkData && bulkData.user?.timestamp) {
            const now = moment(new Date());
            const lastSeen = moment(bulkData.user?.timestamp);
            const duration = moment.duration(now.diff(lastSeen));
            return duration.asMinutes();
        }
        else return null;
    }, [bulkData]);

    const location = useLocation();
    const profileId = useMemo(() => location.pathname.split("/"), [location.pathname]);
    const titles = useMemo(() => (
        {
            "/": "Home",
            [`/profile/${profileId[2]}`]: "Profile",
            [`/profile/${profileId[2]}/calendar`]: "Calendar",
            [`/profile/${profileId[2]}/device`]: "Devices",
            [`/profile/${profileId[2]}/settings`]: "Settings",
            [`/profile/${profileId[2]}/device/${profileId[4]}`]: "Device's calendar",
            "/admin/calendar": "Device calendar",
            "/admin/users": "User list",
            "/admin/dashboard": "Dashboard maker",
            "/admin/socialgroup": "Social groups",
            "/termsconditions": "Terms & Conditions",
            [`/groups/${profileId[2]}`]: "Social group",
            [`/groups/${profileId[2]}/settings`]: "Social group settings",
            [`/groups/${profileId[2]}/invitations`]: "Social group invitations",
            "/groups/discover": "Discover groups",
            "/group_invitation": "Discover groups",
            "/company": "Company",
            "/company/settings": "Company Settings",
            "/myconnections": "My Connections",
        }
    ), [profileId]);

    const [title, setTitle] = useState(titles["/"]);

    useEffect(() => {
        const newTitle = titles[location.pathname];
        setTitle(newTitle);
        document.title = newTitle || "Web timetable";
    }, [location.pathname, titles]);

    const [gridOneHidden, setGridOneHidden] = useState(JSON.parse(localStorage.getItem('gridOneHidden')));
    const [gridTwoHidden, setGridTwoHidden] = useState(JSON.parse(localStorage.getItem('gridTwoHidden')));
    const [gridThreeHidden, setGridThreeHidden] = useState(JSON.parse(localStorage.getItem('gridThreeHidden')));

    useEffect(() => {
        localStorage.setItem('gridOneHidden', JSON.stringify(gridOneHidden));
    },[gridOneHidden]);

    useEffect(() => {
        localStorage.setItem('gridTwoHidden', JSON.stringify(gridTwoHidden));
    },[gridTwoHidden]);

    useEffect(() => {
        localStorage.setItem('gridThreeHidden', JSON.stringify(gridThreeHidden));
    },[gridThreeHidden]);

    /* useEffect(() => {
        const gridOneHidden = JSON.parse(localStorage.getItem('gridOneHidden'));
            if(gridOneHidden) {
                setGridOneHidden(gridOneHidden);
            }
    }, []);

    useEffect(() => {
        const gridTwoHidden = JSON.parse(localStorage.getItem('gridTwoHidden'));
            if(gridTwoHidden) {
                setGridTwoHidden(gridTwoHidden);
            }
    }, []);

    useEffect(() => {
        const gridThreeHidden = JSON.parse(localStorage.getItem('gridThreeHidden'));
            if(gridThreeHidden) {
                setGridThreeHidden(gridThreeHidden);
            }
    }, []); */

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box sx={{ display: 'flex', backgroundColor: theme.palette.primary.main + 50, overflow: "auto", position: "fixed", width: "100vw", overflowX: "hidden", height: "100vh" }}>
                    <CssBaseline />
                    <AppBar
                        open={open}
                        toggleDrawer={toggleDrawer}
                        title={title}
                        bulkData={bulkData}
                        user={user}
                        lastseenminutes={lastseenminutes}
                        gridOneHidden={gridOneHidden} 
                        setGridOneHidden={setGridOneHidden}
                        gridTwoHidden={gridTwoHidden} 
                        setGridTwoHidden={setGridTwoHidden}
                        gridThreeHidden={gridThreeHidden} 
                        setGridThreeHidden={setGridThreeHidden}
                    />
                    {!isSmall ?
                        <Drawer user={user} lastseenminutes={lastseenminutes} open={open} />
                        :
                        <DrawerMobile user={user} lastseenminutes={lastseenminutes} open={open} toggleDrawer={toggleDrawer} />
                    }
                    <Routes>
                        {!isMedium ? 
                            <Route exact path="" element={<Dashboard userId={user.id} lastseenminutes={lastseenminutes} gridOneHidden={gridOneHidden} setGridOneHidden={setGridOneHidden} gridTwoHidden={gridTwoHidden} setGridTwoHidden={setGridTwoHidden} gridThreeHidden={gridThreeHidden} setGridThreeHidden={setGridThreeHidden}/>} />
                            :
                            <Route exact path="" element={<DashboardMobile userId={user.id} lastseenminutes={lastseenminutes} gridOneHidden={gridOneHidden} setGridOneHidden={setGridOneHidden} gridTwoHidden={gridTwoHidden} setGridTwoHidden={setGridTwoHidden} gridThreeHidden={gridThreeHidden} setGridThreeHidden={setGridThreeHidden}/>} />
                        }
                        <Route path="/profile">
                            {/* <Route path="/profile/device" element={<Navigate to={`/profile/${user.id}/device${window.location.search}`} />} /> */}
                            <Route path="/profile/:userId" element={<ProfileView />} />
                            <Route path="/profile/:userId/calendar" element={<ProfileView />} />
                            {user.id === profileId[2] &&
                                <Route path="/profile/:userId/device" element={<ProfileView />} />
                            }
                            {user.id === profileId[2] &&
                                <Route path="/profile/:userId/settings" element={<ProfileView />} />
                            }
                            <Route path="/profile/:userId/device/:deviceId" element={<DeviceRoutes />} />
                            <Route path="/profile/:userId/device/:deviceId/calendar" element={<DeviceRoutes />} />
                        </Route>
                        <Route path="/company">
                            <Route path="/company" element={<CompanyView />} />
                            {(user.perm_level === "superuser" || user.perm_level === "admin") &&
                                <Route path="/company/settings" element={<CompanySettings />} />
                            }
                        </Route>
                        <Route path="/groups/*">
                            <Route path="discover" element={<GroupsList />} />
                            <Route path=":groupId/*" element={<GroupRoutes />} />
                        </Route>
                        <Route path="/myconnections" element={<MyConnectionsView />} />
                        {user && (user.perm_level === "superuser" || user.perm_level === "admin") &&
                            <Route path="/admin">
                                {user.perm_level === "superuser" &&
                                    <Route path="/admin/calendar" element={<AdminCalendar />} />
                                }
                                <Route path="/admin/users" element={<AdminUsers />} />
                                {!isSmall &&
                                    <Route path="/admin/dashboard" element={<AdminDashboardMaker />} />
                                }
                                <Route path="/admin/socialgroup" element={<AdminSocialGroup />} />
                                {user?.next && <Route path="/admin/tokens" element={<AdminTokens />} />}
                            </Route>
                        }
                        <Route exact path="/group_invitation/:token" element={<SocialGroupInvite />} />
                        {user?.next && <Route exact path="/orgshare/:orgId" element={<OrganizationShare />} />}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Box>
            </LocalizationProvider>
        </ThemeProvider >
    );
}

export default App;