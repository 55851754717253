import React from 'react';
import { Typography, Box, FormControlLabel, Checkbox, FormHelperText, FormGroup, Tooltip } from '@mui/material';



export default React.memo(function DataSharing({
    isSmall,
    location_enabled,
    setLocation_enabled,
    contentLocation,
    push_enabled,
    setPush_enabled,
    contentPushNotification,
    public_social,
    setPublic_social,
    contentPublicSocial,
    public_dashboard,
    setPublic_dashboard,
    contentPublic,
    public_calendar,
    setPublic_calendar,
    contentPublicCalendar,
    allow_tracking,
    setAllow_tracking,
    contentAllowTracking,
    termsValid,
    setTermsValid
}) {

    return (
        <>
            <Typography component={"div"} color="#808080" textAlign="center" sx={{ mt: 1, display: "flex" }}>
                Enter data sharing details
            </Typography>
            <Typography component={"div"} color="#808080" textAlign="center" sx={{ display: isSmall ? "none" : "flex" }}>
                Hover over the sections below for more information
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", width: "100%", my: 2, pl: 7 }}>

                {isSmall ?
                    <FormGroup>


                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <FormControlLabel label="Share Location" control={
                                <Checkbox
                                    checked={location_enabled}
                                    onChange={(e) => setLocation_enabled(e.target.checked)}
                                />
                            } />
                            <FormHelperText>{contentLocation}</FormHelperText>
                        </Box>




                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <FormControlLabel label="Push Notifications" control={
                                <Checkbox
                                    checked={push_enabled}
                                    onChange={(e) => setPush_enabled(e.target.checked)}
                                />
                            } />
                            <FormHelperText>{contentPushNotification}</FormHelperText>
                        </Box>


                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <FormControlLabel label="Public Social" control={
                                <Checkbox
                                    checked={public_social}
                                    onChange={(e) => setPublic_social(e.target.checked)}
                                />
                            } />
                            <FormHelperText>{contentPublicSocial}</FormHelperText>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <FormControlLabel label="Public Dashboard" control={<Checkbox
                                checked={public_dashboard}
                                onChange={(e) => setPublic_dashboard(e.target.checked)}
                            />
                            } />
                            <FormHelperText>{contentPublic}</FormHelperText>
                        </Box>


                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <FormControlLabel label="Public Calendar" control={<Checkbox
                                checked={public_calendar}
                                onChange={(e) => setPublic_calendar(e.target.checked)}
                            />
                            } />
                            <FormHelperText>{contentPublicCalendar}</FormHelperText>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <FormControlLabel label="Allow one-time tracking" control={<Checkbox
                                checked={allow_tracking}
                                onChange={(e) => setAllow_tracking(e.target.checked)}
                            />
                            } />
                            <FormHelperText>{contentAllowTracking}</FormHelperText>
                        </Box>

                    </FormGroup>
                    :
                    <FormGroup>
                        <Tooltip title={contentLocation} placement="right">
                            <FormControlLabel label="Share Location" control={
                                <Checkbox
                                    checked={location_enabled}
                                    onChange={(e) => setLocation_enabled(e.target.checked)}
                                />
                            } />
                        </Tooltip>
                        <Tooltip title={contentPushNotification} placement="right">
                            <FormControlLabel label="Push Notifications" control={
                                <Checkbox
                                    checked={push_enabled}
                                    onChange={(e) => setPush_enabled(e.target.checked)}
                                />
                            } />
                        </Tooltip>
                        <Tooltip title={contentPublicSocial} placement="right">
                            <FormControlLabel label="Public Social" control={
                                <Checkbox
                                    checked={public_social}
                                    onChange={(e) => setPublic_social(e.target.checked)}
                                />
                            } />
                        </Tooltip>
                        <Tooltip title={contentPublic} placement="right">
                            <FormControlLabel label="Public Dashboard" control={<Checkbox
                                checked={public_dashboard}
                                onChange={(e) => setPublic_dashboard(e.target.checked)}
                            />
                            } />
                        </Tooltip>
                        <Tooltip title={contentPublicCalendar} placement="right">
                            <FormControlLabel label="Public Calendar" control={<Checkbox
                                checked={public_calendar}
                                onChange={(e) => setPublic_calendar(e.target.checked)}
                            />
                            } />
                        </Tooltip>
                        <Tooltip title={contentAllowTracking} placement="right">
                            <FormControlLabel label="Allow one-time tracking" control={<Checkbox
                                checked={allow_tracking}
                                onChange={(e) => setAllow_tracking(e.target.checked)}
                            />
                            } />
                        </Tooltip>


                    </FormGroup>
                }


                <Box sx={{ mt: isSmall ? 5 : 3, display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                    <Typography>
                        I agree to the <a href="/termsconditions" target="_blank" rel="noreferrer">terms and conditions</a>
                    </Typography>
                    <Checkbox
                        sx={{ color: "#4EC1ED" }}
                        checked={termsValid}
                        onChange={(e) => setTermsValid((prev) => !prev)}
                    />
                </Box>
            </Box>

        </>
    )
})
