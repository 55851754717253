import { memo } from 'react';
import { Divider } from '@mui/material';
import CreateToken from '../../Components/Admin/CreateToken'
import ModifyToken from '../../Components/Admin/ModifyToken'
import LayoutPaper from '../../Components/LayoutPaper';

export default memo(function AdminTokens() {
    return (
        <LayoutPaper>
            <CreateToken />
            <Divider sx={{ borderWidth: 5, my: 2 }} />
            <ModifyToken />
        </LayoutPaper>
    );
});

