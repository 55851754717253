import {
    useState,
    useEffect,
    useContext,
    useMemo,
    useRef,
    useCallback,
} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/en-gb";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./react-big-calendar.css";
import CalendarToolbar from "../../Components/CalendarToolbar";
import { useTheme } from "@mui/material/styles";
import {
    MainContext,
    useWsDataProvider,
    SmallAvatarWrapper,
} from "kerberus-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, IconButton, Paper, Tooltip, Badge } from "@mui/material";
import { useParams } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import GetDeviceIcon from "../../Components/Device/GetDeviceIcon";
import PlaceIcon from '@mui/icons-material/Place';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#1C4E71",
    boxShadow: 24,
    p: 4,
    paddingBottom: 6,
};
const localizer = momentLocalizer(moment);
const dayFormat = (date, culture, localizer) =>
    localizer.format(date, "D", culture);
function EventComponentday(props) {
    return (
        <Typography
            component={"div"}
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {props.event.busy !== "busy" ? (
                props.title !== "Hidden" ? (
                    props.title
                ) : (
                    <VisibilityOffIcon sx={{ marginRight: 0.5 }} />
                )
            ) : (
                "Busy"
            )}
        </Typography>
    );
}
function EventComponentweek(props) {
    return (
        <Typography
            component={"div"}
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {props.event.busy !== "busy" ? (
                props.title !== "Hidden" ? (
                    props.title
                ) : (
                    <VisibilityOffIcon sx={{ marginRight: 0.5 }} />
                )
            ) : (
                "Busy"
            )}
        </Typography>
    );
}
export default function ProfileCalendar({ devgroup, userData, swrSiteData }) {
    const { user } = useContext(MainContext);
    const { userId } = useParams();
    const [events, setEvents] = useState([]);
    const [calDate, setCalDate] = useState(new Date());
    const [showAddress, setShowAddress] = useState(
        JSON.parse(localStorage.getItem("device")) ?? []
    );
    const [showSite, setShowSite] = useState(
        JSON.parse(localStorage.getItem("site")) ?? []
    );
    const { getData, data: providerData } = useWsDataProvider();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const [calView, setCalView] = useState("day");
    const [open, setOpen] = useState(false);
    const handleOpen = (e) => {
        setCurrentEvent(e);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const [currentEvent, setCurrentEvent] = useState();
    const handleTime = useMemo(() => {
        let curr = moment(calDate).toDate();
        let minus = moment(calDate).subtract(2, "hours").toDate();
        return curr.getDate() === minus.getDate() ? minus : curr;
    }, [calDate]);
    const fetchedWeek = useRef(null);
    const fetchedUser = useRef(null);
    // eslint-disable-next-line eqeqeq

    useEffect(() => {
        if (
            fetchedUser.current !== userId ||
            (calDate && moment(calDate).week() !== fetchedWeek.current)
        ) {
            fetchedWeek.current = moment(calDate).week();
            fetchedUser.current = userId;
            getData("socketLocation", "devtracking", {
                filters: {
                    user_id: userId,
                    start_time: moment(calDate).startOf("week"),
                    end_time: moment(calDate).endOf("week"),
                },
            });
        }
    }, [calDate, getData, userId]);
    const navigateFunction = (date) => {
        setCalDate(date);
    };
    const parseEvents = useCallback(
        (data) => {
            const events = [];

            if (userId === user.id) {
                for (const hiddenDate of user.user_hidden) {
                    events.push({
                        title: "Hidden",
                        start: new Date(hiddenDate.start),
                        end: moment(hiddenDate.start)
                            .add(moment.duration(hiddenDate.duration))
                            .toDate(),
                        busy: !hiddenDate.hidden,
                    });
                }
                for (const site of data) {
                    if (!showSite?.includes(site.site_id)) {
                        for (const item of site.online_data) {
                            const date = new Date(item.time);
                            if (!showAddress.some((macAddresses) => item.act?.includes(macAddresses))) {
                                if (
                                    events.length > 0 &&
                                    events[events.length - 1].title !== "Hidden" &&
                                    events[events.length - 1].title ===
                                    site.site_name &&
                                    ((events[events.length - 1].deviceSet.size ===
                                        1 &&
                                        item.act.length === 1) ||
                                        (events[events.length - 1].deviceSet.size >
                                            1 &&
                                            item.act.length > 1)) &&
                                    events[events.length - 1].end.getTime() ===
                                    date.getTime() &&
                                    events[events.length - 1].start.getDay() ===
                                    date.getDay()
                                ) {
                                    events[events.length - 1].end = new Date(
                                        events[events.length - 1].end.getTime() +
                                        30 * 60000
                                    );
                                    events[events.length - 1].deviceSet = new Set([
                                        ...events[events.length - 1].deviceSet,
                                        ...item.act,
                                    ]);
                                } else {
                                    events.push({
                                        title: site.site_name,
                                        start: date,
                                        end: new Date(date.getTime() + 30 * 60000),
                                        deviceSet: new Set(item.act),
                                    });
                                }
                            }
                        }
                    }
                }
            } else {
                for (const site of data) {
                    for (const item of site.online_data) {
                        const date = new Date(item.time);
                        if (
                            events.length > 0 &&
                            events[events.length - 1].title !== "Hidden" &&
                            events[events.length - 1].title ===
                            site.site_name &&
                            events[events.length - 1].end.getTime() ===
                            date.getTime() &&
                            events[events.length - 1].start.getDay() ===
                            date.getDay()
                        ) {
                            events[events.length - 1].end = new Date(
                                events[events.length - 1].end.getTime() +
                                30 * 60000
                            );
                        } else {
                            events.push({
                                title: site.site_name,
                                start: date,
                                end: new Date(date.getTime() + 30 * 60000),
                            });
                        }
                    }
                }
            }
            setEvents(events);
        },
        [user, userId, showAddress, showSite]
    );
    useEffect(() => {
        if (providerData) {
            parseEvents(providerData);
        }
    }, [providerData, parseEvents]);
    useEffect(() => {
        isSmall ? setCalView("day") : setCalView("week");
    }, [isSmall]);
    return (
        <div>
            {user && user.id !== userId && (
                <Grid
                    container
                    sx={{
                        height: "6vh",
                        marginTop: -2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 1,
                    }}
                >
                    <Grid
                        item
                        component={Paper}
                        elevation={5}
                        sx={{
                            width: isSmall ? 280 : 300,
                            backgroundColor: theme.palette.primary.main + "70",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0.5,
                            borderRadius: 7,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: theme.palette.primary.main + 95,
                        }}
                    >
                        <SmallAvatarWrapper
                            alt="ProfilePic"
                            userId={userId}
                            imageSize="_64"
                        />
                        <Typography
                            component={"div"}
                            variant="h6"
                            sx={{
                                color: theme.palette.primary.main,
                                marginLeft: 1,
                                fontWeight: "bold",
                            }}
                        >
                            {userData?.first_name || userData?.username}'s
                        </Typography>
                        <Typography
                            component={"div"}
                            variant="h6"
                            sx={{
                                color: theme.palette.primary.main,
                                marginLeft: 1,
                            }}
                        >
                            calendar
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid
                container
                sx={{
                    marginTop: -2,
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                }}
            >
                <Grid item xs={12}  sx={{ display: user && user.id !== userId ? "none" : "flex" }}>
                    <Grid container>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                            <Typography component={"div"} variant="h6" sx={{ display: user.device_groups.some(item => !item.visible) && "none" }}>
                                Filter by devices
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography component={"div"} variant="h5" sx={{}}>
                                Week
                            </Typography>
                            <Typography component={"div"} variant="h5" sx={{ ml: 1, fontWeight: "bold", color: theme.palette.mlBlue.main }}>
                                {fetchedWeek.current}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <Typography component={"div"} variant="h6" sx={{}}>
                                Filter by sites
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: user && user.id !== userId ? "none" : "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box>
                        {user.device_groups.filter(item => item.visible).map((item, id) => (
                            <IconButton
                                key={id}
                                onClick={() => {
                                    if (
                                        !showAddress.some((macAddresses) =>
                                            item.addresses
                                                .map((item) => item.address)
                                                .includes(macAddresses)
                                        )
                                    ) {
                                        localStorage.setItem(
                                            `device`,
                                            JSON.stringify([
                                                ...new Set([
                                                    ...showAddress,
                                                    ...item.addresses.map(
                                                        (item) => item.address
                                                    ),
                                                ]),
                                            ])
                                        );
                                        setShowAddress([
                                            ...new Set([
                                                ...showAddress,
                                                ...item.addresses.map(
                                                    (item) => item.address
                                                ),
                                            ]),
                                        ]);
                                    } else {
                                        localStorage.setItem(
                                            `device`,
                                            JSON.stringify(
                                                showAddress.filter(function (e) {
                                                    return (
                                                        item.addresses
                                                            .map(
                                                                (item) =>
                                                                    item.address
                                                            )
                                                            .indexOf(e) < 0
                                                    );
                                                })
                                            )
                                        );
                                        setShowAddress(
                                            showAddress.filter(function (e) {
                                                return (
                                                    item.addresses
                                                        .map((item) => item.address)
                                                        .indexOf(e) < 0
                                                );
                                            })
                                        );
                                    }
                                }}
                            >
                                <GetDeviceIcon
                                    value={item}
                                    customTooltip={item.group_name
                                    }
                                    badgeOpacity={
                                        !showAddress.some((macAddresses) =>
                                            item.addresses
                                                .map((item) => item.address)
                                                .includes(macAddresses)
                                        )
                                            ? "1"
                                            : "0.3"
                                    }
                                    sx={{
                                        height: 50,
                                        width: 50,
                                        p: 0.75,
                                        borderStyle: "solid",
                                        borderColor: "#adadb8",
                                        borderWidth: 3,
                                        bgcolor: theme.palette.primary.main + 50,
                                        color: theme.palette.primary.main,
                                        opacity: !showAddress.some((macAddresses) =>
                                            item.addresses
                                                .map((item) => item.address)
                                                .includes(macAddresses)
                                        )
                                            ? "1"
                                            : "0.3",
                                    }}
                                />
                            </IconButton>
                        )
                        )}
                        <Typography component={"div"} variant="h6" sx={{ display: user.device_groups.some(item => item.visible) && "none", color: theme.palette.warning.main }}>
                            You are not tracking any devices
                        </Typography>
                    </Box>
                    <Box>
                        {swrSiteData?.map(item => (
                            <Tooltip title={item.name}>
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    overlap="circular"
                                    badgeContent={item.name.charAt(0).toUpperCase()}
                                    color="primary"
                                    sx={{ "& .MuiBadge-badge": { right: 15 }, opacity: !showSite.includes(item.id) ? "1" : "0.3" }}
                                >
                                    <IconButton
                                        key={item.id}
                                        sx={{ border: "2px solid gray", height: 50, width: 50, mx: 1, backgroundColor: theme.palette.primary.main + 50, color: theme.palette.primary.main }}
                                        onClick={() => {
                                            if (
                                                !showSite.some((data) => item.id.includes(data))
                                            ) {
                                                localStorage.setItem(
                                                    `site`,
                                                    JSON.stringify([
                                                        ...new Set([
                                                            ...showSite,
                                                            item.id,
                                                        ]),
                                                    ])
                                                );
                                                setShowSite([
                                                    ...new Set([
                                                        ...showSite,
                                                        item.id,
                                                    ]),
                                                ]);
                                            } else {
                                                localStorage.setItem(
                                                    `site`,
                                                    JSON.stringify(
                                                        showSite.filter(function (e) {
                                                            return (
                                                                item.id.indexOf(e) < 0
                                                            );
                                                        })
                                                    )
                                                );
                                                setShowSite(
                                                    showSite.filter(function (e) {
                                                        return (
                                                            item.id.indexOf(e) < 0
                                                        );
                                                    })
                                                );
                                            }
                                        }}
                                    >
                                        <PlaceIcon sx={{ height: 30, width: 30 }} />
                                    </IconButton>
                                </Badge>

                            </Tooltip>
                        )
                        )}
                    </Box>
                </Grid>
                {user.id === userId && (
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography
                                component={"div"}
                                id="modal-modal-title"
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    marginTop: -2,
                                    marginBottom: 2,
                                    textDecoration: "underline",
                                }}
                            >
                                {currentEvent && currentEvent.title === "Hidden"
                                    ? "Hidden"
                                    : "Devices:"}
                            </Typography>
                            {currentEvent &&
                                currentEvent.title !== "Hidden" &&
                                [...currentEvent.deviceSet].map((item) => {
                                    const findDevgroup =
                                        user.device_groups.find((i) =>
                                            i.addresses.some(
                                                (addr) => addr.address === item
                                            )
                                        );
                                    return (
                                        <Typography
                                            component={"div"}
                                            key={item}
                                            id="modal-modal-title"
                                            variant="h6"
                                            sx={{ textAlign: "center" }}
                                        >
                                            Name: {findDevgroup?.group_name}{" "}
                                            <br />
                                            MAC: {item}
                                        </Typography>
                                    );
                                })}
                            <Typography
                                component={"div"}
                                id="modal-modal-title"
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    position: "absolute",
                                    bottom: 0,
                                    right: 5,
                                }}
                            >
                                {currentEvent &&
                                    moment
                                        .duration(
                                            moment(currentEvent.end).diff(
                                                moment(currentEvent.start)
                                            )
                                        )
                                        .asMinutes()}{" "}
                                min
                            </Typography>
                            <Typography
                                component={"div"}
                                id="modal-modal-title"
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    position: "absolute",
                                    bottom: 0,
                                    left: 5,
                                }}
                            >
                                During:{" "}
                                {currentEvent &&
                                    moment(currentEvent.start).format(
                                        "H:mm"
                                    )}{" "}
                                -{" "}
                                {currentEvent &&
                                    moment(currentEvent.end).format("H:mm")}
                            </Typography>
                        </Box>
                    </Modal>
                )}
                <Grid item xs={12}>
                    <Calendar
                        defaultDate={calDate}
                        defaultView={calView}
                        onView={(e) => setCalView(e)}
                        view={calView}
                        views={["day", "week"]}
                        startAccessor="start"
                        endAccessor={({ end }) => new Date(end.getTime() - 1)}
                        events={events}
                        tooltipAccessor={false}
                        localizer={localizer}
                        resizable
                        scrollToTime={handleTime}
                        onSelectEvent={user.id === userId && handleOpen}
                        timeslots={1}
                        style={{
                            height: "62vh",
                            width: "100%",
                            marginTop: "10px",
                        }}
                        formats={{
                            dayFormat,
                            eventTimeRangeFormat: (e) => {
                                return "";
                            },
                        }}
                        components={{
                            toolbar: isSmall ? CalendarToolbar : null,
                            day: { event: EventComponentday },
                            week: { event: EventComponentweek },
                        }}
                        dayLayoutAlgorithm="no-overlap"
                        onNavigate={navigateFunction}
                        eventPropGetter={(event, start, end, isSelected) => {
                            let newStyle = {
                                backgroundColor: event.title === "Hidden" ? event.busy ? theme.palette.mlRed.main : "#808080" : event.deviceSet?.size > 1 ? theme.palette.green.main : theme.palette.mlGreen.main,
                                /* color: isSmall ? event.title === 'Hidden' ? '#808080' : theme.palette.mlGreen.main : event.title === 'Hidden' ? '#808080' : '#FFFFFF', */
                                borderRadius: event.title !== "Hidden" && 10,
                                border: "none",
                                textAlign: "center",
                                marginLeft: 5,
                                paddingLeft: 10,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: user.id !== userId && "default",
                            };
                            return {
                                className: "",
                                style: newStyle,
                            };
                        }}
                    />
                </Grid>
            </Grid>
        </div >
    );
}
