import * as React from 'react';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import WatchIcon from '@mui/icons-material/Watch';
import TabletIcon from '@mui/icons-material/Tablet';
import ComputerIcon from '@mui/icons-material/Computer';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import RouterIcon from '@mui/icons-material/Router';
import TvIcon from '@mui/icons-material/Tv';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import HeadphonesIcon from '@mui/icons-material/Headphones';

const style = { width: "100%", height: "100%" }

const DeviceIcons = [
    <DeviceUnknownIcon sx={style} />,
    <PhoneAndroidIcon sx={style} />,
    <PhoneIphoneIcon sx={style} />,
    <TabletAndroidIcon sx={style} />,
    <TabletIcon sx={style} />,
    <ComputerIcon sx={style} />,
    <DesktopMacIcon sx={style} />,
    <TvIcon sx={style} />,
    <HeadphonesIcon sx={style} />,
    <WatchIcon sx={style} />,
    <RouterIcon sx={style} />,
    <DevicesOtherIcon sx={style} />,
];


export default DeviceIcons;