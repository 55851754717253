import * as React from 'react';
import {
    Typography, useTheme, useMediaQuery
} from '@mui/material';
import moment from 'moment';
import { Circle, Square } from '../../Components/Icons/Shapes';

export default React.memo(function ActiveTime({ socialGroup, center }) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (

        socialGroup?.enable_days.length > 0
            ?
            (moment.utc().startOf('day').add(socialGroup?.utc_start).local().format('HH:mm') === moment.utc().startOf('day').add(socialGroup?.utc_end).local().format('HH:mm') && JSON.stringify(socialGroup?.enable_days) === JSON.stringify([1, 2, 3, 4, 5, 6, 7]))
                ?
                <Typography component={"div"} variant="h6" sx={{ color: theme.palette.green.main }}>
                    <Circle/>Always active<Circle/>
                </Typography>
                :
                <>
                    <Typography component={"div"} variant="h6" display="inline" textAlign={center && "center"}
                        sx={{
                            marginBottom: 2,
                            color: theme.palette.green.main,
                            fontSize: isSmall && 18,
                            width: center && "100%"

                        }}
                    >
                        <Circle/>Active during {moment.utc().startOf('day').add(socialGroup?.utc_start).local().format('HH:mm')} - {moment.utc().startOf('day').add(socialGroup?.utc_end).local().format('HH:mm')}<Circle/>
                    </Typography>
                    <Typography component={"div"} variant="h6" display={!isSmall && "inline"} textAlign={center && "center"} sx={{ marginBottom: 2, color: theme.palette.green.main, marginLeft: !isSmall && !center && 2, fontSize: isSmall && 18, width: center && "100%" }}>
                        <Square/>
                        {JSON.stringify(socialGroup?.enable_days) === JSON.stringify([1, 2, 3, 4, 5, 6, 7])
                            ?
                            'Daily'
                            :
                            socialGroup?.enable_days.map((item) => {
                                switch (item) {
                                    case 1:
                                        return "Mon";
                                    case 2:
                                        return "Tue";
                                    case 3:
                                        return "Wed";
                                    case 4:
                                        return "Thu";
                                    case 5:
                                        return "Fri";
                                    case 6:
                                        return "Sat";
                                    case 7:
                                        return "Sun";
                                    default:
                                        return "";
                                }
                            }).join(', ')
                        }
                        <Square/>
                    </Typography>
                </>
            :
            <Typography component={"div"} variant="h6" display="inline" sx={{ marginBottom: 2, color: theme.palette.error.main }}>
                <Circle/>Never active<Circle/>
            </Typography>


    );
});