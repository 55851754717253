import * as React from 'react';
import { useCallback, useEffect, useState, useContext } from 'react';
import { useFetchCloud, useWsDataProvider, LayoutTemplate, timezones } from 'kerberus-components';
import {
    FormGroup, Checkbox, Grid, Typography, useTheme, Button, IconButton, Modal, Divider, Select, MenuItem, FormControl,
    InputLabel, FormControlLabel, InputAdornment, Tooltip, Switch, Autocomplete
} from '@mui/material';
import { MainContext } from 'kerberus-components';
import TextField from '@mui/material/TextField';
import { BlockPicker } from 'react-color';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import * as moment from "moment-timezone";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PaletteIcon from '@mui/icons-material/Palette';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HelperIcon from '../../Components/HelperIcon';
import ConfirmationDialog from '../../Components/ConfirmationDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LayoutPaper from '../../Components/LayoutPaper';
import useSWR, { useSWRConfig } from 'swr';

const themeCheckbox = createTheme({
    palette: {
        action: {
            disabled: "",
        },
    }
}
);

const disableColor = "#80808050";
const inputStyle = "standard";
const contentDashboard = <div>Toggle checkbox to start modifying current dashboard</div>;

const copyToast = {
    open: true,
    type: "success",
    title: "Success",
    message: "Dashboard URL copied to clipboard successfully",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

const dashboardToast = {
    open: true,
    type: "success",
    title: "Success",
    message: "Dashboard saved",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

export default function AdminDashboardMaker(props) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const { user, setOpen: setOpenToast } = useContext(MainContext);
    const { fetchCloud } = useFetchCloud();
    const socials = user?.dashboard?.data?.socials;
    const socialsCompany = user?.user_company.dashboard?.data?.socials;
    const [checked, setChecked] = useState(false);
    const [layout, setLayout] = useState(socialsCompany);
    const [dashboard, setDashboard] = useState("company");
    const [openTrack, setOpenTrack] = useState(false);
    const [valueTrack, setValueTrack] = useState(false);
    const [urlDialog, setUrlDialog] = useState(false);
    const [url, setUrl] = useState('');
    const [urlGroup, setUrlGroup] = useState('');
    const [groupChoices, setGroupChoices] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const [inputValue, setInputValue] = useState('');
    const [userList, setUserList] = useState([]);
    const { data, getData, doUnsubscribe } = useWsDataProvider();
    const [onlineData, setOnlineData] = useState(null);
    const [alwaysActive, setAlwaysActive] = useState((layout?.onlineTime?.start === undefined && layout?.onlineTime?.end === undefined));
    const { data: profileListData } = useSWR('users/profilelist/');
    const { data: socialgroupselectData } = useSWR('users/socialgroupselect/');
    const { mutate } = useSWRConfig();

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    useEffect(() => {
        if (startTime) {
            setLayout(prev =>
            ({
                ...prev, onlineTime: prev?.onlineTime?.start
                    ?
                    { ...prev.onlineTime, start: moment(startTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("HH:mm") }
                    :
                    { ...prev?.onlineTime, start: moment(startTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("HH:mm") }
            }));
        }
    }, [layout?.tz, startTime]);

    useEffect(() => {
        if (endTime) {
            setLayout(prev =>
            ({
                ...prev, onlineTime: prev?.onlineTime?.end
                    ?
                    { ...prev.onlineTime, end: moment(endTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("HH:mm") }
                    :
                    { ...prev?.onlineTime, end: moment(endTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("HH:mm") }
            }));
        }
    }, [layout?.tz, endTime]);

    useEffect(() => {
        if (profileListData) {
            setUserList(profileListData);
        }
    }, [profileListData]);

    useEffect(() => {
        if (dashboard === 'company' || dashboard === 'user') {
            if (userList.length > 0) {
                getData('socketLocation', 'lastFoundMany', {
                    subscribe: true,
                    filters: {
                        company: user.user_company.id
                    }
                });
            }
        }
        else if (dashboard === 'socialgroup' && selectedGroup) {
            if (userList.length > 0) {
                getData('socketLocation', 'lastFoundMany', {
                    subscribe: true,
                    filters: {
                        social_group: selectedGroup.id
                    }
                });
            }
        }

        return () => {
            doUnsubscribe();
        };
    }, [userList, getData, doUnsubscribe, dashboard, selectedGroup, user.user_company.id]);

    useEffect(() => {
        if (data) {
            setOnlineData(data);
        }
    }, [data]);

    useEffect(() => {
        if (socialgroupselectData) {
            setGroupChoices(socialgroupselectData);
        }
    }, [socialgroupselectData]);

    useEffect(() => {
        if (dashboard !== "socialgroup") {
            setSelectedGroup(null);
        }
        else {
            setSelectedGroup(groupChoices.length > 0 && groupChoices[0]);
        }
    }, [dashboard, groupChoices]);

    useEffect(() => {
        if (selectedGroup) {
            setLayout(selectedGroup.dashboard?.data?.socials);
            setDashboard("socialgroup");
            setUrl("");
            setUrlGroup("");
        }
    }, [selectedGroup]);


    const handleLayout = (event) => {
        if (event.target.value === "company") {
            setLayout(socialsCompany);
            setDashboard("company");
            setUrl("");
            setUrlGroup("");
        }
        else if (event.target.value === "user") {
            setLayout(socials);
            setDashboard("user");
            setUrl("");
            setUrlGroup("");
        }
        else if (event.target.value === "socialgroup") {
            setDashboard("socialgroup");
        }
        else {
            return null;
        }
    };

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [displayColorPicker2, setDisplayColorPicker2] = useState(false);
    const [displayColorPicker3, setDisplayColorPicker3] = useState(false);
    const [displayColorPicker4, setDisplayColorPicker4] = useState(false);
    const [displayColorPicker5, setDisplayColorPicker5] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    /*     const handleLayoutChange = (e) => {
            setLayout(prev => {
                let newLayout = { ...prev };
                if (backgroundColor !== prev.backgroundColor) newLayout.backgroundColor = backgroundColor;
                return newLayout;
            });

        }; */

    /*     const handleChange1 = (event, newValue) => {
            setValue(newValue);
        }; */

    const handleModalOpen = () => {
        setOpen(true);
        /* toggleFullScreen(document.body); */
    };

    const handleModalClose = () => {
        setOpen(false);
        /* toggleFullScreen(document.body); */
    };

    // Functions like F11 fullscreen toggle
    /*     function toggleFullScreen(elem) {
            // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                if (elem.requestFullScreen) {
                    elem.requestFullScreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullScreen) {
                    elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        } */

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const handleLayoutSave = async (e) => {
        if (dashboard === "user") {
            await fetchCloud(`users/update_dashboard`, 'post', {
                dashboard: { ...user.dashboard?.data, socials: layout },
                dashboard_type: dashboard,
            });
            setOpenToast(dashboardToast);
        }
        else if (dashboard === "company") {
            await fetchCloud(`users/update_dashboard`, 'post', {
                dashboard: { ...user.user_company.dashboard?.data, socials: layout },
                dashboard_type: dashboard,
            });
            setOpenToast(dashboardToast);
        }
        else {
            await fetchCloud(`users/update_dashboard`, 'post', {
                dashboard: { ...user.social_groups?.data, socials: layout },
                dashboard_type: dashboard,
                social_group: selectedGroup.id
            });
            setOpenToast(dashboardToast);
        }
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    const handleLayoutUrl = async (e) => {
        const res = await fetchCloud(`users/generate_dashboard`, 'post', {
            dashboard_type: dashboard,
            social_group: selectedGroup?.id
        });
        if (dashboard === "company") {
            const resGroup = await fetchCloud(`users/generate_dashboard`, 'post', {
                dashboard_type: "group",
            });
            setUrlGroup(resGroup.data);

        }
        setUrl(res.data);
    };

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(url);
        setOpenToast(copyToast);
    };

    const copyToClipboardGroup = async () => {
        await navigator.clipboard.writeText(urlGroup);
        setOpenToast(copyToast);
    };

    const classes = {
        gridContainer: {
            height: "100%",
            marginTop: 8.5,
            marginLeft: 2,
            marginRight: 2,
        },
        gridItemLayoutUrl: {
            backgroundColor: "#fff",
            paddingLeft: 1,
            paddingTop: 1,
            border: "1px solid #808080",
        },
        textFieldUrl: {
            position: "relative",
            bottom: 10,
            width: 300,
            marginX: 1,
            marginTop: 0.75
        },
        copyIcon: {
            height: 20,
            width: 20
        },
        gridItemLayoutContent: {
            border: "2px solid #808080",
            position: "relative",
            height: "74vh",
            marginTop: -1,
            backgroundColor: "#fff",
        },
        swatch: {
            padding: 5,
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: checked && 'pointer',
            position: "relative",
            bottom: 9,
        },
        fullscreenIcon: {
            position: "absolute",
            top: 0,
            right: 5,
        },
        gridItemLayoutSettings: {
            display: "flex",
            alignItems: "center",
            border: "2px solid #808080",
            flexDirection: "column",
            backgroundColor: "#fff",
            height: "74vh",
            marginTop: -1,
            borderRadius: 4,
            overflow: "auto",
            '&::-webkit-scrollbar': {
                width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#1C4E71",
            },
            '&::-webkit-scrollbar-corner': {
                display: "none"
            },
            scrollbarColor: `${theme.palette.primary.main} #fff`,
            scrollbarWidth: "thin",
        },
        divider: {
            borderBottomWidth: 4,
            borderColor: !checked ? disableColor : theme.palette.primary.main
        },
        settingsSubheader: {
            fontSize: 22,
            color: !checked && disableColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%"
        },
        settingsIcon: {
            color: !checked ? disableColor : theme.palette.primary.main
        },
        disabled: {
            color: !checked && disableColor
        },
        inputTitle: {
            textAlign: "center",
            fontSize: 20,
            color: !checked && disableColor,
            marginTop: 3,
        },
        inputTitleColor: {
            textAlign: "center",
            fontSize: 20,
            color: !checked && disableColor,
            marginTop: 3,
            marginBottom: 1
        },
        input: {
            width: "90%"
        },
        bgColor: {
            width: "14vw",
            height: '14px',
            borderRadius: '2px',
            background: `${layout?.backgroundColor}`,
        },
        titleColor: {
            width: "14vw",
            height: '14px',
            borderRadius: '2px',
            background: `${layout?.titleColor}`,
        },
        subheaderColor: {
            width: "14vw",
            height: '14px',
            borderRadius: '2px',
            background: `${layout?.subheaderColor}`,
        },
        textColor: {
            width: "14vw",
            height: '14px',
            borderRadius: '2px',
            background: `${layout?.textColor}`,
        },
        clockColor: {
            width: "14vw",
            height: '14px',
            borderRadius: '2px',
            background: `${layout?.clockColor}`,
        },
        disableColor: {
            width: "14vw",
            height: '14px',
            borderRadius: '2px',
            background: disableColor,
        }
    };

    return (
        <LayoutPaper>
            <Grid container direction={"row"} justifyContent="center">
                <Grid item xs={12} sx={{ marginTop: -1 }}>
                    <Button variant="contained" onClick={() => setUrlDialog(true)/* handleLayoutUrl */} >
                        Layout URL
                    </Button>
                    {dashboard === "user"
                        ?
                        <TextField
                            value={url}
                            variant="outlined"
                            sx={classes.textFieldUrl}
                            helperText="Dashboard for 1-4 members"
                            disabled={url.length < 1}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Copy to Clipboard">
                                            <IconButton disabled={url.length < 1} onClick={copyToClipboard}>
                                                <ContentCopyIcon sx={classes.copyIcon} />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                        :
                        dashboard === "company"
                            ?
                            <>
                                <TextField
                                    value={urlGroup}
                                    variant="outlined"
                                    sx={classes.textFieldUrl}
                                    helperText="Dashboard for 1-8 members"
                                    disabled={url.length < 1}
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Copy to Clipboard">
                                                    <IconButton disabled={url.length < 1} onClick={copyToClipboardGroup} >
                                                        <ContentCopyIcon sx={classes.copyIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <TextField
                                    value={url}
                                    variant="outlined"
                                    sx={classes.textFieldUrl}
                                    helperText="Dashboard for 1-16 members"
                                    disabled={url.length < 1}
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Copy to Clipboard">
                                                    <IconButton disabled={url.length < 1} onClick={copyToClipboard}>
                                                        <ContentCopyIcon sx={classes.copyIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                            :
                            <TextField
                                value={url}
                                variant="outlined"
                                sx={classes.textFieldUrl}
                                helperText="Dashboard for 1-16 members"
                                disabled={url.length < 1}
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Copy to Clipboard">
                                                <IconButton disabled={url.length < 1} onClick={copyToClipboard}>
                                                    <ContentCopyIcon sx={classes.copyIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />

                    }
                </Grid>
                <Grid item sm={12} md={9}
                    sx={classes.gridItemLayoutContent}>
                    {
                        dashboard === "user"
                            ?
                            <LayoutTemplate
                                onlineData={onlineData}
                                users={userList}
                                socialsData={layout}
                                avatarSize="4vw"
                                badgeSize={30}
                                fontSize="1vw"
                                titleFontSize="0.75vw"
                                gridFlexNumber={1.25}
                                small
                                scale={0.7}
                            />
                            :
                            <LayoutTemplate
                                type="company"
                                onlineData={onlineData}
                                users={dashboard === "socialgroup" ? selectedGroup && selectedGroup.users : userList}
                                socialsData={layout}
                                avatarSize="4vw"
                                fontSize="0.7vw"
                                gridFlexNumber={1.175}
                                badgeSize="1vw"
                                titleFontSize="0.5vw"
                                small
                                scale={0.7}
                            />
                    }
                    <IconButton onClick={handleModalOpen} color="primary" sx={classes.fullscreenIcon}>
                        <FullscreenIcon sx={{ width: "1.5vw", height: "1.5vw", filter: "invert(100%)", color: layout?.backgroundColor }} />
                    </IconButton>
                    <Modal
                        open={open}
                        onClose={handleModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <>
                            {dashboard === "user"
                                ?
                                <LayoutTemplate
                                    onlineData={onlineData}
                                    users={userList}
                                    socialsData={layout}
                                    avatarSize="6vw"
                                    badgeSize={30}
                                    fontSize="1vw"
                                    titleFontSize="0.75vw"
                                    gridFlexNumber={1.25}
                                />
                                :
                                <LayoutTemplate
                                    type="company"
                                    onlineData={onlineData}
                                    users={dashboard === "socialgroup" ? selectedGroup && selectedGroup.users : userList}
                                    socialsData={layout}
                                    avatarSize="6vw"
                                    fontSize="1.05vw"
                                    gridFlexNumber={1.25}
                                    badgeSize="1.5vw"
                                    titleFontSize="0.7vw"
                                />
                            }
                            <IconButton onClick={handleModalClose} color="primary" sx={classes.fullscreenIcon}>
                                <FullscreenExitIcon sx={{ width: "1.5vw", height: "1.5vw", filter: "invert(100%)", color: layout?.backgroundColor }} />
                            </IconButton>
                        </>
                    </Modal>
                </Grid>
                <Grid item sm={12} md={0.2} />
                <Grid item sm={12} md={2.8}
                    sx={classes.gridItemLayoutSettings}>
                    <Grid item xs={12} sx={{ marginTop: 3, display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>

                        <FormControl sx={{ width: "75%" }}>

                            <InputLabel id="demo-simple-select-label">Dashboard</InputLabel>
                            <Select
                                value={dashboard}
                                label="Dashboard"
                                onChange={handleLayout}
                                disabled={checked}
                            >
                                <MenuItem value="company">Company</MenuItem>
                                <MenuItem value="user">User</MenuItem>
                                {groupChoices.length > 0 &&
                                    <MenuItem value="socialgroup">Group</MenuItem>
                                }
                            </Select>
                            <HelperIcon content={contentDashboard} style={{ position: "relative", right: 35, bottom: 70 }} />
                            {(dashboard === "socialgroup" && groupChoices.length > 0) &&
                                <>
                                    <Autocomplete
                                        disablePortal
                                        id="groupselect"
                                        options={groupChoices}
                                        disabled={checked}
                                        value={selectedGroup}
                                        getOptionLabel={option => option.name}
                                        onChange={(e, newVal) => { setSelectedGroup(newVal); setLayout(newVal?.dashboard ? newVal.dashboard.data.socials : {}); }}
                                        inputValue={inputValue}
                                        onInputChange={(e, newVal) => setInputValue(newVal)}
                                        sx={{}}
                                        renderInput={(params) => <TextField {...params} label="Social groups" />}
                                    />
                                    {selectedGroup && selectedGroup.users.length === 0 &&
                                        <Typography component={"div"} variant="h6" color="error" textAlign="center">
                                            Group has no members
                                        </Typography>
                                    }
                                </>

                            }
                        </FormControl>
                    </Grid>
                    <Grid sx={{ display: "flex", marginBottom: 3, marginRight: 2, marginTop: dashboard === "socialgroup" ? 2 : -2 }}>
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            disabled={dashboard === "socialgroup" && !selectedGroup}
                        />
                        <Typography component={"div"} variant="caption" sx={{ fontSize: 24, color: !checked && disableColor }}>Layout settings</Typography>
                    </Grid>


                    <Divider flexItem fullWidth sx={classes.divider} />
                    <Typography component={"div"} variant="caption" sx={classes.settingsSubheader}>
                        <AddBoxIcon sx={classes.settingsIcon} />
                        Layout
                        <AddBoxIcon sx={classes.settingsIcon} />
                    </Typography>
                    <Divider flexItem fullWidth sx={classes.divider} />

                    <Grid textAlign="center">
                        <FormControlLabel sx={classes.disabled} label="Whereabouts" control={
                            <Checkbox
                                checked={!!layout?.hasLocation ? layout?.hasLocation : false}
                                onChange={(e) => setLayout(prev => ({ ...prev, hasLocation: !layout?.hasLocation }))}
                                disabled={!checked}
                                sx={classes.disabled}
                            />}
                        />
                        <FormControlLabel sx={classes.disabled} label="User Titles" control={
                            <Checkbox
                                checked={!!layout?.hasUserTitle ? layout?.hasUserTitle : false}
                                onChange={(e) => setLayout(prev => ({ ...prev, hasUserTitle: !layout?.hasUserTitle }))}
                                disabled={!checked}
                                sx={classes.disabled}
                            />}
                        />
                    </Grid>

                    <FormControlLabel sx={classes.disabled} label="2nd Logo instead of title" control={
                        <Checkbox
                            checked={!!layout?.hasSecondLogo ? layout?.hasSecondLogo : false}
                            onChange={(e) => setLayout(prev => ({ ...prev, hasSecondLogo: !layout?.hasSecondLogo }))}
                            disabled={!checked}
                            sx={classes.disabled}
                        />}
                    />

                    {!layout?.hasSecondLogo &&
                        <>
                            <Typography component={"div"} variant="caption" sx={classes.inputTitle}>
                                Title
                            </Typography>
                            <TextField
                                disabled={!checked}
                                helperText="Title"
                                label="Title"
                                variant={inputStyle}
                                sx={{ width: "90%" }}
                                value={layout?.title ? layout?.title : ""}
                                onChange={(e) => setLayout(prev => ({ ...prev, title: e.target.value }))}
                            />
                        </>
                    }

                    <Divider flexItem fullWidth sx={{
                        borderBottomWidth: 4,
                        borderColor: !checked ? disableColor : theme.palette.primary.main,
                        marginTop: 5
                    }} />
                    <Typography component={"div"} variant="caption" sx={classes.settingsSubheader}>
                        <AddPhotoAlternateIcon sx={classes.settingsIcon} />
                        Logos & Images
                        <AddPhotoAlternateIcon sx={classes.settingsIcon} />
                    </Typography>
                    <Divider flexItem fullWidth sx={classes.divider} />

                    <Typography component={"div"} variant="caption" sx={classes.inputTitle}>
                        Background Image
                    </Typography>
                    <TextField
                        disabled={!checked}
                        helperText="Enter URL."
                        label="Background Image"
                        variant={inputStyle}
                        sx={classes.input}
                        value={layout?.background?.url ? layout?.background?.url : ""}
                        onChange={(e) => (e.target.value[0] !== "'" && e.target.value[0] !== "`" && e.target.value[0] !== '"') ? setLayout(prev => ({ ...prev, background: prev?.background ? { ...prev.background, url: e.target.value } : { url: e.target.value } })) : ""}
                    />
                    <Grid textAlign="center">
                        <FormControlLabel sx={classes.disabled} label="Cover" control={
                            <Checkbox
                                checked={layout?.backgroundSize === "cover" || layout?.backgroundSize === undefined}
                                onChange={(e) => setLayout(prev => ({ ...prev, backgroundSize: "cover" }))}
                                disabled={!checked}
                                sx={classes.disabled}
                            />}
                        />
                        <FormControlLabel sx={classes.disabled} label="100%" control={
                            <Checkbox
                                checked={layout?.backgroundSize === "100% 100%"}
                                onChange={(e) => setLayout(prev => ({ ...prev, backgroundSize: "100% 100%" }))}
                                disabled={!checked}
                                sx={classes.disabled}
                            />}
                        />
                    </Grid>

                    <Typography component={"div"} variant="caption" sx={classes.inputTitle}>
                        Company Logo
                    </Typography>
                    <TextField
                        disabled={!checked}
                        helperText="Located in top left corner. Enter URL. "
                        label="Company logo"
                        variant={inputStyle}
                        sx={classes.input}
                        value={layout?.companylogo?.url ? layout?.companylogo?.url : ""}
                        onChange={(e) => setLayout(prev => ({ ...prev, companylogo: prev?.companylogo ? { ...prev.companylogo, url: e.target.value } : { url: e.target.value } }))}

                    />
                    <Grid textAlign="center">
                        <FormControlLabel sx={classes.disabled} label="Auto" control={
                            <Checkbox
                                checked={layout?.companylogoSize === "auto" || layout?.companylogoSize === undefined}
                                onChange={(e) => setLayout(prev => ({ ...prev, companylogoSize: "auto" }))}
                                disabled={!checked}
                                sx={classes.disabled}
                            />}
                        />
                        <FormControlLabel sx={classes.disabled} label="Contain" control={
                            <Checkbox
                                checked={layout?.companylogoSize === "contain"}
                                onChange={(e) => setLayout(prev => ({ ...prev, companylogoSize: "contain" }))}
                                disabled={!checked}
                                sx={classes.disabled}
                            />}
                        />
                        <FormControlLabel sx={classes.disabled} label="Cover" control={
                            <Checkbox
                                checked={layout?.companylogoSize === "cover"}
                                onChange={(e) => setLayout(prev => ({ ...prev, companylogoSize: "cover" }))}
                                disabled={!checked}
                                sx={classes.disabled}
                            />}
                        />
                        <Grid textAlign="center">
                            <FormControlLabel sx={classes.disabled} label="80%" control={
                                <Checkbox
                                    checked={layout?.companylogoSize === "80% 80%"}
                                    onChange={(e) => setLayout(prev => ({ ...prev, companylogoSize: "80% 80%" }))}
                                    disabled={!checked}
                                    sx={classes.disabled}
                                />}
                            />
                            <FormControlLabel sx={classes.disabled} label="50%" control={
                                <Checkbox
                                    checked={layout?.companylogoSize === "50% 50%"}
                                    onChange={(e) => setLayout(prev => ({ ...prev, companylogoSize: "50% 50%" }))}
                                    disabled={!checked}
                                    sx={classes.disabled}
                                />}
                            />
                        </Grid>
                    </Grid>

                    {layout?.hasSecondLogo &&
                        <>
                            <Typography component={"div"} variant="caption" sx={classes.inputTitle}>
                                Company Logo 2
                            </Typography>
                            <TextField
                                disabled={!checked}
                                helperText="Located in top center. Enter URL. "
                                label="Company logo 2"
                                variant={inputStyle}
                                sx={classes.input}
                                value={layout?.companylogo2?.url ? layout?.companylogo2?.url : ""}
                                onChange={(e) => setLayout(prev => ({ ...prev, companylogo2: prev.companylogo2 ? { ...prev.companylogo2, url: e.target.value } : { url: e.target.value } }))}
                            />

                            <Grid textAlign="center">
                                <FormControlLabel sx={classes.disabled} label="Auto" control={
                                    <Checkbox
                                        checked={layout?.companylogo2Size === "auto" || layout?.companylogo2Size === undefined}
                                        onChange={(e) => setLayout(prev => ({ ...prev, companylogo2Size: "auto" }))}
                                        disabled={!checked}
                                        sx={classes.disabled}
                                    />}
                                />
                                <FormControlLabel sx={classes.disabled} label="Contain" control={
                                    <Checkbox
                                        checked={layout?.companylogo2Size === "contain"}
                                        onChange={(e) => setLayout(prev => ({ ...prev, companylogo2Size: "contain" }))}
                                        disabled={!checked}
                                        sx={classes.disabled}
                                    />}
                                />
                                <FormControlLabel sx={classes.disabled} label="Cover" control={
                                    <Checkbox
                                        checked={layout?.companylogo2Size === "cover"}
                                        onChange={(e) => setLayout(prev => ({ ...prev, companylogo2Size: "cover" }))}
                                        disabled={!checked}
                                        sx={classes.disabled}
                                    />}
                                />
                                <Grid textAlign="center">
                                    <FormControlLabel sx={classes.disabled} label="80%" control={
                                        <Checkbox
                                            checked={layout?.companylogo2Size === "80% 80%"}
                                            onChange={(e) => setLayout(prev => ({ ...prev, companylogo2Size: "80% 80%" }))}
                                            disabled={!checked}
                                            sx={classes.disabled}
                                        />}
                                    />
                                    <FormControlLabel sx={classes.disabled} label="50%" control={
                                        <Checkbox
                                            checked={layout?.companylogo2Size === "50% 50%"}
                                            onChange={(e) => setLayout(prev => ({ ...prev, companylogo2Size: "50% 50%" }))}
                                            disabled={!checked}
                                            sx={classes.disabled}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }

                    {dashboard === "user" &&
                        <>
                            <Typography component={"div"} variant="caption" sx={classes.inputTitle}>
                                Company Picture
                            </Typography>
                            <TextField
                                disabled={!checked}
                                helperText="Located under clock. Enter URL. "
                                label="Company picture"
                                variant={inputStyle}
                                sx={classes.input}
                                value={layout?.picture1?.url ? layout?.picture1?.url : ""}
                                onChange={(e) => setLayout(prev => ({ ...prev, picture1: prev.picture1 ? { ...prev.picture1, url: e.target.value } : { url: e.target.value } }))}
                            />
                            <Grid textAlign="center">
                                <FormControlLabel sx={classes.disabled} label="Auto" control={
                                    <Checkbox
                                        checked={layout?.picture1Size === "auto" || layout?.picture1Size === undefined}
                                        onChange={(e) => setLayout(prev => ({ ...prev, picture1Size: "auto" }))}
                                        disabled={!checked}
                                        sx={classes.disabled}
                                    />}
                                />
                                <FormControlLabel sx={classes.disabled} label="Contain" control={
                                    <Checkbox
                                        checked={layout?.picture1Size === "contain"}
                                        onChange={(e) => setLayout(prev => ({ ...prev, picture1Size: "contain" }))}
                                        disabled={!checked}
                                        sx={classes.disabled}
                                    />}
                                />
                                <FormControlLabel sx={classes.disabled} label="Cover" control={
                                    <Checkbox
                                        checked={layout?.picture1Size === "cover"}
                                        onChange={(e) => setLayout(prev => ({ ...prev, picture1Size: "cover" }))}
                                        disabled={!checked}
                                        sx={classes.disabled}
                                    />}
                                />
                                <Grid textAlign="center">
                                    <FormControlLabel sx={classes.disabled} label="80%" control={
                                        <Checkbox
                                            checked={layout?.picture1Size === "80% 80%"}
                                            onChange={(e) => setLayout(prev => ({ ...prev, picture1Size: "80% 80%" }))}
                                            disabled={!checked}
                                            sx={classes.disabled}
                                        />}
                                    />
                                    <FormControlLabel sx={classes.disabled} label="50%" control={
                                        <Checkbox
                                            checked={layout?.picture1Size === "50% 50%"}
                                            onChange={(e) => setLayout(prev => ({ ...prev, picture1Size: "50% 50%" }))}
                                            disabled={!checked}
                                            sx={classes.disabled}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }

                    {/*                <Typography component={"div"} variant="caption" sx={classes.inputTitle}>
                    Powered By
                </Typography>
                <TextField
                    disabled={!checked}
                    helperText="Powered by Missing-Link!"
                    label="Powered by image"
                    variant={inputStyle}
                    sx={classes.input}
                    value={layout?.poweredBy?.url}
                    onChange={(e) => setLayout(prev => ({ ...prev, poweredBy: { ...prev.poweredBy, url: e.target.value } }))}
                /> */}

                    <div style={{ marginBottom: 30 }} />

                    <Divider flexItem fullWidth sx={classes.divider} />
                    <Typography component={"div"} variant="caption" sx={classes.settingsSubheader} >
                        <PaletteIcon sx={classes.settingsIcon} />
                        Colors
                        <PaletteIcon sx={classes.settingsIcon} />
                    </Typography>
                    <Divider flexItem fullWidth sx={classes.divider} />

                    <Typography component={"div"} variant="caption" sx={classes.inputTitleColor}>
                        Background color
                    </Typography>

                    {checked
                        ?
                        <>
                            <div style={classes.swatch} onClick={() => setDisplayColorPicker(!displayColorPicker)}>
                                <div style={classes.bgColor} />
                            </div>

                            {displayColorPicker ? <div >
                                <div onClick={() => setDisplayColorPicker(false)} />
                                <BlockPicker color={layout?.backgroundColor} onChangeComplete={(e) => setLayout(prev => ({ ...prev, backgroundColor: e.hex }))} />
                            </div> : null}
                        </>
                        :
                        <>
                            <div style={classes.swatch} >
                                <div style={classes.disableColor} />
                            </div>
                        </>
                    }

                    <Typography component={"div"} variant="caption" sx={classes.inputTitleColor}>
                        Title color
                    </Typography>

                    {checked
                        ?
                        <>
                            <div style={classes.swatch} onClick={() => setDisplayColorPicker5(!displayColorPicker5)}>
                                <div style={classes.titleColor} />
                            </div>

                            {displayColorPicker5 ? <div >
                                <div onClick={() => setDisplayColorPicker5(false)} />
                                <BlockPicker color={layout?.titleColor} onChange={(e) => setLayout(prev => ({ ...prev, titleColor: e.hex }))} />
                            </div> : null}
                        </>
                        :
                        <>
                            <div style={classes.swatch} >
                                <div style={classes.disableColor} />
                            </div>
                        </>
                    }

                    <Typography component={"div"} variant="caption" sx={classes.inputTitleColor}>
                        Subheader color
                    </Typography>

                    {checked
                        ?
                        <>
                            <div style={classes.swatch} onClick={() => setDisplayColorPicker3(!displayColorPicker3)}>
                                <div style={classes.subheaderColor} />
                            </div>

                            {displayColorPicker3 ? <div >
                                <div onClick={() => setDisplayColorPicker3(false)} />
                                <BlockPicker color={layout?.subheaderColor} onChange={(e) => setLayout(prev => ({ ...prev, subheaderColor: e.hex }))} />
                            </div> : null}
                        </>
                        :
                        <>
                            <div style={classes.swatch} >
                                <div style={classes.disableColor} />
                            </div>
                        </>
                    }

                    <Typography component={"div"} variant="caption" sx={classes.inputTitleColor}>
                        Text color
                    </Typography>

                    {checked
                        ?
                        <>
                            <div style={classes.swatch} onClick={() => setDisplayColorPicker4(!displayColorPicker4)}>
                                <div style={classes.textColor} />
                            </div>

                            {displayColorPicker4 ? <div>
                                <div onClick={() => setDisplayColorPicker4(false)} />
                                <BlockPicker color={layout?.textColor} onChange={(e) => setLayout(prev => ({ ...prev, textColor: e.hex }))} />
                            </div> : null}
                        </>
                        :
                        <>
                            <div style={classes.swatch} >
                                <div style={classes.disableColor} />
                            </div>
                        </>
                    }


                    <Typography component={"div"} variant="caption" sx={classes.inputTitleColor}>
                        Clock color
                    </Typography>

                    {checked
                        ?
                        <>
                            <div style={classes.swatch} onClick={() => setDisplayColorPicker2(!displayColorPicker2)}>
                                <div style={classes.clockColor} />
                            </div>

                            {displayColorPicker2 ? <div>
                                <div onClick={() => setDisplayColorPicker4(false)} />
                                <BlockPicker color={layout?.clockColor} onChange={(e) => setLayout(prev => ({ ...prev, clockColor: e.hex }))} />
                            </div> : null}
                        </>
                        :
                        <>
                            <div style={classes.swatch} >
                                <div style={classes.disableColor} />
                            </div>
                        </>
                    }

                    <Typography component={"div"} variant="caption" sx={classes.inputTitleColor}>
                        Powered by Missing-Link color
                    </Typography>
                    <Grid sx={{ display: "flex", alignItems: "center", }}>
                        <Typography component={"div"} sx={classes.disabled}>White</Typography>
                        <Switch
                            onChange={(e) => setLayout(prev => ({ ...prev, poweredBlack: e.target.checked }))}
                            checked={layout?.poweredBlack}
                            disabled={!checked}
                            sx={{
                                '& .MuiSwitch-switchBase': {
                                    '&.Mui-checked': {
                                        color: '#000',
                                        '& + .MuiSwitch-track': {
                                            backgroundColor: '#808080',
                                        },
                                    },
                                },
                            }}
                        />
                        <Typography component={"div"} sx={classes.disabled}>Black</Typography>
                    </Grid>

                    <div style={{ marginBottom: 30 }} />

                    <Divider flexItem fullWidth sx={classes.divider} />
                    <Typography component={"div"} variant="caption" sx={classes.settingsSubheader}>
                        <AccessTimeIcon sx={classes.settingsIcon} />
                        Active Time
                        <AccessTimeIcon sx={classes.settingsIcon} />
                    </Typography>
                    <Divider flexItem fullWidth sx={classes.divider} />

                    <div style={{ marginBottom: 20 }} />

                    <LocalizationProvider dateAdapter={AdapterMoment}>

                        <Typography component={"div"} variant="h5" sx={{ textAlign: "center", color: !checked && disableColor }}>
                            Timezone
                        </Typography>
                        <Autocomplete
                            disablePortal
                            id="timezone-autocomplete-input"
                            options={timezones}
                            value={layout?.tz ? layout?.tz : ""}
                            onChange={(e, newVal) => setLayout(prev => ({ ...prev, tz: newVal }))}
                            renderInput={(params) => <TextField {...params} label="Timezone" helperText="Determine dashboard timezone" />}
                            sx={{ mt: 1, mb: 3, width: "80%" }}
                            disabled={!checked}
                        />


                        <Typography component={"div"} id="modal-modal-title" variant="h5" textAlign="center" sx={{ color: !checked && disableColor, marginBottom: 1 }}>
                            Visibility Hours
                        </Typography>

                        <FormControlLabel
                            control={
                                <Tooltip title="This choice only affects time">
                                    <Checkbox
                                        checked={alwaysActive}
                                        onChange={(e) => {
                                            setAlwaysActive(!alwaysActive);
                                            setLayout(prev => ({ ...prev, onlineTime: { ...prev.onlineTime, start: undefined } }));
                                            setLayout(prev => ({ ...prev, onlineTime: { ...prev.onlineTime, end: undefined } }));
                                        }}
                                        disabled={!checked}
                                    />
                                </Tooltip>
                            } disabled={!checked} label="Always active" labelPlacement="top" sx={{ '.MuiFormControlLabel-label.Mui-disabled': { color: checked && "rgba(0, 0, 0, 0.87)" } }} />


                        <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginY: 2, color: !checked && disableColor }}>

                            <Grid item xs={5.5} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                                <TimePicker
                                    disabled={!checked || alwaysActive}
                                    key={layout?.tz}
                                    renderInput={(props) => <TextField {...props} />}
                                    value={moment(layout?.onlineTime?.start, "HH:mm").tz(layout?.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone)}
                                    onChange={(e) => {
                                        setStartTime(e);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <hr style={{ borderColor: (checked || alwaysActive) && theme.palette.primary.main, borderWidth: 2, width: "40%" }} />
                            </Grid>
                            <Grid item xs={5.5} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                <TimePicker
                                    disabled={!checked || alwaysActive}
                                    key={layout?.tz}
                                    renderInput={(props) => <TextField {...props} />}
                                    value={moment(layout?.onlineTime?.end, "HH:mm").tz(layout?.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone)}
                                    onChange={(e) => {
                                        setEndTime(e);
                                    }}
                                />
                            </Grid>

                            <FormGroup sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: 3, marginTop: 3 }}>

                                <ThemeProvider theme={checked && themeCheckbox}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={Array.isArray(layout?.onlineTime?.showWeekDay) ? layout.onlineTime.showWeekDay.includes(1) : true}
                                            onChange={() => {
                                                setLayout(prev => {
                                                    let weekDays = Array.isArray(prev.onlineTime?.showWeekDay) ? new Set(prev.onlineTime.showWeekDay) : new Set([1, 2, 3, 4, 5, 6, 7]);
                                                    if (weekDays.has(1)) {
                                                        weekDays.delete(1);
                                                    }
                                                    else {
                                                        weekDays.add(1);
                                                    }
                                                    return ({
                                                        ...prev, onlineTime: {
                                                            ...prev.onlineTime, showWeekDay: Array.from(weekDays)
                                                        }
                                                    }
                                                    );
                                                });
                                            }}
                                            disabled={!checked}
                                        />
                                    } label="Mon" labelPlacement="top" sx={{ width: 15, '.MuiFormControlLabel-label.Mui-disabled': { color: checked && "rgba(0, 0, 0, 0.87)" } }} />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={Array.isArray(layout?.onlineTime?.showWeekDay) ? layout.onlineTime.showWeekDay.includes(2) : true}
                                            onChange={() => {
                                                setLayout(prev => {
                                                    let weekDays = Array.isArray(prev.onlineTime?.showWeekDay) ? new Set(prev.onlineTime?.showWeekDay) : new Set([1, 2, 3, 4, 5, 6, 7]);
                                                    if (weekDays.has(2)) {
                                                        weekDays.delete(2);
                                                    }
                                                    else {
                                                        weekDays.add(2);
                                                    }
                                                    return ({
                                                        ...prev, onlineTime: {
                                                            ...prev.onlineTime, showWeekDay: Array.from(weekDays)
                                                        }
                                                    }
                                                    );
                                                });
                                            }}
                                            disabled={!checked}
                                        />
                                    } label="Tue" labelPlacement="top" sx={{ width: 15, '.MuiFormControlLabel-label.Mui-disabled': { color: checked && "rgba(0, 0, 0, 0.87)" } }} />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={Array.isArray(layout?.onlineTime?.showWeekDay) ? layout.onlineTime.showWeekDay.includes(3) : true}
                                            onChange={() => {
                                                setLayout(prev => {
                                                    let weekDays = Array.isArray(prev.onlineTime?.showWeekDay) ? new Set(prev.onlineTime?.showWeekDay) : new Set([1, 2, 3, 4, 5, 6, 7]);
                                                    if (weekDays.has(3)) {
                                                        weekDays.delete(3);
                                                    }
                                                    else {
                                                        weekDays.add(3);
                                                    }
                                                    return ({
                                                        ...prev, onlineTime: {
                                                            ...prev.onlineTime, showWeekDay: Array.from(weekDays)
                                                        }
                                                    }
                                                    );
                                                });
                                            }}
                                            disabled={!checked}
                                        />
                                    } label="Wed" labelPlacement="top" sx={{ width: 15, '.MuiFormControlLabel-label.Mui-disabled': { color: checked && "rgba(0, 0, 0, 0.87)" } }} />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={Array.isArray(layout?.onlineTime?.showWeekDay) ? layout.onlineTime.showWeekDay.includes(4) : true}
                                            onChange={() => {
                                                setLayout(prev => {
                                                    let weekDays = Array.isArray(prev.onlineTime?.showWeekDay) ? new Set(prev.onlineTime?.showWeekDay) : new Set([1, 2, 3, 4, 5, 6, 7]);
                                                    if (weekDays.has(4)) {
                                                        weekDays.delete(4);
                                                    }
                                                    else {
                                                        weekDays.add(4);
                                                    }
                                                    return ({
                                                        ...prev, onlineTime: {
                                                            ...prev.onlineTime, showWeekDay: Array.from(weekDays)
                                                        }
                                                    }
                                                    );
                                                });
                                            }}
                                            disabled={!checked}
                                        />
                                    } label="Thu" labelPlacement="top" sx={{ width: 15, '.MuiFormControlLabel-label.Mui-disabled': { color: checked && "rgba(0, 0, 0, 0.87)" } }} />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={Array.isArray(layout?.onlineTime?.showWeekDay) ? layout.onlineTime.showWeekDay.includes(5) : true}
                                            onChange={() => {
                                                setLayout(prev => {
                                                    let weekDays = Array.isArray(prev.onlineTime?.showWeekDay) ? new Set(prev.onlineTime?.showWeekDay) : new Set([1, 2, 3, 4, 5, 6, 7]);
                                                    if (weekDays.has(5)) {
                                                        weekDays.delete(5);
                                                    }
                                                    else {
                                                        weekDays.add(5);
                                                    }
                                                    return ({
                                                        ...prev, onlineTime: {
                                                            ...prev.onlineTime, showWeekDay: Array.from(weekDays)
                                                        }
                                                    }
                                                    );
                                                });
                                            }}
                                            disabled={!checked}
                                        />
                                    } label="Fri" labelPlacement="top" sx={{ width: 15, '.MuiFormControlLabel-label.Mui-disabled': { color: checked && "rgba(0, 0, 0, 0.87)" } }} />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={Array.isArray(layout?.onlineTime?.showWeekDay) ? layout.onlineTime.showWeekDay.includes(6) : true}
                                            onChange={() => {
                                                setLayout(prev => {
                                                    let weekDays = Array.isArray(prev.onlineTime?.showWeekDay) ? new Set(prev.onlineTime?.showWeekDay) : new Set([1, 2, 3, 4, 5, 6, 7]);
                                                    if (weekDays.has(6)) {
                                                        weekDays.delete(6);
                                                    }
                                                    else {
                                                        weekDays.add(6);
                                                    }
                                                    return ({
                                                        ...prev, onlineTime: {
                                                            ...prev.onlineTime, showWeekDay: Array.from(weekDays)
                                                        }
                                                    }
                                                    );
                                                });
                                            }}
                                            disabled={!checked}
                                        />
                                    } label="Sat" labelPlacement="top" sx={{ width: 15, '.MuiFormControlLabel-label.Mui-disabled': { color: checked && "rgba(0, 0, 0, 0.87)" } }} />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={Array.isArray(layout?.onlineTime?.showWeekDay) ? layout.onlineTime.showWeekDay.includes(7) : true}
                                            onChange={() => {
                                                setLayout(prev => {
                                                    let weekDays = Array.isArray(prev.onlineTime?.showWeekDay) ? new Set(prev.onlineTime?.showWeekDay) : new Set([1, 2, 3, 4, 5, 6, 7]);
                                                    if (weekDays.has(7)) {
                                                        weekDays.delete(7);
                                                    }
                                                    else {
                                                        weekDays.add(7);
                                                    }
                                                    return ({
                                                        ...prev, onlineTime: {
                                                            ...prev.onlineTime, showWeekDay: Array.from(weekDays)
                                                        }
                                                    }
                                                    );
                                                });
                                            }}
                                            disabled={!checked}
                                        />
                                    } label="Sun" labelPlacement="top" sx={{ width: 15, '.MuiFormControlLabel-label.Mui-disabled': { color: checked && "rgba(0, 0, 0, 0.87)" } }} />
                                </ThemeProvider>
                            </FormGroup>

                        </Grid>


                    </LocalizationProvider>

                    <Button variant="contained" sx={{ width: "40%", marginBottom: 5 }} disabled={!checked}
                        onClick={() => { setValueTrack(); setOpenTrack(true); }} >
                        Save
                    </Button>

                    <ConfirmationDialog
                        title={["Save"]}
                        handleDevice={handleLayoutSave}
                        name={dashboard}
                        value={valueTrack}
                        open={openTrack}
                        setOpen={setOpenTrack}
                        content={<div>This will apply changes to dashboard and it will be available for viewing from the layout URL button</div>}
                    />

                    <ConfirmationDialog
                        title={["Get"]}
                        handleDevice={handleLayoutUrl}
                        name="dashboard URL"
                        open={urlDialog}
                        setOpen={setUrlDialog}
                        content={<div>
                            By fetching this url and sharing this dashboard you have agreed and are aware that you will be sharing group information, images and location data to where the dashboard will be displayed.
                            <br /><br />
                            Note that your responsibility is to make sure that there is no third-party access to the URL inorder to prevent unauthorized copy and use.
                        </div>}
                    />

                </Grid>
            </Grid >
        </LayoutPaper>
    );
}

/* ALTERNATIVE TIME INPUT
<TextField
    id="time"
    label="Starting Time"
    type="time"
    disabled={!checked}
    value={layout?.onlineTime?.start}
    onChange={(e) => {
        setLayout(prev => ({ ...prev, onlineTime: { ...prev.onlineTime, start: e.target.value + ":00" } }));
    }}
    InputLabelProps={{
        shrink: true,
    }}
    inputProps={{
        step: 300,
    }}
    sx={{ width: "90%" }}
/> */