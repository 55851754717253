import { Card, Typography, Box } from "@mui/material";
import OnlineHours from "../Views/Profile/OnlineHours";
import OfflineHours from "../Views/Profile/OfflineHours";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import LastWeekOnlineHours from "../Views/Profile/LastWeekOnlineHours";
import LastWeekOfflineHours from "../Views/Profile/LastWeekOfflineHours";

export default function OfficeHoursCard({ disableIcon, online, site, ...props }) {
    const disabled = disableIcon

    return (
        <Card {...props}>
            <Box sx={{ height: "100%", p: 1, pl: 2, width: "100%" }}>
                {online
                    ?
                    <>
                        <Box sx={{ height: "33%", display: "flex", alignItems: "center" }}>
                            <Typography variant="h6" component="div" sx={{}}>
                                Weekly office hours
                            </Typography>
                        </Box>
                        <Box sx={{ height: "34%", p: 2, pl: 1, display: "flex" }}>
                            <Box sx={{ width: "80%" }}>
                                <Typography variant="h4" sx={{ display: "flex", alignItems: "center", height: "100%" }} color="text.secondary">
                                    <OnlineHours site={site} />
                                </Typography>
                            </Box>
                            <Box sx={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <WorkHistoryIcon color="mlGreen" sx={{ display: disabled ? "none" : "block", width: 36, height: 36 }} />
                            </Box>
                        </Box>
                        <Box sx={{ height: "33%", display: "flex", alignItems: "flex-end" }}>
                            <Typography sx={{ fontSize: 16 }} color="primary">
                                <LastWeekOnlineHours site={site} />
                            </Typography>
                        </Box>
                    </>
                    :
                    <>
                        <Box sx={{ height: "33%", display: "flex", alignItems: "center" }}>
                            <Typography variant="h6" component="div" sx={{}}>
                                Weekly offline hours
                            </Typography>
                        </Box>
                        <Box sx={{ height: "34%", p: 2, pl: 1, display: "flex" }}>
                            <Box sx={{ width: "80%" }}>
                                <Typography variant="h4" sx={{ display: "flex", alignItems: "center", height: "100%" }} color="text.secondary">
                                    <OfflineHours site={site} />
                                </Typography>
                            </Box>
                            <Box sx={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <WorkOffIcon color="mlRed" sx={{ display: disabled ? "none" : "block", width: 36, height: 36 }} />
                            </Box>
                        </Box>
                        <Box sx={{ height: "33%", display: "flex", alignItems: "flex-end" }}>
                            <Typography sx={{ fontSize: 16 }} color="primary">
                                <LastWeekOfflineHours site={site} />
                            </Typography>
                        </Box>
                    </>
                }
            </Box>
        </Card>
    );
}