/* eslint-disable eqeqeq */
import moment from "moment";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';

function CalendarToolbar(props) {
    const [viewState, setViewState] = useState();
    const today5 = new Date();
    const getCustomToolbar = () => {
        // const toolbarDate = props.date;
        const goToDayView = () => {
            props.onView("day");
            setViewState("day");
        };
        const goToWeekView = () => {
            props.onView("week");
            setViewState("week");
        };
        /*         const goToMonthView = () => {
                    props.onView("month");
                    setViewState("month");
                }; */
        const goToBack = () => {
            let view = viewState;
            let mDate = props.date;
            let newDate;
            if (view === "month") {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, mDate.getHours(), mDate.getMinutes(), mDate.getSeconds());
            } else if (view === "week") {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 7, mDate.getHours(), mDate.getMinutes(), mDate.getSeconds());
            } else {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 1, mDate.getHours(), mDate.getMinutes(), mDate.getSeconds());
            }
            props.onNavigate("prev", newDate);
        };
        const goToNext = () => {
            let view = viewState;
            let mDate = props.date;
            let newDate;
            if (view === "month") {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth() + 1,
                    mDate.getHours(),
                    mDate.getMinutes(),
                    mDate.getSeconds()
                );
            } else if (view === "week") {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() + 7,
                    mDate.getHours(), mDate.getMinutes(), mDate.getSeconds()
                );
            } else {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() + 1,
                    mDate.getHours(), mDate.getMinutes(), mDate.getSeconds()
                );
            }
            props.onNavigate("next", newDate);
        };

        const goToToday = () => {
            const now = new Date();
            props.date.setMonth(now.getMonth());
            props.date.setYear(now.getFullYear());
            props.date.setDate(now.getDate());
            props.onNavigate("current");
        };

        /*         const goToBackYear = () => {
                    let mDate = props.date;
                    let newDate = new Date(mDate.getFullYear() - 1, 1);
                    props.onNavigate("prev", newDate);
                };
        
                const goToNextYear = () => {
                    let mDate = props.date;
                    let newDate = new Date(mDate.getFullYear() + 1, 1);
                    props.onNavigate("next", newDate);
                }; */

        const month = () => {
            const date = moment(props.date);
            let month = date.format("M");
            let day = date.format("D");
            let year = date.format("yyyy");

            return (

                <Typography component={"div"} variant="h6" sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 1 }}>
                    {`${day}.${month}.${year}`}
                </Typography>
            );
        };
        /*         const year = () => {
                    const date = moment(props.date);
                    let year = date.format("YYYY");
        
                    return (
                        <span className="rbc-btn-group">
                            {viewState === "month" && (
                                <button type="button" onClick={goToBackYear}>
                                    <span className="prev-icon">&#8249;&#8249;</span>
                                </button>
                            )}
                            <span className="rbc-toolbar-label">{year}</span>
                            {viewState === "month" && (
                                <button type="button" onClick={goToNextYear}>
                                    <span className="prev-icon">&#8250;&#8250;</span>
                                </button>
                            )}
                        </span>
                    );
                };
        
                const day = () => {
                    let view = viewState;
                    const date = moment(props.date);
                    let day;
                    if (view === "day") {
                        day = date.format("ddd") + " " + date.format("Do");
                    }
                    return <span className="rbc-toolbar-label">{day}</span>;
                }; */

        return (
            <>
                <div style={{ alignSelf: "center", marginTop: 6 }}>
                    <Button variant={props.view == "day" ? "contained" : "outlined"} sx={{ mx: 1, backgroundColor: "blue" + 80 }} onClick={goToDayView}>
                        Day
                    </Button>
                    <Button variant={props.view == "week" ? "contained" : "outlined"} sx={{ mx: 0 }} onClick={goToWeekView}>
                        Week
                    </Button>
                </div>
                {month()}

                <div style={{ alignSelf: "center", marginBottom: 5 }}>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={goToBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Button onClick={goToToday} variant={moment(today5).format('D.M.yyyy') == moment(props.date).format('D.M.yyyy') ? "contained" : "outlined"} sx={{ mx: 3 }}>
                        Today
                    </Button>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={goToNext}>
                        <ArrowForwardIcon />
                    </IconButton>
                </div>


            </>
        );
    };

    return <>{getCustomToolbar()}</>;
}

export default CalendarToolbar;