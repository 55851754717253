import * as React from 'react';
import { useContext, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import {
    Grid, useTheme, useMediaQuery, Divider, Button, Badge
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import LayoutPaper from '../../Components/LayoutPaper';
import { MainContext } from 'kerberus-components';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SocialGroupModal from '../../Components/Groups/SocialGroupModal';
/* import GraphModal from '../../Components/Groups/GraphModal'; */
import SocialGroupActivityLogModal from '../../Components/Groups/SocialGroupActivityLogModal';
import UserList from '../../Components/UserList';
import DatagridColumns from '../../Components/Groups/DatagridColumns';
import ActiveTime from '../../Components/Groups/ActiveTime';
import DatagridColumnsMobile from '../../Components/Groups/DatagridColumnsMobile';
import SocialGroupHeader from '../../Components/Groups/SocialGroupHeader';
import SocialGroupToolbar from '../../Components/Groups/SocialGroupToolbar';
import moment from 'moment';
import { useWsDataProvider } from 'kerberus-components';


export default React.memo(function GroupView({ count, socialGroup, grouprequestsData, modal, isActive, isActiveWeekday }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useContext(MainContext);
    const { groupId } = useParams(); const [activityLogModal, setActivityLogModal] = useState(false);

    const { bulkData } = useContext(MainContext);

    const gridColumnsMobile = useMemo(() => (
        DatagridColumnsMobile
    ), []);

    const onlineData = useMemo(() => {
        return bulkData?.social_groups.find(i => i.group_id === groupId)?.users;
    }, [groupId, bulkData]);

    const [date, setDate] = useState(moment());
    const [interval, setInterval] = useState(60);
    const [duration, setDuration] = useState("day");
    const [chartType, setChartType] = useState("bar");

    const { data, getData } = useWsDataProvider();

    const cacheMap = useRef(new Map());

    const [graphData, setGraphData] = useState();

    const dataProviderParams = useRef();

    useEffect(() => {
        if (data) {
            cacheMap.current.set(
                moment(dataProviderParams.current.date).startOf(dataProviderParams.current.duration).toJSON()
                +
                moment(dataProviderParams.current.date).endOf(dataProviderParams.current.duration).toJSON(), data);
            setGraphData(data);
        }
    }, [data]);

    const getDataProviderData = useCallback(() => {
        getData('socketLocation', 'ucountGraph', {
            filters: {
                start_time: moment(date).startOf(duration),
                end_time: moment(date).endOf(duration),
                interval: interval,
                social_group: groupId,
            }
        });
        dataProviderParams.current = {
            date: date,
            duration: duration
        };
    }, [date, duration, getData, groupId, interval]);

    useEffect(() => {
        const cacheObj = cacheMap.current.get(moment(date).startOf(duration).toJSON() + moment(date).endOf(duration).toJSON());
        if (cacheObj) {
            setGraphData(cacheObj);
        }
        else {
            getDataProviderData();
        }
    }, [date, duration, getData, getDataProviderData, interval, groupId]);

    useEffect(() => {
        if (duration === "day") {
            setInterval(60);
        }
        else if (duration === "week") {
            setInterval(120);
        }
        else {
            setInterval(240);
        }
    }, [date, duration, getData, interval, groupId]);


    return user.social_groups.some(group => group['id'] === groupId) ? (
        <LayoutPaper>
            <Grid container>

                <Grid item xs={12} md={10} sx={{ display: "flex", flexDirection: isSmall ? "column" : "row", marginBottom: 1, marginTop: isSmall ? 5 : -1, alignItems: "center" }}>
                    <SocialGroupHeader socialGroup={socialGroup} modal={modal} count={count} isActive={isActive} isActiveWeekday={isActiveWeekday} />
                </Grid>

                <Grid item xs md={2} sx={isSmall ? { position: "absolute", top: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "flex-end" } : { display: "flex", alignItems: "center", justifyContent: "flex-end", position: "relative", bottom: 20, left: 20 }}>
                    <SocialGroupToolbar activityData={graphData} socialGroup={socialGroup} modal={modal} setActivityLogModal={setActivityLogModal} setDuration={setDuration} interval={interval} duration={duration} chartType={chartType} setChartType={setChartType} date={date} setDate={setDate} />
                </Grid>

                <Grid item xs={12} sm={11} sx={{ textAlign: isSmall && "center" }}>
                    <ActiveTime socialGroup={socialGroup} />
                </Grid>
                <Grid item xs={12} sm={1} sx={isSmall ? { display: "flex", alignItems: "center", justifyContent: "center", marginTop: 3, marginBottom: 1 } : { display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    {(socialGroup.creator === user.id || user.perm_level === "superuser") &&
                        <Badge badgeContent={grouprequestsData?.filter(item => item.social_group === groupId && item).length}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            color="error">
                            <Button variant="outlined" onClick={() => navigate('./invitations')} sx={{ marginTop: -2 }}>
                                <GroupAddIcon sx={{ marginRight: 1 }} />
                                Invitations
                            </Button>
                        </Badge>
                    }
                </Grid>
                <Divider flexItem sx={{ width: "100%", mb: 2 }} />

                <Grid container>
                    <UserList socialGroup={socialGroup} onlineData={onlineData} userList={socialGroup.users} gridColumns={DatagridColumns} gridColumnsMobile={gridColumnsMobile} default="card" datagridHeight="65vh" />
                </Grid>

                <SocialGroupActivityLogModal modal={{ activityLogModal, setActivityLogModal }} groupId={groupId} />

                <SocialGroupModal modal={modal} groupId={groupId} socialGroup={socialGroup} />

            </Grid>
        </LayoutPaper >
    )
        :
        null;

});