import { useEffect, useState, useContext, useMemo } from "react";
import { Grid, Box, Typography, Button, useTheme, useMediaQuery, styled, Divider } from "@mui/material";
import { useFetchCloud, MainContext, SmallAvatarWrapper } from "kerberus-components";
import { DataGrid } from "@mui/x-data-grid";
import SearchToolbar from '../../Components/SearchToolbar';
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import LayoutPaper from "../../Components/LayoutPaper";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import useSWR, { useSWRConfig } from 'swr';

const DataGridNoHeader = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeaders": { display: "none" },
    "& .MuiDataGrid-virtualScroller": { marginTop: "0!important", height: "49vh!important" },
}));

const toastRequestSent = {
    open: true,
    type: "success",
    title: "Request sent!",
    message: "Your request has been sent to the group's admin.",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default function GroupsList(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { user, setOpen } = useContext(MainContext);
    const { fetchCloud } = useFetchCloud();
    const [socialGroups, setSocialGroups] = useState([]);
    const userGroups = user.social_groups.map(item => item.id);
    const [content, setContent] = useState({});
    const [openReq, setOpenReq] = useState(false);
    const [emailNotif, setEmailNotif] = useState(false);
    const navigate = useNavigate();
    const { data: socialgrouppublicData } = useSWR('users/socialgrouppublic/');
    const { mutate } = useSWRConfig();

    useEffect(() => {
        if (socialgrouppublicData) {
            setSocialGroups(socialgrouppublicData);
        }
    }, [socialgrouppublicData]);

    const handleRequestSend = async (e) => {
        await fetchCloud(`users/send_group_request`, 'post', {
            social_group: e
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        setOpen(toastRequestSent);
    };

    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(socialGroups);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = socialGroups.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] ? searchRegex.test(row[field].toString()) : null;
            });
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        setRows(socialGroups);
    }, [socialGroups]);

    const contentDialog = (
        <>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography component={"div"} variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }} >
                {content.name}
            </Typography>
            <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
                {content.description}
            </Typography>
            <br />
            <Divider sx={{ marginBottom: 2 }} />
            <Typography component={"div"} style={{ overflowWrap: "break-word", fontWeight: "bold", fontSize: 16 }}>
                Group request will be sent to the group's admin who will either accept or reject your request.
                When the admin accepts your request you will receive an email on how to join the group with the options you choose.
            </Typography>
        </>

    );

    const contentEmailNotif = (
        <>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
                You haven't set up email yet.
                <br />
                You can add email to your account {""}
                <Typography component={"div"} sx={{ color: theme.palette.mlBlue.main, textDecoration: "underline", "&:hover": { cursor: "pointer" }, display: "inline" }}
                    onClick={() => navigate('/profile/' + user.id + '/settings')}>
                    here
                </Typography>
            </Typography>
        </>

    );

    const gridColumns = useMemo(() => ([

        {
            field: 'groupImg', flex: 0.3, headerName: '', align: "center",
            renderCell: (e) =>
                <SmallAvatarWrapper alt="GroupPic" sx={{ width: 50, height: 50 }}
                    userId={e.id} imageType="socialgrouppic" imageSize="_64"/>
        },
        {
            field: 'name', flex: 1, headerName: 'Name',
            renderCell: (e) => <Typography component={"div"} variant="h6" sx={{ fontSize: 19 }}>{e.value}</Typography>
        },
        {
            field: 'description', flex: 3, headerName: 'Description',
            renderCell: (e) => <Typography component={"div"} variant="subtitle1" sx={{}}>{e.value}</Typography>
        },
        {
            field: 'joined', flex: 1, headerName: '', headerAlign: "center", align: "center",
            renderCell: (e) =>
                <>
                    {userGroups.includes(e.id)
                        ?
                        <Box sx={{ backgroundColor: theme.palette.success.main, color: "#fff", paddingX: 2, paddingY: 1, borderRadius: 1 }}
                            variant="contained" >
                            Joined
                        </Box>
                        :
                        user.requests.includes(e.id)
                            ?
                            <Button disabled sx={{ fontSize: 13 }}
                                variant="contained" >
                                Request sent
                            </Button >
                            :
                            <Button sx={{ fontSize: 13 }}
                                onClick={
                                    (user.email && user.email.length > 0) ?
                                        (() => {
                                            setOpenReq(true);
                                            setContent(e.row);
                                        })
                                        :
                                        () => {
                                            setEmailNotif(true);
                                        }
                                }
                                variant="contained" >
                                Request to join

                            </Button >
                    }

                </>
        },

    ]), [theme.palette.success.main, user.email, user.requests, userGroups]);

    const gridColumnsMobile = useMemo(() => ([

        {
            field: 'name', flex: 1, headerName: 'Groups',
            renderCell: (e) =>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Accordion elevation={0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ display: "flex", flexDirection: "column" }}

                        >
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                {userGroups.includes(e.id)
                                    ?
                                    <Typography component={"div"} sx={{ color: theme.palette.success.main, fontWeight: "bold" }}>
                                        Joined
                                    </Typography>
                                    :
                                    user.requests.includes(e.id)
                                        ?
                                        <Typography component={"div"} sx={{ color: theme.palette.warning.main, fontWeight: "bold" }}>
                                            Request sent
                                        </Typography>
                                        :
                                        null
                                }
                                <SmallAvatarWrapper alt="GroupPic" sx={{ width: 60, height: 60 }}
                                    userId={e.id} imageType="socialgrouppic" imageSize="_64" />
                                <Typography component={"div"} variant="h6" sx={{ fontSize: "5vw" }}>
                                    {e.value}
                                </Typography>
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography component={"div"} variant="h6" textAlign={"center"}>
                                <span style={{ color: "gray", textDecoration: "underline" }}>Description</span>
                                <br />
                                {e.row.description}
                            </Typography>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginY: 3 }}>
                                {userGroups.includes(e.id)
                                    ?
                                    <Box sx={{ backgroundColor: theme.palette.success.main, color: "#fff", paddingX: 2, paddingY: 1, borderRadius: 1 }}
                                        variant="contained" >
                                        Joined
                                    </Box>
                                    :
                                    user.requests.includes(e.id)
                                        ?
                                        <Button disabled sx={{ fontSize: 13 }}
                                            variant="contained" >
                                            Request sent
                                        </Button >
                                        :
                                        <Button sx={{ fontSize: 13 }}
                                            onClick={() => { setOpenReq(true); setContent(e.row); }}
                                            variant="contained" >
                                            Request to join
                                        </Button >
                                }
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box >

        },

    ]), [theme.palette.success.main, theme.palette.warning.main, user.requests, userGroups]);

    return (
        <LayoutPaper hideoverflowy="true">
            <Typography component={"div"} variant="h5" sx={{ display: "flex", alignItems: "center", marginTop: -2, marginBottom: 2 }}>Public Groups</Typography>
            <Grid container>
                {isSmall
                    ?
                    <DataGridNoHeader
                        disableVirtualization
                        density="comfortable"
                        components={{ Toolbar: SearchToolbar }}
                        componentsProps={{
                            toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(''),
                            },
                        }}
                        rows={rows}
                        columns={isSmall ? gridColumnsMobile : gridColumns}
                        disableSelectionOnClick
                        isCellEditable={() => false}
                        /* onRowClick={handleRowClick} */
                        sx={{
                            height: "68vh",
                            overflowX: "hidden",
                            '& .MuiDataGrid-renderingZone': {
                                maxHeight: 'none !important'
                            },
                            '& .MuiDataGrid-cell': {
                                lineHeight: 'unset !important',
                                maxHeight: 'none !important',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word'
                            },
                            '& .MuiDataGrid-row': {
                                maxHeight: 'none !important'
                            }
                        }}
                    />
                    :
                    <DataGrid
                        density="comfortable"
                        components={{ Toolbar: SearchToolbar }}
                        componentsProps={{
                            toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(''),
                            },
                        }}
                        rows={rows}
                        columns={isSmall ? gridColumnsMobile : gridColumns}
                        disableSelectionOnClick
                        isCellEditable={() => false}
                        /* onRowClick={handleRowClick} */
                        sx={{
                            height: "78vh",
                            '& .MuiDataGrid-renderingZone': {
                                maxHeight: 'none !important',
                            },
                            '& .MuiDataGrid-cell': {
                                lineHeight: 'unset !important',
                                maxHeight: 'none !important',
                                whiteSpace: 'normal',
                            },
                            '& .MuiDataGrid-row': {
                                maxHeight: 'none !important',
                            },

                        }}
                    />
                }

                <ConfirmationDialog
                    title={["Send"]}
                    handleDevice={() => handleRequestSend(content.id)}
                    handle
                    name={"join request to the group"}
                    open={openReq}
                    setOpen={setOpenReq}
                    content={contentDialog}
                />

                <ConfirmationDialog
                    title={["Missing"]}
                    name={"email address"}
                    noAction
                    info
                    open={emailNotif}
                    setOpen={setEmailNotif}
                    content={contentEmailNotif}

                />
            </Grid>
        </LayoutPaper>
    );
}

