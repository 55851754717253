import { useState } from "react";
import { Grid, Paper, TextField, Button, Typography, Divider, IconButton } from "@mui/material";
import jwt_decode from 'jwt-decode';
import { useLayoutEffect } from "react";
import MissingLinkLogo from '../Assets/Images/missingLinkLogo.svg';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toast from '../Components/Toast';
import Copyright from "../Components/Copyright";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoginScreen() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [openEmailError, setOpenEmailError] = useState(false);
    const [openEmailSuccess, setOpenEmailSuccess] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleLoginError = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const checkLogin = async () => {
        let refresh = localStorage.getItem('refresh');

        if (refresh) {
            var refreshTest = jwt_decode(refresh).exp * 1000;
            if (refreshTest - Date.now() > 1000) {
                let response = await fetch(process.env.REACT_APP_AUTH_HOST + '/api/token/refresh/', {
                    credentials: 'include',
                    method: 'POST',
                    body: JSON.stringify({
                        refresh: refresh
                    }),
                    headers: new Headers({ 'Content-Type': 'application/json' })
                });
                if (response.ok) {
                    let json = await response.json();
                    localStorage.setItem('access', json.access);
                    localStorage.setItem('userId', json.id);
                    navigate('/');
                }
                else if (response.status === 401 || response.status === 403) {
                    localStorage.removeItem('refresh');
                }
            }
            else {
                localStorage.removeItem('refresh');
            }

        }
    };

    const handleSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        try {
            var response = await fetch(process.env.REACT_APP_AUTH_HOST + '/api/users/reset_password', {
                method: 'POST',
                body: JSON.stringify({
                    email: email
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            });
            if (response.ok) {
                setEmailSent(true);
                setOpenEmailSuccess(true);
            }
            else {
                setOpenEmailError(true);

            }
        }
        catch (e) {
            console.error(e);
        }
    };

    useLayoutEffect(() => {
        checkLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doLogin = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        try {
            var response = await fetch(process.env.REACT_APP_AUTH_HOST + '/api/token/', {
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({
                    username: username,
                    password: password
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            });
        }
        catch (err) {
            console.error(err);
        }

        if (response?.ok) {
            let json = await response.json();
            localStorage.setItem('access', json.access);
            localStorage.setItem('refresh', json.refresh);
            localStorage.setItem('userId', json.id);
            if (location.state?.toRoot) {
                navigate('/');
            }
            else {
                if (location?.state?.from) {
                    navigate(location.state.from);
                }
                else {
                    navigate('/');
                }
            }
        }
        else {
            handleLoginError();
        }
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'repeat',
                width: '100%',
                height: "100%",
                position: "fixed",
                background: "linear-gradient(70deg, rgba(231,45,80,.3), rgba(231,45,80,0) 70.71%), linear-gradient(140deg, rgba(141,191,48,.3), rgba(141,191,48,0) 70.71%), linear-gradient(240deg, rgba(78,193,237,.3), rgba(78,193,237,0) 70.71%), linear-gradient(320deg, rgba(0,28,81,.3), rgba(0,28,81,0) 70.71%)"
            }}
        >
            <Grid item xs={12} sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <Paper
                    onSubmit={forgotPassword ? handleSubmit : doLogin}
                    component="form"
                    noValidate
                    autoComplete="off"
                    elevation={8}
                    sx={{ borderRadius: isSmall ? 0 : 10 }}
                >
                    <Container component="main" maxWidth={"xs"}
                        sx={{
                            height: isSmall ? "100vh" : "75%", width: isSmall ? "100vw" : "100%", backgroundColor: "#ffffff", borderRadius: isSmall ? 0 : 10, padding: 2, margin: isSmall ? 0 : 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                        }}>
                        <CssBaseline />
                        <img src={MissingLinkLogo} alt="MissingLinlogo" width={"45%"} />
                        <Box
                            sx={{
                            }}>
                            {forgotPassword ?

                                <Box sx={{ marginTop: isSmall ? -3 : 3  }}>
                                    <Typography component={"div"} variant={isSmall ? "caption" : "overline"} sx={{ fontSize: 18, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        Recover Password
                                    </Typography>
                                    <Divider />
                                    <Typography component={"div"} color="#808080" textAlign="center" sx={{ marginBottom: 3 }}>
                                        Enter your email address and we will send you instructions for resetting password
                                    </Typography>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label='email'
                                        name="email"
                                        disabled={emailSent}
                                        inputProps={{
                                            autoComplete: "off",
                                        }}
                                        autoFocus
                                        sx={{ marginBottom: 3 }}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />

                                    {emailSent ?
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: theme.palette.success.main,
                                            color: "#fff",
                                            height: 36,
                                            borderRadius: 2

                                        }}>
                                            Email sent!
                                        </Box>

                                        :
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            disabled={email.length < 1}
                                            sx={{ mt: 0, mb: 0 }}
                                        >
                                            Submit
                                        </Button>
                                    }
                                    <IconButton
                                        onClick={() => setForgotPassword(false)}
                                        sx={{ color: "#4EC1ED", position: "relative", top: isSmall ? 20 : 40, right: 20 }}
                                    >
                                        <KeyboardBackspaceIcon sx={{ color: "#1976d2", height: 34, width: 34 }} />
                                    </IconButton>

                                </Box>
                                :
                                <Box sx={{ marginTop: isSmall ? -3 : 3 }}>
                                    <Typography component={"div"} variant="overline" sx={{ fontSize: 18, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        Login
                                    </Typography>
                                    <Divider />
                                    <Typography component={"div"} color="#808080" textAlign="center">
                                        Enter credentials
                                    </Typography>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="username"
                                        label='username'
                                        name="username"
                                        autoComplete="username"
                                        autoFocus
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label='password'
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography component={"div"} onClick={() => setForgotPassword(true)} sx={{ textDecoration: "underline", color: "#4EC1ED", fontSize: 15, float: "right", '&:hover': { cursor: "pointer" } }} >
                                                Forgot password?
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, backgroundColor: 'primary.main' }}
                                    >
                                        login
                                    </Button>
                                </Box>
                            }
                        </Box>
                        <Copyright />
                    </Container>
                    <Toast
                        sx={{}}
                        type="error"
                        title="Login error"
                        message="Wrong username or password"
                        verticalPos="bottom"
                        horizontalPos="center"
                        open={open}
                        handleClose={handleClose}
                        autoHideDuration={5000}
                    />
                    <Toast
                        sx={{}}
                        type="error"
                        title="Email send failed"
                        message="Internal server error"
                        verticalPos="bottom"
                        horizontalPos="center"
                        open={openEmailError}
                        handleClose={() => setOpenEmailError(false)}
                        autoHideDuration={50000}
                    />
                    <Toast
                        sx={{}}
                        type="success"
                        title="Email sent"
                        message="Instructions for resetting your password has been sent to your email if the email exists"
                        verticalPos="bottom"
                        horizontalPos="center"
                        open={openEmailSuccess}
                        handleClose={() => setOpenEmailSuccess(false)}
                        autoHideDuration={50000}
                    />
                </Paper>
            </Grid>

        </Grid>
    );
}
