import * as React from 'react';
import { useState, useEffect } from "react";
import {
    Button, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useFetchCloud, SmallAvatarWrapper } from 'kerberus-components';
import Toast from "../../Components/Toast";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import SearchToolbar from '../../Components/SearchToolbar';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default React.memo(function SocialGroupInviteDatagrid({ data, sx, groupId }) {
    const { fetchCloud } = useFetchCloud();
    const [openEmailSuccess, setOpenEmailSuccess] = useState(false);
    const [openEmailError, setOpenEmailError] = useState(false);
    const [inviteProfile, setInviteProfile] = useState();
    const [openInvite, setOpenInvite] = useState(false);

    const contentInvite = (
        <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
            Are you sure you want to invite
            <span style={{ fontWeight: "bold" }}>{inviteProfile?.row.first_name && inviteProfile?.row.last_name ? " " + inviteProfile?.row.first_name + " " + inviteProfile?.row.last_name : " " + inviteProfile?.row.username + "?"}</span>
        </Typography>
    );

    const gridColumns = [
        {
            field: 'id', headerName: '', flex: 0.25, align: "center", renderCell: (e) => (
                <SmallAvatarWrapper alt="ProfilePic" userId={e.row.id} sx={{ width: 32, height: 32 }} imageSize="_32" />
            )
        },
        {
            field: 'first_name', headerName: 'First Name', flex: 2
        },
        {
            field: 'last_name', headerName: 'Last Name', flex: 2
        },
        {
            field: 'username', headerName: 'Username', flex: 2
        },
        {
            field: 'invite', headerName: '', flex: 1, align: "center", renderCell: (e) => (<Button variant="contained" color="primary" onClick={() => { setInviteProfile(e); setOpenInvite(true); }}>Invite</Button>)
        },
    ];

    const handleInvite = async () => {
        try {
            await fetchCloud('users/create_social_invite', 'POST', {
                username: inviteProfile.row.username,
                social_group: groupId
            });
            setOpenEmailSuccess(true);
        }
        catch (err) {
            setOpenEmailError(true);
        }
    };

    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(data);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = data.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] ? searchRegex.test(row[field].toString()) : null;
            });
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        setRows(data);
    }, [data]);

    return (
        <>

            <DataGrid
                components={{ Toolbar: SearchToolbar }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch(''),
                    },
                }}
                rows={rows}
                columns={gridColumns}
                disableSelectionOnClick
                isCellEditable={() => false}
                sx={sx}
            />

            <ConfirmationDialog
                title={["Invite"]}
                handleDevice={(e) => handleInvite(e)}
                info
                handle
                name={"to social group"}
                open={openInvite}
                setOpen={setOpenInvite}
                content={contentInvite}
            />

            <Toast
                sx={{}}
                type="success"
                title="Email sent"
                message="Instructions for accepting group invite has been sent to the email if the email exists"
                verticalPos="bottom"
                horizontalPos="center"
                open={openEmailSuccess}
                handleClose={() => setOpenEmailSuccess(false)}
                autoHideDuration={50000}
            />
            <Toast
                sx={{}}
                type="error"
                title="Email send failed"
                message="Internal server error"
                verticalPos="bottom"
                horizontalPos="center"
                open={openEmailError}
                handleClose={() => setOpenEmailError(false)}
                autoHideDuration={50000}
            />

        </>
    );
});