import { handleAuthentication } from 'kerberus-components';

export default async function SWRFetcher(route, access, refresh) {
    const auth = await handleAuthentication(access, refresh);

    if (Array.isArray(route)) {
        const res = await fetch(`${process.env.REACT_APP_AUTH_HOST}/api/${route[0]}`, {
            headers: new Headers({ 'Authorization': 'Bearer ' + auth, 'Content-Type': 'application/json' })
        });
        return res.json();
    }
    else {
        const res = await fetch(`${process.env.REACT_APP_AUTH_HOST}/api/${route}`, {
            headers: new Headers({ 'Authorization': 'Bearer ' + auth, 'Content-Type': 'application/json' })
        });
        return res.json();
    }
}
