import * as React from 'react';
import { Typography, Box } from "@mui/material";
import StyledBadgeAvatar from "../StyledBadgeAvatar";
import { dayjs } from '../dayjs-utils';



export default function GridSmall(props) {
    const { lastseenminutes, avatarWidth, avatarHeight, busy, item, small, userObj } = props;

    return (
        <>
            <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>

                <Box sx={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", minHeight: !small && 200 }}>
                    <StyledBadgeAvatar
                        tooltipContent={lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? 'Away' : lastseenminutes > -5 && lastseenminutes < 5 ? 'Online' : 'Offline' : 'Offline'}
                        tooltipPlacement="bottom-end"
                        badgeOverlap="circular"
                        badgeAnchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeVariant="dot"
                        badgeheight="25%"
                        badgewidth="25%"
                        badgeanimation={!busy ? lastseenminutes ? lastseenminutes > -5 && lastseenminutes < 5 : false : false}
                        badgeboxshadow={`0 0 0 2px #fff`}
                        badgeSx={{
                            width: avatarWidth,
                            height: avatarHeight,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                        avatarSx={{
                            width: "105%",
                            height: "105%",
                            borderWidth: 6,
                            borderStyle: "solid",
                            borderColor: busy ? "#E72D50" : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? "#FCC041" : lastseenminutes > -5 && lastseenminutes < 5 ? "#8DBF30" : '#adadb8' : '#adadb8',
                        }}
                        avatarVariant="circular"
                        alt="ProfileIcon"
                        imgSrc={item.id}
                        lastseenminutes={lastseenminutes}
                        busy={busy}
                        skipCookie
                        imageSize="_256"
                    />
                    {(props.hasUserTitle && item?.title?.length > 0) &&
                        <Typography
                            textAlign="center"
                            fontSize={
                                small
                                    ?
                                    {
                                        xl: 14,
                                        lg: 13,
                                        md: 13,
                                        sm: 12,
                                        xs: 12
                                    }
                                    :
                                    {
                                        xl: 16,
                                        lg: 14,
                                        md: 12,
                                        sm: 11,
                                        xs: 11
                                    }
                            }
                            sx={{ color: props.subheaderColor, mt: 2, paddingX: 2 }}>
                            {item.title}
                        </Typography>
                    }
                </Box>
                <Box sx={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: "column", position: "relative", top: "15%", pr: 4 }}>
                    <Typography
                        fontSize={
                            small
                                ?
                                {
                                    xl: 20,
                                    lg: 18,
                                    md: 16,
                                    sm: 14,
                                    xs: 12
                                }
                                :
                                {
                                    xl: 26,
                                    lg: 24,
                                    md: 20,
                                    sm: 16,
                                    xs: 14
                                }
                        }
                        component="div"
                        sx={{ color: props.textColor, textAlign: "center" }}
                    >
                        {(item.first_name && item.last_name) ? item.first_name + " " + item.last_name : item.username}
                    </Typography>
                    <Box sx={{ /* display: "flex", alignItems: "center", justifyContent: "center",  */height: "50%", flexDirection: "column" }}>
                        <Typography
                            textAlign="center"
                            fontSize={
                                small
                                    ?
                                    {
                                        xl: 16,
                                        lg: 14,
                                        md: 12,
                                        sm: 12,
                                        xs: 10
                                    }
                                    :
                                    {
                                        xl: 18,
                                        lg: 16,
                                        md: 14,
                                        sm: 12,
                                        xs: 10
                                    }
                            }
                            sx={{ color: props.subheaderColor, }}>
                            Last Seen:
                        </Typography>
                        {props.hasLocation ?
                            <Typography
                                fontSize={
                                    small
                                        ?
                                        {
                                            xl: 18,
                                            lg: 16,
                                            md: 14,
                                            sm: 12,
                                            xs: 11
                                        }
                                        :
                                        {
                                            xl: 22,
                                            lg: 20,
                                            md: 16,
                                            sm: 14,
                                            xs: 12
                                        }
                                }
                                sx={{ color: props.textColor }}>
                                {
                                    userObj?.timestamp
                                        ?
                                        lastseenminutes <= 10 && lastseenminutes >= 5
                                            ?
                                            dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                            :
                                            lastseenminutes >= 10
                                                ?
                                                dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                                :
                                                userObj.layer_name ?? dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds'))
                                        :
                                        'Long time ago'
                                }
                            </Typography>
                            :
                            <Typography
                                textAlign="center"
                                fontSize={
                                    small
                                        ?
                                        {
                                            xl: 18,
                                            lg: 16,
                                            md: 14,
                                            sm: 12,
                                            xs: 11
                                        }
                                        :
                                        {
                                            xl: 22,
                                            lg: 20,
                                            md: 16,
                                            sm: 14,
                                            xs: 12
                                        }
                                }
                                sx={{ color: props.textColor }}>
                                {userObj?.timestamp ? dayjs(userObj.timestamp).from(dayjs().add(30, 'seconds')) : 'Long time ago'}
                            </Typography>

                        }
                    </Box>
                </Box>

            </Box>
        </>
    );
}