import * as React from 'react';
import { SocketContext, MainContext, useWsDataProvider } from 'kerberus-components';
import useWebSocket from 'react-use-websocket';
import { useContext, useState, useLayoutEffect, useEffect } from 'react';

export default function SocketProvider({ children }) {
    const { accessToken, setBulkData, user } = useContext(MainContext);
    const [connect, setConnect] = useState(false);
    const ws = useWebSocket(`${process.env.REACT_APP_WS_HOST}?Bearer`, {
        protocols: accessToken.access,
        shouldReconnect: (closeEvent) => {
            return true;
        },
        reconnectInterval: 5000,
        retryOnError: true,
        reconnectAttempts: 20,
        onOpen: () => {
            getData('socketLocation', 'clientBulk', {
                filters: {
                    tz: user.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                subscribe: true
            });
        }
    }, connect);
    const { data, getData } = useWsDataProvider(ws);

    useLayoutEffect(() => {
        if (accessToken.access) {
            setConnect(true);
        }
        else {
            setConnect(false);
        }
    }, [accessToken.access]);

    const refreshSocket = () => {
        setConnect(false);
        setConnect(true);
    };

    useEffect(() => {
        if (data) {
            setBulkData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <SocketContext.Provider value={{ ...ws, refreshSocket }}>
            {children}
        </SocketContext.Provider>
    );
}