import React from 'react';
import { useMemo } from 'react';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import LayoutPaper from '../../Components/LayoutPaper';
import useSWR from 'swr';
import UserList from '../../Components/UserList';
import DatagridColumns from '../../Components/Admin/DatagridColumns';
import UserCreation from '../../Components/Admin/UserCreation';
import DatagridColumnsMobile from '../../Components/Admin/DatagridColumnsMobile';

export default React.memo(function AdminUsers() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { data: userList = [] } = useSWR('users/usermanage/');

    const gridColumnsMobile = useMemo(() => (
        DatagridColumnsMobile
    ), []);

    return (
        <LayoutPaper>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                    <UserList userList={userList} gridColumns={DatagridColumns} gridColumnsMobile={gridColumnsMobile} default="list" smallArea hideLinks datagridHeight="75vh" />
                </Grid>
                <Grid item xs={12} md={3} sx={{ display: "flex", alignItems: "center", flexDirection: "column", position: "relative", mt: isSmall && 4, mr: isSmall && 2 }}>
                    <UserCreation userList={userList} />
                </Grid>
            </Grid >
        </LayoutPaper>
    );
});