import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GavelIcon from '@mui/icons-material/Gavel';
import LogoutIcon from '@mui/icons-material/Logout';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MenuIcon from '@mui/icons-material/Menu';
import { Accordion, AccordionDetails, AccordionSummary, AppBar as MuiAppBarComponent, Badge, Button, Divider, IconButton, Menu, MenuItem, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SmallAvatarWrapper, useFetchCloud } from 'kerberus-components';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSWRConfig } from 'swr';
import HelperIcon from '.././HelperIcon';
import HiddenTimeSlider from '.././HiddenTimeSlider';
import InfoModal from '.././InfoModal';
import ShowOfflineStatus from '.././ShowOfflineStatus';
import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

const content = (<><div>It may take a few minutes to update the status</div></>);
const contentHidden = (<><div>It may take a few minutes to update the status.</div> <br /><div> You will be shown as active for the next 10 minutes.</div> </>);

const drawerWidth = 240;

const MuiAppBar = styled(MuiAppBarComponent, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isSmall',
})(({ theme, open, isSmall }) => ({
    zIndex: !isSmall ? theme.zIndex.drawer - 1 : theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: !isSmall ? "100%" : `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const StyledBadge = styled(Badge, { shouldForwardProp: (prop) => prop !== 'userOnlineData' })(({ theme, lastseenminutes, userOnlineData }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: userOnlineData?.status === 'busy'
            ? theme.palette.mlRed.main
            : userOnlineData?.status === 'hidden'
                ? '#adadb8'
                : lastseenminutes <= 10 && lastseenminutes >= 5
                    ? theme.palette.mlYellow.main
                    : lastseenminutes > 0 && lastseenminutes < 5
                        ? theme.palette.mlGreen.main
                        : '#adadb8',
        width: 12,
        height: 12,
        borderRadius: "50%"
    },
}));

export default React.memo(function AppBar({
    open,
    title,
    bulkData,
    user,
    lastseenminutes,
    toggleDrawer,
    gridOneHidden,
    setGridOneHidden,
    gridTwoHidden,
    setGridTwoHidden,
    gridThreeHidden,
    setGridThreeHidden,
}) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const { fetchCloud } = useFetchCloud();
    const { first_name, last_name, username } = user;
    const [anchorEl, setAnchorEl] = useState(null);
    const { mutate } = useSWRConfig();
    const [hiddenTime, setHiddenTime] = useState();
    const [busyTime, setBusyTime] = useState();
    const [nowHidden, setNowHidden] = useState(false);
    const [hideDuration, setHideDuration] = useState(0);
    const [status, setStatus] = useState();
    const [busyExpanded, setBusyExpanded] = useState();
    const [hiddenExpanded, setHiddenExpanded] = useState();

    /* const buttonColor = createTheme({
        palette: {
            primary: {
                main: '#ffffff',
            },
        },
    }); */

    const handleHiddenTime = (e) => {
        setHiddenTime(e);
    };
    const handleBusyTime = (e) => {
        setBusyTime(e);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleStatusHidden = async () => {
        if (nowHidden || !hiddenTime) {
            return;
        }
        try {
            await fetchCloud('users/hiddentime/', 'post', {
                start: new Date(),
                duration: hiddenTime * 60
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            console.error(err);
        }
    };

    const handleStatusBusy = async () => {
        if (nowHidden || !busyTime) {
            return;
        }
        try {
            await fetchCloud('users/hiddentime/', 'post', {
                start: new Date(),
                duration: busyTime * 60,
                hidden: false
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            console.error(err);
        }
    };

    const handleStatusCancel = async () => {
        if (!nowHidden) {
            return;
        }
        try {
            await fetchCloud('users/cancelhide', 'post');
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            setHideDuration(0);
            setBusyTime(0);
            setHiddenTime(0);
            setNowHidden(false);
        }
        catch (err) {
            console.error(err);
        }
    };

    const handleLogout = async () => {
        localStorage.removeItem('refresh');
        localStorage.removeItem('access');
        localStorage.removeItem('userId');
        try {
            await caches.delete("pwa-cache");
        }
        catch {
            console.log("cache not available");
        }
        finally {
            navigate('/login');
        }
    };

    useEffect(() => {
        let currDate = new Date();
        let hideInterval = null;
        for (let item of user.user_hidden) {
            if (new Date(item.start) < currDate && moment(item.start).add(moment.duration(item.duration)).toDate() > currDate) {
                setHideDuration(moment.duration(moment(item.start).add(moment.duration(item.duration)).diff(moment(currDate))).asMinutes());
                setNowHidden(true);
                setStatus(item.hidden);
                hideInterval = setInterval(() => {
                    setHideDuration(moment.duration(moment(item.start).add(item.duration, 'm').diff(moment(new Date()))).asMinutes());
                }, 60000);
                break;
            }
        }
        return () => {
            clearInterval(hideInterval);
            hideInterval = null;
        };
    }, [user]);

    /* const [toggles, setToggles] = useState(() => [JSON.parse(localStorage.getItem('toggles'))?.find(() => "toggle1"), JSON.parse(localStorage.getItem('toggles'))?.find(() =>"toggle2"), JSON.parse(localStorage.getItem('toggles'))?.find(() =>"toggle3")]);

    const handleToggles = (event, newToggles) => {
        setToggles(newToggles);
        localStorage.setItem('toggles', JSON.stringify(newToggles));
    } */

    /* useEffect(() => {
        localStorage.setItem('toggles', JSON.stringify(toggles));
    },[toggles]); */

    /* useEffect(() => {
        const toggles = JSON.parse(localStorage.getItem('toggles'));
            if(toggles) {
                setToggles(toggles);
            }
    }, []); */

    /* console.log(JSON.parse(localStorage.getItem('toggles'))) */
    return (
        <MuiAppBar position="absolute" open={open} display="flex" isSmall={!isSmall} sx={{}}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed

                }}
            >
                {/* DrawerMenu*/}
                 <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                {/* Appbar title */}
                <Typography component={"div"}
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flex: 1 }}
                >
                    {isSmall ? title !== "Home" && title : title}
                </Typography>
                {/* <ToggleButtonGroup
                    color="mlBlue"
                    value={toggles}
                    onChange={handleToggles}
                >  
                    <ToggleButton value="toggle1" sx={{display: title !== "Home" ? "none" : "flex"}} size={isSmall ? "small" : "medium"} onClick={gridOneHidden ? () => setGridOneHidden(false) : () => setGridOneHidden(true)}>
                        <WorkHistoryIcon />
                    </ToggleButton>
                    <ToggleButton value="toggle2" sx={{display: title !== "Home" ? "none" : "flex"}} size={isSmall ? "small" : "medium"} onClick={gridTwoHidden ? () => setGridTwoHidden(false) : () => setGridTwoHidden(true)}>
                        <ArticleIcon />
                    </ToggleButton>
                    <ToggleButton value="toggle3" sx={{display: title !== "Home" ? "none" : "flex"}} size={isSmall ? "small" : "medium"} onClick={gridThreeHidden ? () => setGridThreeHidden(false) : () => setGridThreeHidden(true)}>
                        <GroupIcon />
                    </ToggleButton>
                </ToggleButtonGroup> */}
                <IconButton sx={{display: title !== "Home" ? "none" : "flex", border: "0.5px solid white", borderRadius: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0}} size={isSmall ? "small" : "medium"} onClick={gridOneHidden ? () => setGridOneHidden(false) : () => setGridOneHidden(true)}>
                    <WorkHistoryIcon color={gridOneHidden ? "darkBlue" : "mlBlue"} sx={{ color: "mlBlue"}} />
                </IconButton>
                <IconButton sx={{display: title !== "Home" ? "none" : "flex", border: "0.5px solid white", borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0}} size={isSmall ? "small" : "medium"} onClick={gridTwoHidden ? () => setGridTwoHidden(false) : () => setGridTwoHidden(true)}>
                    <ArticleIcon color={gridTwoHidden ? "darkBlue" : "mlBlue"} sx={{ color: "mlBlue" }}/>
                </IconButton>
                <IconButton sx={{display: title !== "Home" ? "none" : "flex", border: "0.5px solid white", borderRadius: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} size={isSmall ? "small" : "medium"} onClick={gridThreeHidden ? () => setGridThreeHidden(false) : () => setGridThreeHidden(true)}>
                    <GroupIcon color={gridThreeHidden ? "darkBlue" : "mlBlue"} />
                </IconButton>
                {/* Show offline on appBar if offline */}
                <ShowOfflineStatus />
                {/* InfoButton in Appbar */}

                <InfoModal />
                {/* ProfileMenu */}
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    edge="end"
                >
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        lastseenminutes={lastseenminutes}
                        userOnlineData={bulkData?.user}
                    >
                        <SmallAvatarWrapper alt="ProfilePic" userId={user.id} imageSize="_64" />
                    </StyledBadge>
                </IconButton>
                {/* ProfileMenu content */}
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose} component={Link} to={`/profile/${user.id}`}>
                        {/* <StyledBadgeAvatar /> */}
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            lastseenminutes={lastseenminutes}
                            userOnlineData={bulkData?.user}
                        >
                            <SmallAvatarWrapper alt="ProfilePic" userId={user.id} imageSize="_64" />
                        </StyledBadge>
                        <Typography component={"div"} sx={{ marginLeft: 2 }}>
                            {first_name && last_name ? `${first_name} ${last_name}` : username}
                        </Typography>
                        {/* Hide Profile online status from profile menu */}
                        {/* <Grid item xs={12} sx={{ textAlign: "center" }}>
                                        {status ? 'Online' : 'Offline'}
                                    </Grid> */}
                    </MenuItem >
                    {/* ProfileMenu online switch, not used */}
                    {/* <MenuItem sx={{ justifyContent: "space-between" }}>Online <OnlineSwitch checked={status} onChange={handleStatus} /></MenuItem > */}

                    <MenuItem onClick={handleClose} component={Link} to={`/termsconditions`} target="_blank" rel="noopener noreferrer" sx={{ marginTop: 1, paddingY: 1 }}>
                        <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <GavelIcon sx={{ marginRight: 1, color: "#000" }} />Terms & Conditions
                        </Typography>
                    </MenuItem>
                    <Divider />

                    {/* Go Busy */}
                    <Accordion onChange={(e, expanded) => setBusyExpanded(expanded)} elevation={0} sx={{ width: 240, '&:hover': { backgroundColor: "#f5f5f5" }, marginTop: -1 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{}}
                        >
                            <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <DoNotDisturbOnTotalSilenceIcon color="mlRed" sx={{ marginRight: 1 }} />Go Busy
                                {busyExpanded && <HelperIcon content={content} style={{ marginLeft: 10, display: "flex" }} />}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{}}>
                            {nowHidden ?
                                <>
                                    <Typography component={"div"} sx={{ textAlign: "center" }}>
                                        <div>
                                            You are {status ? "hidden" : "busy"} for
                                        </div>
                                        <div>
                                            {Math.floor(hideDuration / 60) + 'h ' + Math.floor(hideDuration % 60) + 'minutes'}
                                        </div>
                                    </Typography>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button variant='contained' onClick={handleStatusCancel} sx={{ top: 10 }}>
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <HiddenTimeSlider handleStatus={handleBusyTime} name="busy" />
                                    {busyTime > 0 &&
                                        <>
                                            <Typography component={"div"} sx={{ textAlign: "center" }}>
                                                {busyTime > 60 ?
                                                    'Go busy for ' + Math.floor(busyTime / 60) + 'h ' + busyTime % 60 + 'min'
                                                    :
                                                    'Go busy for ' + busyTime + 'min'
                                                }
                                            </Typography>
                                            <Button variant="contained" sx={{ float: "right", marginTop: 2 }} onClick={handleStatusBusy}>
                                                Go
                                            </Button>
                                        </>
                                    }
                                </>
                            }
                        </AccordionDetails>
                    </Accordion>

                    {/* Go Hidden */}
                    <Accordion onChange={(e, expanded) => setHiddenExpanded(expanded)} elevation={0} sx={{ width: 240, '&:hover': { backgroundColor: "#f5f5f5" } }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{}}
                        >
                            <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <VisibilityOffIcon sx={{ marginRight: 1, color: "#808080" }} />Go Hidden
                                {hiddenExpanded && <HelperIcon content={contentHidden} style={{ marginLeft: 10, display: "flex" }} />}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{}}>
                            {nowHidden ?
                                <>
                                    <Typography component={"div"} sx={{ textAlign: "center" }}>
                                        <div>
                                            You are {status ? "hidden" : "busy"} for
                                        </div>
                                        <div>
                                            {Math.floor(hideDuration / 60) + 'h ' + Math.floor(hideDuration % 60) + 'minutes'}
                                        </div>
                                    </Typography>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button variant='contained' onClick={handleStatusCancel} sx={{ top: 10, marginBottom: 2 }}>
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <HiddenTimeSlider handleStatus={handleHiddenTime} name="hidden" />
                                    {hiddenTime > 0 &&
                                        <>
                                            <Typography component={"div"} sx={{ textAlign: "center" }}>
                                                {hiddenTime > 60 ?
                                                    'Go hidden for ' + Math.floor(hiddenTime / 60) + 'h ' + hiddenTime % 60 + 'min'
                                                    :
                                                    'Go hidden for ' + hiddenTime + 'min'
                                                }
                                            </Typography>
                                            <Button variant="contained" sx={{ float: "right", marginY: 2 }} onClick={handleStatusHidden}>
                                                Go
                                            </Button>
                                        </>

                                    }
                                </>
                            }
                        </AccordionDetails>
                    </Accordion>

                    <Divider />
                    <MenuItem onClick={handleLogout} sx={{ alignItems: "center", justifyContent: "center" }}>
                        <LogoutIcon sx={{ color: "#808080", marginRight: 1 }} />Logout
                    </MenuItem >
                </Menu>
            </Toolbar>
        </MuiAppBar>
    );
});