import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import List from '@mui/material/List';
import { Link } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box } from '@mui/material';
import { Divider, Tooltip, Typography, Badge } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { MainContext, SmallAvatarWrapper, StyledBadgeAvatar } from 'kerberus-components';
import { ListItemAvatar } from '@mui/material';
import moment from 'moment';
import useSWR from 'swr';
import PeopleIcon from '@mui/icons-material/People';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TokenIcon from '@mui/icons-material/Token';

const itemGroup = {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
    pl: 8
};

const item = {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
    pl: 4
};

const subtitle = {
    marginLeft: -2
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    px: 3,
    py: 1.5
};

const subHeader = {
    backgroundColor: "#081627",
    color: "#FFFFFF",
    fontSize: 18,
    '&:hover': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

function getActiveState(utc_start, utc_end) {
    let [hoursEnd, minsEnd] = utc_end && utc_end.split(':');
    let [hours, mins] = utc_start.split(':');
    let yesterday = moment.utc().startOf('day').subtract(1, 'day');
    let today = moment.utc().startOf('day');
    let tomorrow = moment.utc().startOf('day').add(1, 'day');


    if (hours > hoursEnd || (hours === hoursEnd && mins > minsEnd)) {
        let isBetween1 = moment().isBetween(yesterday.utc().set('hour', hours).set('minute', mins).local(), today.utc().set('hour', hoursEnd).set('minute', minsEnd).local());
        let isBetween2 = moment().isBetween(today.utc().set('hour', hours).set('minute', mins).local(), tomorrow.utc().set('hour', hoursEnd).set('minute', minsEnd).local());
        return (isBetween1 || isBetween2);
    }
    else {
        return (moment().isBetween(moment.utc().startOf('day').add(utc_start).local(), moment.utc().startOf('day').add(utc_end).local()));
    }
}

export default function ListItems({ userId, drawerOpen, toggleDrawer, userdata, lastseenminutes, onlineData }) {
    const { first_name, last_name, username, title, id, user_company } = userdata;
    const theme = useTheme();
    /* const isSmall = useMediaQuery(theme.breakpoints.down('sm')); */
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const [socialGroups, setSocialGroups] = useState([]);
    const [hidden, setHidden] = useState(false)
    const { bulkData } = useContext(MainContext);
    const busy = bulkData && bulkData.user?.status;
    const [onlineMembers, setOnlineMembers] = useState(0);
    const [profiles, setProfiles] = useState([]);
    const { data: groupListData } = useSWR('users/socialgrouplist/');
    const { data: profileListData } = useSWR('users/profilelistsocial/');
    const { data: grouprequestsData = [] } = useSWR('users/grouprequests/');

    useEffect(() => {
        if (groupListData) {
            setSocialGroups(groupListData.map(item => ({ ...item, open: false })));
        }
    }, [groupListData]);

    useEffect(() => {
        if (profileListData) {
            setProfiles(profileListData);
        }
    }, [profileListData]);

    const listCollapseLS = localStorage.getItem("preferences");
    const listCollapseProfile = JSON.parse(listCollapseLS)?.listCollapse1;
    const listCollapseGroups = JSON.parse(listCollapseLS)?.listCollapse2;
    const listCollapseAdmin = JSON.parse(listCollapseLS)?.listCollapse3;
    const listCollapseMyGroups = JSON.parse(listCollapseLS)?.listCollapse4;

    const [openProfile, setOpenProfile] = useState(listCollapseProfile ?? true);
    const [openGroups, setOpenGroups] = useState(listCollapseGroups ?? true);
    const [openMyGroups, setOpenMyGroups] = useState(listCollapseMyGroups ?? true);
    const [openAdmin, setOpenAdmin] = useState(listCollapseAdmin ?? true);

    useEffect(() => {
        let tempNum = 0;
        if (bulkData) {
            for (const userObj of bulkData.company) {
                const now = moment();
                const lastSeen = userObj.timestamp ? moment(userObj.timestamp) : null;
                const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                if (lastSeen && lastseenminutes <= 10) {
                    tempNum++;
                }
            }
        }
        setOnlineMembers(tempNum);
    }, [bulkData]);

    useEffect(() => {
        let currDate = new Date();
        if (userdata.user_hidden.length > 0 && !hidden) {
            for (let item of userdata.user_hidden) {
                if (new Date(item.start) < currDate && moment(item.start).add(moment.duration(item.duration)).toDate() > currDate) {
                    setHidden(item.hidden);
                    break;
                }
            }
        }
        else {
            setHidden(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userdata]);

    return (
        <>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: "#fff", marginTop: 2 }}>
                <Typography textAlign="center" underline="none" sx={{ mt: -2, mb: 1, color: "#FFFFFFB3", display: !hidden && "none" }}>
                    Hidden
                </Typography>
                <Link to={`/profile/${userdata.id}`}>
                    <StyledBadgeAvatar
                        hasHiddenBadge={hidden}
                        noTooltip
                        avatarSx={{
                            width: "10vh",
                            height: "10vh",
                            borderWidth: 3,
                            borderStyle: "solid",
                            marginBottom: 1,
                            borderColor: busy ? theme.palette.mlRed.main : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? theme.palette.mlYellow.main : lastseenminutes > -5 && lastseenminutes < 5 ? theme.palette.mlGreen.main : '#adadb8' : '#adadb8'
                        }}
                        avatarVariant="circular"
                        alt="ProfileIcon"
                        imgSrc={id}
                        lastseenminutes={lastseenminutes}
                        busy={busy}
                        imageSize="_128"
                    />
                </Link>
                <Typography component={"div"} textAlign="center" sx={{}}> {first_name && last_name ? `${first_name} ${last_name}` : username}</Typography>
                {title &&
                    <Typography component={"div"} textAlign="center" sx={{ color: "#808080", display: "inline-block", whiteSpace: "pre-line" }}>
                        {title}
                    </Typography >
                }
            </Box>
            <List>
                <div>
                    <ListItem button component={Link} onClick={toggleDrawer} to="" disablePadding sx={{ ...item, ...itemCategory }}>
                        <Tooltip title="Home" placement='right'>
                            <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                <HomeIcon />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText sx={{ ...subtitle }} primary="Home" />
                    </ListItem>
                </div>
            </List>

            <Box sx={{
                /* height: isSmall ? "40%" : "55%", */
                overflow: 'none',
                overflowX: "hidden",
                width: "100%",
                '&::-webkit-scrollbar': {
                    width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#4EC1ED',
                },
                /* borderBottom: "1px solid #212e3d", */
                paddingBottom: 5,
                scrollbarColor: `${theme.palette.mlBlue.main} ${theme.palette.darkBlue.main}`,
                scrollbarWidth: "thin"
            }}>
                <List>
                    <div>
                        <ListItemButton sx={{ ...subHeader }} onClick={() => { setOpenProfile(!openProfile); localStorage.setItem("preferences", JSON.stringify({ listCollapse1: !openProfile, listCollapse2: openGroups, listCollapse3: openAdmin, listCollapse4: openMyGroups })); }}>
                            Profile
                            {openProfile ? <ChevronRightIcon /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openProfile} timeout="auto" unmountOnExit>
                            <ListItem button component={Link} onClick={toggleDrawer} to={`/profile/${userId}`} disablePadding sx={{ ...item }} >
                                <Tooltip title="Account" placement='right'>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                        <PersonIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText sx={{ ...subtitle }} primary="Account" />
                            </ListItem>
                            <ListItem button component={Link} onClick={toggleDrawer} to={`/profile/${userId}/calendar`} disablePadding sx={{ ...item }}>
                                <Tooltip title="Calendar" placement='right'>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                        <CalendarTodayIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText sx={{ ...subtitle }} primary="Calendar" />
                            </ListItem>

                            <ListItem button component={Link} onClick={toggleDrawer} to={`/profile/${userId}/device`} disablePadding sx={{ ...item }}>
                                <Tooltip title="Device" placement='right'>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                        <DevicesOtherIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText sx={{ ...subtitle }} primary="Device" />
                            </ListItem>
                            <ListItem button component={Link} onClick={toggleDrawer} to={`/profile/${userId}/settings`} disablePadding sx={{ ...item }}>
                                <Tooltip title="Settings" placement='right'>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} >
                                        <SettingsIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText sx={{ ...subtitle }} primary="Settings" />
                            </ListItem>
                        </Collapse>

                    </div>
                </List>
                <List>
                    <div>
                        <ListItemButton sx={{ ...subHeader }} onClick={() => { setOpenGroups(!openGroups); localStorage.setItem("preferences", JSON.stringify({ listCollapse1: openProfile, listCollapse2: !openGroups, listCollapse3: openAdmin, listCollapse4: openMyGroups })); }}>
                            Groups
                            {openGroups ? <ChevronRightIcon /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openGroups} timeout="auto" unmountOnExit >
                            <ListItem button component={Link} onClick={toggleDrawer} to={`/groups/discover`} sx={{ ...item, mb: 1 }}>
                                <Tooltip title="Discover" placement='right'>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                        <GroupAddIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText sx={{ ...subtitle }} primary="Discover" />
                            </ListItem>
                            <Divider style={{ width: "100%", borderBottomColor: "#212e3d", borderBottomWidth: 1.5, marginBottom: 5, marginTop: -9 }} />

                            <ListItem button component={Link} onClick={toggleDrawer} to={`/myconnections`} sx={{ ...item, mb: 1 }}>
                                <Tooltip title="My connections" placement='right'>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                        <GroupsIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText sx={{ ...subtitle }} primary="My connections" />
                            </ListItem>
                            <Divider style={{ width: "100%", borderBottomColor: "#212e3d", borderBottomWidth: 1.5, marginBottom: 5, marginTop: -9 }} />
                            {userdata.user_company &&
                                <ListItem button component={Link} onClick={toggleDrawer} to={`/company`} disablePadding sx={{ ...itemGroup }} >
                                    <Tooltip title={user_company.name} placement='right'>
                                        <ListItemAvatar sx={{ marginLeft: -4, my: 1 }}>
                                            <SmallAvatarWrapper alt={userdata.user_company.name} sx={{ width: 30, height: 30 }}
                                                userId={userdata.user_company.id} imageType="companypic" imageSize="_32" />
                                        </ListItemAvatar>
                                    </Tooltip>
                                    <ListItemText sx={{ ...subtitle }} primary={user_company.name} secondary={`Users: ${bulkData ? onlineMembers + '/' + profiles.length : ""}`} secondaryTypographyProps={{ color: theme.palette.mlBlue.main }} />

                                </ListItem>
                            }

                            {socialGroups.length > 3
                                ?
                                <>
                                    <ListItem button sx={{ ...item, mb: 1 }}
                                        onClick={() => { setOpenMyGroups(!openMyGroups); localStorage.setItem("preferences", JSON.stringify({ listCollapse1: openProfile, listCollapse2: openGroups, listCollapse3: openAdmin, listCollapse4: !openMyGroups })); }}
                                    >
                                        <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)', marginRight: -2 }}>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        My Groups
                                        {openMyGroups ? <ChevronRightIcon /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={openMyGroups} timeout="auto" unmountOnExit >
                                        {socialGroups.length > 0 &&
                                            socialGroups.map(group => {
                                                let tempNum = 0;
                                                const groupObj = bulkData?.social_groups.find(i => i.group_id === group.id);

                                                if (groupObj) {
                                                    for (const userObj of groupObj.users) {
                                                        const now = moment();
                                                        const lastSeen = userObj.timestamp ? moment(userObj.timestamp) : null;
                                                        const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                                                        const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                                                        if (group.users.some(i => i.id === userObj.user_id) && lastSeen && lastseenminutes <= 10 && lastseenminutes > 0) {
                                                            tempNum++;
                                                        }
                                                    }
                                                }

                                                const isActive = (group.utc_start && group.utc_end) ? getActiveState(group.utc_start, group.utc_end) || group.utc_start === group.utc_end : true;
                                                const isActiveWeekday = group.enable_days.includes(moment().isoWeekday()) || group.enable_days === undefined;

                                                return (
                                                    <ListItem key={group.id} button component={Link} onClick={toggleDrawer} to={`/groups/${group.id}`} disablePadding sx={{ ...itemGroup }}>
                                                        <Tooltip title={group.name} placement='right'>
                                                            <ListItemAvatar sx={{ marginLeft: -4, my: 1 }}>
                                                                <Badge badgeContent={grouprequestsData?.filter(item => item.social_group === group.id && item).length} color="error"
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}>
                                                                    <SmallAvatarWrapper alt="GroupPic"
                                                                        sx={{
                                                                            width: 30,
                                                                            height: 30,
                                                                            borderWidth: 2,
                                                                            borderStyle: "solid",
                                                                            borderColor: (isActive && isActiveWeekday) ? theme.palette.mlGreen.main : theme.palette.mlRed.main
                                                                        }}
                                                                        userId={group.id} imageType="socialgrouppic" imageSize="_32" />
                                                                </Badge>
                                                            </ListItemAvatar>
                                                        </Tooltip>
                                                        <ListItemText sx={{ ...subtitle }} primary={group.name} secondary={`Users: ${bulkData ? tempNum + '/' + group.users.length : group.users.length}`} secondaryTypographyProps={{ color: theme.palette.mlBlue.main }} />

                                                    </ListItem>
                                                );
                                            }


                                            )
                                        }
                                    </Collapse>
                                </>
                                :
                                socialGroups.length > 0 &&
                                socialGroups.map((group, index) => {
                                    let tempNum = 0;
                                    const groupObj = bulkData?.social_groups.find(i => i.group_id === group.id);

                                    if (groupObj) {
                                        for (const userObj of groupObj.users) {
                                            const now = moment();
                                            const lastSeen = userObj.timestamp ? moment(userObj.timestamp) : null;
                                            const duration = lastSeen ? moment.duration(now.diff(lastSeen)) : null;
                                            const lastseenminutes = lastSeen ? duration.asMinutes() : null;
                                            if (group.users.some(i => i.id === userObj.user_id) && lastSeen && lastseenminutes <= 10 && lastseenminutes > 0) {
                                                tempNum++;
                                            }
                                        }
                                    }

                                    const isActive = (group.utc_start && group.utc_end) ? getActiveState(group.utc_start, group.utc_end) || group.utc_start === group.utc_end : true;
                                    const isActiveWeekday = group.enable_days.includes(moment().isoWeekday()) || group.enable_days === undefined;

                                    return (
                                        <ListItem key={group.id} button component={Link} onClick={toggleDrawer} to={`/groups/${group.id}`} disablePadding sx={{ ...itemGroup }}>
                                            <Tooltip title={group.name} placement='right'>
                                                <ListItemAvatar sx={{ marginLeft: -4, my: 1 }}>
                                                    <Badge badgeContent={grouprequestsData?.filter(item => item.social_group === group.id && item).length} color="error"
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}>
                                                        <SmallAvatarWrapper alt="GroupPic"
                                                            sx={{
                                                                width: 30,
                                                                height: 30,
                                                                borderWidth: 2,
                                                                borderStyle: "solid",
                                                                borderColor: (isActive && isActiveWeekday) ? theme.palette.mlGreen.main : theme.palette.mlRed.main
                                                            }}
                                                            userId={group.id} imageType="socialgrouppic" imageSize="_32" />
                                                    </Badge>
                                                </ListItemAvatar>
                                            </Tooltip>
                                            <ListItemText sx={{ ...subtitle }} primary={group.name} secondary={`Users: ${bulkData ? tempNum + '/' + group.users.length : group.users.length}`} secondaryTypographyProps={{ color: theme.palette.mlBlue.main }} />

                                        </ListItem>
                                    );
                                }


                                )

                            }

                        </Collapse>

                    </div>
                </List>
                {/* Admin section hidden */}
                {
                    userdata && (userdata.perm_level === "superuser" || userdata.perm_level === "admin") &&
                    <List>
                        <div>
                            <ListItemButton sx={{ ...subHeader }} onClick={() => { setOpenAdmin(!openAdmin); localStorage.setItem("preferences", JSON.stringify({ listCollapse1: openProfile, listCollapse2: openGroups, listCollapse3: !openAdmin, listCollapse4: openMyGroups })); }}>
                                Admin
                                {openAdmin ? <ChevronRightIcon /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                                {userdata.perm_level === "superuser" &&
                                    <ListItem button component={Link} onClick={toggleDrawer} to={`/admin/calendar`} disablePadding sx={{ ...item }}>
                                        <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                            <DateRangeIcon />
                                        </ListItemIcon>
                                        <ListItemText sx={{ ...subtitle }} primary="Device Calendar" />
                                    </ListItem>
                                }
                                <ListItem button component={Link} onClick={toggleDrawer} to={`/admin/users`} disablePadding sx={{ ...item }}>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                        <GroupsIcon />
                                    </ListItemIcon>
                                    <ListItemText sx={{ ...subtitle }} primary="Users" />

                                </ListItem>
                                {!isLarge &&
                                    <ListItem button component={Link} onClick={toggleDrawer} to={`/admin/dashboard`} disablePadding sx={{ ...item }}>
                                        <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                            <DashboardCustomizeIcon />
                                        </ListItemIcon>
                                        <ListItemText sx={{ ...subtitle }} primary="Dashboard Maker" />

                                    </ListItem>
                                }
                                <ListItem button component={Link} onClick={toggleDrawer} to={`/admin/socialgroup`} disablePadding sx={{ ...item }}>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                        <GroupAddIcon />
                                    </ListItemIcon>
                                    <ListItemText sx={{ ...subtitle }} primary="Social groups" />

                                </ListItem>
                                {userdata.next && <ListItem button component={Link} onClick={toggleDrawer} to={`/admin/tokens`} disablePadding sx={{ ...item }}>
                                    <ListItemIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                        <TokenIcon />
                                    </ListItemIcon>
                                    <ListItemText sx={{ ...subtitle }} primary="Integrations" />
                                </ListItem>}
                            </Collapse>
                        </div>
                    </List>
                }
                {/*                 {(userdata.next && socialGroups.length > 0) &&
                    <List>
                        <div>
                            {drawerOpen && !isSmall &&
                                <ListItemButton sx={{ ...subHeader }} onClick={() => setOpen4(!open4)} >
                                    Socials
                                    {open4 ? <ChevronRightIcon /> : <ExpandMore />}
                                </ListItemButton>}
                            <Collapse in={open4 || !drawerOpen} timeout="auto" unmountOnExit>
                                {socialGroups.map((item, index) => {
                                    return (
                                        <div style={{ marginTop: 10 }} key={index}>
                                            <ListItemButton sx={drawerOpen && { ...subHeader }} onClick={() => {
                                                setSocialGroups(prev => {
                                                    let temp = [...prev];
                                                    let idx = temp.findIndex(i => i.id === item.id);
                                                    temp[idx].open = !temp[idx].open;
                                                    return temp;
                                                });
                                            }} key={id}>
                                                <Tooltip title={item.name} placement='right'>
                                                    <ListItemAvatar>
                                                        <Avatar alt="Remy Sharp"
                                                            src={index > 0
                                                                ?
                                                                index === 1
                                                                    ?
                                                                    "https://wallpaperping.com/wp-content/uploads/2018/06/cool-wallpapers.jpg"
                                                                    :
                                                                    "https://pricespy-75b8.kxcdn.com/product/standard/280/497968.jpg"
                                                                :
                                                                "https://www.missing-link.fi/wp-content/themes/missing-link/assets/img/phone-app.jpg"} />
                                                    </ListItemAvatar>
                                                </Tooltip>
                                                {drawerOpen &&
                                                    <ListItemText sx={{ ...subtitle }}align="left" primary={item.name} secondary={`Users: ${item.users.length - 1}`} secondaryTypographyProps={{ color: theme.palette.mlBlue.main }} sx={{ color: "#fff" }} />
                                                }
                                                {!item.open ? <ChevronRightIcon /> : <ExpandMore />}
                                            </ListItemButton>

                                            <Collapse in={item.open} timeout="auto" unmountOnExit sx={{ marginTop: 1 }}>
                                                {item.users.map((item, index2) => {
                                                    if (item.id !== id) {
                                                        return (
                                                            <ListItem key={index2} button component={Link} onClick={toggleDrawer} to={`/profile/${item.id}`} disablePadding sx={{
                                                                marginBottom: 1, color: "#fff",
                                                                '&:hover, &:focus': {
                                                                    bgcolor: 'rgba(255, 255, 255, 0.08)',
                                                                },
                                                            }}>
                                                                <Tooltip title={item.first_name ? item.first_name : item.username} placement='right'>
                                                                    <ListItemAvatar sx={{ position: "relative", left: "25%" }}>
                                                                        <SmallAvatarWrapper alt="ProfilePic" userId={item.id} sx={{ width: 32, height: 32 }} />
                                                                    </ListItemAvatar>
                                                                </Tooltip>
                                                                {drawerOpen &&
                                                                    <ListItemText sx={{ ...subtitle }}align="center" primary={item.first_name ? item.first_name : item.username} />
                                                                }

                                                            </ListItem>
                                                        );
                                                    }
                                                    else return null;
                                                }
                                                )
                                                }
                                            </Collapse>
                                        </div>
                                    );
                                }
                                )}
                            </Collapse>

                        </div>
                    </List>
                } */}
            </Box >
            {/*             {
                userdata.next && !isSmall &&
                <MuiLink href="https://www.google.com/" target="_blank" rel="noopener noreferrer"
                    sx={{
                        color: theme.palette.mlBlue.main,
                        textDecoration: "underline",
                        fontSize: 19,
                        position: "absolute",
                        bottom: "3vh",
                        display: "flex",
                        alignSelf: "center",
                        justifySelf: "center"
                    }}>
                    Missing-Link Manuals
                </MuiLink>
            }; */}
        </>
    );
}