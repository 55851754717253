import * as React from 'react';
import { useContext } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { Typography, useTheme, useMediaQuery, Box } from '@mui/material';
import SocialIcons from '../../Components/SocialIcons';
import ProfileMenu from '../../Components/Profile/ProfileMenu';
import { useParams } from "react-router-dom";
import { MainContext } from 'kerberus-components';

export default function ProfileText({ userData, onlineData, lastseenminutes }) {
    const { userId } = useParams();
    const { first_name, last_name, username, title } = userData;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useContext(MainContext);

    return (
        <Card elevation={isSmall ? 0 : 3} sx={{
            backgroundColor: "#fff", display: "flex", width: "100%", alignItems: "center", justifyContent: "center",/* paddingTop: isSmall ? 2 : 5, paddingBottom: 3, */ minHeight: 240, position: "relative", paddingTop: 2, paddingBottom: 2
        }}>


            <Grid container spacing={2} sx={{ textAlign: "center" }} >

                <Box sx={isSmall ? { display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginLeft: 2 } : { position: "absolute", display: "flex", flexDirection: "column", top: 10, left: 10 }}>
                    <SocialIcons user={userData} />
                </Box>

                {userId !== user.id &&
                    <ProfileMenu user={userData} userId={userId} sx={isSmall ? { width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" } : { position: "absolute", right: 0, top: 0 }} />
                }

                <Grid item xs={6} sx={{ color: "#000000" }}>
                    <Typography component={"div"} variant="h5">
                        Name:
                    </Typography>
                </Grid>
                <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }}>
                    <Typography component={"div"} variant="h5">
                        {first_name && last_name ? `${first_name} ${last_name}` : username}
                    </Typography>
                </Grid>
                {title &&
                    <>
                        <Grid item xs={6} sx={{ color: "#000000" }}>
                            <Typography component={"div"} variant="h5">
                                Title:
                            </Typography>
                        </Grid>
                        <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }}>
                            <Typography component={"div"} variant="h5">
                                {title}
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={6} sx={{ color: "#000000" }}>
                    <Typography component={"div"} variant="h5">
                        Status:
                    </Typography>
                </Grid>
                <Grid item xs={isSmall ? 6 : 5} sx={{
                    height: "5vh",
                    color: onlineData?.status === 'busy'
                        ? theme.palette.mlRed.main
                        : onlineData?.status === 'hidden'
                            ? '#adadb8'
                            : lastseenminutes <= 10 && lastseenminutes >= 5
                                ? theme.palette.mlYellow.main
                                : lastseenminutes > 0 && lastseenminutes < 5
                                    ? theme.palette.mlGreen.main
                                    : '#adadb8',
                    fontWeight: "bold"
                }}>
                    <Typography component={"div"} variant="h5">
                        {onlineData?.status === 'busy'
                            ? 'Busy'
                            : onlineData?.status === 'hidden'
                                ? 'Hidden'
                                : lastseenminutes <= 10 && lastseenminutes >= 5
                                    ? 'Away'
                                    : lastseenminutes && lastseenminutes > -5 && lastseenminutes < 5
                                        ? 'Active'
                                        : 'Offline'}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ color: "#000000" }}>
                    <Typography component={"div"} variant="h5">
                        Site:
                    </Typography>
                </Grid>
                <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }}>
                    <Typography component={"div"} variant="h5" >
                        {onlineData?.site_name ? onlineData.site_name : "Unknown"}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ color: "#000000" }}>
                    <Typography component={"div"} variant="h5">
                        Last seen:
                    </Typography>
                </Grid>
                <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }}>
                    <Typography component={"div"} variant="h5" >
                        {onlineData ? onlineData?.timestamp ? moment(onlineData.timestamp).fromNow() : '> 1 week' : 'Long time ago'}
                    </Typography>
                </Grid>
                {onlineData?.layer_name && onlineData.layer_name !== "Null" &&
                    <>
                        <Grid item xs={6} sx={{ color: "#000000" }}>
                            <Typography component={"div"} variant="h5">
                                Location:
                            </Typography>
                        </Grid>
                        <Grid item xs={isSmall ? 6 : 5} sx={{ color: "#808080" }}>
                            <Typography component={"div"} variant="h5">
                                {onlineData.layer_name}
                            </Typography>
                        </Grid>
                    </>
                }
            </ Grid>
        </Card >
    );
}