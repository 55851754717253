import { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import 'moment/locale/en-gb';
import useMediaQuery from '@mui/material/useMediaQuery';
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarToolbar from '../../Components/CalendarToolbar';
import { useTheme } from '@mui/material/styles';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import OnlineHours from '../Profile/OnlineHours';
import OfflineHours from '../Profile/OfflineHours';
import { Paper, Typography, Box, Modal, Grid, IconButton, Card } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#1C4E71',
    boxShadow: 24,
    p: 4,
    paddingBottom: 6
};

const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#1C4E71',
    boxShadow: 24,
    p: 4,
    paddingBottom: 6
};

const localizer = momentLocalizer(moment);
const dayFormat = (date, culture, localizer) => localizer.format(date, 'D', culture);

function EventComponentday({ title }) {
    return <Typography component={"div"} sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>{title}</Typography>;

}

function EventComponentweek({ title }) {
    return <Typography component={"div"} sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>{title}</Typography>;

}

export default memo(function DeviceCalendar({ devGroup, providerData, calDate, setCalDate }) {
    const [events, setEvents] = useState([]);
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [calView, setCalView] = useState('day');
    const [open, setOpen] = useState(false);
    const handleOpen = (e) => {
        setCurrentEvent(e);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const [currentEvent, setCurrentEvent] = useState();
    const handleTime = useMemo(() => {
        let curr = moment(calDate).toDate();
        let minus = moment(calDate).subtract(2, 'hours').toDate();
        return curr.getDate() === minus.getDate() ? minus : curr;

    }, [calDate]);

    const navigateFunction = (date) => {
        setCalDate(date);
    };

    const parseEvents = useCallback((data) => {
        const events = [];

        for (const site of data) {
            for (const item of site.online_data) {
                const date = new Date(item.time);
                if (events.length > 0 && events[events.length - 1].title === site.site_name && events[events.length - 1].end.getTime() === date.getTime() && events[events.length - 1].start.getDay() === date.getDay()) {
                    events[events.length - 1].end = new Date(events[events.length - 1].end.getTime() + 30 * 60000);
                }
                else {
                    events.push({
                        title: site.site_name,
                        start: date,
                        end: new Date(date.getTime() + 30 * 60000),
                        item: item.act
                    });
                }
            }
        }

        setEvents(events);
    }, []);

    const deviceHours = providerData?.map(item => item.online_data).flat().length * 30

    useEffect(() => {
        if (providerData) {
            parseEvents(providerData);
        }
    }, [providerData, parseEvents]);

    useEffect(() => {
        isMedium ? setCalView('day') : setCalView('week');
    }, [isMedium]);

    return (
        <Box sx={{ bgcolor: 'background.paper', overflowY: "hidden", position: "relative" }}>

            <IconButton disableFocusRipple disableRipple sx={{ position: "absolute", top: -15, left: -15}} onClick={() => navigate(-1)}>
                <ArrowBackIcon color="primary" sx={{ width: 40, height: 40 }} />
            </IconButton>
            <Grid container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    px: "10%",
                    mb: 1
                }}>

                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Paper elevation={isMedium ? 0 : 5}
                        sx={{
                            maxWidth: "100%",
                            backgroundColor: theme.palette.primary.main + "70",
                            display: isMedium ? "flex" : "none",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0.5,
                            paddingX: 2,
                            borderRadius: 7,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: theme.palette.primary.main + 95,
                        }}>
                        <PhoneAndroidIcon />
                        <Typography component={"div"} variant="h6" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", color: theme.palette.primary.main, marginLeft: 1, fontWeight: "bold" }}>
                            {devGroup.group_name}
                        </Typography>
                    </Paper>
                </Grid>
                
                <Grid item xs={ isMedium ? 6 : "auto" }>
                    <Card elevation={isMedium ? 0 : 1} sx={{ p: 2 }}>
                        <Typography fontSize={ isMedium && "12px" } textAlign="center">
                            Weekly online hours:
                            <OnlineHours deviceHours={deviceHours} isMedium={isMedium}/>
                        </Typography> 
                    </Card>
                </Grid>

                <Box component={Paper} elevation={ isMedium ? 0 : 5 }
                    sx={{
                        maxWidth: isMedium ? 114 : '100%',
                        backgroundColor: theme.palette.primary.main + "70",
                        display: isMedium ? "none" : "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0.5,
                        paddingX: 2,
                        borderRadius: 7,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: theme.palette.primary.main + 95,
                    }}>
                    <PhoneAndroidIcon />
                    <Typography component={"div"} variant="h6" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", color: theme.palette.primary.main, marginLeft: 1, fontWeight: "bold" }}>
                        {devGroup.group_name}
                    </Typography>
                </Box>

                <Grid item xs={isMedium ? 6 : "auto"}>
                    <Card elevation={isMedium ? 0 : 1} sx={{p: 2}}>
                        <Typography fontSize={ isMedium && "12px" } textAlign="center">
                            Weekly offline hours:
                            <OfflineHours deviceHours={deviceHours} isMedium={isMedium} />
                        </Typography> 
                    </Card>
                </Grid>

            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={isMedium ? mobileStyle : style}>
                    <Typography component={"div"} id="modal-modal-title" variant="h6" sx={{ textAlign: "center", marginTop: -2, marginBottom: 2, textDecoration: "underline" }}>
                        {currentEvent && currentEvent.title === "Hidden" ? "Hidden" : "Devices:"}
                    </Typography>
                    {currentEvent && currentEvent.item.map((item, idx) => {
                        return (
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography component={"div"} key={idx} id="modal-modal-title" variant="h6" sx={{ textAlign: "center" }}>
                                    MAC: {item}
                                </Typography>
                            </Box>
                        );
                    })}
                    <Typography component={"div"} id="modal-modal-title" variant="h6" sx={{ textAlign: "center", position: "absolute", bottom: 0, right: 5 }}>
                        {currentEvent && moment.duration(moment(currentEvent.end).diff(moment(currentEvent.start))).asMinutes()} min
                    </Typography>
                    <Typography component={"div"} id="modal-modal-title" variant="h6" sx={{ textAlign: "center", position: "absolute", bottom: 0, left: 5 }}>
                        During:  {currentEvent && moment(currentEvent.start).format('H:mm')} - {currentEvent && moment(currentEvent.end).format('H:mm')}
                    </Typography>
                </Box>
            </Modal>

            <Calendar
                defaultDate={calDate}
                defaultView={calView}
                onView={(e) => setCalView(e)}
                view={calView}
                views={['day', 'week']}
                startAccessor="start"
                endAccessor={({ end }) => new Date(end.getTime() - 1)}
                events={events}
                tooltipAccessor={(e) => e.title === "Hidden" ? "Hidden" : `Devices: ${e.title}`}
                localizer={localizer}
                resizable
                scrollToTime={handleTime}
                onSelectEvent={handleOpen}
                timeslots={1}
                style={{
                    height: "65vh", width: "100%"
                }}
                formats={{
                    dayFormat,
                    eventTimeRangeFormat: (e) => { return ""; }
                }}
                components={{
                    toolbar: isMedium ? CalendarToolbar : null,
                    day: { event: EventComponentday },
                    week: { event: EventComponentweek }
                }}
                dayLayoutAlgorithm="no-overlap"
                onNavigate={navigateFunction}
                eventPropGetter={
                    (event, start, end, isSelected) => {
                        let newStyle = {
                            backgroundColor: event.title === 'Hidden' ? '#808080' : '#8DBF30',
                            color: event.title === 'Hidden' ? '#808080' : '#FFFFFF',
                            borderRadius: 10,
                            border: "none",
                            textAlign: "center",
                            marginLeft: 5,
                            paddingLeft: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        };
                        return {
                            className: "",
                            style: newStyle
                        };
                    }
                }
            />

        </Box>
    );
})
