import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import Label from '@mui/icons-material/Label';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTheme, Grid, Divider, Tooltip, Link as MuiLink, Button, Modal, IconButton, Checkbox, useMediaQuery, Menu, MenuItem, Avatar } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { SmallAvatarWrapper, useFetchCloud, MainContext } from 'kerberus-components';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardModalCompany from './DashboardModalCompany.js';
import DashboardModalSocialGroup from './DashboardModalSocialGroup.js';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import useSWR, { useSWRConfig } from 'swr';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import { toast } from "react-toastify";
import HistoryIcon from '@mui/icons-material/History';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ConfirmationDialog from './ConfirmationDialog.js';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { Virtuoso } from 'react-virtuoso';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

/* import PendingIcon from '@mui/icons-material/Pending';

const ScrollSeekPlaceholder = () => (
    <ListItem alignItems="flex-start">
        <ListItemIcon>
            <PendingIcon />
        </ListItemIcon>
        <ListItemText
            primary="Scrolling"
        />
    </ListItem>
); */

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    height: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

/*
# Valid types:
# 1: User joined company
# 2: User joined social group
# 3: User alarm triggered
# 4: Invited to social group
# 5: Pending social group request
# 6: Social group settings changed
# 7: Social group dashboard changed
# 8: Company dashboard changed
# 9: Social group public state changed
# 10: User left company
# 11: Emoji message
# 12: Respa message
# 13: Custom Operator/Admin Message
*/

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(0),
        },
    },
}));

function StyledTreeItem(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    /* const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isXl = useMediaQuery(theme.breakpoints.down('xl')); */
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));


    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0, pl: 0, ml: -1 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    {!isSmall &&
                        <>
                            <Typography component={"div"} variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                {labelText}
                            </Typography>
                            {!isLarge &&
                                <Typography component={"div"} variant="caption" color="inherit" sx={{ position: "relative", top: 2 }}>
                                    {labelInfo}
                                </Typography>
                            }
                        </>
                    }
                </ Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.number,
    labelText: PropTypes.string.isRequired,
};


export default function ActivityLog(props) {
    const { fetchCloud } = useFetchCloud();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [activityLog, setActivityLog] = useState([]);
    const [activeFilters, setActiveFilters] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]);
    const [openCompany, setOpenCompany] = useState(false);
    const [openGroup, setOpenGroup] = useState(false);
    const [dashboardContent, setDashboardContent] = useState(null);
    const { user } = useContext(MainContext);
    const newNotif = activityLog.length - activityLog.map(item => item.id).reduce((a, c) => a + user.read_logs.includes(c), 0);
    const { data: activitylogData } = useSWR(props.groupId ? `users/activitylog/?social_group=${props.groupId}` : 'users/activitylog/', {
        refreshInterval: 60000
    });
    const { mutate } = useSWRConfig();
    const [toHistory, setToHistory] = useState([]);
    const [openHistory, setOpenHistory] = useState(false);
    const [history, setHistory] = useState(false);

    const [openTypesMenu, setOpenTypesMenu] = useState(null);
    const openTypesMobile = Boolean(openTypesMenu);
    const handleClickTypes = (event) => {
        setOpenTypesMenu(event.currentTarget);
    };
    const handleCloseTypes = () => {
        setOpenTypesMenu(null);
    };

    const [openHistoryMenu, setOpenHistoryMenu] = useState(null);
    const openHistoryMobile = Boolean(openHistoryMenu);
    const handleClickHistory = (event) => {
        setOpenHistoryMenu(event.currentTarget);
    };
    const handleCloseHistory = () => {
        setOpenHistoryMenu(null);
    };

    useEffect(() => {
        if (activitylogData) {
            setActivityLog(activitylogData);
        }
    }, [activitylogData]);

    const handleGroupRequest = async (request_id, accept) => {
        try {
            await fetchCloud('users/handle_group_request', 'POST', {
                request_id: request_id,
                accept: accept
            });
        }
        catch (err) {
            console.error(err);
        }
    };

    const pushToHistory = (event) => {
        var historyList = [...toHistory];
        if (event.target.checked) {
            historyList = [...toHistory, parseInt(event.target.value)];
        } else {
            historyList.splice(toHistory.indexOf(parseInt(event.target.value)), 1);
        }
        setToHistory(historyList);
    };

    const pushAllToHistory = () => {
        var activeLogs = activityLog.length > 0 && activityLog.filter(item => !user.history_logs.includes(item.id) && activeFilters.includes(item.type)).map(item => item.id);
        setToHistory(activeLogs);
    };

    const handleRead = async (e) => {
        await fetchCloud('users/set_read_logs', 'POST', {
            one: e
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    const handleHistoryMany = async (e) => {
        await fetchCloud('users/set_history_logs', 'POST', {
            many: toHistory
        });
        handleReadMany();
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    const handleReadMany = async () => {
        await fetchCloud('users/set_read_logs', 'POST', {
            many: toHistory
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    const handleReadAll = async () => {
        await fetchCloud('users/set_read_logs', 'POST', {
            all: true
        });
        mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    /* auto move single or all logs to history  */

    /*
    const handleHistory = async (e) => {
                await fetchCloud('users/set_history_logs', 'POST', {
                    one: e
                });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };

    const handleHistoryAll = async (e) => {
                await fetchCloud('users/set_history_logs', 'POST', {
                    all: true
                });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }; */

    /* unread notfications & remove all from history */

    /*     const handleUnread = async (e) => {
                await fetchCloud('users/set_read_logs', 'POST', {
                    remove: e
                });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        };

    const handleUnreadAll = async () => {
                await fetchCloud('users/set_read_logs', 'POST', {
                    empty: true
                });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };
    const handleHistoryRemoveMany = async (e) => {
                await fetchCloud('users/set_history_logs', 'POST', {
                    remove_many: user.history_logs
                });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
    };
            */

    const contentHistory = (
        <>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography component={"div"} variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }} >
                Move to history
            </Typography>
            <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
                Logs selected: <span style={{ fontWeight: "bold" }}>{toHistory?.length}</span>
            </Typography>
            <br />
            <Divider sx={{ marginBottom: 2 }} />
            <Typography component={"div"} style={{ overflowWrap: "break-word", fontWeight: "bold", fontSize: 16 }}>
                All selected logs are moved to the history section on the left side of this activity log.
                You can then view them there and they will no longer appear here in the main view.
                However, you cannot move logs from history back to the main view.
            </Typography>
        </>

    );

    const virtList = activityLog.filter(item => ((history && user.history_logs.includes(item.id)) || (!history && !user.history_logs.includes(item.id))) && activeFilters.includes(item.type));


    return (
        <Grid container sx={{ height: "100%", width: "100%" }}>
            {!isSmall &&
                <Grid item xs={2.5} sx={{ borderRightColor: theme.palette.primary.main, borderRightWidth: "5px", borderRightStyle: "solid" }}>
                    <TreeView
                        aria-label="gmailLike"
                        defaultExpanded={['2', '8']}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                        sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                    >

                        <StyledTreeItem
                            sx={{ marginTop: 2 }}
                            nodeId="1"
                            labelText="All Activity"
                            labelIcon={ChatIcon}
                            labelInfo={activityLog.filter(x => !user.history_logs.includes(x.id) && x).length}
                            color={theme.palette.primary.main}
                            bgColor="#e8f0fe"
                            onClick={() => { setActiveFilters([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]); setHistory(false); }}
                        />
                        <StyledTreeItem nodeId="2" labelText="Types" labelIcon={Label} >
                            <StyledTreeItem
                                nodeId="3"
                                labelText="Company"
                                labelIcon={ApartmentIcon}
                                labelInfo={activityLog.filter(x => !user.history_logs.includes(x.id) && (x.type === 1 || x.type === 8)).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([1, 8, 10]); setHistory(false); }}
                            />
                            <StyledTreeItem
                                nodeId="4"
                                labelText="Groups"
                                labelIcon={GroupIcon}
                                labelInfo={activityLog.filter(x => !user.history_logs.includes(x.id) && (x.type === 2 || x.type === 4 || x.type === 6 || x.type === 7 || x.type === 9)).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([2, 4, 6, 7, 9]); setHistory(false); }}
                            />
                            <StyledTreeItem
                                nodeId="5"
                                labelText="Users"
                                labelIcon={PersonIcon}
                                labelInfo={activityLog.filter(x => !user.history_logs.includes(x.id) && x.type === 3).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([3]); setHistory(false); }}
                            />
                            <StyledTreeItem
                                nodeId="6"
                                labelText="Messages"
                                labelIcon={ForumIcon}
                                labelInfo={activityLog.filter(x => !user.history_logs.includes(x.id) && (x.type === 11 || x.type === 12 || x.type === 13)).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([11, 12, 13]); setHistory(false); }}
                            />
                            <StyledTreeItem
                                nodeId="7"
                                labelText="Invites"
                                labelIcon={LocalOfferIcon}
                                labelInfo={activityLog.filter(x => !user.history_logs.includes(x.id) && x.type === 5).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([5]); setHistory(false); }}
                            />
                        </StyledTreeItem>

                        <StyledTreeItem nodeId="8" labelText="History" labelIcon={HistoryIcon}>
                            <StyledTreeItem
                                nodeId="9"
                                labelText="All History"
                                labelIcon={ChatIcon}
                                labelInfo={activityLog.filter(x => user.history_logs.includes(x.id) && x).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]); setHistory(true); }}
                            />
                            <StyledTreeItem
                                nodeId="10"
                                labelText="Company"
                                labelIcon={ApartmentIcon}
                                labelInfo={activityLog.filter(x => user.history_logs.includes(x.id) && (x.type === 1 || x.type === 8)).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([1, 8, 10]); setHistory(true); }}
                            />
                            <StyledTreeItem
                                nodeId="11"
                                labelText="Groups"
                                labelIcon={GroupIcon}
                                labelInfo={activityLog.filter(x => user.history_logs.includes(x.id) && (x.type === 2 || x.type === 4 || x.type === 6 || x.type === 7 || x.type === 9)).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([2, 4, 6, 7, 9]); setHistory(true); }}
                            />
                            <StyledTreeItem
                                nodeId="12"
                                labelText="Users"
                                labelIcon={PersonIcon}
                                labelInfo={activityLog.filter(x => user.history_logs.includes(x.id) && x.type === 3).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([3]); setHistory(true); }}
                            />
                            <StyledTreeItem
                                nodeId="13"
                                labelText="Messages"
                                labelIcon={ForumIcon}
                                labelInfo={activityLog.filter(x => user.history_logs.includes(x.id) && (x.type === 11 || x.type === 12 || x.type === 13)).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([11, 12, 13]); setHistory(true); }}
                            />
                            <StyledTreeItem
                                nodeId="14"
                                labelText="Invites"
                                labelIcon={LocalOfferIcon}
                                labelInfo={activityLog.filter(x => user.history_logs.includes(x.id) && x.type === 5).length}
                                color={theme.palette.primary.main}
                                bgColor="#e8f0fe"
                                onClick={() => { setActiveFilters([5]); setHistory(true); }}
                            />
                        </StyledTreeItem>
                    </TreeView>
                </Grid>
            }
            <Grid item xs={isSmall ? 12 : 9.5}>
                <Grid container sx={isSmall ? { borderBottom: "1px solid black", height: "10%" } : { borderBottom: "1px solid black", height: "100%" }}>
                    <Box sx={isSmall ? { height: "100%", width: "100%", display: "flex" } : { height: "10%", width: "100%", display: "flex", borderBottom: "1px solid black" }}>
                        {!isSmall &&
                            <Grid item xs={4.5} sx={isSmall ? {} : { display: "flex", alignItems: "center", pl: 2 }}>
                                {newNotif > 0 ?
                                    <Typography component={"div"} color="primary" sx={{ fontWeight: "bold" }}>You have {newNotif} new notification{newNotif !== 1 && 's'}</Typography>
                                    :
                                    <Typography component={"div"} color="primary" sx={{ fontWeight: "bold" }}>No new notifications</Typography>
                                }
                            </Grid>
                        }
                        <Grid item xs={isSmall ? 4 : 2.5} sx={isSmall ? { display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" } : { display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {isSmall
                                ?
                                <IconButton sx={isSmall ? { flexDirection: "column" } : { flexDirection: "column" }} onClick={handleReadAll} >
                                    <MarkChatReadIcon color="primary" />
                                    <Typography component={"div"} color="primary" sx={{ fontWeight: "bold" }}>Read all</Typography>
                                </IconButton>
                                :
                                <Button sx={isSmall ? { flexDirection: "column" } : { flexDirection: "column", height: "100%" }} onClick={handleReadAll} >
                                    <MarkChatReadIcon color="primary" />
                                    <Typography component={"div"} color="primary" sx={{ fontWeight: "bold", fontSize: 12 }}>Read all</Typography>
                                </Button>
                            }

                        </Grid >
                        <Grid item xs={isSmall ? 4 : 2.5} sx={isSmall ? { display: "flex", alignItems: "center", justifyContent: "center", } : { display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {isSmall
                                ?
                                <IconButton disabled={history} sx={isSmall ? { flexDirection: "column" } : { flexDirection: "column" }} onClick={() => setOpenHistory(true)} >
                                    <MoveDownIcon disabled={history} color={!history ? "primary" : "gray"} />
                                    <Typography disabled={history} component={"div"} color={!history ? "primary" : "gray"} sx={{ fontWeight: "bold" }}>to history</Typography>
                                </IconButton>
                                :
                                <Button disabled={history} sx={isSmall ? { flexDirection: "column" } : { flexDirection: "column", height: "100%" }} onClick={() => setOpenHistory(true)} >
                                    <MoveDownIcon disabled={history} color={!history ? "primary" : "gray"} />
                                    <Typography disabled={history} component={"div"} color={!history ? "primary" : "gray"} sx={{ fontWeight: "bold", fontSize: 12 }}>to history</Typography>
                                </Button>
                            }
                        </Grid >
                        <Grid item xs={isSmall ? 4 : 2.5} sx={isSmall ? { display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" } : { display: "flex", alignItems: "center", justifyContent: "center" }}>

                            {isSmall
                                ?
                                <IconButton disabled={history} sx={isSmall ? { flexDirection: "column" } : { position: "relative", bottom: 8, left: 4 }} onClick={pushAllToHistory} >
                                    <LibraryAddCheckIcon disabled={history} color={!history ? "primary" : "gray"} />
                                    <Typography disabled={history} component={"div"} color={!history ? "primary" : "gray"} sx={{ fontWeight: "bold" }}>Select all</Typography>
                                </IconButton>
                                :
                                <Button disabled={history} sx={isSmall ? { flexDirection: "column" } : { flexDirection: "column", height: "100%" }} onClick={pushAllToHistory}  >
                                    <LibraryAddCheckIcon disabled={history} color={!history ? "primary" : "gray"} />
                                    <Typography component={"div"} color="primary" sx={{ fontWeight: "bold", fontSize: 12 }}>Select All</Typography>
                                </Button>
                            }

                            {/*<IconButton disabled={history} sx={{ position: "relative", bottom: 8, left: 4 }} onClick={handleHistoryRemoveMany} >
                                    <LibraryAddCheckIcon disabled={history} color={!history ? "primary" : "gray"} />
                                </IconButton> */}
                        </Grid>
                    </Box>
                    <Box sx={isSmall ? { display: "none" } : { height: "90%", width: "100%" }}>
                        {virtList.length > 0
                            ?
                            <>
                                <Virtuoso
                                    /* Placeholder content for list when user scrolls fast */
                                    /* components={{ ScrollSeekPlaceholder }} */
                                    style={
                                        isSmall ?
                                            {
                                                padding: 0,
                                                height: "82%",
                                                overflow: 'scroll',
                                                overflowX: "hidden",
                                            }
                                            :
                                            {
                                                padding: 0,
                                                height: "100%",
                                                overflow: 'scroll',
                                                overflowX: "hidden",
                                                scrollbarColor: `${theme.palette.primary.main} #fff`,
                                            }}
                                    totalCount={virtList.length}
                                    itemContent={(index) => {
                                        const item = virtList[index];
                                        const data = item.data;
                                        let content = "";
                                        let companyIcon = "";
                                        let link = "";
                                        let avatarLink = "";
                                        let leftImg = "";
                                        let rightImg = "";
                                        let leftTooltip = "";
                                        let rightTooltip = "";
                                        switch (item.type) {
                                            case 1:
                                                content = data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has joined the company.` : data.username + " has joined the company.";
                                                companyIcon = <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50 }}
                                                    userId={user.user_company.id} imageType="companypic" imageSize="_64" />;
                                                link = `/profile/${data.user_id}`;
                                                avatarLink = `/profile/${data.user_id}`;
                                                leftImg = data.user_id;
                                                leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                                rightTooltip = data.company_name;
                                                break;
                                            case 2:
                                                content = data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has joined ` + data.social_group_name + "." : data.username + " has joined " + data.social_group_name + ".";
                                                link = `/profile/${data.user_id}`;
                                                avatarLink = `/profile/${data.user_id}`;
                                                leftImg = data.user_id;
                                                rightImg = item.social_group;
                                                leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                                rightTooltip = data.social_group_name;
                                                break;
                                            case 3:
                                                content = data.online
                                                    ?
                                                    data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has come online` : data.username + " has come online."
                                                    :
                                                    data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has gone offline` : data.username + " has gone offline."
                                                    ;
                                                link = `/profile/${data.user_id}`;
                                                avatarLink = `/profile/${data.user_id}`;
                                                leftImg = data.user_id;
                                                leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                                break;
                                            case 4:
                                                content = <div>{data.inviter_first_name && data.inviter_last_name ? `${data.inviter_first_name} ${data.inviter_last_name}` : data.inviter_username} has invited you to the group <span style={{ fontWeight: "bold" }}>{data.social_group_name}</span>
                                                    <br />
                                                    <Button onClick={() => handleRead(item.id)} component={MuiLink} href={data.link} target="_blank" rel="noopener noreferrer" variant="contained" color="primary" sx={{ height: 30, marginLeft: 3, marginBottom: 1, marginTop: 1 }}>
                                                        Join
                                                    </Button>
                                                </div>;
                                                link = `profile/${data.link}`;
                                                avatarLink = `/profile/${data.inviter}`;
                                                leftImg = data.inviter;
                                                rightImg = data.social_group;
                                                leftTooltip = data.inviter_first_name && data.inviter_last_name ? `${data.inviter_first_name} ${data.inviter_last_name}` : data.inviter_username;
                                                rightTooltip = data.social_group_name;
                                                break;
                                            case 5:
                                                content =
                                                    <div>{data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username} has requested to join group
                                                        <span style={{ fontWeight: "bold" }}> {data.social_group_name}</span>.
                                                        <br />
                                                        <div style={isSmall ? { display: "flex", alignItems: "center", justifyContent: "space-around" } : {}}>
                                                            <Button onClick={() => {
                                                                handleGroupRequest(data.request_id, true);
                                                                toast.success(`${data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username} accepted to the group ${data.social_group_name}`,
                                                                    {
                                                                        position: "bottom-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        theme: "colored"
                                                                    }
                                                                );
                                                            }}
                                                                variant="contained" color="success" sx={isSmall ? { width: 70, marginY: 1 } : { marginX: 3, marginY: 1 }}>
                                                                Accept
                                                            </Button>
                                                            <Button onClick={() => {
                                                                handleGroupRequest(data.request_id, false);
                                                                toast.error(`${data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username} rejected from the group ${data.social_group_name}`,
                                                                    {
                                                                        position: "bottom-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        theme: "colored"
                                                                    }
                                                                );
                                                            }}
                                                                variant="contained" color="primary" sx={isSmall ? { width: 70, marginY: 1 } : { marginX: 3, marginY: 1 }}>
                                                                Reject
                                                            </Button>
                                                        </div>
                                                    </div>;
                                                avatarLink = `/profile/${data.user_id}`;
                                                leftImg = data.user_id;
                                                rightImg = data.social_group;
                                                leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                                rightTooltip = data.social_group_name;
                                                break;
                                            case 6:
                                                content = <div>
                                                    <span style={{ fontWeight: "bold" }}> {data.social_group_name} </span>
                                                    group's visibility time have been changed to <span style={{ fontWeight: "bold" }}>{moment.utc().startOf('day').add(data.utc_start).local().format('HH:mm')}
                                                        <hr style={{ borderColor: theme.palette.primary.main, width: 10, display: "inline-block", marginBottom: 5, marginLeft: 3, marginRight: 3 }} />
                                                        {moment.utc().startOf('day').add(data.utc_end).local().format('HH:mm')}</span> on < span style={{ fontWeight: "bold" }
                                                        }> {
                                                            isEqual(sortBy(data.enable_days), [1, 2, 3, 4, 5, 6, 7])
                                                                ?
                                                                'daily'
                                                                :
                                                                data.enable_days.map((item) => {
                                                                    switch (item) {
                                                                        case 1:
                                                                            return "Mon";
                                                                        case 2:
                                                                            return "Tue";
                                                                        case 3:
                                                                            return "Wed";
                                                                        case 4:
                                                                            return "Thu";
                                                                        case 5:
                                                                            return "Fri";
                                                                        case 6:
                                                                            return "Sat";
                                                                        case 7:
                                                                            return "Sun";
                                                                        default:
                                                                            return "";
                                                                    }
                                                                }).join(', ')
                                                        }</span>.
                                                </div>;
                                                link = `groups/${item.social_group}`;
                                                avatarLink = `groups/${item.social_group}`;
                                                leftImg = item.social_group;
                                                leftTooltip = data.social_group_name;
                                                break;
                                            case 7: {
                                                content = <div><span style={{ fontWeight: "bold" }}>{data.social_group_name}</span> group's dashboard has been changed.
                                                    <br />
                                                    <Button onClick={() => {
                                                        setOpenGroup(true);
                                                        setDashboardContent(item.social_group);
                                                    }} variant="contained" color="primary" sx={{ height: 30, marginLeft: 3, marginBottom: 1, marginTop: 1 }}>
                                                        <DashboardIcon sx={{ marginLeft: -1, marginRight: 1 }} />Dashboard
                                                    </Button>
                                                </div>;
                                                avatarLink = `groups/${item.social_group}`;
                                                leftImg = item.social_group;
                                                leftTooltip = data.social_group_name;
                                                break;
                                            }
                                            case 8:
                                                content = <div><span style={{ fontWeight: "bold" }}>{data.company_name}</span>'s dashboard has been changed.
                                                    <br />
                                                    <Button onClick={() => {
                                                        setOpenCompany(true);
                                                    }} variant="contained" color="primary" sx={isSmall ? { height: 30, marginLeft: 3, marginBottom: 1, marginTop: 1, fontSize: 12 } : { height: 30, marginLeft: 3, marginBottom: 1, marginTop: 1 }}>
                                                        <DashboardIcon sx={{ marginLeft: -1, marginRight: 1, display: isSmall && "none" }} />Dashboard
                                                    </Button>
                                                </div>;
                                                avatarLink = `/company`;
                                                companyIcon = <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50 }}
                                                    userId={user.user_company.id} imageType="companypic" imageSize="_64" />;
                                                leftTooltip = data.company_name;
                                                break;
                                            case 9:
                                                content = data.public
                                                    ?
                                                    <div><span style={{ fontWeight: "bold" }}>{data.social_group_name}</span> group has been changed to <span style={{ fontWeight: "bold" }}>public.</span></div>
                                                    :
                                                    <div><span style={{ fontWeight: "bold" }}>{data.social_group_name}</span> group has been changed to <span style={{ fontWeight: "bold" }}>private.</span></div>
                                                    ;
                                                link = `groups/${item.social_group}`;
                                                avatarLink = `groups/${item.social_group}`;
                                                leftImg = item.social_group;
                                                leftTooltip = data.social_group_name;
                                                break;
                                            case 10:
                                                content = data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has left the company.` : data.username + " has left the company.";
                                                companyIcon = <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50 }}
                                                    userId={user.user_company.id} imageType="companypic" imageSize="_64" />;
                                                link = `/profile/${data.user_id}`;
                                                avatarLink = `/profile/${data.user_id}`;
                                                leftImg = data.user_id;
                                                leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                                rightTooltip = data.company_name;
                                                break;
                                            case 11:
                                                content = `${data.senderName} sent you: ${String.fromCodePoint(parseInt(data.emoji))}`;
                                                avatarLink = `/profile/${data.senderId}`;
                                                leftImg = data.senderId;
                                                leftTooltip = data.senderName;
                                                break;
                                            case 12:
                                                content = `Message from reception: ${data.message}`;
                                                companyIcon = <Avatar sx={{ width: 50, height: 50, color: theme.palette.primary.main, border: "1px solid gray" }}>
                                                    <SupportAgentIcon sx={{ width: "75%", height: "75%" }} />
                                                </Avatar>;
                                                break;
                                            case 13:
                                                content = data.message;
                                                companyIcon = <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50 }}
                                                    userId={user.user_company.id} imageType="companypic" imageSize="_64" />;
                                                avatarLink = `/company`;
                                                leftImg = data.user_id;
                                                break;
                                            default:
                                                content = "😇 This should not be showing here, there must be lack of something in somewhere 😇";
                                                break;
                                        }

                                        return (
                                            <ListItem key={item.id}
                                                sx={{ zIndex: 1, borderTopRightRadius: index === 0 && 10, borderBottom: "1px solid gray", "&:hover": { filter: "invert(4%)" }, backgroundColor: user.read_logs.includes(item.id) ? "#FFFFFF" : "#e8f0fe", position: "relative" }}
                                                /* RIGHT IMAGE */
                                                secondaryAction={
                                                    !isSmall
                                                        &&
                                                        ((item.social_group || item.company || data.social_group) && item.type !== 9 && item.type !== 6 && item.type !== 8 && item.type !== 7)
                                                        ?
                                                        companyIcon
                                                            ?
                                                            <Box sx={{ display: "flex" }}>
                                                                <Tooltip title={rightTooltip}>
                                                                    <ListItemAvatar onClick={() => { navigate('/company'); !user.read_logs.includes(item.id) && handleRead(item.id); }} sx={isSmall ? {} : { position: "relative", left: 10, "&:hover": { cursor: "pointer" } }}>
                                                                        {companyIcon}
                                                                    </ListItemAvatar>
                                                                </Tooltip>
                                                                {!history &&
                                                                    <Checkbox checked={toHistory.includes(item.id)} value={item.id} color="primary" sx={isSmall ? {} : { marginLeft: 1, marginRight: -1, }} onClick={pushToHistory} />
                                                                }
                                                            </Box>
                                                            :
                                                            (item.type === 2 || item.type === 4 || item.type === 5)
                                                                ?
                                                                <Box sx={{ display: "flex" }}>
                                                                    <Tooltip title={rightTooltip}>
                                                                        <ListItemAvatar onClick={() => { item.type !== 4 && navigate('/groups/' + (data.social_group || item.social_group)); !user.read_logs.includes(item.id) && handleRead(item.id); }} sx={{ position: "relative", left: 10, "&:hover": { cursor: "pointer" } }} >
                                                                            <SmallAvatarWrapper alt="GroupPic" sx={{ width: 50, height: 50, border: "1px solid gray", "&:hover": { cursor: item.type === 4 && "initial" } }}
                                                                                userId={rightImg} imageType="socialgrouppic" imageSize="_64" />
                                                                        </ListItemAvatar>
                                                                    </Tooltip>

                                                                    {!history &&
                                                                        <Checkbox checked={toHistory.includes(item.id)} value={item.id} color="primary" sx={{ marginLeft: 1, marginRight: -1, }} onClick={pushToHistory} />
                                                                    }
                                                                </Box>
                                                                :
                                                                <Box sx={{ display: "flex" }}>
                                                                    <Tooltip title={rightTooltip}>
                                                                        <ListItemAvatar onClick={() => { item.type !== 4 && navigate('/groups/' + (data.social_group || item.social_group)); !user.read_logs.includes(item.id) && handleRead(item.id); }} sx={{ position: "relative", left: 10, "&:hover": { cursor: "pointer" } }} >
                                                                            <SmallAvatarWrapper userId={rightImg} sx={{ border: "1px solid gray", height: 50, width: 50 }} imageSize="_64" />
                                                                        </ListItemAvatar>
                                                                    </Tooltip>
                                                                    {!history &&
                                                                        <Checkbox checked={toHistory.includes(item.id)} value={item.id} color="primary" sx={{ marginLeft: 1, marginRight: -1, }} onClick={pushToHistory} />
                                                                    }
                                                                </Box>
                                                        :
                                                        !history &&
                                                        <Checkbox checked={toHistory.includes(item.id)} value={item.id} color="primary" sx={{ marginLeft: 1, marginRight: -1, }} onClick={pushToHistory} />
                                                }>

                                                {/* LEFT IMAGE */}
                                                < Tooltip title={leftTooltip} >
                                                    <ListItemAvatar onClick={() => { navigate(avatarLink); !user.read_logs.includes(item.id) && handleRead(item.id); }} sx={{ "&:hover": { cursor: "pointer" } }}>
                                                        {(item.type === 8 || item.type === 12 || item.type === 13)
                                                            ?
                                                            companyIcon
                                                            :
                                                            (item.type === 6 || item.type === 7 || item.type === 9)
                                                                ?
                                                                <SmallAvatarWrapper alt="GroupPic" sx={{ width: 50, height: 50 }}
                                                                    userId={leftImg} imageType="socialgrouppic" imageSize="_64" />
                                                                :
                                                                <SmallAvatarWrapper userId={leftImg} sx={{ border: "1px solid gray", height: 50, width: 50 }} imageSize="_64" />
                                                        }
                                                    </ListItemAvatar>
                                                </Tooltip>

                                                {/* TEXT AND CONTENT INSIDE */}
                                                {
                                                    item.type === 4
                                                        ?
                                                        <ListItem sx={{ marginRight: 8, paddingLeft: 1 }} >
                                                            <ListItemText
                                                                primary={content}
                                                                secondary={moment(item.timestamp).fromNow()}
                                                            />
                                                        </ListItem>
                                                        :
                                                        <ListItem component="div" button={item.type !== 5 && item.type !== 7 && item.type !== 8} sx={{ marginRight: isSmall ? 0 : 8, paddingLeft: 1, "&:hover": { cursor: (item.type === 5 || item.type === 7 || item.type === 8 || item.type === 11 || item.type === 12 || item.type === 13) && "initial", backgroundColor: 'transparent' } }}
                                                            onClick={() => { link && navigate(link); !user.read_logs.includes(item.id) && handleRead(item.id); }}
                                                        >
                                                            <ListItemText
                                                                primary={content}
                                                                secondary={moment(item.timestamp).fromNow()}
                                                            />
                                                        </ListItem>
                                                }
                                            </ListItem>
                                        );
                                    }}
                                >
                                </Virtuoso>
                                {isSmall &&
                                    <Box sx={{ height: "8%", width: "100%" }}>
                                        <Button
                                            aria-controls={openTypesMobile ? 'types-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openTypesMobile ? 'true' : undefined}
                                            onClick={handleClickTypes}
                                            variant="outlined"
                                            sx={{ fontWeight: "bold", width: "50%", height: "100%", borderWidth: 1, borderRightWidth: 0.5, borderRadius: 0, borderBottomLeftRadius: 16, borderColor: theme.palette.primary.main }}>
                                            Activity
                                        </Button>
                                        <Button
                                            aria-controls={openHistoryMobile ? 'history-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openHistoryMobile ? 'true' : undefined}
                                            onClick={handleClickHistory}
                                            variant="outlined"
                                            sx={{ fontWeight: "bold", width: "50%", height: "100%", borderWidth: 1, borderLeftWidth: 0.5, borderRadius: 0, borderBottomRightRadius: 16, borderColor: theme.palette.primary.main }}>
                                            History
                                        </Button>
                                    </Box>
                                }
                            </>
                            :
                            !isSmall
                                ?
                                <Box sx={{ height: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
                                        No new notifications
                                    </Typography>
                                </Box>
                                :
                                <>
                                    <Box sx={{ height: "82%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                        <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.primary.main, position: "relative", top: "10%" }}>
                                            No new notifications
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "8%" }}>
                                        <Button
                                            aria-controls={openTypesMobile ? 'types-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openTypesMobile ? 'true' : undefined}
                                            onClick={handleClickTypes}
                                            variant="outlined"
                                            sx={{ fontWeight: "bold", width: "50%", height: "100%", borderWidth: 1, borderRightWidth: 0.5, borderRadius: 0, borderBottomLeftRadius: 16, borderColor: theme.palette.primary.main }}>
                                            Activity
                                        </Button>
                                        <Button
                                            aria-controls={openHistoryMobile ? 'history-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openHistoryMobile ? 'true' : undefined}
                                            onClick={handleClickHistory}
                                            variant="outlined"
                                            sx={{ fontWeight: "bold", width: "50%", height: "100%", borderWidth: 1, borderLeftWidth: 0.5, borderRadius: 0, borderBottomRightRadius: 16, borderColor: theme.palette.primary.main }}>
                                            History
                                        </Button>
                                    </Box>
                                </>
                        }
                    </Box>
                </Grid >

                {virtList.length > 0 && isSmall
                    ?
                    <>
                        <Virtuoso
                            /* Placeholder content for list when user scrolls fast */
                            /* components={{ ScrollSeekPlaceholder }} */
                            style={
                                isSmall ?
                                    {
                                        padding: 0,
                                        height: "82%",
                                        overflow: 'scroll',
                                        overflowX: "hidden",
                                    }
                                    :
                                    {
                                        padding: 0,
                                        height: "100%",
                                        overflow: 'scroll',
                                        overflowX: "hidden",
                                        scrollbarColor: `${theme.palette.primary.main} #fff`,
                                    }}
                            totalCount={virtList.length}
                            itemContent={(index) => {
                                const item = virtList[index];
                                const data = item.data;
                                let content = "";
                                let companyIcon = "";
                                let link = "";
                                let avatarLink = "";
                                let leftImg = "";
                                let rightImg = "";
                                let leftTooltip = "";
                                let rightTooltip = "";
                                switch (item.type) {
                                    case 1:
                                        content = data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has joined the company.` : data.username + " has joined the company.";
                                        companyIcon = <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50 }}
                                            userId={user.user_company.id} imageType="companypic" imageSize="_64" />;
                                        link = `/profile/${data.user_id}`;
                                        avatarLink = `/profile/${data.user_id}`;
                                        leftImg = data.user_id;
                                        leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                        rightTooltip = data.company_name;
                                        break;
                                    case 2:
                                        content = data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has joined ` + data.social_group_name + "." : data.username + " has joined " + data.social_group_name + ".";
                                        link = `/profile/${data.user_id}`;
                                        avatarLink = `/profile/${data.user_id}`;
                                        leftImg = data.user_id;
                                        rightImg = item.social_group;
                                        leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                        rightTooltip = data.social_group_name;
                                        break;
                                    case 3:
                                        content = data.online
                                            ?
                                            data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has come online` : data.username + " has come online."
                                            :
                                            data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has gone offline` : data.username + " has gone offline."
                                            ;
                                        link = `/profile/${data.user_id}`;
                                        avatarLink = `/profile/${data.user_id}`;
                                        leftImg = data.user_id;
                                        leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                        break;
                                    case 4:
                                        content = <div>{data.inviter_first_name && data.inviter_last_name ? `${data.inviter_first_name} ${data.inviter_last_name}` : data.inviter_username} has invited you to the group <span style={{ fontWeight: "bold" }}>{data.social_group_name}</span>
                                            <br />
                                            <Button onClick={() => handleRead(item.id)} component={MuiLink} href={data.link} target="_blank" rel="noopener noreferrer" variant="contained" color="primary" sx={{ height: 30, marginLeft: 3, marginBottom: 1, marginTop: 1 }}>
                                                Join
                                            </Button>
                                        </div>;
                                        link = `profile/${data.link}`;
                                        avatarLink = `/profile/${data.inviter}`;
                                        leftImg = data.inviter;
                                        rightImg = data.social_group;
                                        leftTooltip = data.inviter_first_name && data.inviter_last_name ? `${data.inviter_first_name} ${data.inviter_last_name}` : data.inviter_username;
                                        rightTooltip = data.social_group_name;
                                        break;
                                    case 5:
                                        content =
                                            <div>{data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username} has requested to join group
                                                <span style={{ fontWeight: "bold" }}> {data.social_group_name}</span>.
                                                <br />
                                                <div style={isSmall ? { display: "flex", alignItems: "center", justifyContent: "space-around" } : {}}>
                                                    <Button onClick={() => {
                                                        handleGroupRequest(data.request_id, true);
                                                        toast.success(`${data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username} accepted to the group ${data.social_group_name}`,
                                                            {
                                                                position: "bottom-center",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                theme: "colored"
                                                            }
                                                        );
                                                    }}
                                                        variant="contained" color="success" sx={isSmall ? { width: 70, marginY: 1 } : { marginX: 3, marginY: 1 }}>
                                                        Accept
                                                    </Button>
                                                    <Button onClick={() => {
                                                        handleGroupRequest(data.request_id, false);
                                                        toast.error(`${data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username} rejected from the group ${data.social_group_name}`,
                                                            {
                                                                position: "bottom-center",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                theme: "colored"
                                                            }
                                                        );
                                                    }}
                                                        variant="contained" color="primary" sx={isSmall ? { width: 70, marginY: 1 } : { marginX: 3, marginY: 1 }}>
                                                        Reject
                                                    </Button>
                                                </div>
                                            </div>;
                                        avatarLink = `/profile/${data.user_id}`;
                                        leftImg = data.user_id;
                                        rightImg = data.social_group;
                                        leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                        rightTooltip = data.social_group_name;
                                        break;
                                    case 6:
                                        content = <div>
                                            <span style={{ fontWeight: "bold" }}> {data.social_group_name} </span>
                                            group's visibility time have been changed to <span style={{ fontWeight: "bold" }}>{moment.utc().startOf('day').add(data.utc_start).local().format('HH:mm')}
                                                <hr style={{ borderColor: theme.palette.primary.main, width: 10, display: "inline-block", marginBottom: 5, marginLeft: 3, marginRight: 3 }} />
                                                {moment.utc().startOf('day').add(data.utc_end).local().format('HH:mm')}</span> on < span style={{ fontWeight: "bold" }
                                                }> {
                                                    isEqual(sortBy(data.enable_days), [1, 2, 3, 4, 5, 6, 7])
                                                        ?
                                                        'daily'
                                                        :
                                                        data.enable_days.map((item) => {
                                                            switch (item) {
                                                                case 1:
                                                                    return "Mon";
                                                                case 2:
                                                                    return "Tue";
                                                                case 3:
                                                                    return "Wed";
                                                                case 4:
                                                                    return "Thu";
                                                                case 5:
                                                                    return "Fri";
                                                                case 6:
                                                                    return "Sat";
                                                                case 7:
                                                                    return "Sun";
                                                                default:
                                                                    return "";
                                                            }
                                                        }).join(', ')
                                                }</span>.
                                        </div>;
                                        link = `groups/${item.social_group}`;
                                        avatarLink = `groups/${item.social_group}`;
                                        leftImg = item.social_group;
                                        leftTooltip = data.social_group_name;
                                        break;
                                    case 7: {
                                        content = <div><span style={{ fontWeight: "bold" }}>{data.social_group_name}</span> group's dashboard has been changed.
                                            <br />
                                            <Button onClick={() => {
                                                setOpenGroup(true);
                                                setDashboardContent(item.social_group);
                                            }} variant="contained" color="primary" sx={{ height: 30, marginLeft: 3, marginBottom: 1, marginTop: 1 }}>
                                                <DashboardIcon sx={{ marginLeft: -1, marginRight: 1 }} />Dashboard
                                            </Button>
                                        </div>;
                                        avatarLink = `groups/${item.social_group}`;
                                        leftImg = item.social_group;
                                        leftTooltip = data.social_group_name;
                                        break;
                                    }
                                    case 8:
                                        content = <div><span style={{ fontWeight: "bold" }}>{data.company_name}</span>'s dashboard has been changed.
                                            <br />
                                            <Button onClick={() => {
                                                setOpenCompany(true);
                                            }} variant="contained" color="primary" sx={isSmall ? { height: 30, marginLeft: 3, marginBottom: 1, marginTop: 1, fontSize: 12 } : { height: 30, marginLeft: 3, marginBottom: 1, marginTop: 1 }}>
                                                <DashboardIcon sx={{ marginLeft: -1, marginRight: 1, display: isSmall && "none" }} />Dashboard
                                            </Button>
                                        </div>;
                                        avatarLink = `/company`;
                                        companyIcon = <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50 }}
                                            userId={user.user_company.id} imageType="companypic" imageSize="_64" />;
                                        leftTooltip = data.company_name;
                                        break;
                                    case 9:
                                        content = data.public
                                            ?
                                            <div><span style={{ fontWeight: "bold" }}>{data.social_group_name}</span> group has been changed to <span style={{ fontWeight: "bold" }}>public.</span></div>
                                            :
                                            <div><span style={{ fontWeight: "bold" }}>{data.social_group_name}</span> group has been changed to <span style={{ fontWeight: "bold" }}>private.</span></div>
                                            ;
                                        link = `groups/${item.social_group}`;
                                        avatarLink = `groups/${item.social_group}`;
                                        leftImg = item.social_group;
                                        leftTooltip = data.social_group_name;
                                        break;
                                    case 10:
                                        content = data.first_name && data.last_name ? `${data.first_name} ${data.last_name} has left the company.` : data.username + " has left the company.";
                                        companyIcon = <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50 }}
                                            userId={user.user_company.id} imageType="companypic" imageSize="_64" />;
                                        link = `/profile/${data.user_id}`;
                                        avatarLink = `/profile/${data.user_id}`;
                                        leftImg = data.user_id;
                                        leftTooltip = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.username;
                                        rightTooltip = data.company_name;
                                        break;
                                    case 11:
                                        content = `${data.senderName} sent you: ${String.fromCodePoint(parseInt(data.emoji))}`;
                                        avatarLink = `/profile/${data.senderId}`;
                                        leftImg = data.senderId;
                                        leftTooltip = data.senderName;
                                        break;
                                    case 12:
                                        content = `Message from reception: ${data.message}`;
                                        companyIcon = <Avatar sx={{ width: 50, height: 50, color: theme.palette.primary.main, border: "1px solid gray" }}>
                                            <SupportAgentIcon sx={{ width: "75%", height: "75%" }} />
                                        </Avatar>;
                                        break;
                                    case 13:
                                        content = data.message;
                                        companyIcon = <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50 }}
                                            userId={user.user_company.id} imageType="companypic" imageSize="_64" />;
                                        avatarLink = `/company`;
                                        leftImg = data.user_id;
                                        break;
                                    default:
                                        content = "😇 This should not be showing here, there must be lack of something in somewhere 😇";
                                        break;
                                }

                                return (
                                    <ListItem key={item.id}
                                        sx={{ zIndex: 1, borderTopRightRadius: index === 0 && 10, borderBottom: "1px solid gray", "&:hover": { filter: "invert(4%)" }, backgroundColor: user.read_logs.includes(item.id) ? "#FFFFFF" : "#e8f0fe", position: "relative" }}
                                        /* RIGHT IMAGE */
                                        secondaryAction={
                                            !isSmall
                                                &&
                                                ((item.social_group || item.company || data.social_group) && item.type !== 9 && item.type !== 6 && item.type !== 8 && item.type !== 7)
                                                ?
                                                companyIcon
                                                    ?
                                                    <Box sx={{ display: "flex" }}>
                                                        <Tooltip title={rightTooltip}>
                                                            <ListItemAvatar onClick={() => { navigate('/company'); !user.read_logs.includes(item.id) && handleRead(item.id); }} sx={isSmall ? {} : { position: "relative", left: 10, "&:hover": { cursor: "pointer" } }}>
                                                                {companyIcon}
                                                            </ListItemAvatar>
                                                        </Tooltip>
                                                        {!history &&
                                                            <Checkbox checked={toHistory.includes(item.id)} value={item.id} color="primary" sx={isSmall ? {} : { marginLeft: 1, marginRight: -1, }} onClick={pushToHistory} />
                                                        }
                                                    </Box>
                                                    :
                                                    (item.type === 2 || item.type === 4 || item.type === 5)
                                                        ?
                                                        <Box sx={{ display: "flex" }}>
                                                            <Tooltip title={rightTooltip}>
                                                                <ListItemAvatar onClick={() => { item.type !== 4 && navigate('/groups/' + (data.social_group || item.social_group)); !user.read_logs.includes(item.id) && handleRead(item.id); }} sx={{ position: "relative", left: 10, "&:hover": { cursor: "pointer" } }} >
                                                                    <SmallAvatarWrapper alt="GroupPic" sx={{ width: 50, height: 50, border: "1px solid gray", "&:hover": { cursor: item.type === 4 && "initial" } }}
                                                                        userId={rightImg} imageType="socialgrouppic" imageSize="_64" />
                                                                </ListItemAvatar>
                                                            </Tooltip>

                                                            {!history &&
                                                                <Checkbox checked={toHistory.includes(item.id)} value={item.id} color="primary" sx={{ marginLeft: 1, marginRight: -1, }} onClick={pushToHistory} />
                                                            }
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: "flex" }}>
                                                            <Tooltip title={rightTooltip}>
                                                                <ListItemAvatar onClick={() => { item.type !== 4 && navigate('/groups/' + (data.social_group || item.social_group)); !user.read_logs.includes(item.id) && handleRead(item.id); }} sx={{ position: "relative", left: 10, "&:hover": { cursor: "pointer" } }} >
                                                                    <SmallAvatarWrapper userId={rightImg} sx={{ border: "1px solid gray", height: 50, width: 50 }} imageSize="_64" />
                                                                </ListItemAvatar>
                                                            </Tooltip>
                                                            {!history &&
                                                                <Checkbox checked={toHistory.includes(item.id)} value={item.id} color="primary" sx={{ marginLeft: 1, marginRight: -1, }} onClick={pushToHistory} />
                                                            }
                                                        </Box>
                                                :
                                                !history &&
                                                <Checkbox checked={toHistory.includes(item.id)} value={item.id} color="primary" sx={{ marginLeft: 1, marginRight: -1, }} onClick={pushToHistory} />
                                        }>

                                        {/* LEFT IMAGE */}
                                        < Tooltip title={leftTooltip} >
                                            <ListItemAvatar onClick={() => { navigate(avatarLink); !user.read_logs.includes(item.id) && handleRead(item.id); }} sx={{ "&:hover": { cursor: "pointer" } }}>
                                                {(item.type === 8 || item.type === 12 || item.type === 13)
                                                    ?
                                                    companyIcon
                                                    :
                                                    (item.type === 6 || item.type === 7 || item.type === 9)
                                                        ?
                                                        <SmallAvatarWrapper alt="GroupPic" sx={{ width: 50, height: 50 }}
                                                            userId={leftImg} imageType="socialgrouppic" imageSize="_64" />
                                                        :
                                                        <SmallAvatarWrapper userId={leftImg} sx={{ border: "1px solid gray", height: 50, width: 50 }} imageSize="_64" />
                                                }
                                            </ListItemAvatar>
                                        </Tooltip>

                                        {/* TEXT AND CONTENT INSIDE */}
                                        {
                                            item.type === 4
                                                ?
                                                <ListItem sx={{ marginRight: 8, paddingLeft: 1 }} >
                                                    <ListItemText
                                                        primary={content}
                                                        secondary={moment(item.timestamp).fromNow()}
                                                    />
                                                </ListItem>
                                                :
                                                <ListItem component="div" button={item.type !== 5 && item.type !== 7 && item.type !== 8} sx={{ marginRight: isSmall ? 0 : 8, paddingLeft: 1, "&:hover": { cursor: (item.type === 5 || item.type === 7 || item.type === 8 || item.type === 11 || item.type === 12 || item.type === 13) && "initial" } }}
                                                    onClick={() => { link && navigate(link); !user.read_logs.includes(item.id) && handleRead(item.id); }}
                                                >
                                                    <ListItemText
                                                        primary={content}
                                                        secondary={moment(item.timestamp).fromNow()}
                                                    />
                                                </ListItem>
                                        }
                                    </ListItem>
                                );
                            }}
                        >
                        </Virtuoso>
                        {isSmall &&
                            <Box sx={{ height: "8%", width: "100%" }}>
                                <Button
                                    aria-controls={openTypesMobile ? 'types-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openTypesMobile ? 'true' : undefined}
                                    onClick={handleClickTypes}
                                    variant="outlined"
                                    sx={{ fontWeight: "bold", width: "50%", height: "100%", borderWidth: 1, borderRightWidth: 0.5, borderRadius: 0, borderBottomLeftRadius: 16, borderColor: theme.palette.primary.main }}>
                                    Activity
                                </Button>
                                <Button
                                    aria-controls={openHistoryMobile ? 'history-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openHistoryMobile ? 'true' : undefined}
                                    onClick={handleClickHistory}
                                    variant="outlined"
                                    sx={{ fontWeight: "bold", width: "50%", height: "100%", borderWidth: 1, borderLeftWidth: 0.5, borderRadius: 0, borderBottomRightRadius: 16, borderColor: theme.palette.primary.main }}>
                                    History
                                </Button>
                            </Box>
                        }
                    </>
                    :
                    !isSmall
                        ?
                        <Box sx={{ height: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
                                No new notifications
                            </Typography>
                        </Box>
                        :
                        <>
                            <Box sx={{ height: "82%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.primary.main, position: "relative", top: "10%" }}>
                                    No new notifications
                                </Typography>
                            </Box>
                            <Box sx={{ height: "8%" }}>
                                <Button
                                    aria-controls={openTypesMobile ? 'types-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openTypesMobile ? 'true' : undefined}
                                    onClick={handleClickTypes}
                                    variant="outlined"
                                    sx={{ fontWeight: "bold", width: "50%", height: "100%", borderWidth: 1, borderRightWidth: 0.5, borderRadius: 0, borderBottomLeftRadius: 16, borderColor: theme.palette.primary.main }}>
                                    Activity
                                </Button>
                                <Button
                                    aria-controls={openHistoryMobile ? 'history-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openHistoryMobile ? 'true' : undefined}
                                    onClick={handleClickHistory}
                                    variant="outlined"
                                    sx={{ fontWeight: "bold", width: "50%", height: "100%", borderWidth: 1, borderLeftWidth: 0.5, borderRadius: 0, borderBottomRightRadius: 16, borderColor: theme.palette.primary.main }}>
                                    History
                                </Button>
                            </Box>
                        </>
                }


            </Grid >
            <ConfirmationDialog
                title={["Move"]}
                handleDevice={(e) => handleHistoryMany(e)}
                handle
                name={"all selected logs"}
                open={openHistory}
                setOpen={setOpenHistory}
                content={contentHistory}
            />
            {
                openGroup && dashboardContent &&
                <Modal
                    open={openGroup}
                    onClose={() => setOpenGroup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <DashboardModalSocialGroup item={dashboardContent} />
                    </Box>
                </Modal>
            }

            {
                openCompany &&
                <Modal
                    open={openCompany}
                    onClose={() => setOpenCompany(false)}
                    aria-labelledby="modal-modal-title2"
                    aria-describedby="modal-modal-description2"
                    BackdropProps={{ style: { opacity: 0.2 } }}
                >
                    <Box sx={style}>
                        <DashboardModalCompany />
                    </Box>
                </Modal>
            }

            <Menu
                id="basic-menu"
                anchorEl={openTypesMenu}
                open={openTypesMobile}
                onClose={handleCloseTypes}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                PaperProps={{ sx: { width: "45%" } }}
            >
                <MenuItem onClick={() => { setActiveFilters([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]); setHistory(false); handleCloseTypes(); }}>All</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([1, 8, 10]); setHistory(false); handleCloseTypes(); }}>Company</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([2, 4, 6, 7, 9]); setHistory(false); handleCloseTypes(); }}>Groups</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([3]); setHistory(false); handleCloseTypes(); }}>Users</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([11, 12, 13]); setHistory(true); handleCloseHistory(); }}>Messages</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([5]); setHistory(false); handleCloseTypes(); }}>Invites</MenuItem>
            </Menu>
            <Menu
                id="basic-menu"
                anchorEl={openHistoryMenu}
                open={openHistoryMobile}
                onClose={handleCloseHistory}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                PaperProps={{ sx: { width: "45%" } }}
            >
                <MenuItem onClick={() => { setActiveFilters([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]); setHistory(true); handleCloseHistory(); }}>All</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([1, 8, 10]); setHistory(true); handleCloseHistory(); }}>Company</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([2, 4, 6, 7, 9]); setHistory(true); handleCloseHistory(); }}>Groups</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([3]); setHistory(true); handleCloseHistory(); }}>Users</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([11, 12, 13]); setHistory(true); handleCloseHistory(); }}>Messages</MenuItem>
                <MenuItem onClick={() => { setActiveFilters([5]); setHistory(true); handleCloseHistory(); }}>Invites</MenuItem>
            </Menu>
        </Grid >

    );
}
