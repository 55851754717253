import React, { } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import MissingLinkLogo from '../Assets/Images/missingLinkLogo.svg';

export default function TermsConditions() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box
            sx={{
                padding: isSmall ? 0 : 10,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'repeat',
                background: "linear-gradient(70deg, rgba(231,45,80,.3), rgba(231,45,80,0) 70.71%), linear-gradient(140deg, rgba(141,191,48,.3), rgba(141,191,48,0) 70.71%), linear-gradient(240deg, rgba(78,193,237,.3), rgba(78,193,237,0) 70.71%), linear-gradient(320deg, rgba(0,28,81,.3), rgba(0,28,81,0) 70.71%)",
            }}
        >
            <Box sx={{ padding: 5, borderRadius: 4, backgroundColor: "#ffffff", position: "relative" }}>
                <img src={MissingLinkLogo} alt="MissingLinlogo" width={120} style={{ position: "absolute", top: 30, right: 40, display: isSmall && "none"}}/>
                <Typography component={"div"} sx={{ color: "#808080", fontSize: 14 }}>
                    AGREEMENT
                </Typography>
                <Typography component={"div"} variant="h4" sx={{ mt: 3, fontWeight: "bold" }}>
                    Terms & Conditions
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold", textDecoration: "underline" }}>
                    Wifi-WiSe system in general:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    This application is part of the WiSe System of Missing-Link Oy. The system scans wireless networks in exactly the same way as any wireless base station. The system does not store or process encrypted data, and therefore does not know anything about the payload data being transmitted in the area. The system is therefore fully legal and complies with both Finnish and EU legislation.

                    The system continuously collects more data from its environment, which can be used for general statistical purposes. It can be used many wonderful things like an example: To improve the coverage of wireless networks, calculate their utilisation rates, interferences and find out the number of visitors and mass movements in the area without creating a so-called “personal data record.”
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold", textDecoration: "underline" }}>
                    a few words about GDPR:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    This application and the information entered by the User about his/her own devices is the only link to the User's profile, picture, devices, and other similar data with the data collected by the Wifi-Wise System. Without the information controlled by the User's own profile, the information collected by the System cannot be attributed to an individual. Thus, the user becomes the owner of the data collected from the devices when he or she has confirmed that he or she is the legal owner and/or user of that device. Once the owner of the collected data is identified, he/she has the right to control how and in what context the data collected from his/her devices is used.
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold", textDecoration: "underline" }}>
                    Control of collected data:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    The individual therefore has the right to decide how their data is used. For this purpose, there is always a choice of features in the system that allow the individual to limit the amount of information that is visible to others than him/her or to prevent its use, for example in external communications.

                    These features are always available in the required number as the system is extended, and there are currently three features available:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold" }}>
                    1. Dashboard:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    The Dasboard tab contains a "Data Share" section from which the user can choose his visibility. For example, this means that you can directly influence whether you want to appear on the Info screen.
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold" }}>
                    2. Go busy:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    This option can be found in the top right corner by clicking on the profile picture. From the drop-down menu, the user can choose to be present but busy/busy at the time of their choice. When this function is enabled, the user can select the "Go Busy" option from the drop-down menu. The system will indicate that you do not want to be disturbed during this time.
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold" }}>
                    3. Go hidden:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    This option can also be found in the drop-down menu in the top right corner. If desired, the user can specify to be “away” and present at desired time. When this function is enabled, the system will show that you are “away.”

                    We are constantly improving our system and your customer experience is important to us and safeguarding your privacy is our top priority. We welcome your feedback on how our system should be improved to make it the best possible tool for your daily use.
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold", textDecoration: "underline" }}>
                    Deletion of personal data from the system:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    As mentioned in previous chapters, the only link between personal data and the WiSe system is through this application and user's own user profile. If you do not want your name, picture, or other information to be linked to your devices through the system, you have the right, at any time, to disconnect this link if necessary. This is done by deleting your registered devices and data from the system.
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold", textDecoration: "underline" }}>
                    Permission to use the data collected throught the application:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    By accepting this document, the user grants access to the data collected from his/her devices and link to their profiles for the purpose of developing the system.
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold", textDecoration: "underline" }}>
                    Updating the terms of use:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    These terms of use will no doubt be revised several times in the future and will certainly take on the characteristics of legal jargon. However, we hope to keep it as human-oriented and clear for as long as possible.

                    Unfortunately, each update to the Terms of Use will also lead to a new request for approval, so we will endeavour to prepare them carefully before bothering you with them.

                    We apologise for any inconvenience or inconvenience this process may cause and hope that you will help us to make our system the best in the world.
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16, fontWeight: "bold", textDecoration: "underline" }}>
                    Contact details:
                </Typography>
                <Typography component={"div"} sx={{ mt: 3, fontSize: 16 }}>
                    If you have any suggestions for improvements or are concerned about how your data is stored or used, please contact our customer service team at <a href="mailto: Support@Missing-Link.fi" target="_blank" rel="noreferrer"> Support@Missing-Link.fi</a> or visit our offices at Åkerlundinkatu 8, 33100 Tampere.
                </Typography>
            </Box>
        </Box>
    );
}


