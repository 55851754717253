import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Divider, IconButton, SwipeableDrawer, Toolbar, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import ListItems from './ListItems';


export default React.memo(function DrawerMobile({ user, open, toggleDrawer, lastseenminutes }) {
    const theme = useTheme();

    return (
        <SwipeableDrawer
            open={open}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            PaperProps={{ sx: { backgroundColor: theme.palette.darkBlue.main, width: 240, minHeight: '100vh' } }}
            /* ModalProps={{ keepMounted: true }} */
            disableSwipeToOpen
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: [1],
                }}
            >
                <Typography component={"div"} variant="h6" sx={{ fontSize: 22, color: '#fff' }}>Missing-Link</Typography>
                <IconButton onClick={toggleDrawer} sx={{ fontSize: 22, color: '#fff' }}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <ListItems userId={user.id} userdata={user} drawerOpen={open} toggleDrawer={toggleDrawer} lastseenminutes={lastseenminutes} />
        </SwipeableDrawer>
    );
});