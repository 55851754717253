import {
    Typography, Box, Card, CardContent, IconButton, Modal, Button, CardMedia, Grid, useMediaQuery, useTheme
} from '@mui/material';
import ActivityLog from '../ActivityLog';
import BallotIcon from '@mui/icons-material/Ballot';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';




export default function SocialGroupModal({ modal, groupId, socialGroup }) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));


    const modalStyleMobile = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: modal.activityLogOn ? 1 : 4,
        pr: modal.activityLogOn ? 6 : 4,
        borderRadius: 4,
        display: "flex",
        width: "90vw",
        height: modal.activityLogOn ? "75%" : undefined,
    };

    return (

        <Modal
            open={modal.modalOpen}
            onClose={() => modal.setModalOpen(false)}
            aria-labelledby="img-modal-title"
            aria-describedby="img-modal-description"
        /* keepMounted */
        >
            {isSmall
                ?
                <Card sx={modalStyleMobile}>
                    {modal.activityLogOn
                        ?
                        <>
                            <IconButton
                                size="medium"
                                onClick={() => modal.setActivyLogOn(false)}
                                sx={{ position: "absolute", right: 8, top: 8 }}
                            >
                                <ArrowBackIcon sx={{ color: '#000' }} />
                            </IconButton>

                            <Box sx={{ width: "100%", height: "100%" }}>
                                <ActivityLog groupId={groupId} />
                            </Box>
                        </>
                        :
                        <Grid container>
                            <IconButton
                                size="medium"
                                onClick={() => modal.setModalOpen(false)}
                                sx={{ position: "absolute", right: 8, top: 8 }}
                            >
                                <ClearIcon sx={{ color: '#000' }} />
                            </IconButton>

                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 250, height: 250 }}
                                    image={`${process.env.REACT_APP_IMAGE_SERVE_HOST}/socialgrouppic/${socialGroup.id}_256.webp`}
                                    alt="socialGroupImg"
                                />
                            </Grid>


                            <Grid item xs={12} textAlign="center">
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h4">
                                            {socialGroup.name}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div" fontSize={16}>
                                            {socialGroup.description}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </Card>
                :
                <Card sx={modal.modalStyle}>
                    {modal.activityLogOn
                        ?
                        <>
                            <IconButton
                                size="medium"
                                onClick={() => modal.setActivyLogOn(false)}
                                sx={{ position: "absolute", right: 8, top: 8 }}
                            >
                                <ArrowBackIcon sx={{ color: '#000' }} />
                            </IconButton>

                            <Box sx={{ width: "100%", height: "100%" }}>
                                <ActivityLog groupId={groupId} />
                            </Box>
                        </>
                        :
                        <>
                            <IconButton
                                size="medium"
                                onClick={() => modal.setModalOpen(false)}
                                sx={{ position: "absolute", right: 8, top: 8 }}
                            >
                                <ClearIcon sx={{ color: '#000' }} />
                            </IconButton>

                            <CardMedia
                                component="img"
                                sx={{ width: 250, height: 250 }}
                                image={`${process.env.REACT_APP_IMAGE_SERVE_HOST}/socialgrouppic/${socialGroup.id}_256.webp`}
                                alt="socialGroupImg"
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h4">
                                        {socialGroup.name}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" component="div" fontSize={16}>
                                        {socialGroup.description}
                                    </Typography>
                                </CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end", pl: 1, width: "100%" }}>
                                    <Button variant="outlined" aria-label="activityLog" onClick={() => modal.setActivyLogOn(true)}>
                                        <BallotIcon sx={{ height: 38, width: 38 }} />
                                        Activity Log
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    }
                </Card>
            }

        </Modal>
    );
}