import React from 'react';
import { useContext, useMemo } from 'react';
import { Grid, Typography, Divider, useMediaQuery, useTheme } from "@mui/material";
import LayoutPaper from "../../Components/LayoutPaper";
import { MainContext } from 'kerberus-components';
import moment from 'moment';
import useSWR from 'swr';
import GroupsIcon from '@mui/icons-material/Groups';
import UserList from '../../Components/UserList';
import DatagridColumns from '../../Components/MyConnections/DatagridColumns';
import DatagridColumnsMobile from '../../Components/MyConnections/DatagridColumnsMobile';


export default React.memo(function MyConnectionsView() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { bulkData } = useContext(MainContext);
    const { data: profiles = [] } = useSWR('users/user_connections');

    const onlineData = useMemo(() => {
        const userMap = new Map();
        if (bulkData) {
            for (const user of bulkData.company) {
                userMap.set(user.user_id, user);
            }
            for (const group of bulkData.social_groups) {
                for (const user of group.users) {
                    const oldUser = userMap.get(user.user_id);

                    if (!oldUser?.timestamp) {
                        userMap.set(user.user_id, user);
                    }
                    else if (oldUser.timestamp && user.timestamp) {
                        if (new Date(user.timestamp) > new Date(oldUser.timestamp)) {
                            userMap.set(user.user_id, user);
                        }
                    }
                }
            }
        }

        return [...userMap.values()];
    }, [bulkData]);

    const onlineMembers = useMemo(() => {
        let tempNum = 0;
        const now = moment();

        for (const user of onlineData) {
            if (user.timestamp) {
                const lastSeen = moment(user.timestamp);
                const duration = moment.duration(now.diff(lastSeen));
                const lastseenminutes = duration.asMinutes();
                if (lastseenminutes <= 10) {
                    tempNum++;
                }
            }
        }
        return tempNum;
    }, [onlineData]);

    return (
        <LayoutPaper>
            <Grid container>

                <Grid item xs={12} md sx={{ display: "flex", flexDirection: "row", marginBottom: 1, marginTop: -1, alignItems: "center", width: "100%" }}>

                    <Typography component={"div"} variant="h4" textAlign="center" sx={{ color: theme.palette.primary.main, width: isSmall ? "100%" : undefined, display: "flex", alignItems: "center", justifyContent: "center", mb: isSmall && 2 }} >
                        <GroupsIcon color="primary" sx={{ width: 42, height: 42, marginRight: 2 }} />
                        My Connections
                    </Typography>
                    {!isSmall &&
                        <Typography component={"div"} variant="h4"
                            sx={{ color: theme.palette.primary.main, marginLeft: 2 }}
                        >
                            {onlineMembers}/{profiles.length}
                        </Typography>
                    }

                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ mb: 2 }} />
                </Grid>

                <Grid container>
                    <UserList userList={profiles} onlineData={onlineData} gridColumns={DatagridColumns} gridColumnsMobile={DatagridColumnsMobile} default="card" datagridHeight="70vh" />
                </Grid>
            </Grid>
        </LayoutPaper>
    );
});