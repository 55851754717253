import React, { useEffect } from "react";
import { TextField, Button } from "@mui/material";
import InputMask from "react-input-mask";


export default function TagsInput2({ selectedItem, setSelectedItem, inputValue, setInputValue, selectedTags, placeholder, tags, macAddressAlreadyTaken, addrBluetooth, setAddrBluetooth, ...props }) {

    useEffect(() => {
        setSelectedItem(tags);
    }, [setSelectedItem, tags]);

    useEffect(() => {
        selectedTags(selectedItem);
    }, [selectedItem, selectedTags]);

    function addAddress() {
        const newSelectedItem = [...selectedItem];

        if (newSelectedItem.length > 0 && newSelectedItem.find(object => object.address === inputValue)) {
            setInputValue("");
            return;
        }

        else {
            setSelectedItem([...selectedItem, { address: inputValue, bluetooth: addrBluetooth }]);
            setInputValue("");
            setAddrBluetooth(false)
        }
    }

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }

    return (
        <InputMask
            mask="**:**:**:**:**:**"
            value={inputValue}
            onChange={handleInputChange}
            disabled={false}
            maskChar=""
            formatChars={{
                '9': '[0-9]',
                'a': '[A-Za-z]',
                '*': '[A-Fa-f0-9]',
            }}
        >
            {() =>
                <TextField
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && inputValue.length === 17 && !macAddressAlreadyTaken) {
                            addAddress()
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <Button
                                aria-label="add"
                                variant="contained"
                                sx={{ height: "50%" }}
                                onClick={addAddress}
                                disabled={inputValue.length !== 17 || macAddressAlreadyTaken}
                            >
                                Add
                            </Button>
                        )
                    }}
                    {...props}
                />}
        </InputMask>
    );
}
