function getCached(params) {
    try {
        let b64 = window.btoa(JSON.stringify(params));
        let stored = localStorage.getItem(b64);
        return JSON.parse(stored);
    }
    catch (err) {
        console.error(err);
        return null;
    }

}

function populateCache(params, data) {
    try {
        let b64 = window.btoa(JSON.stringify(params));
        localStorage.setItem(b64, JSON.stringify(data));
    }
    catch (err) {
        console.error(err);
    }
}

export { getCached, populateCache };