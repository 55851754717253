import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Tooltip, useTheme } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import HelperIcon from './HelperIcon';
import ConfirmationDialog from './ConfirmationDialog';
import sortBy from 'lodash-es/sortBy';
import moment from 'moment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReportIcon from '@mui/icons-material/Report';
import GetDeviceIcon from './Device/GetDeviceIcon';

const contentHelperIcon = (
    <>
        <div>This selection determines if device is used in status and calendar view.</div>
        <br />
        <div>Changes may take several minutes to reflect.</div >
    </>
);

const locationHelperIcon = (
    <>
        <div>This selection determines which device is used for showing location data.</div>
        <br />
        <div>Changes may take several minutes to reflect.</div >
    </>
);

const statusHelperIcon = (
    <>
        <div>This indicates if device is active at the moment</div>
    </>
);

export default function InteractiveDeviceList({ devgroup, isSmall, handleDeviceRemove, handleDeviceVisibility, handleDeviceLocation, onlineDevs, handleDeleteDevGroup }) {
    const theme = useTheme();
    const [openTrack, setOpenTrack] = useState(false);
    const [valueTrack, setValueTrack] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [valueDelete, setValueDelete] = useState(false);

    const [openLocation, setOpenLocation] = useState(false);
    const [valueLocation, setValueLocation] = useState(false);

    function everyCheckboxIsFalse(element) {
        return element.every(element => element === false);
    };

    const checkboxBooleansArray = devgroup.map((value) => {
        return value.visible;
    });

    const checkHowManyBooleansAreTrue = checkboxBooleansArray.filter(Boolean).length;

    const contentTrack = (
        <>
            <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
                This selection determines if
                <span style={{ fontWeight: "bold" }}>{" " + valueTrack.group_name + " "}</span>
                is used in status and calendar view. Changes to tracking may take several minutes to reflect.
            </Typography>
            <Typography fontWeight={"bold"} sx={{ display: checkHowManyBooleansAreTrue === 1 && valueTrack.visible ? "auto" : "none" }}>
                Warning! 2 weeks of inactivity will take away your rights to see other people!
            </Typography>
        </>

    );

    const contentLocationTrack = (
        <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
            This selection determines if
            <span style={{ fontWeight: "bold" }}>{" " + valueLocation.group_name + " "}</span>
            is used for showing location data. Changes to location usage may take several minutes to reflect.
        </Typography>

    );

    const contentDelete = (
        <Typography component={"div"} style={{ overflowWrap: "break-word" }}>
            Are you sure you want to delete
            <span style={{ fontWeight: "bold" }}>{" " + valueDelete.group_name + "? "}</span>
            This will remove device from the list and stop tracking.
        </Typography>
    );

    const today = moment();

    return (
        <Grid item xs={12} md={12} sx={{ bgcolor: "white" }}>
            <Typography component={"div"} sx={{ textAlign: "center", textDecoration: "underline" }} variant="h5">
                Connected devices
            </Typography>
            <Typography sx={{ display: everyCheckboxIsFalse(checkboxBooleansArray) && isSmall ? "auto" : "none", color: "red", textAlign: "center" }}>
                You are not tracking any devices!
            </Typography>
            <>
                {devgroup && devgroup.length > 0 ?
                    <List dense sx={{ marginX: isSmall ? 0.3 : 1 }}>

                        {!isSmall &&
                            <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: everyCheckboxIsFalse(checkboxBooleansArray) && 3 }}>
                                <Grid item xs={3}>
                                    <Typography component={"div"} sx={{ position: "relative", left: 65 }}>
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", left: 6 }}>
                                        Device info
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        Status
                                        <HelperIcon style={{ position: "relative", top: 3, left: 3 }}
                                            content={statusHelperIcon} />
                                    </Typography>

                                </Grid>
                                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                    <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        Location
                                        <HelperIcon style={{ position: "relative", top: 3, left: 3 }}
                                            content={locationHelperIcon} />
                                    </Typography>
                                    <Typography component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", right: 15 }}>
                                        Track
                                        <HelperIcon style={{ position: "relative", top: 3, left: 3 }}
                                            content={contentHelperIcon} />
                                        <Typography sx={{ display: everyCheckboxIsFalse(checkboxBooleansArray) ? "auto" : "none", color: "red", position: "absolute", minWidth: 300, bottom: 30, ml: 5 }}>
                                            You are not tracking any devices!
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>

                                </Grid>
                            </Grid>
                        }
                        {sortBy(devgroup, ["id"]).map((value, id) => {
                            const activeAddresses = onlineDevs.length > 0 && onlineDevs.filter(addr => value.addresses.some(item => addr.wlan_sa === item.address))
                            const lastseen = activeAddresses.length > 0 && activeAddresses.reduce((a, b) => today - moment(a.timestamp) < today - moment(b.timestamp) ? a : b);
                            const lastseenminutes = moment.duration(today.diff(lastseen.timestamp)).asMinutes();

                            return (
                                <ListItem

                                    key={value.id}
                                    sx={{ borderStyle: "solid", borderColor: theme.palette.primary.main, borderWidth: 2, borderRadius: 10, marginY: isSmall ? 2 : 1 }}
                                >
                                    {isSmall
                                        ?
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <ListItemButton
                                                    component={Link}
                                                    to={`${value.id}`}
                                                    sx={{
                                                        borderRadius: 10,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}>
                                                    <GetDeviceIcon value={value} component={Link} to={`${value.id}`}
                                                        sx={{
                                                            height: 50,
                                                            width: 50,
                                                            p: 0.75,
                                                            borderStyle: "solid",
                                                            borderColor: lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? "#FCC041" : lastseenminutes > 0 && lastseenminutes < 5 ? "#8DBF30" : '#adadb8' : '#adadb8',
                                                            borderWidth: 3,
                                                            bgcolor: theme.palette.primary.main + 50,
                                                            color: theme.palette.primary.main,
                                                        }}
                                                    />
                                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "flex", flexDirection: "row", marginLeft: 5 }}>
                                                        <ListItemText
                                                            primary={value.group_name ?? "Device"}
                                                        />
                                                    </div>
                                                </ListItemButton>
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                                <Typography component={"div"}>
                                                    Use for location
                                                </Typography>
                                                <Checkbox sx={{}} checked={value.location} onChange={() => { setValueLocation(value); setOpenLocation(true); }} />
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                                <Typography component={"div"}>
                                                    Track
                                                </Typography>
                                                <Checkbox sx={{}} checked={value.visible} onChange={() => { setValueTrack(value); setOpenTrack(true); }} />
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: 1, marginBottom: 2 }}>
                                                <Button variant="contained" color="error" sx={{ borderRadius: 3 }} onClick={() => { setValueDelete(value); setOpenDelete(true); }}>
                                                    <DeleteIcon sx={{ marginLeft: -1, marginRight: 1 }} />
                                                    Remove device
                                                </Button>
                                            </Grid>

                                        </Grid>
                                        :
                                        <Grid container>

                                            <Grid item xs={3}>

                                                <Box
                                                    sx={{
                                                        borderRadius: 10,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        position: "relative",
                                                        p: 1
                                                    }}>
                                                    {value.addresses.length <= 0 &&
                                                        <Tooltip title="No mac addresses to be found. Refreshing the page may help!" placement='left'>
                                                            <IconButton component={Link} to={`${value.id}`}>
                                                                <ReportIcon color="warning" sx={{ ml: -1 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    <GetDeviceIcon value={value} tooltipPlacement="left" component={Link} to={`${value.id}`}
                                                        sx={{
                                                            height: 40,
                                                            width: 40,
                                                            p: 0.35,
                                                            borderStyle: "solid",
                                                            borderColor: lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? "#FCC041" : lastseenminutes > 0 && lastseenminutes < 5 ? "#8DBF30" : '#adadb8' : '#adadb8',
                                                            borderWidth: 3,
                                                            bgcolor: theme.palette.primary.main + 50,
                                                            color: theme.palette.primary.main,
                                                        }}
                                                    />
                                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "flex", flexDirection: "row", marginLeft: 8 }}>
                                                        <ListItemText
                                                            primary={value.group_name ?? "Device"}
                                                        />
                                                    </div>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                                <Tooltip title="Device info" placement='left'>
                                                    <IconButton component={Link} to={`${value.id}`}>
                                                        <PermDeviceInformationIcon sx={{ color: theme.palette.primary.main }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Device calendar" placement='right'>
                                                    <IconButton component={Link} to={`${value.id}/calendar`}>
                                                        <CalendarMonthIcon sx={{ color: theme.palette.green.main }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>

                                            <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography component={"div"}>
                                                    {lastseen && lastseenminutes <= 10
                                                        ?
                                                        <Typography sx={lastseenminutes >= 5 ? { color: theme.palette.mlYellow.main } : { color: theme.palette.mlGreen.main }}>Active</Typography>
                                                        :
                                                        <Typography sx={{ color: "gray" }}>Inactive</Typography>}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                                <Checkbox sx={{}} checked={value.location} onChange={() => { setValueLocation(value); setOpenLocation(true); }} />
                                                <Checkbox sx={{}} checked={value.visible} onChange={() => { setValueTrack(value); setOpenTrack(true); }} />
                                            </Grid>

                                            <Grid item xs={1} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                <IconButton aria-label="delete" onClick={() => { setValueDelete(value); setOpenDelete(true); }}>
                                                    <DeleteIcon sx={{ color: theme.palette.mlRed.main }} />
                                                </IconButton>
                                            </Grid>


                                        </Grid>
                                    }
                                </ListItem>
                            )
                        }
                        )}
                    </List>
                    :
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "40vh", textAlign: "center" }} >
                        <Avatar sx={{ marginBottom: 2, width: 120, height: 120, bgcolor: theme.palette.primary.main + 50 }}>
                            <DeviceUnknownIcon sx={{ width: 70, height: 70, color: theme.palette.primary.main }} />
                        </Avatar>
                        <Typography component={"div"} variant="h5">
                            No devices found
                        </Typography>
                        <Typography component={"div"} variant="h6" color={"#808080"}>
                            Please add device to populate the list :)
                        </Typography>
                    </div>
                }
            </>

            <ConfirmationDialog
                title={["Track", "Stop Tracking"]}
                handleDevice={handleDeviceVisibility}
                name={valueTrack.group_name}
                address={valueTrack.address}
                value={valueTrack}
                open={openTrack}
                setOpen={setOpenTrack}
                content={contentTrack}
                checkValue={valueTrack.visible}
            />

            <ConfirmationDialog
                title={["Delete"]}
                handleDevice={handleDeleteDevGroup}
                name={valueDelete.group_name}
                value={valueDelete}
                open={openDelete}
                setOpen={setOpenDelete}
                content={contentDelete}
            />

            <ConfirmationDialog
                title={["Add", "Remove"]}
                handleDevice={handleDeviceLocation}
                name={valueLocation.group_name}
                address={valueLocation.address}
                value={valueLocation}
                open={openLocation}
                setOpen={setOpenLocation}
                content={contentLocationTrack}
                checkValue={valueLocation.location}
            />

        </Grid >
    );
}