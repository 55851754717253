import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend } from 'recharts';
import { Box, Typography, useTheme, ToggleButtonGroup, ToggleButton, IconButton, Grid, useMediaQuery, TextField, Button } from '@mui/material';
import HelperIcon from './HelperIcon';
import moment from 'moment';
import { Square } from "../Components/Icons/Shapes";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const renderLegend = (props) => {
    const { payload } = props;
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {
                payload.map((entry, index) => (
                    <Box key={`item-${index}`} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography>
                            <Square scale={2} color={entry.color} />
                            User count
                        </Typography>
                    </Box>
                ))
            }
        </Box>
    );
};

/* const CustomTooltip = ({ active, payload, label }) => {
    console.log(active, payload, label)
    if (active && payload && payload.length) {
      return (
        <Box sx={{ backgroundColor: "#ffffff", p: 1, border: "1px solid gray"}}>
          <Typography>{`${moment(label).format("DD.MM.YYYY HH:mm")}`}</Typography>
          <Typography color="primary">{`Users count: ${payload[0].value}`}</Typography>
        </Box>
      );
    }
  
    return null;
  }; */

export default function ActivityGraph({ data, setDuration, interval, duration, chartType, setChartType, date, setDate, label }) {
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    const [alignment, setAlignment] = useState(duration);

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const nextDay = () => {
        setDate(moment(date).add(1, 'days'));
    };

    const nextWeek = () => {
        setDate(moment(date).add(1, 'weeks'));
    };

    const nextMonth = () => {
        setDate(moment(date).add(1, 'month'));
    };

    const prevDay = () => {
        setDate(moment(date).subtract(1, 'days'));
    };

    const prevWeek = () => {
        setDate(moment(date).subtract(1, 'weeks'));
    };

    const prevMonth = () => {
        setDate(moment(date).subtract(1, 'months'));
    };

    return (
        <Grid container>

            <IconButton style={{ position: "absolute", right: 3, bottom: 3, display: isMedium && "none", zIndex: 1 }} onClick={() => setChartType((prev) => prev === "bar" ? "area" : "bar")}>
                <EqualizerIcon color="primary" />
            </IconButton>
            <HelperIcon placement="left" content="This graph displays group's online count by time" style={{ position: "absolute", left: 5, top: 5, display: isMedium && "none" }} />

            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: isMedium && "column" }}>



                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button variant="outlined" sx={{ display: isMedium && "none", mr: 2 }} onClick={() => setDate(moment())}>
                        Today
                    </Button>

                    <IconButton onClick={duration === "day" ? prevDay : duration === "week" ? prevWeek : prevMonth}>
                        <ArrowBackIcon color="primary" />
                    </IconButton>
                    {isMedium
                        ?
                        <MobileDatePicker
                            label="Date"
                            inputFormat="DD.MM.yyyy"
                            value={date}
                            onChange={(e) => setDate(e)}
                            renderInput={(params) => <TextField {...params} sx={{ my: 1 }} />}
                            mask=""
                        />
                        :
                        <DesktopDatePicker
                            label="Date"
                            inputFormat="DD.MM.yyyy"
                            value={date}
                            onChange={(e) => setDate(e)}
                            renderInput={(params) => <TextField {...params} sx={{ mx: 1 }} />}
                            mask=""
                        />
                    }
                    <IconButton onClick={duration === "day" ? nextDay : duration === "week" ? nextWeek : nextMonth}>
                        <ArrowForwardIcon color="primary" />
                    </IconButton>
                </Box>

                <Button variant="outlined" sx={{ mb: 1, display: !isMedium && "none" }} onClick={() => setDate(moment())}>
                    Today
                </Button>

                <Box sx={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <ToggleButtonGroup
                        aria-label="duration-group"
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        color="primary"
                    >
                        <ToggleButton value="day" aria-label="left aligned" onClick={() => setDuration("day")}>
                            Day
                        </ToggleButton>
                        <ToggleButton value="week" aria-label="centered" onClick={() => setDuration("week")}>
                            Week
                        </ToggleButton>
                        <ToggleButton value="month" aria-label="right aligned" onClick={() => setDuration("month")}>
                            Month
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

            </Grid>

            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={500}>
                    {chartType === "bar"
                        ?
                        <BarChart
                            width={150}
                            height={40}
                            data={data}
                            margin={
                                isMedium
                                    ?
                                    {
                                        top: 10,
                                        right: 10,
                                        left: 0,
                                        bottom: 5,
                                    }
                                    :
                                    {
                                        top: 10,
                                        right: 30,
                                        left: 5,
                                        bottom: 5,
                                    }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="time"
                                ticks={
                                    duration === "week" && data && interval === 120 &&
                                    [data[2]?.time, data[14]?.time, data[26]?.time, data[38]?.time, data[50]?.time, data[62]?.time, data[74]?.time]
                                }
                                tickFormatter={
                                    duration === "month"
                                        ?
                                        (e) => moment(e).format("DD")
                                        :
                                        duration === "week"
                                            ?
                                            (e) => moment(e).format("dd")
                                            :
                                            (e) => moment(e).format("HH")
                                }
                            />
                            <YAxis width={isMedium ? 20 : 60} type="number" allowDecimals={false} label={!isMedium && { value: label, angle: -90, position: 'insideLeft', offset: 10 }} />
                            <Tooltip /* content={<CustomTooltip />} */ labelFormatter={(e) => moment(e).format("DD.MM.YYYY HH:mm")} formatter={(e) => [e, "Users count"]}/>
                            <Legend content={renderLegend} />
                            <Bar dataKey="ucount" fill={theme.palette.primary.main} />
                        </BarChart>
                        :
                        <AreaChart
                            data={data}
                            margin={
                                isMedium
                                    ?
                                    {
                                        top: 10,
                                        right: 10,
                                        left: 0,
                                        bottom: 5,
                                    }
                                    :
                                    {
                                        top: 10,
                                        right: 30,
                                        left: 5,
                                        bottom: 5,
                                    }}
                        >
                            <defs>
                                <linearGradient id="blueish" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={theme.palette.mlBlue.main} stopOpacity={0.95} />
                                    <stop offset="95%" stopColor={theme.palette.mlBlue.main} stopOpacity={0.1} />
                                </linearGradient>
                                <linearGradient id="blueish" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={theme.palette.mlBlue.main} stopOpacity={0.95} />
                                    <stop offset="95%" stopColor={theme.palette.mlBlue.main} stopOpacity={0.1} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="time"
                                ticks={
                                    duration === "week" && data && interval === 120 &&
                                    [data[2]?.time, data[14]?.time, data[26]?.time, data[38]?.time, data[50]?.time, data[62]?.time, data[74]?.time]
                                }
                                tickFormatter={
                                    duration === "month"
                                        ?
                                        (e) => moment(e).format("DD")
                                        :
                                        duration === "week"
                                            ?
                                            (e) => moment(e).format("dd")
                                            :
                                            (e) => moment(e).format("HH")
                                }
                            />
                            <YAxis allowDecimals={false} type="number" label={!isMedium && { value: label, angle: -90, position: 'insideLeft', offset: 10 }} />
                            <Tooltip labelFormatter={(e) => moment(e).format("DD.MM.YYYY HH:mm")} formatter={(e) => [e, "Users count"]} />
                            <Legend content={renderLegend} />
                            <Area type="monotone" dataKey="ucount" stroke={theme.palette.mlBlue.main} fillOpacity={1} fill="url(#blueish)" />
                        </AreaChart>

                    }
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
}