import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MainContext, SmallAvatarWrapper, useFetchCloud } from 'kerberus-components';
import * as React from 'react';
import { useCallback, useContext, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import CompanyLinks from '../../Components/Company/CompanyLinks';
import LayoutPaper from "../../Components/LayoutPaper";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ConfirmationDialog from '../../Components/ConfirmationDialog';

const copyToast = {
    open: true,
    type: "success",
    title: "Success",
    message: "Dashboard URL copied to clipboard successfully",
    verticalPos: "bottom",
    horizontalPos: "center",
    autoHideDuration: 5000
};

export default function CompanySettings() {
    const { user } = useContext(MainContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const imgSrc = `${process.env.REACT_APP_IMAGE_SERVE_HOST}/companypic/${user.user_company.id}_128.webp`;
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [loading, setLoading] = useState('');
    const [file, setFile] = useState(null);
    const [image, setImage] = useState();
    const [urlDialog, setUrlDialog] = useState(false);
    const [dashboard] = useState("company");
    const [selectedGroup] = useState();
    const [setUrlGroup] = useState('');
    const [url, setUrl] = useState('');
    const [dialogShown, setDialogShown] = useState(false);

    const { setOpen: setOpenToast } = useContext(MainContext);

    const handleLayoutUrl = async (e) => {
        const res = await fetchCloud(`users/generate_dashboard`, 'post', {
            dashboard_type: dashboard,
            social_group: selectedGroup?.id
        });
        if (dashboard === "company") {
            const resGroup = await fetchCloud(`users/generate_dashboard`, 'post', {
                dashboard_type: "group",
            });
            setUrlGroup(resGroup.data);

        }
        setUrl(res.data);
    };

    const { fetchCloud } = useFetchCloud();

    const onDrop = useCallback(async acceptedFiles => {
        setUploadDisabled(true);
        const imageUrl = URL.createObjectURL(acceptedFiles[0]);
        setImage(imageUrl);
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, disabled: uploadDisabled });

    const handleUpload = async () => {
        try {
            setLoading('Loading...');
            await fetchCloud('upload/company', 'post', {
                file: file,
            }, true, true);
            window.location.reload();
        }
        catch (err) {
            setLoading('Failed upload');
            console.error(err);
        }
    };

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(url);
        setOpenToast(copyToast);
    };

    const handleGetUrl = () => {
        if(!dialogShown) {
            setUrlDialog(true);
            /* setDialogShown(true); */
        } else {
            handleLayoutUrl()
        }
    }
    return (
        <LayoutPaper>
            <Grid container >
                <Grid item xs={12} md={10} sx={{ display: "flex", flexDirection: "row", marginBottom: 1, marginTop: isSmall ? 3 : -1, alignItems: "center" }}>

                    <Box sx={isSmall && { width: "100%" }}>
                        <Typography component={"div"} variant="h4" textAlign="center" sx={{ color: theme.palette.primary.main, width: isSmall ? "100%" : undefined, display: "flex", alignItems: "center", justifyContent: "center", mb: isSmall && 2 }} >
                            <SmallAvatarWrapper alt={user.user_company.name} sx={{ width: 50, height: 50, marginRight: 2, "&:hover": { cursor: "pointer" } }}
                                userId={user.user_company.id} imageType="companypic" imageSize="_64" />
                            {user.user_company.name}
                        </Typography>
                    </Box>


                </Grid>
                <Grid item xs md={2} sx={isSmall ? { position: "absolute", top: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "flex-end" } : { display: "flex", alignItems: "center", justifyContent: "flex-end", position: "relative", bottom: 20, left: 20 }}>
                    <IconButton onClick={() => navigate(-1)} >
                        <ArrowBackIcon sx={{ height: 30, width: 30 }} />
                    </IconButton>
                </Grid>
                <Divider sx={{ width: "100%", marginBottom: 2 }} />

                <Grid item xs={12} sx={{ display: "flex", flexDirection: isSmall ? "column" : "row" }}>

                    {(user.perm_level === "admin" || user.perm_level === "superuser") &&
                        <>
                            {isSmall
                                ?
                                <Divider flexItem sx={{ my: 6 }}>
                                    <AdminPanelSettingsIcon color="primary" sx={{ position: "relative", top: 20 }} />
                                </Divider>
                                :
                                <Divider orientation="vertical" flexItem sx={{ height: "100%" }} />
                            }

                            <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", paddingLeft: !isSmall && 4 }}>

                                <Grid item xs={12}>
                                    <Typography component={"div"} variant="h5" sx={{ width: "100%", textAlign: "center" }}>
                                        Company settings
                                        {!isSmall &&
                                            <Tooltip title="Admin" sx={{ position: "relative", left: 10, top: 5 }}>
                                                <AdminPanelSettingsIcon color="primary" />
                                            </Tooltip>
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: 2, justifyContent: isSmall && "center", mb: isSmall && 1 }}>
                                    {isSmall
                                        ?
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                            <Avatar onClick={() => setUploadDialogOpen(true)} alt={user.user_company.name} src={imgSrc} sx={{ height: 100, width: 100, my: 1 }} />
                                            <Typography variant="caption" textAlign="center">
                                                Press to change company picture
                                            </Typography>
                                        </Box>
                                        :
                                        <Avatar alt={user.user_company.name} src={imgSrc} sx={{ height: 100, width: 100, marginLeft: 6 }} />
                                    }

                                    {!isSmall &&
                                        <>
                                            <Button sx={{ marginLeft: 2 }} variant="contained" onClick={() => setUploadDialogOpen(true)}>
                                                Update Company Picture
                                            </Button>
                                        </>
                                    }

                                </Grid>

                                <Divider sx={{ my: 2 }} />

                                <Grid item xs={12} md={12} textAlign="center" >
                                    <Typography component={"div"} variant="h6" sx={{ marginBottom: 2 }}>Company Website & Slack</Typography>
                                    <CompanyLinks />
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 2, textAlign:"center" }}>
                                    <Typography variant="h6" sx={{ marginBottom: 2}}>
                                        Company Dashboard link
                                    </Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <Box sx={{ }}>
                                            {!isSmall &&
                                                <DashboardIcon color="primary" sx={{ position: "relative", width: 30, height: 30, mr: 1, mb: 4}} />
                                            }
                                        </Box>
                                        <TextField
                                            fullWidth
                                            value={url}
                                            variant="outlined"
                                            sx={{ position: "relative", bottom: 10, marginTop: 0.75 }}
                                            helperText="Click to reveal dashboard link"
                                            disabled={url.length < 1}
                                            placeholder="Click to reveal dashboard link"
                                            onClick={handleGetUrl}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip title="Copy to Clipboard">
                                                            <IconButton disabled={url.length < 1} onClick={copyToClipboard}>
                                                                <ContentCopyIcon sx={{ height: 20, width: 20 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>  
                                </Grid>
                            </Grid>
                        </>
                    }

                </Grid>

            </Grid>

            <Dialog
                open={uploadDialogOpen}
                onClose={() => setUploadDialogOpen(false)}
            >
                <DialogTitle>
                    <Typography component={"div"}>
                        Upload Group Picture
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {!file &&
                        <div {...getRootProps()} style={{ borderStyle: "solid", borderWidth: 1, height: 200, width: "100%", alignItems: "center", justifyContent: "center" }}>
                            <input {...getInputProps()} />
                            {
                                uploadDisabled ? <p style={{ textAlign: "center" }}>File selected</p> :
                                    isDragActive ?
                                        <p>Drop the files here ...</p> :
                                        <p style={{ textAlign: "center", marginInline: 30 }}>{isSmall ? 'Press to select file' : 'Drag and drop some files here, or click to select files'}</p>
                            }
                        </div>}
                    {file && <Grid container >
                        <Grid item style={{}}>
                            <Typography component={"div"} style={{ margin: 10 }}>{file.name}</Typography>
                        </Grid>
                        <Grid item >
                            <Button style={{ marginTop: 3 }} variant="contained" onClick={() => {
                                setFile(null);
                                setUploadDisabled(false);
                                setImage(null);
                            }}> Remove </Button>

                        </Grid>
                    </Grid>}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                        {image && <Avatar src={image} sx={{ width: 150, height: 150 }} />}
                    </div>
                    <div style={{ marginTop: 20, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Button variant="contained" onClick={handleUpload} disabled={!file}> Confirm </Button>
                        <Typography component={"div"}>{loading}</Typography>
                    </div>
                </DialogContent>
            </Dialog>

            <ConfirmationDialog
                title={["Get"]}
                handleDevice={() => {handleLayoutUrl(); setDialogShown(true)}}
                name="dashboard URL"
                open={urlDialog}
                setOpen={setUrlDialog}
                content={<div>
                    By fetching this url and sharing this dashboard you have agreed and are aware that you will be sharing group information, images and location data to where the dashboard will be displayed.
                    <br /><br />
                    Note that your responsibility is to make sure that there is no third-party access to the URL in order to prevent unauthorized copy and use.
                </div>}
            />

        </LayoutPaper>
    );
}