import { useCallback, useEffect, useReducer, useState } from 'react';
import { SmallAvatarWrapper, useDataProvider, useWsDataProvider } from 'kerberus-components';
import { Autocomplete, Button, MenuItem, Modal, Select, TextField, Typography, Grid, Card, Box, useTheme, IconButton, Tooltip } from '@mui/material';
import useSWR from 'swr';
import Toast from '../Toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 4,
    minHeight: 300,
    minWidth: 350
};

const boxStyle = {
    p: 4
};

const permissions = [
    { id: 0, name: "Every permission" },
    { id: 1, name: "Can view current online users" },
    { id: 2, name: "Can get last seen online timestamps for users" },
    { id: 3, name: "Query with public_social filters" },
]

export default function CreateToken() {
    const theme = useTheme();
    const { getData: wsSetter } = useDataProvider();
    const { getData: getOrganizationList, data: organizationListData } = useWsDataProvider();
    const [organizationList, setOrganizationList] = useState([]);
    const [activeOrg, setActiveOrg] = useState();
    const [selectedSocial, setSelectedSocial] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(false);
    const [selectedSites, setSelectedSites] = useState([]);
    const { data: socialGroupSelectData = [] } = useSWR('users/socialgroupselect/');
    const { data: companySelectData = [] } = useSWR('users/companyselect/');
    const { data: siteSelectData = [] } = useSWR('devices/siteselect/');
    const [groupPerms, setGroupPerms] = useState([]);
    const [companyPerms, setCompanyPerms] = useState([]);
    const [socialsToAdd, setSocialsToAdd] = useState(new Map());
    const [companiesToAdd, setCompaniesToAdd] = useState(new Map());
    const [socialGroupModalOpen, toggleSocialGroupModal] = useReducer(prev => !prev, false);
    const [companyModalOpen, toggleCompanyModal] = useReducer(prev => !prev, false);
    const [createSuccess, setCreateSuccess] = useState(false);
    const [createError, setCreateError] = useState(false);
    const [copyToken, setCopyToken] = useState(false);
    const [newToken, setNewToken] = useState();

    useEffect(() => {
        getOrganizationList('socketToken', 'listOrganizations');
    }, [getOrganizationList]);

    useEffect(() => {
        if (organizationListData?.data) {
            setOrganizationList(organizationListData.data);
        }
    }, [organizationListData]);

    const handleCreateToken = useCallback(async () => {
        try {
            const res = await wsSetter({ serial: 'socketToken' }, 'set', 'createToken', {
                filters: {
                    social_groups: socialsToAdd.size > 0 ? [...socialsToAdd].map(([key, value]) => ({ id: key, permissions: value.permissions })) : undefined,
                    companies: companiesToAdd.size > 0 ? [...companiesToAdd].map(([key, value]) => ({ id: key, permissions: value.permissions })) : undefined,
                    sites: selectedSites.map(i => i.id),
                    organization: activeOrg && activeOrg.id
                }
            });
            if (!res.data.error) {
                setNewToken(res.data.data);
            }
            else {
                alert(res.data.error);
            }
        }
        catch (err) {
            console.error(err);
        }
    }, [activeOrg, companiesToAdd, socialsToAdd, wsSetter, selectedSites]);

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(newToken);
        setCopyToken(true);
    };

    return (
        <Grid container sx={{ display: "flex", flexDirection: "column" }}>
            <Typography component={"div"} variant="h5" sx={{ mb: 2 }}>Create token</Typography>

            <Grid item sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography component={"div"} variant="h6">
                        Social Groups to add:
                    </Typography>
                    <Button sx={{ width: 200 }} variant="contained" onClick={() => toggleSocialGroupModal()}>Add Social Group</Button>
                </Box>

                {[...socialsToAdd].map(([key, value]) => (
                    <Box key={key} sx={{ ml: 2, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Typography component={"div"} >Permissions</Typography>
                        <Typography component={"div"} >{value.permissions.join(', ')}</Typography>
                        <SmallAvatarWrapper alt="groupPic" imageType="socialgrouppic" userId={key} sx={{ width: 32, height: 32 }} imageSize="_32" />
                        <Typography component={"div"} >{value.name}</Typography>
                    </Box>
                ))}

            </Grid>

            <Grid item sx={{ display: "flex", mt: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography component={"div"} variant="h6">
                        Companies to add:
                    </Typography>
                    <Button sx={{ width: 150 }} variant="contained" onClick={() => toggleCompanyModal()}>Add Company</Button>
                </Box>

                {[...companiesToAdd].map(([key, value]) => (
                    <Box key={key} sx={{ ml: 2, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Typography component={"div"} >Permissions</Typography>
                        <Typography component={"div"} >{value.permissions.join(', ')}</Typography>
                        <SmallAvatarWrapper alt="companyPic" imageType="companypic" userId={key} sx={{ width: 32, height: 32 }} imageSize="_32" />
                        <Typography component={"div"} >{value.name}</Typography>
                    </Box>
                ))}


            </Grid>

            <Grid item xs={6} >
                <Typography component={"div"} variant="h6" sx={{ mt: 2 }}>
                    Organization:
                </Typography>
                <Autocomplete
                    options={organizationList}
                    getOptionLabel={option => option.name}
                    renderInput={(params) => <TextField {...params} label="Organizations" />}
                    onChange={(e, value) => setActiveOrg(value)}
                    sx={{ my: 1 }}
                />
                <Typography component={"div"} variant="h6" sx={{ mt: 2 }}>
                    Sites:
                </Typography>
                <Autocomplete
                    options={siteSelectData}
                    multiple
                    getOptionLabel={option => option.name}
                    renderInput={(params) => <TextField {...params} label="Sites" />}
                    onChange={(e, value) => setSelectedSites(value)}
                    sx={{ my: 1, mb: 2 }}
                />
            </Grid>

            <Grid item xs={12} sx={{ display: "block" }}>
                <Button variant="contained" onClick={handleCreateToken}>Create token</Button>
                <Box sx={{ display: newToken ? "flex" : "none", alignItems: "center", justifyContent: "center" }}>
                    <Typography component={"div"} variant="h6">
                        New token: <span style={{ fontWeight: "bold", color: theme.palette.primary.main }}>{newToken}</span>
                        <Tooltip title="Copy to clipboard">
                            <IconButton color="primary" variant="contained" sx={{ ml: 1, backgroundColor: theme.palette.primary.main + 35, "&:hover": { backgroundColor: theme.palette.primary.main + 50 } }} onClick={copyToClipboard}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Box>
            </Grid>

            <Modal
                open={socialGroupModalOpen}
                onClose={() => { toggleSocialGroupModal(); setSelectedSocial(false); }}
            >
                <Card style={modalStyle}>
                    <Box sx={boxStyle}>
                        <Typography component={"div"} variant="h6" textAlign="center" sx={{ mb: 2 }}>
                            Add Social Groups
                        </Typography>
                        <Autocomplete
                            options={socialGroupSelectData}
                            getOptionLabel={option => option.name}
                            renderInput={(params) => <TextField {...params} label="Social Groups" />}
                            onChange={(e, value) => setSelectedSocial(value)}
                            sx={{ width: 300, mb: 2 }}
                        />
                        {selectedSocial &&
                            <Box sx={{ display: "flex", alignItems: "center", my: 1, ml: 2, mb: 2 }}>
                                <SmallAvatarWrapper alt="groupPic" imageType="socialgrouppic" userId={selectedSocial.id} sx={{ width: 64, height: 64 }} imageSize="_64" />
                            </Box>
                        }

                        <FormControl sx={{ width: 300 }}>
                            <InputLabel id="permissions-group">Add permissions</InputLabel>
                            <Select
                                labelId="permissions-group"
                                id="permissions-group"
                                multiple
                                value={groupPerms}
                                onChange={(e) => setGroupPerms(e.target.value)}
                                input={<OutlinedInput id="permissions-group" label="Add permissions" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} >
                                        {selected.map((value) => (
                                            <Chip key={value} label={permissions[value].name} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {permissions.map((perm) =>
                                    <MenuItem key={perm.id} value={perm.id}>{perm.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <Button sx={{ display: "flex", width: "100%", mt: 2 }} variant="contained" onClick={() => {
                            if (selectedSocial && groupPerms.length > 0 && !socialsToAdd.has(selectedSocial.id)) {
                                setSocialsToAdd(prev => {
                                    prev.set(selectedSocial.id, {
                                        name: selectedSocial.name,
                                        permissions: groupPerms
                                    });
                                    return prev;
                                });
                                toggleSocialGroupModal()
                                setSelectedSocial(false);

                            }
                            else {
                                alert('Failed');
                            }
                        }}>Add Social Group</Button>
                    </Box>
                </Card>
            </Modal>

            <Modal
                open={companyModalOpen}
                onClose={() => { toggleCompanyModal();; setSelectedCompany(false); }}
            >
                <Card style={modalStyle}>
                    <Box sx={boxStyle}>
                        <Typography component={"div"} variant="h6" textAlign="center" sx={{ mb: 2 }}>
                            Add Companies
                        </Typography>
                        <Autocomplete
                            options={companySelectData}
                            getOptionLabel={option => option.name}
                            renderInput={(params) => <TextField {...params} label="Companies" />}
                            onChange={(e, value) => setSelectedCompany(value)}
                            sx={{ width: 300, mb: 2 }}
                        />
                        {selectedCompany &&
                            <Box sx={{ display: "flex", alignItems: "center", my: 1, ml: 2, mb: 2 }}>
                                <SmallAvatarWrapper alt="companyPic" imageType="companypic" userId={selectedCompany.id} sx={{ width: 64, height: 64 }} imageSize="_64" />
                            </Box>
                        }

                        <FormControl sx={{ width: 300 }}>
                            <InputLabel id="permissions-company">Add permissions</InputLabel>
                            <Select
                                labelId="permissions-company"
                                id="permissions-company"
                                multiple
                                value={companyPerms}
                                onChange={(e) => setCompanyPerms(e.target.value)}
                                input={<OutlinedInput id="permissions-company" label="Add permissions" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} >
                                        {selected.map((value) => (
                                            <Chip key={value} label={permissions[value].name} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {permissions.map((perm) =>
                                    <MenuItem key={perm.id} value={perm.id}>{perm.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <Button sx={{ display: "flex", width: "100%", mt: 2 }} variant="contained" onClick={() => {
                            if (selectedCompany && companyPerms.length > 0 && !companiesToAdd.has(selectedCompany.id)) {
                                setCompaniesToAdd(prev => {
                                    prev.set(selectedCompany.id, {
                                        name: selectedCompany.name,
                                        permissions: companyPerms
                                    });
                                    return prev;
                                });
                                toggleCompanyModal()
                                setSelectedCompany(false);
                            }
                            else {
                                alert('Failed');
                            }
                        }}>Add Company</Button>
                    </Box>
                </Card>
            </Modal>

            <Toast
                sx={{}}
                type="success"
                title={createSuccess}
                message="Token has been succesfully deleted"
                verticalPos="bottom"
                horizontalPos="center"
                open={createSuccess}
                handleClose={() => setCreateSuccess(false)}
                autoHideDuration={50000}
            />

            <Toast
                sx={{}}
                type="error"
                title="Delete error"
                message={createError}
                verticalPos="bottom"
                horizontalPos="center"
                open={createError}
                handleClose={() => setCreateError(false)}
                autoHideDuration={50000}
            />

            <Toast
                sx={{}}
                type="success"
                title="Success"
                message="Token copied to clipboard"
                verticalPos="bottom"
                horizontalPos="center"
                open={copyToken}
                handleClose={() => setCopyToken(false)}
                autoHideDuration={50000}
            />

        </Grid>
    );
}

