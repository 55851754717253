import * as React from 'react';
import { Paper, useTheme, useMediaQuery } from '@mui/material';



export default function LayoutPaper(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const paperStyle = {
        bgcolor: 'background.paper',
        marginTop: 10,
        marginBottom: 2.5,
        borderRadius:  4,
        p:  4,
        paddingTop: 4,
        height: "-webkit-fill-available",
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            marginTop: 1.5,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#1C4E71",
        },
        '&::-webkit-scrollbar-corner': {
            display: "none"
        },
        overflowY: props.hideoverflowy === "true" && "hidden",
        scrollbarColor: `${theme.palette.primary.main} #fff`,
        scrollbarWidth: "thin",
        width: "100%",
        overflowX: "hidden",
        marginX: 2
    };

    const paperStyleMobile = {
        bgcolor: 'background.paper',
        marginTop: 8,
        p: 1,
        paddingTop: 4,
        height: "-webkit-fill-available",
        overflowY: props.hideoverflowy === "true" && "hidden",
        width: "100%",
        overflowX: "hidden",
        position: "relative"
    };

    return (
        <Paper {...props} elevation={props.elevation} sx={isSmall ? paperStyleMobile : paperStyle}>
            {props.children}
        </Paper>
    );

}