import { Checkbox, FormControlLabel } from '@mui/material';

export default function DayEnabledCheckbox({ day, list, setList, num }) {
    return (
        <FormControlLabel control={
            <Checkbox
                checked={list.has(num)}
                onChange={() => setList(prev => {
                    let temp = new Set(prev);
                    if (temp.has(num)) temp.delete(num);
                    else temp.add(num);
                    return temp;
                })}
            />
        } label={day} labelPlacement="top" sx={{ width: 15 }} />
    );
}
