import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from 'react';
import { Controller } from "react-hook-form";

const contentPublic = (<><div>Allow profile usage in info dashboard</div></>);
const contentPublicSocialsGroup = (<><div>Allow profile visibility in your group's social list</div></>);
const contentPublicCalendar = (<><div>Allow other users to see your calendar</div></>);

export default React.memo(function SocialGroupSettingsUser({
    handleSubmit,
    onSubmit,
    control,
    watch,
    formState,
    dayOptions
}) {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>

                <Grid item xs={12} sx={{ mt: -3, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Grid item xs>
                        <Controller
                            name="publicSocialUser"
                            control={control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Tooltip title={contentPublicSocialsGroup} placement="bottom-start">
                                            <Checkbox checked={field.value} {...field} />
                                        </Tooltip>
                                    }
                                    label={
                                        <div>
                                            Public
                                            <br />
                                            Social
                                        </div>
                                    }
                                    labelPlacement="top"
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs>
                        <Controller
                            name="publicDashboardUser"
                            control={control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Tooltip title={contentPublic} placement="bottom-start">
                                            <Checkbox checked={field.value} {...field} />
                                        </Tooltip>
                                    }
                                    label={
                                        <div>
                                            Public
                                            <br />
                                            Dashboard
                                        </div>
                                    }
                                    labelPlacement="top"
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs>
                        <Controller
                            name="publicCalendarUser"
                            control={control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Tooltip title={contentPublicCalendar} placement="bottom-start">
                                            <Checkbox checked={field.value} {...field} />
                                        </Tooltip>
                                    }
                                    label={
                                        <div>
                                            Public
                                            <br />
                                            Calendar
                                        </div>
                                    }
                                    labelPlacement="top"
                                />
                            }
                        />
                    </Grid>
                </Grid>

                <Divider sx={{ width: "100%", marginBottom: 3, marginTop: 2 }} />

                <Grid item xs sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", textAlign: "center" }}>
                    <Typography component={"div"} variant="h6" sx={{ marginBottom: 2 }}>
                        Visibility time
                    </Typography>
                    <Controller
                        name="alwaysActive"
                        control={control}
                        render={({ field }) =>
                            <FormControlLabel
                                control={
                                    <Tooltip title="This choice only affects time">
                                        <Checkbox checked={field.value} {...field} />
                                    </Tooltip>
                                }
                                label={
                                    <div>
                                        Always active
                                    </div>
                                }
                                labelPlacement="top"
                            />
                        }
                    />
                </Grid>

                <Grid item xs sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", textAlign: "center" }}>
                    <Grid item xs>
                        <Typography component={"div"} variant="h6">
                            Starting Time
                        </Typography>
                        <Controller
                            name="startTimeUser"
                            control={control}
                            render={({ field }) =>
                                <TimePicker
                                    {...field}
                                    label="Start time"
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={watch("alwaysActive")}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs sx={{ ml: 1 }}>
                        <Typography component={"div"} variant="h6">
                            Ending Time
                        </Typography>
                        <Controller
                            name="endTimeUser"
                            control={control}
                            render={({ field }) =>
                                <TimePicker
                                    {...field}
                                    label="End time"
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={watch("alwaysActive")}
                                />
                            }
                        />
                    </Grid>
                </Grid>

                <Grid item xs sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", textAlign: "center", mt: 4 }}>
                    <FormGroup row>
                        <Controller
                            name="daysEnabledUser"
                            control={control}
                            render={({ field }) => (
                                <>
                                    {dayOptions.map((day) => (
                                        <FormControlLabel
                                            key={day.value}
                                            label={day.label}
                                            labelPlacement="top"
                                            sx={{ width: 15 }} 
                                            control={
                                                <Checkbox
                                                    value={day.value}
                                                    checked={field?.value?.some(
                                                        // eslint-disable-next-line eqeqeq
                                                        (existingValue) => existingValue == day.value
                                                    )}
                                                    onChange={(event, checked) => {
                                                        if (checked) {
                                                            field.onChange([
                                                                ...field.value,
                                                                event.target.value
                                                            ]);
                                                        } else {
                                                            field.onChange(
                                                                field.value.filter(
                                                                    (value) => value !== event.target.value
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    ))}
                                </>
                            )}
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={12} sx={{ display: formState.isDirty ? "inline" : "none", marginTop: 5, textAlign: isSmall ? "center" : "right", position: "relative", right: isSmall ? 0 : 20 }} >
                    <Button variant="contained" type="submit">
                        Save
                    </Button>
                </Grid>

            </LocalizationProvider>
        </form >
    );
});