import * as React from 'react';
import { useRef, useState } from 'react';
import { TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import InputMask from "react-input-mask";

export default function MaskedTextFieldWrapper(props) {
    const [value, setValue] = useState(props.value);
    const timeoutref = useRef(null);
    const { onBlur, onChange, onFocus, onMouseDown, onPaste, value: textfieldvalue, hasClear, ...rest } = props;

    const handleChange = (e) => {
        setValue(e.target.value);
        clearTimeout(timeoutref.current);
        timeoutref.current = setTimeout(() => {
            props.onChange(e.target.value);
        }, 200);
    };
    return (
        <InputMask
            mask="**:**:**:**:**:**"
            value={value}
            onChange={handleChange}
            disabled={false}
            maskChar=""
            formatChars={{
                '9': '[0-9]',
                'a': '[A-Za-z]',
                '*': '[A-Fa-f0-9]',
            }}
        >
            {() =>
                <TextField
                    {...rest}
                    InputProps={{
                        endAdornment: hasClear && value && (
                            <IconButton
                                aria-label="clear input"
                                onClick={() => setValue("")}
                            ><ClearIcon /></IconButton>
                        )
                    }}
                />}
        </InputMask>
    );
}