import { Avatar, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useImageProvider } from "./Backend/ImageProvider";

export default function SmallAvatarWrapper(props) {
    const { userId, skipCookie, tooltipContent, hasTooltip, imageSize, imageType = 'profilepic', ...rest } = props;
    const [imgSrc, setImgSrc] = useState();
    const { getImage, imageData } = useImageProvider();

    useEffect(() => {
        if (userId && skipCookie) {
            getImage(userId, imageType, imageSize);
        }
    }, [getImage, userId]);

    useEffect(() => {
        if (skipCookie) {
            setImgSrc(imageData);
        }
    }, [imageData]);

    return (
        hasTooltip
            ?
            <Tooltip title={tooltipContent}>
                <Avatar {...rest} src={skipCookie ? imgSrc : userId ? `${process.env.REACT_APP_IMAGE_SERVE_HOST}/${imageType}/${userId}${imageSize ?? ''}.webp` : 'data:,'} />
            </Tooltip>
            :
            <Avatar {...rest} src={skipCookie ? imgSrc : userId ? `${process.env.REACT_APP_IMAGE_SERVE_HOST}/${imageType}/${userId}${imageSize ?? ''}.webp` : 'data:,'} />
    );
}
