import LayoutPaper from "../../Components/LayoutPaper";
import { useState, useEffect } from "react";
import {
    Grid, Box, Typography, Button, useTheme, IconButton, Divider, useMediaQuery,
    TextField
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFetchCloud } from 'kerberus-components';
import SocialGroupRequests from "../Admin/SocialGroupRequests";
import Toast from "../../Components/Toast";
import SocialGroupModal from "../../Components/Groups/SocialGroupModal";
import SocialGroupInviteDatagrid from "../../Components/Groups/SocialGroupInviteDatagrid";
import useSWR from 'swr';
import ActiveTime from "../../Components/Groups/ActiveTime";
import SocialGroupHeader from "../../Components/Groups/SocialGroupHeader";

export default function GroupInvitations({ count, socialGroup, grouprequestsData, modal, isActive, isActiveWeekday  }) {
    const { fetchCloud } = useFetchCloud();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [openEmailSuccess, setOpenEmailSuccess] = useState(false);
    const [openEmailError, setOpenEmailError] = useState(false);
    const { groupId } = useParams();
    const [profiles, setProfiles] = useState([]);
    const { data: myConnectionsData } = useSWR('users/user_connections');

    useEffect(() => {
        if (myConnectionsData) {
            setProfiles(myConnectionsData.map(users => users).filter(user => !socialGroup.users.map(members => members.id).includes(user.id)));
        }
    }, [myConnectionsData, socialGroup.users]);

    const handleInvite = async () => {
        try {
            await fetchCloud('users/create_social_invite', 'POST', {
                email: email,
                social_group: groupId
            });
            setOpenEmailSuccess(true);
        }
        catch (err) {
            setOpenEmailError(true);
        }
    };


    return (
        <LayoutPaper>
            <Grid container>

                <Grid item xs={12} md={10} sx={{ display: "flex", flexDirection: isSmall ? "column" : "row", marginBottom: 1, marginTop: isSmall ? 5 : -1, alignItems: "center" }}>
                    <SocialGroupHeader socialGroup={socialGroup} modal={modal} count={count} isActive={isActive} isActiveWeekday={isActiveWeekday} />
                </Grid>

                <Grid item xs md={2} sx={isSmall ? { position: "absolute", top: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "flex-end" } : { display: "flex", alignItems: "center", justifyContent: "flex-end", position: "relative", bottom: 20, left: 20 }}>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIcon sx={{ height: 30, width: 30 }} />
                    </IconButton>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: isSmall && "center" }}>
                    <ActiveTime socialGroup={socialGroup} />
                </Grid>
                <Divider flexItem sx={{ width: "100%" }} />

                <Box sx={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", textAlign: "center", flexDirection: "column" }}>


                    {/* Invitations */}
                    <Grid container sx={{ width: "100%", display: "flex" }}>

                        <Grid item xs={12} md={8}>
                            <Grid item xs={12} >
                                <Typography component={"div"} variant="h5" sx={{ width: "100%", textAlign: "center", height: 100, marginTop: 2 }}>
                                    Invite my connections to social groups
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <SocialGroupInviteDatagrid sx={{ height: 550, mt: -4 }} data={profiles} groupId={groupId} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Grid item xs={12} >
                                <Typography component={"div"} variant="h5" sx={{ width: "100%", textAlign: "center", marginTop: 2 }}>
                                    Invite to social group
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 5, marginBottom: -1 }}>
                                Email to send the invite to
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    id="email"
                                    label='Email'
                                    name="email"
                                    inputProps={{
                                        autoComplete: "off",
                                    }}
                                    sx={{ marginBottom: 3 }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" onClick={handleInvite} disabled={!email} >Invite</Button>
                            </Grid>
                        </Grid>

                        {/* Requests */}
                        <Grid item xs={12} md={8} sx={isSmall && { mt: 4 }}>

                            <Grid item xs={12} >
                                <Typography component={"div"} variant="h5" sx={{ width: "100%", textAlign: "center", height: 100, marginTop: 2 }}>
                                    Social Group join requests
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <SocialGroupRequests sx={{ height: 550, mt: -4 }} grouprequestsData={grouprequestsData?.filter(item => item.social_group === groupId && item)} filtered />
                            </Grid>

                        </Grid>


                    </Grid>



                </Box>

                <Toast
                    sx={{}}
                    type="success"
                    title="Email sent"
                    message="Instructions for accepting group invite has been sent to the email if the email exists"
                    verticalPos="bottom"
                    horizontalPos="center"
                    open={openEmailSuccess}
                    handleClose={() => setOpenEmailSuccess(false)}
                    autoHideDuration={50000}
                />
                <Toast
                    sx={{}}
                    type="error"
                    title="Email send failed"
                    message="Internal server error"
                    verticalPos="bottom"
                    horizontalPos="center"
                    open={openEmailError}
                    handleClose={() => setOpenEmailError(false)}
                    autoHideDuration={50000}
                />

                <SocialGroupModal modal={modal} groupId={groupId} socialGroup={socialGroup} />

            </Grid>
        </LayoutPaper>
    );
}