import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MainContext from './MainContext';
import jwt_decode from 'jwt-decode';


export function autoRefresh(refresh) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_AUTH_HOST + '/api/token/refresh/', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                refresh: refresh
            }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        })
            .then(res => {
                if (res.ok) {
                    res.json()
                        .then(json => {
                            localStorage.setItem('access', json.access);
                            localStorage.setItem('userId', json.id);
                            resolve(json.access);
                        })
                        .catch(err => {
                            reject(err);
                        });
                }
                else {
                    localStorage.removeItem('refresh');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('access');
                    window.location.reload();
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}


export function handleAuthentication(access, refresh) {
    var promise = new Promise((resolve, reject) => {
        var token = null;
        if (access) {
            var testVal = (jwt_decode(access).exp * 1000 - Date.now());
            if (testVal > 10000) {
                token = access;
            }
        }

        if (token) {
            resolve(token);
        }
        else if (refresh) {
            fetch(process.env.REACT_APP_AUTH_HOST + '/api/token/refresh/', {
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({
                    refresh: refresh
                }),
                headers: new Headers({ 'Content-Type': 'application/json' })
            })
                .then(res => {
                    if (res.ok) {
                        res.json()
                            .then(json => {
                                localStorage.setItem('access', json.access);
                                localStorage.setItem('userId', json.id);
                                resolve(json.access);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    }
                    else if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('access');
                        localStorage.removeItem('refresh');
                        localStorage.removeItem('userId');
                        window.location.reload();
                    }
                    else {
                        reject(res.status);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        }
        else {
            reject('Error in authentication');
        }
    });
    return promise;
}

export function useFetchCloud() {
    const { accessToken, setAccessToken, tokenType, user } = useContext(MainContext);
    const accessRef = useRef(accessToken);
    const [rawRes, setRawRes] = useState(null);
    const [cloudData, setCloudData] = useState(null);
    useEffect(() => {
        accessRef.current = accessToken;
    }, [accessToken]);

    useEffect(() => {
        if (rawRes && !rawRes.bodyUsed) {
            rawRes.text()
                .then((ev) => {
                    let json = ev ? JSON.parse(ev) : {};
                    setCloudData(json);
                });
        }
    }, [rawRes]);

    const fetchCloud = useCallback((endpoint, method, body, fileUpload = false, imageUpload = false) => {
        if (!endpoint || !method) throw new Error('Missing params');

        var contentType = 'application/json';
        var fetchBody = undefined;

        if (body) {
            if (fileUpload) {
                fetchBody = new FormData();
                for (let key in body) {
                    fetchBody.append(key, body[key]);
                }
            }
            else {
                fetchBody = JSON.stringify(body);
            }
        }

        if (tokenType === 'bearer') {
            return new Promise((resolve, reject) => {
                handleAuthentication(accessRef.current.access, accessRef.current.refresh)
                    .then((access) => {
                        fetch(`${imageUpload ? process.env.REACT_APP_IMAGE_UPLOAD_HOST : process.env.REACT_APP_AUTH_HOST}/api/${endpoint}`, {
                            method: method.toUpperCase(),
                            headers: fileUpload ?
                                new Headers({ 'Authorization': 'Bearer ' + access, }) :
                                new Headers({ 'Authorization': 'Bearer ' + access, 'Content-Type': contentType }),
                            body: fetchBody
                        })
                            .then(res => {
                                res.text()
                                    .then((data) => {
                                        if (res.ok) resolve(data ? JSON.parse(data) : {});
                                        else reject(data ? JSON.parse(data) : {});
                                    });
                            })
                            .catch(err => reject(err));
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        }
        else if (tokenType === 'token') {
            return new Promise((resolve, reject) => {
                fetch(`${process.env.REACT_APP_AUTH_HOST}/api/${endpoint}`, {
                    method: method.toUpperCase(),
                    headers: new Headers({ 'Authorization': 'Token ' + accessRef.current.access, 'Content-Type': contentType }),
                    body: fetchBody
                })
                    .then(res => {
                        if (res.ok) {
                            res.text()
                                .then((data) => {
                                    resolve(data ? JSON.parse(data) : {});
                                });
                        }
                        else reject(res.statusText);
                    })
                    .catch(err => reject(err));
            });
        }
        else {
            throw new Error('Invalid tokentype');
        }
    }, [setAccessToken]);

    const fetchCloudWithCache = useCallback(async (endpoint) => {
        if (!endpoint) throw new Error('Missing endpoint');

        var tokenString = tokenType === 'bearer' ? 'Bearer ' : tokenType === 'token' ? 'Token ' : '';

        var url = endpoint === 'users/profilelist' || endpoint === 'users/profilelistsocial' || endpoint === 'users/socialgrouplist'
            ? `${process.env.REACT_APP_AUTH_HOST}/api/${endpoint}/?id=${user.id}`
            : `${process.env.REACT_APP_AUTH_HOST}/api/${endpoint}`;

        var requestVar = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Authorization': tokenString + accessRef.current.access,
                'Content-Type': 'application/json'
            })
        });
        try {
            var cache = await caches.open('pwa-cache');
            var cacheRes = await cache.match(requestVar);
            if (cacheRes) setRawRes(cacheRes);
        }
        catch {
            console.log('Cache unavailable');
        }


        if (tokenType === 'bearer') {
            try {
                var auth = await handleAuthentication(accessRef.current.access, accessRef.current.refresh);
                accessRef.current.access = auth;
                var bearerFetchRes = await fetch(requestVar);
                if (bearerFetchRes.ok) {
                    setRawRes(bearerFetchRes);
                    try {
                        cache.add(requestVar);
                    }
                    catch {
                        console.log('Cache unavailable');
                    }
                }
                else {
                    console.error(bearerFetchRes.statusText);
                }
            }
            catch (err) {
                console.error(err);
            }
        }

        else if (tokenType === 'token') {
            try {
                var tokenFetchRes = await fetch(requestVar);
                if (tokenFetchRes.ok) {
                    setRawRes(tokenFetchRes);
                    try {
                        cache.add(requestVar);
                    }
                    catch {
                        console.log('Cache unavailable');
                    }
                }
                else {
                    console.error(bearerFetchRes.statusText);
                }
            }
            catch (err) {
                console.error(err);
            }
        }
        else {
            throw new Error('Invalid tokentype');
        }
    }, [user]);

    return { fetchCloud, cloudData, fetchCloudWithCache };
}
