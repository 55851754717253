import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DatagridColumnsMobile = [
    {
        field: 'username', flex: 1, headerName: 'Users',
        renderCell: (e) =>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Accordion elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ display: "flex", flexDirection: "column" }}

                    >
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Typography component={"div"} variant="h6" sx={{ fontSize: "5vw" }}>
                                {e.row.first_name && e.row.last_name ? `${e.row.first_name} ${e.row.last_name}` : e.row.username}
                            </Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails sx={{ textAlign: "center" }}>
                        {e.row.first_name &&
                            <>
                                <Typography component={"div"} sx={{ color: "gray", textDecoration: "underline" }}>
                                    First name
                                </Typography>
                                <Typography component={"div"} sx={{ marginBottom: 1 }}>
                                    {e.row.first_name}
                                </Typography>
                            </>
                        }

                        {e.row.last_name &&
                            <>
                                <Typography component={"div"} sx={{ color: "gray", textDecoration: "underline" }}>
                                    Last Name
                                </Typography>
                                <Typography component={"div"} sx={{ marginBottom: 1 }}>
                                    {e.row.last_name}
                                </Typography>
                            </>
                        }
                        <Typography component={"div"} sx={{ color: "gray", textDecoration: "underline" }}>
                            Title
                        </Typography>
                        <Typography component={"div"} sx={{ marginBottom: 1 }}>
                            {e.row.title}
                        </Typography>
                        <Typography component={"div"} sx={{ color: "gray", textDecoration: "underline" }}>
                            Company
                        </Typography>
                        <Typography component={"div"} sx={{ marginBottom: 1 }}>
                            {e.row.company_name}
                        </Typography>

                    </AccordionDetails>
                </Accordion>
            </Box >
    }
]

export default DatagridColumnsMobile;