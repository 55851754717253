import { Grid, Typography, Card, Tooltip, ListItemAvatar, useMediaQuery } from "@mui/material";
import { SmallAvatarWrapper } from "kerberus-components";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function ProfileMutualSocialGroups({ socialGroups, userId, userPersonalId, userData }) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    /* Filter own socials groups with target's socials groups */
    const mutualSocialGroups = socialGroups.filter((group) => {
        return userData.social_groups.includes(group.id);
    });

    return (
        <Card elevation={1} sx={{ display: userPersonalId === userId && "none", mt: 5, backgroundColor: "#FFFFFF", padding: 3, borderWidth: 3, borderColor: theme.palette.primary.main + 80, borderStyle: "solid", borderRadius: 5 }}>
            <Typography textAlign={isSmall ? "center" : "auto"} variant="h5" sx={{ mb: 1 }}>
                Mutual Social Groups
            </Typography>
            <Grid container spacing={0}>
                {mutualSocialGroups.length > 0 ? mutualSocialGroups.map((socialGroupsData) => {
                    return (
                        <Grid item key={socialGroupsData.id} component={Link} to={`/groups/${socialGroupsData.id}`} xs={6} sm={4} md={3} lg={2} xl={1}
                            sx={{ color: "black", textDecoration: "none", p: !isSmall ? 2 : 4, position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Tooltip title={socialGroupsData.name} placement="top">
                                <ListItemAvatar>
                                    <SmallAvatarWrapper
                                        alt={socialGroupsData.name}
                                        userId={socialGroupsData.id}
                                        imageType="socialgrouppic"
                                        sx={{ width: 65, height: 65 }}
                                        imageSize="_64"
                                    />
                                </ListItemAvatar>
                            </Tooltip>
                            <Typography sx={{ width: 121 }} textAlign="center">
                                {socialGroupsData.name}
                            </Typography>
                        </Grid>
                    );
                })
                    :
                    <Typography sx={{ color: "gray" }} variant="h6">
                        No mutual social groups
                    </Typography>
                }
            </Grid>
        </Card>
    );
}
