import React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { useTheme, useMediaQuery, styled } from '@mui/material';
import { useNavigate } from "react-router-dom";
import SearchToolbar from './SearchToolbar';

const DataGridNoHeader = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeaders": { display: "none" },
    "& .MuiDataGrid-virtualScroller": { marginTop: "0!important", height: "70vh!important" },
}));


export default React.memo(function UserDatagrid({
    gridColumns,
    gridColumnsMobile,
    rows,
    datagridHeight
}) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    function handleRowClick(e) {
        navigate(`/profile/${e.id}`);
    }

    return (
        <>
            {
                isSmall
                    ?
                    <DataGridNoHeader
                        disableVirtualization
                        rows={rows}
                        columns={gridColumnsMobile}
                        disableSelectionOnClick
                        isCellEditable={() => false}
                        sx={{
                            height: "78vh",
                            overflowX: "hidden",
                            '& .MuiDataGrid-renderingZone': {
                                maxHeight: 'none !important'
                            },
                            '& .MuiDataGrid-cell': {
                                lineHeight: 'unset !important',
                                maxHeight: 'none !important',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word'
                            },
                            '& .MuiDataGrid-row': {
                                maxHeight: 'none !important'
                            }

                        }}
                    />
                    :
                    <DataGrid
                        components={{ Toolbar: SearchToolbar }}
                        componentsProps={{
                            toolbar: {
                                hideSearch: true
                            },
                        }}
                        rows={rows}
                        columns={gridColumns}
                        disableSelectionOnClick
                        isCellEditable={() => false}
                        onRowClick={handleRowClick}
                        sx={{
                            cursor: 'pointer', height: datagridHeight,
                        }}
                    />
            }

        </>
    )
})