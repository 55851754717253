import * as React from 'react';
import { styled } from '@mui/material/styles';
import SmallAvatarWrapper from './SmallAvatarWrapper';
import { Badge, Tooltip } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/* const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} asd={console.log("props:", props.lastseenminutes)} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#44b700',
        fontWeight: "bold",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
})); */

const StyledBadge = styled(Badge)(({ badgewidth, badgeheight, badgeboxshadow, badgeanimation, lastseenminutes, theme, busy }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: busy ? "#E72D50" : lastseenminutes ? lastseenminutes <= 10 && lastseenminutes >= 5 ? "#FCC041" : lastseenminutes > 0 && lastseenminutes < 5 ? "#8DBF30" : '#adadb8' : '#adadb8',
        color: "#8DBF30",
        width: badgewidth,
        height: badgeheight,
        borderRadius: "50%",
        boxShadow: badgeboxshadow,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        '&::after':
        {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
            display: !badgeanimation ? "none" : undefined
        }
    },
    '& .MuiBadge-root': {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "red"
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.5)',
            opacity: 0,
        },
    },
}));

export default function StyledBadgeAvatar({
    isSmall,
    tooltipContent,
    tooltipPlacement,
    badgeOverlap,
    badgeAnchorOrigin,
    badgeVariant,
    badgeSx,
    avatarSx,
    alt,
    imgSrc,
    avatarVariant,
    akey,
    badgeheight,
    badgewidth,
    badgeanimation,
    badgeboxshadow,
    lastseenminutes,
    busy,
    skipCookie,
    imageSize,
    noTooltip,
    hasHiddenBadge
}) {
    return (
        noTooltip
            ?
            isSmall ?
                <SmallAvatarWrapper sx={avatarSx} alt={alt} userId={imgSrc} skipCookie={skipCookie} imageSize={imageSize} />
                :
                hasHiddenBadge
                    ?
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        badgeContent={
                            <VisibilityOffIcon sx={{ height: 28, width: 28, color: "gray", backgroundColor: "#FFFFFF", borderRadius: "50%", padding: "5%" }} />
                        }
                    >
                        <SmallAvatarWrapper variant={avatarVariant} sx={avatarSx} alt={"Profile pic"} userId={imgSrc} skipCookie={skipCookie} imageSize={imageSize} />
                    </Badge>
                    :
                    <StyledBadge
                        overlap={badgeOverlap}
                        anchorOrigin={badgeAnchorOrigin}
                        variant={badgeVariant}
                        sx={badgeSx}
                        badgeheight={badgeheight}
                        badgewidth={badgewidth}
                        badgeanimation={badgeanimation}
                        badgeboxshadow={badgeboxshadow}
                        lastseenminutes={lastseenminutes}
                        busy={busy}
                    >
                        <SmallAvatarWrapper variant={avatarVariant} sx={avatarSx} alt={"Profile pic"} userId={imgSrc} skipCookie={skipCookie} imageSize={imageSize} />
                    </StyledBadge >
            :
            <Tooltip title={tooltipContent} placement={tooltipPlacement} key={akey} lastseenminutes={lastseenminutes} >
                {isSmall ?
                    <SmallAvatarWrapper sx={avatarSx} alt={alt} userId={imgSrc} skipCookie={skipCookie} imageSize={imageSize} />
                    :
                    <StyledBadge
                        overlap={badgeOverlap}
                        anchorOrigin={badgeAnchorOrigin}
                        variant={badgeVariant}
                        sx={badgeSx}
                        badgeheight={badgeheight}
                        badgewidth={badgewidth}
                        badgeanimation={badgeanimation}
                        badgeboxshadow={badgeboxshadow}
                        lastseenminutes={lastseenminutes}
                        busy={busy}
                    >
                        <SmallAvatarWrapper variant={avatarVariant} sx={avatarSx} alt={alt} userId={imgSrc} skipCookie={skipCookie} imageSize={imageSize} />
                    </StyledBadge >
                }
            </Tooltip>
    );
}
