import { memo, useState, useCallback, useEffect, useContext } from 'react';
import { Button } from '@mui/material';
import { MainContext, useDataProvider, useWsDataProvider, validV4 } from 'kerberus-components';
import LayoutPaper from '../Components/LayoutPaper';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';

const OrganizationShare = memo(() => {
    const { user } = useContext(MainContext);
    const { getData: wsSetter } = useDataProvider();
    const { getData: getOrg, data: orgData } = useWsDataProvider();
    const [organizationData, setOrganizationData] = useState([]);
    const { orgId } = useParams();
    const [validId, setValidId] = useState(() => validV4(orgId));
    const [shared, setShared] = useState(() => user.org_shared.includes(orgId));
    const { mutate } = useSWRConfig();

    useEffect(() => {
        if (orgData?.data) {
            setOrganizationData(orgData.data);
        }
    }, [orgData]);

    useEffect(() => {
        setValidId(validV4(orgId));
    }, [orgId]);

    useEffect(() => {
        setShared(user.org_shared.includes(orgId));
    }, [orgId, user.org_shared]);

    useEffect(() => {
        if (validId) {
            getOrg('socketToken', 'previewOrganization', {
                filters: {
                    organization: orgId
                }
            });
        }
    }, [getOrg, orgId, validId]);

    const handleShareOrg = useCallback(async () => {
        if (!validId || !organizationData) return;
        try {
            await wsSetter({ serial: 'socketToken' }, 'set', 'addUserToOrganization', {
                filters: {
                    user: user.id,
                    organization: orgId
                }
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            console.error(err);
        }
    }, [validId, organizationData, wsSetter, user.id, orgId, mutate]);

    const handleRevokeOrg = useCallback(async () => {
        if (!validId || !organizationData) return;
        try {
            await wsSetter({ serial: 'socketToken' }, 'set', 'removeUserFromOrganization', {
                filters: {
                    user: user.id,
                    organization: orgId
                }
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
        }
        catch (err) {
            console.error(err);
        }
    }, [validId, organizationData, wsSetter, user.id, orgId, mutate]);


    return (
        <LayoutPaper>
            {organizationData
                ? (
                    organizationData?.name ?
                        <>
                            <b>Organization: {organizationData.name}</b>
                            <br />
                            {shared ? "You have shared your info with this organization." : "You have not shared your info with this organization."}
                            <br />
                            <Button variant="contained" disabled={shared} onClick={handleShareOrg}>Share</Button>
                            <br />
                            <Button variant="contained" disabled={!shared} onClick={handleRevokeOrg}>Revoke</Button>
                            <br />
                        </>
                        :
                        "Invalid url"
                )
                :
                !validId
                    ? "Invalid url"
                    :
                    "Loading"
            }

        </LayoutPaper>
    );
});

export default OrganizationShare;
