import { MainContext } from 'kerberus-components';
import { useContext, useMemo } from 'react';

export default function LastWeekOnlineHours({site}) {
    const { bulkData } = useContext(MainContext);

    const filteredData = bulkData.user.prev_online_minutes.filter(item => item.site_name === site)
    const weekData = useMemo(() => {
        return filteredData.filter(i => i.online_minutes > 0).map(i => {
            return {
                site_name: i.site_name,
                hours: Math.floor(i.online_minutes / 60),
                minutes: i.online_minutes % 60
            };
        });
    }, [filteredData]);
    return (
        weekData?.length > 0
            ?
                `${weekData[0].hours}h ${weekData[0].minutes}min  last week`
            :
            "0h 0min  last week"

    );
}