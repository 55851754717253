import React, { } from 'react';
import { IconButton, Avatar, Tooltip, Badge, Grid, useTheme } from '@mui/material';
import DeviceIcons from '../../Components/Icons/DeviceIcons';


export default React.memo(function DisplayDeviceIcons({ setDeviceType, handleClose, isSmall }) {
    const theme = useTheme();

    return (
        DeviceIcons.map((item, index) => {
            let tooltip = ""
            switch (index) {
                case 0:
                    tooltip = "Unknown"
                    break;
                case 1:
                    tooltip = "Android"
                    break;
                case 2:
                    tooltip = "Iphone"
                    break;
                case 3:
                    tooltip = "Android Tablet"
                    break;
                case 4:
                    tooltip = "Ipad"
                    break;
                case 5:
                    tooltip = "Laptop"
                    break;
                case 6:
                    tooltip = "Desktop"
                    break;
                case 7:
                    tooltip = "Tv"
                    break;
                case 8:
                    tooltip = "Headphones"
                    break;
                case 9:
                    tooltip = "Watch"
                    break;
                case 10:
                    tooltip = "Router"
                    break;
                case 11:
                    tooltip = "Iot Device"
                    break;
                default:
                    tooltip = "Iot Device"
            }

            return (
                <Grid item xs={isSmall ? 12 / 4 : 12 / 8} key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip title={tooltip} placement="top">
                        <IconButton onClick={() => { setDeviceType({ type: index, component: DeviceIcons[index] }); handleClose() }}>
                            <Badge
                                overlap='circular'
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            >
                                <Badge
                                    overlap='circular'
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                >
                                    <Avatar sx={{ bgcolor: theme.palette.primary.main + 50, color: theme.palette.primary.main, p: 1 }}>
                                        {item}
                                    </Avatar>
                                </Badge>
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </Grid>
            )
        }
        )
    )
})