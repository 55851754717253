import * as React from 'react';
import { Drawer as MuiDrawerComponent, Toolbar, Typography, Divider, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ListItems from './ListItems';

const drawerWidth = 240;

const MuiDrawer = styled(MuiDrawerComponent, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'normal',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(0),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default React.memo(function Drawer({ user, lastseenminutes, open }) {
    const theme = useTheme();

    return (
        <MuiDrawer variant="permanent" open={open}
            PaperProps={{ sx: { backgroundColor: theme.palette.darkBlue.main, height: "100%" } }}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: [1],
                    /*                                 backgroundColor: theme.palette.primary.main, 
                                                    border: "4px solid black" */
                }}
            >
                <Typography component={"div"} variant="h6" sx={{ fontSize: 22, color: '#fff' }}>Missing-Link</Typography>
                {/* This allows user to minimize drawer in desktop mode */}
                {/*<IconButton onClick={toggleDrawer} sx={{ fontSize: 22, color: '#fff' }}>
                <ChevronLeftIcon />
            </IconButton> */}
            </Toolbar>
            <Divider />
            <ListItems userId={user.id} drawerOpen={open} userdata={user} lastseenminutes={lastseenminutes} />
        </MuiDrawer>
    );
});