import {
    Box, Card, Modal, IconButton
} from '@mui/material';
import ActivityLog from '../ActivityLog';
import ClearIcon from '@mui/icons-material/Clear';


export default function SocialGroupActivityLogModal({ modal, groupId }) {

    const modalStyleMobile = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 1,
        pr: 6,
        borderRadius: 4,
        display: "flex",
        width: "90vw",
        height: "75%",
    };

    return (

        <Modal
            open={modal.activityLogModal}
            onClose={() => modal.setActivityLogModal(false)}
            aria-labelledby="img-modal-activity-title"
            aria-describedby="img-modal-activity-description"
        >
            <Card sx={modalStyleMobile}>
                <IconButton
                    size="medium"
                    onClick={() => modal.setActivityLogModal(false)}
                    sx={{ position: "absolute", right: 8, top: 8 }}
                >
                    <ClearIcon sx={{ color: '#000' }} />
                </IconButton>
                <Box sx={{ width: "100%", height: "100%" }}>
                    <ActivityLog groupId={groupId} />
                </Box>
            </Card>
        </Modal>
    );
}