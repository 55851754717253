import React, { useState, useEffect } from "react";
import { LayoutTemplate, LayoutTemplateMobile, useWsDataProvider } from 'kerberus-components';
import useSWR from 'swr';
import { useTheme, useMediaQuery } from "@mui/material";

export default function DashboardModalSocialGroup(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { data, getData, doUnsubscribe } = useWsDataProvider();
    const [groupChoices, setGroupChoices] = useState([]);
    const [currentGroup, setCurrentGroup] = useState(null);
    const [onlineData, setOnlineData] = useState(null);
    const [userList, setUserList] = useState([]);
    const { data: profileListData } = useSWR('users/profilelist/');
    const { data: socialGroupSelectData } = useSWR('users/socialgroupselect/');

    const [orientation, setOrientation] = useState(window.orientation);

    window.onresize = function (event) {
        applyOrientation();
    };

    function applyOrientation() {
        if (window.innerHeight > window.innerWidth) {
            setOrientation("portrait");
        } else {
            setOrientation("landscape");
        }
    }

    useEffect(() => {
        applyOrientation();
    }, []);

    useEffect(() => {
        if (profileListData) {
            setUserList(profileListData);
        }
    }, [profileListData]);


    useEffect(() => {
        if (userList.length > 0 && currentGroup?.id) {
            getData('socketLocation', 'lastFoundMany', {
                subscribe: true,
                filters: {
                    social_group: currentGroup.id
                }
            });
        }
        return () => {
            doUnsubscribe();
        };
    }, [userList, getData, doUnsubscribe, currentGroup]);

    useEffect(() => {
        if (data) {
            setOnlineData(data);
        }
    }, [data]);

    useEffect(() => {
        if (socialGroupSelectData) {
            setGroupChoices(socialGroupSelectData);
        }
    }, [socialGroupSelectData]);

    useEffect(() => {
        if (groupChoices) {
            setCurrentGroup(groupChoices.find(item => item.id === props.item));
        }
    }, [groupChoices, props.item]);

    return (
        isSmall
            ?
            <LayoutTemplateMobile
                orientation={orientation}
                onlineData={onlineData}
                users={currentGroup?.users}
                socialsData={currentGroup?.dashboard?.data?.socials}
                avatarSize={orientation === "portrait" ? 80 : 40}
            />
            :
            <LayoutTemplate
                onlineData={onlineData}
                users={currentGroup?.users}
                socialsData={currentGroup?.dashboard?.data?.socials}
                avatarSize="5vw"
                fontSize="1.00vw"
                gridFlexNumber={1.25}
                badgeSize="1.5vw"
                titleFontSize="0.65vw"
            />
    );
}