import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography, useMediaQuery, Tooltip, Divider, Box } from '@mui/material';
import { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useTheme } from '@mui/material/styles';
import { MainContext, useFetchCloud, SmallAvatarWrapper } from 'kerberus-components';
import TimePicker from '@mui/lab/TimePicker';
import moment from 'moment';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DayEnabledCheckbox from '../Components/DayEnabledCheckbox';
import LayoutPaper from '../Components/LayoutPaper';
import { useSWRConfig } from 'swr';
import ActiveTime from '../Components/Groups/ActiveTime';

export default function SocialGroupInvite(props) {
    const { fetchCloud } = useFetchCloud();
    const { user } = useContext(MainContext);
    const theme = useTheme();
    const { token } = useParams();
    const [validToken, setValidToken] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [duplicateGroup, setDuplicateGroup] = useState(false);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [publicDashboardCheck, setPublicDashboardCheck] = useState(true);
    const [publicSocialCheck, setPublicSocialCheck] = useState(true);
    const [publicCalendarCheck, setPublicCalendarCheck] = useState(false);
    const [startTime, setStartTime] = useState(moment().startOf('day'));
    const [endTime, setEndTime] = useState(moment().endOf('day'));
    const [daysEnabled, setDaysEnabled] = useState(new Set([1, 2, 3, 4, 5, 6, 7]));
    const [alwaysActive, setAlwaysActive] = useState(true);
    const { mutate } = useSWRConfig();

    const navigate = useNavigate();

    const getGroupInfo = useCallback((groupId) => {
        fetchCloud(`users/socialgroupinfo/${groupId}/`, 'get')
            .then((res) => {
                setGroupData(res);
            })
            .catch(console.error);
    }, [fetchCloud]);



    useLayoutEffect(() => {
        if (token) {
            try {
                var decoded = jwt_decode(token);
                var tokenTest = decoded.exp * 1000;
                if (new Date(tokenTest) > new Date()) {
                    if (user?.social_groups.map(item => item.id).includes(decoded.social_group)) {
                        setDuplicateGroup(true);
                        getGroupInfo(decoded.social_group);
                    }
                    else {
                        getGroupInfo(decoded.social_group);
                    }
                    setValidToken(true);
                }
                else setValidToken(false);
            }
            catch (e) {
                setValidToken(false);
            }
        }
    }, [token, user.social_groups, getGroupInfo]);

    const handleJoin = async () => {
        let startDiff = null;
        let endDiff = null;

        if (!alwaysActive) {
            let startUtc = moment.utc(startTime).set("second", 0);;
            let startUtcMid = startUtc.clone().startOf('day');
            startDiff = startUtc.diff(startUtcMid, 'seconds');
            let endUtc = moment.utc(endTime).set("second", 0);;
            let endUtcMid = endUtc.clone().startOf('day');
            endDiff = endUtc.diff(endUtcMid, 'seconds');
        }

        try {
            await fetchCloud('users/join_social', 'POST', {
                token: token,
                public_dashboard: publicDashboardCheck,
                public_social: publicSocialCheck,
                public_calendar: publicCalendarCheck,
                utc_start: !alwaysActive ? startDiff : 0,
                utc_end: !alwaysActive ? endDiff : 86400,
                enable_days: Array.from(daysEnabled).sort()
            });
            mutate(['users/timetableprofile/', localStorage.getItem('userId')]);
            navigate('/');
        }
        catch (err) {
            alert(err.data);
        }
    };

    useEffect(() => {
        if(duplicateGroup && groupData) {
            navigate(`/groups/${groupData?.id}`)
        }
    },[duplicateGroup, groupData, navigate]);

    return (
        <LayoutPaper>
            {validToken ?
                !duplicateGroup ?
                    groupData &&
                    <Grid container>
                        <Grid item xs={12} >
                            <Typography component={"div"} variant="h4">
                                Group Invitation
                            </Typography>
                            <Divider sx={{ marginY: 1 }} />
                            <Typography component={"div"} sx={{ mt: 2 }}>
                                You have been invited to join the social group <span style={{ fontWeight: "bold" }}>{groupData.name}</span>.

                                <Box sx={{ display: "flex", alignItems: "center"}}>
                                    <SmallAvatarWrapper alt="GroupPic" sx={{ width: 100, height: 100 }}
                                        userId={groupData.id} imageType="socialgrouppic" imageSize="_64" />
                                    <div style={isSmall ? { marginTop: "20px" } : { marginLeft: 20, display: "inline" }}>
                                        <ActiveTime socialGroup={groupData} />
                                    </div>
                                </Box>
                            </Typography>
                            <Typography component={"div"} sx={{ marginY: 2, fontSize: 18, border: "1px gray solid", padding: 1, borderRadius: 4 }}>
                                {groupData.description}
                            </Typography>
                            <Typography component={"div"} >
                                Select settings you want to join the group with.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={publicDashboardCheck} onChange={() => setPublicDashboardCheck(prev => !prev)} />}
                                    label="Allow profile usage in dashboards" />
                                <FormControlLabel
                                    control={<Checkbox checked={publicSocialCheck} onChange={() => setPublicSocialCheck(prev => !prev)} />}
                                    label="Allow profile usage in socials list" />
                                <FormControlLabel
                                    control={<Checkbox checked={publicCalendarCheck} onChange={() => setPublicCalendarCheck(prev => !prev)} />}
                                    label="Allow users in social group to view your calendar" />
                            </FormGroup>
                        </Grid>
{/*                         <Divider sx={{ marginY: 1, width: "100%" }} />
                        <Grid item xs={12}>
                            <Typography component={"div"} id="modal-modal-title" variant="h5" sx={{ my: 2, display: "flex", alignItems: "center" }}>
                                Social Links
                                <HelperIcon
                                    content="Select which social links you want to show in this group"
                                    style={{ marginLeft: 5, display: "flex", alignItems: "center" }}
                                />
                            </Typography>
                            <SocialIconsCheckbox />
                        </Grid> */}
                        <Divider sx={{ marginY: 1, width: "100%" }} />
                        <Grid item xs={12}>
                            <Typography component={"div"} id="modal-modal-title" variant="h5" sx={{ marginTop: 2 }}>
                                Visibility Hours
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Tooltip title="This choice only affects time">
                                        <Checkbox
                                            checked={alwaysActive}
                                            onChange={(e) => {
                                                setAlwaysActive(!alwaysActive);
                                            }}
                                        />
                                    </Tooltip>
                                } label="Always active" labelPlacement="top"
                            />
                            <Typography component={"div"} variant="h6">
                                Starting Time
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label="Start time"
                                    ampm={false}
                                    value={startTime}
                                    onChange={(e) => setStartTime(e)}
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={alwaysActive}
                                />
                            </LocalizationProvider>

                            <Typography component={"div"} variant="h6" sx={{ marginTop: 1 }}>
                                Ending Time
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label="End time"
                                    ampm={false}
                                    value={endTime}
                                    onChange={(e) => setEndTime(e)}
                                    renderInput={(params) => <TextField {...params} sx={{ marginBottom: 5 }} />}
                                    disabled={alwaysActive}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: -1 }}>
                            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((item, idx) => (
                                <DayEnabledCheckbox key={idx} list={daysEnabled} num={idx + 1} setList={setDaysEnabled} day={item} />
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={handleJoin} sx={{ marginLeft: 2, my: 2 }}>Join group</Button>
                        </Grid>
                    </Grid>
                    :
                    null
                :
                <Typography component={"div"} variant={isSmall ? "caption" : "overline"} sx={{ fontSize: 18, display: "flex", alignItems: "center", justifyContent: "center", color: "#E72D50" }}>
                    Link is invalid or expired.
                </Typography>
            }
        </LayoutPaper>
    );
};
