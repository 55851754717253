import React from 'react';
import {
    Typography, Box, IconButton, useTheme, useMediaQuery
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { SmallAvatarWrapper } from 'kerberus-components';
import { Circle } from '../../Components/Icons/Shapes';

export default React.memo(function SocialGroupHeader({ socialGroup, modal, count, isActive, isActiveWeekday }) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box sx={isSmall && { width: "90%" }}>
                <Typography component={"div"} variant="h4" textAlign="center" sx={{ color: theme.palette.primary.main, width: isSmall ? "100%" : undefined, display: "flex", alignItems: "center", justifyContent: "center", mb: isSmall && 2 }} >
                    <SmallAvatarWrapper alt="GroupPic" sx={{ width: 50, height: 50, marginRight: 2, "&:hover": { cursor: "pointer" } }} onClick={() => modal.setModalOpen(true)}
                        userId={socialGroup.id} imageType="socialgrouppic" imageSize="_64" />
                    {socialGroup.name}
                </Typography>
            </Box>

            {!isSmall &&
                <Typography component={"div"} variant="h4"
                    sx={{ color: theme.palette.primary.main, marginLeft: 2, display: "flex", alignItems: "center", justifyContent: "center", mt: 0.5 }}
                >
                    {count}/{socialGroup.users.length}
                </Typography>
            }
            {!isSmall &&
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => modal.setModalOpen(true)}
                    sx={isSmall ? { position: "relative", bottom: 30, right: 0 } : { paddingY: 1, marginBottom: 0.5, paddingX: 1 }}
                >
                    <InfoIcon color="primary" />
                </IconButton>
            }
            {(!isActive || !isActiveWeekday) &&
                <Typography component={"div"} variant="h5" color="error" textAlign="center" sx={{ marginLeft: !isSmall && 2 }}>
                    <Circle/>Not active right now<Circle/>
                </Typography>
            }
        </>
    );
});