import { Box, Button, Grid, Typography, Paper, Container } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Copyright from '../Components/Copyright';
import CssBaseline from '@mui/material/CssBaseline';
import MissingLinkLogo from '../Assets/Images/missingLinkLogo.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import jwt_decode from 'jwt-decode';
import AccountCreateStepper from '../Components/AccountCreate/AccountCreateStepper';

export default function AccountCreate(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { token } = useParams();
    const [validToken, setValidToken] = useState(null);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (token) {
            try {
                var tokenTest = jwt_decode(token).exp * 1000;
                if (new Date(tokenTest) > new Date()) {
                    setValidToken(true);
                }
                else setValidToken(false);
            }
            catch (e) {
                setValidToken(false);
            }
        }
    }, [token]);

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'repeat',
                width: '100%',
                height: "100%",
                position: "fixed",
                background: "linear-gradient(70deg, rgba(231,45,80,.3), rgba(231,45,80,0) 70.71%), linear-gradient(140deg, rgba(141,191,48,.3), rgba(141,191,48,0) 70.71%), linear-gradient(240deg, rgba(78,193,237,.3), rgba(78,193,237,0) 70.71%), linear-gradient(320deg, rgba(0,28,81,.3), rgba(0,28,81,0) 70.71%)"
            }}>
            {validToken !== null &&
                <Paper
                    elevation={8}
                    sx={{ height: isSmall ? "100vh" : "100%", width: isSmall ? "100%" : undefined, backgroundColor: "#ffffff", borderRadius: isSmall ? 0 : 10, padding: 2, margin: isSmall ? 0 : 5, overflow: isSmall && "auto" }}
                >
                    <Container component="main" maxWidth={false}>
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src={MissingLinkLogo} alt="MissingLinlogo" width={200} height={200} style={{}} />

                            {validToken
                                ?
                                <Box sx={{ marginTop: 3 }}>
                                    <AccountCreateStepper token={token} isSmall={isSmall}/>
                                </Box>
                                :
                                <Box sx={{ marginTop: 5 }}>
                                    <Typography component={"div"} variant={isSmall ? "caption" : "overline"} sx={{ fontSize: 18, display: "flex", alignItems: "center", justifyContent: "center", color: "#E72D50" }}>
                                        Link is invalid or expired.
                                    </Typography>
                                    <Button variant="contained" onClick={() => navigate('/login')} fullWidth sx={{ marginTop: 5 }}>
                                        Return to login
                                    </Button>
                                </Box>
                            }
                        </Box>

                        <Copyright style={{ mt: 4, mb: 2 }} />

                    </Container>

                </Paper>
            }
        </Grid>
    );
}