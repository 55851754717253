import React from 'react';
import { useEffect, useState } from 'react';
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import UserCardList from "./UserCardList";
import UserDatagrid from "./UserDatagrid";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SearchBar from './MyConnections/SearchBar';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default React.memo(function Userlist(props) {
    const [view, setView] = useState(props.default || "list");
    const handleViewChange = (event, nextView) => {
        setView(nextView);
    };
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(props.userList);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = props.userList.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] ? searchRegex.test(row[field].toString()) : null;
            });
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        setRows(props.userList);
    }, [props.userList]);

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ mt: -2, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                {!props.noCard &&
                    <ToggleButtonGroup
                        orientation="horizontal"
                        value={view}
                        exclusive
                        onChange={handleViewChange}
                        color="primary"
                        sx={{ mr: 2 }}
                    >
                        <ToggleButton value="list" aria-label="list" >
                            <ViewListIcon />
                        </ToggleButton>
                        <ToggleButton value="card" aria-label="card">
                            <ViewModuleIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                }
                <SearchBar
                    sx={{
                        width: {
                            xs: 1,
                            sm: 'auto',
                        },
                        '& .MuiSvgIcon-root': {
                            mr: 0.5,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                        my: 1
                    }}
                    value={searchText}
                    onChange={(event) => requestSearch(event.target.value)}
                    clearSearch={() => requestSearch('')}
                />
            </Box >
            {
                view === "list"
                    ?
                    <Box sx={{ width: "100%" }}>
                        <UserDatagrid {...props} searchText={searchText} requestSearch={requestSearch} rows={rows} handleViewChange={handleViewChange} view={view} />
                    </Box>
                    :
                    <Box sx={{ width: "100%" }}>
                        <UserCardList {...props} onlineData={props.onlineData} allGroups={props.allGroups} socialGroup={props.socialGroup} searchText={searchText} requestSearch={requestSearch} rows={rows} handleViewChange={handleViewChange} view={view} />
                    </Box>
            }
        </Box >
    );
});