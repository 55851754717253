import { useState, useEffect, useMemo, useCallback } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import 'moment/locale/en-gb';
import useMediaQuery from '@mui/material/useMediaQuery';
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarToolbar from '../../Components/CalendarToolbar';
import { useWsDataProvider, MaskedTextFieldWrapper } from 'kerberus-components';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button, Card } from '@mui/material';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import LayoutPaper from '../../Components/LayoutPaper';
import useSWR from 'swr';
import OnlineHours from '../Profile/OnlineHours';
import OfflineHours from '../Profile/OfflineHours';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#1C4E71',
    boxShadow: 24,
    p: 4,
    paddingBottom: 6
};

const localizer = momentLocalizer(moment);
const dayFormat = (date, culture, localizer) => localizer.format(date, 'D', culture);

function EventComponentday() {
    return <h5 style={{ marginTop: 2, height: "100%" }}>Active</h5>;

}

function EventComponentweek() {
    return <Typography component={"div"} sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>Active</Typography>;

}

export default function AdminCalendar({ devgroup, device }) {
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const [profiles, setProfiles] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [events, setEvents] = useState([]);
    const [calDate, setCalDate] = useState(new Date());
    const { getData, data: providerData } = useWsDataProvider();
    const { getData: userGetData, data: userProviderData } = useWsDataProvider();
    const [data, setData] = useState();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [calView, setCalView] = useState('day');
    const [macAddressInput, setMacAddressInput] = useState("");
    const [macAddress, setMacAddress] = useState("");
    const [userChoice, setUserChoice] = useState('');
    const [open, setOpen] = useState(false);
    const handleOpen = (e) => {
        setCurrentEvent(e);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const [currentEvent, setCurrentEvent] = useState();
    const handleTime = useMemo(() => {
        let curr = moment(calDate).toDate();
        let minus = moment(calDate).subtract(2, 'hours').toDate();
        return curr.getDate() === minus.getDate() ? minus : curr;

    }, [calDate]);
    const { data: profilelistSocialData } = useSWR('users/profilelistsocial/');

    const deviceHours = providerData?.map(item => item.online_data).flat().length * 30

    useEffect(() => {
        if (profilelistSocialData) {
            setProfiles(profilelistSocialData);
        }
    }, [profilelistSocialData]);

    useEffect(() => {
        if (macAddress && calDate) {
            getData('socketLocation', 'devtrackingAddresses', {
                filters: {
                    mac_array: macAddress, start_time: moment(calDate).startOf('week'), end_time: moment(calDate).endOf('week')
                }
            });
        }
    }, [calDate, getData, macAddress]);

    useEffect(() => {
        if (userChoice) {
            userGetData('socketLocation', 'devtrackingSuper', {
                filters: {
                    user_id: userChoice, start_time: moment(calDate).startOf('week'), end_time: moment(calDate).endOf('week')
                }
            });
        }
    }, [userChoice, userGetData, calDate]);

    useEffect(() => {
        if (providerData) {
            setData(providerData);
        }
    }, [providerData]);

    useEffect(() => {
        if (userProviderData) {
            setData(userProviderData);
        }
    }, [userProviderData]);

    const parseEvents = useCallback((data) => {
        const events = [];

        for (const site of data) {
            for (const item of site.online_data) {
                const date = new Date(item.time);

                if (events.length > 0 && events[events.length - 1].title !== "Hidden" && events[events.length - 1].title === site.site_name && ((events[events.length - 1].deviceSet.size === 1 && item.act.length === 1) || (events[events.length - 1].deviceSet.size > 1 && item.act.length > 1)) && events[events.length - 1].end.getTime() === date.getTime() && events[events.length - 1].start.getDay() === date.getDay()) {
                    events[events.length - 1].end = new Date(events[events.length - 1].end.getTime() + 30 * 60000);
                    events[events.length - 1].deviceSet = new Set([...events[events.length - 1].deviceSet, ...item.act]);
                    events[events.length - 1].title = events[events.length - 1].deviceSet.size;
                }
                else {
                    events.push({
                        title: site.site_name,
                        start: date,
                        end: new Date(date.getTime() + 30 * 60000),
                        deviceSet: new Set(item.act)
                    });
                }
            }
        }

        setEvents(events);
    }, []);

    useEffect(() => {
        if (data) {
            parseEvents(data);
        }
    }, [data, parseEvents]);


    const navigateFunction = (date) => {
        setCalDate(date);
    };

    useEffect(() => {
        isSmall ? setCalView('day') : setCalView('week');
    }, [isSmall]);

    return (
        <LayoutPaper>
            <Grid container sx={{ marginTop: -2, marginBottom: 1 }}>
                <Grid item xs={12} sm sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>

                    <MaskedTextFieldWrapper
                        id="DeviceSearch"
                        label="Mac Address"
                        variant="outlined"
                        helperText="Type Device MAC Address"
                        sx={{
                            marginRight: 1, width: 300,
                            '& .MuiFormHelperText-root': {
                                width: 250,
                            },
                        }}
                        value={macAddressInput}
                        onChange={(e) => setMacAddressInput(e)}
                    />
                    <Button onClick={() => setMacAddress([macAddressInput])} variant="contained" sx={{ marginLeft: isSmall ? 1 : 0, marginRight: 1, position: "relative", bottom: 12 }}>
                        Set
                    </Button>
                    <Grid container xs={12} sx={{ display: isMedium ? "none" : "flex", justifyContent: "space-evenly" }}>
                        <Grid item xs={"auto"}>
                            <Card elevation={ isMedium ? 0 : 1 } sx={{ p: 2 }}>
                                <Typography fontSize={ isMedium && "12px" } textAlign="center">
                                    Weekly online hours:
                                    <OnlineHours deviceHours={deviceHours} isMedium={isMedium}/>
                                </Typography> 
                            </Card>
                        </Grid>
                        <Grid item xs={"auto"}>
                            <Card elevation={ isMedium ? 0 : 1 } sx={{ p: 2 }}>
                                <Typography fontSize={ isMedium && "12px" } textAlign="center">
                                    Weekly offline hours:
                                    <OfflineHours deviceHours={deviceHours} isMedium={isMedium} />
                                </Typography> 
                            </Card>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={profiles}
                    getOptionLabel={option => option.username}
                    onChange={(e, newVal) => {
                        if (newVal?.id) {
                            setUserChoice(newVal.id);
                        }
                        else {
                            setUserChoice('');
                            parseEvents([]);
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={(e, newVal) => setInputValue(newVal)}
                    sx={
                        isSmall ?
                            {
                                width: "100%",
                                '& .MuiFormHelperText-root': {
                                    width: 250,
                                },
                                mt: 2,
                            }
                            :
                            {
                                ml: 1,
                                width: 300,
                                '& .MuiFormHelperText-root': {
                                    width: 250,
                                },
                            }
                    }
                    renderInput={(params) => <TextField {...params} label="Users" helperText="Devices By User" />}
                />
            </Grid>
            <Grid container sx={{ display: isMedium ? "flex" : "none" }}>
                <Grid item xs={6}>
                    <Card elevation={ isMedium ? 0 : 1 } sx={{ p: 2 }}>
                        <Typography fontSize={ isMedium && "12px" } textAlign="center">
                            Weekly online hours:
                            <OnlineHours deviceHours={deviceHours} isMedium={isMedium}/>
                        </Typography> 
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card elevation={isMedium ? 0 : 1} sx={{ p: 2 }}>
                        <Typography fontSize={ isMedium && "12px" } textAlign="center">
                            Weekly offline hours:
                            <OfflineHours deviceHours={deviceHours} isMedium={isMedium} />
                        </Typography> 
                    </Card>
                </Grid>
            </Grid> 
            {!isSmall &&
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                    {events.length === 0 && (macAddress.length > 0 || userChoice) &&
                        <Typography component={"div"} variant="h6"
                            sx={isSmall ? { color: theme.palette.warning.main, marginTop: 1, textAlign: "center", width: "100%" } : { color: theme.palette.warning.main }}>
                            No data this week!
                        </Typography>
                    }
                </Box>
            }
            {isSmall &&
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                    {events.length === 0 && (macAddress.length > 0 || userChoice) &&
                        <Typography component={"div"} variant="h6"
                            sx={isSmall ? { color: theme.palette.warning.main, marginTop: 1, textAlign: "center", width: "100%" } : { color: theme.palette.warning.main }}>
                            No data this week!
                        </Typography>
                    }
                </Box>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <Typography component={"div"} id="modal-modal-title" variant="h6" sx={{ textAlign: "center", marginTop: -2, marginBottom: 2, textDecoration: "underline" }}>
                        {currentEvent && currentEvent.title === "Hidden" ? "Hidden" : "Devices:"}
                    </Typography>
                    {currentEvent && [...currentEvent.deviceSet].map((item, idx) => {
                        return (
                            <Typography component={"div"} key={idx} id="modal-modal-title" variant="h6" sx={{ textAlign: "center" }}>
                                MAC: {item}
                            </Typography>
                        );
                    })

                    }
                    <Typography component={"div"} id="modal-modal-title" variant="h6" sx={{ textAlign: "center", position: "absolute", bottom: 0, right: 5 }}>
                        {currentEvent && moment.duration(moment(currentEvent.end).diff(moment(currentEvent.start))).asMinutes()} min
                    </Typography>
                    <Typography component={"div"} id="modal-modal-title" variant="h6" sx={{ textAlign: "center", position: "absolute", bottom: 0, left: 5 }}>
                        During:  {currentEvent && moment(currentEvent.start).format('H:mm')} - {currentEvent && moment(currentEvent.end).format('H:mm')}
                    </Typography>
                </Box>
            </Modal>
            <Calendar
                defaultDate={calDate}
                defaultView={calView}
                onView={(e) => setCalView(e)}
                view={calView}
                views={['day', 'week']}
                startAccessor="start"
                endAccessor={({ end }) => new Date(end.getTime() - 1)}
                events={events}
                tooltipAccessor={(e) => e.title === "Hidden" ? "Hidden" : `Devices: ${e.title}`}
                localizer={localizer}
                resizable
                scrollToTime={handleTime}
                onSelectEvent={handleOpen}
                timeslots={1}
                style={{
                    height: "72vh", width: "100%", marginTop: isSmall && 20
                }}
                formats={{
                    dayFormat,
                    eventTimeRangeFormat: (e) => { return ""; }
                }}
                components={{
                    toolbar: isSmall ? CalendarToolbar : undefined,
                    day: { event: EventComponentday },
                    week: { event: EventComponentweek }
                }}
                dayLayoutAlgorithm="no-overlap"
                onNavigate={navigateFunction}
                eventPropGetter={
                    (event, start, end, isSelected) => {
                        let newStyle = {
                            backgroundColor: event.deviceSet.size > 1 ? theme.palette.green.main : theme.palette.mlGreen.main,
                            /* color: isSmall ? event.title === 'Hidden' ? '#808080' : theme.palette.mlGreen.main : event.title === 'Hidden' ? '#808080' : '#FFFFFF', */
                            borderRadius: event.title !== 'Hidden' && 10,
                            border: "none",
                            textAlign: "center",
                            marginLeft: 5,
                            paddingLeft: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        };
                        return {
                            className: "",
                            style: newStyle
                        };
                    }
                }
            />
        </LayoutPaper >
    );
}
